import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceTileComponent } from './workspace-tile.component';
import { RaUiCardModule } from '@ra-web-tech-ui-toolkit/layout/card';
import { TranslateModule } from '@ngx-translate/core';
import { RaUiLoadingSpinnerModule } from '@ra-web-tech-ui-toolkit/indicators/loading-spinner';

@NgModule({
  declarations: [WorkspaceTileComponent],
  imports: [
    CommonModule,
    RaUiCardModule,
    RaUiLoadingSpinnerModule,
    TranslateModule,
  ],
  exports: [WorkspaceTileComponent],
})
export class WorkspaceTileModule {}
