<app-loading *ngIf="displayFullPageLoading"></app-loading>
<div *ngIf="!displayFullPageLoading">
  <div class="header-push-projects">
    <h1>{{ "menu.push.unknownProjects.title" | translate }}</h1>
    <p class="header-description">
      {{ "menu.push.unknownProjects.description" | translate }}
    </p>
  </div>

  <div class="flex-row">
    <app-menu-selector
      *ngIf="solutions"
      [inputId]="'solutions-selector'"
      [data]="solutions"
      [label]="'vaultBrowseComponent.selectASolution' | translate"
      [placeholderLabel]="'vaultBrowseComponent.findSolution' | translate"
      [noEntriesFoundLabel]="
        'vaultBrowseComponent.solutionNotFound' | translate
      "
      (selectionChanged)="onSolutionChanged($event)"
    >
    </app-menu-selector>
  </div>

  <div class="flex-row">
    <div class="explorer-summary">
      <div>
        <app-workstation-explorer
          *ngIf="workstationModel"
          [workstationModel]="workstationModel"
          (itemSelectedEmitter)="onSelectionUpdated($event)"
          (currentPathEmitter)="onFolderLocationUpdated($event)"
          (regionSelectedEmitter)="onRegionSelected($event)"
        >
        </app-workstation-explorer>
      </div>
      <div class="content-row">
        <div class="table-area">
          <app-loading *ngIf="displayLoading"></app-loading>
          <div class="warning-banner" *ngIf="displayWarningConflicts">
            <img
              class="warning-icon"
              src="assets/images/icons/warning-triangle-small-yellow.svg"
            />
            <p class="warning-message">
              {{ "menu.upload.project.conflict.message" | translate }}
            </p>
          </div>
          <app-design-hub-table
            *ngIf="displayTable && solutionSelected"
            [filterText]="'vaultBrowseComponent.filterByName' | translate"
            [showHeaderCheckbox]="true"
            [disableCheckboxIds]="disableItems"
            [checkboxToolTips]="checkboxToolTipItems"
            [tableData]="dataTable"
            [columns]="columns"
            (itemsSelected)="onItemSelectionChanged($event)"
          >
          </app-design-hub-table>
        </div>
      </div>
    </div>

    <div class="button-row">
      <div class="button-left">
        <div id="help-button" class="help-item" (click)="openPanel()">
          <i class="ra-icon-ide-md-info-filled help-icon"> </i>
          <span class="help-title">{{
            "appstream.dataStored" | translate
          }}</span>
        </div>
      </div>
      <div class="button-right">
        <app-secondary-button
          *ngIf="displayCancel"
          [id]="'cancel-button'"
          (clickEvent)="onCancel()"
          [label]="'backButton' | translate"
        >
        </app-secondary-button>

        <app-primary-button
          [id]="'push-button'"
          (clickEvent)="onContinue()"
          [label]="'pushButton' | translate"
          [isDisabled]="!completed"
        >
        </app-primary-button>
      </div>
    </div>
  </div>
</div>

<ng-template #infoRef>
  <app-info-sidebar [infoData]="infoSidebarData" (closePanel)="closePanel()">
  </app-info-sidebar>
</ng-template>
