<app-title-step
  [iconTitle]="'ra-icon-ide-md-push'"
  [labelTitle]="'pushToVault.btnPushToVault' | translate"
  [numberStep]="1"
  [subtitle]="'pushToVault.selectTheRegion' | translate"
>
</app-title-step>
<ra-ui-form class="workspace-form" [formGroup]="formGroup">
  <ra-ui-select
    id="select-region"
    [label]="'region' | translate"
    [errorMessages]="errorMessages"
    [items]="items"
    [(value)]="regionSelected"
    formControlName="region"
    (valueChange)="enableNextButton()"
  >
  </ra-ui-select>
</ra-ui-form>
