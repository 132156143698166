<div id="button">
  <button
    *ngIf="!isMatStepperNext"
    appDebounceClick
    mat-flat-button
    color="primary"
    id="{{ id }}"
    type="button"
    [style.width]="widthPx ? widthPx + 'px' : 'fit-content'"
    [disabled]="isDisabled"
    [debounceTime]="debounceTime"
    routerLink="routerLink"
    (debounceClick)="onClickButton($event)"
  >
    <mat-icon *ngIf="icon" class="{{ icon }}"></mat-icon>
    {{ label }}
  </button>
  <button
    *ngIf="isMatStepperNext"
    appDebounceClick
    mat-flat-button
    color="primary"
    id="{{ id }}"
    type="button"
    [style.width]="widthPx ? widthPx + 'px' : 'fit-content'"
    [disabled]="isDisabled"
    [debounceTime]="debounceTime"
    routerLink="routerLink"
    (debounceClick)="onClickButton($event)"
    matStepperNext
  >
    <mat-icon *ngIf="icon" class="{{ icon }}"></mat-icon>
    {{ label }}
  </button>
</div>
