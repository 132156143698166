import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { SidebarHeaderModule } from './sidebar-header/sidebar-header.module';
import { SidebarOptionsModule } from './sidebar-options/sidebar-options.module';
import { SidebarNoticeModule } from './sidebar-notice/sidebar-notice.module';
import { SidebarMeterModule } from './sidebar-meter/sidebar-meter.module';

@NgModule({
  declarations: [SidebarComponent],
  imports: [
    CommonModule,
    SidebarHeaderModule,
    SidebarOptionsModule,
    SidebarNoticeModule,
    SidebarMeterModule,
  ],
  exports: [SidebarComponent],
})
export class SidebarModule {}
