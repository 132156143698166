import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnknownProjectsComponent } from './unknown-projects.component';
import { TranslateModule } from '@ngx-translate/core';
import { RegionSelectorModule } from '../../../../shared/components/region-selector/region-selector.module';
import { SharedButtonModule } from '../../../../shared/components/buttons/shared-button.module';
import { LoadingModule } from '../../../../shared/components/loading/loading.module';
import { MenuSelectorModule } from '../../../../shared/components/menu-selector/menu-selector.module';
import { DesignHubTableModule } from '../../../design-hub-table/design-hub-table.module';
import { MaterialModule } from '../../../../material.module';
import { WorkstationExplorerModule } from '../../../../shared/components/workstation-explorer/workstation-explorer.module';
import { ProjectsSummaryModule } from '../../../projects-summary/projects-summary.module';
import { InfoSidebarModule } from '../../../../shared/components/info-sidebar/info-sidebar.module';

@NgModule({
  declarations: [UnknownProjectsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    DesignHubTableModule,
    LoadingModule,
    MaterialModule,
    RegionSelectorModule,
    SharedButtonModule,
    MenuSelectorModule,
    WorkstationExplorerModule,
    ProjectsSummaryModule,
    InfoSidebarModule,
  ],
  exports: [UnknownProjectsComponent],
})
export class UnknownProjectsModule {}
