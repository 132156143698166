import { createSelector } from '@ngrx/store';
import { TenantInfoState } from './tenant-info.reducer';
import { AppState } from '..';

export const selectTenantDataFeature = (state: AppState): TenantInfoState =>
  state.currentTenant;

export const selectTenantId = createSelector(
  selectTenantDataFeature,
  (state: TenantInfoState) => state.tenantInfo?.id
);

export const selectTenantServices = createSelector(
  selectTenantDataFeature,
  (state: TenantInfoState) => state.tenantInfo?.services
);

export const selectCurrentTenant = createSelector(
  selectTenantDataFeature,
  (state: TenantInfoState) => state.tenantInfo
);
