import { Region } from './twin-studio.model';

export const ipRegex =
  '^(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/(?:3[0-2]|[0-2]?[0-9])$';
export const portRegex =
  '^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$';

export const descriptionRegex =
  '^[a-zA-Z0-9\\s\\+\\-\\=\\.\\_\\:\\/\\@\\,\\;\\!\\&\\$\\*\\(\\)\\[\\]\\{\\}]*$';
export interface Instances {
  running: number;
  stopped: number;
  pending: number;
}

export interface WorkspaceData {
  id: string;
  name: string;
  region: Region;
  instances: Instances;
}

export interface GetWorkspacesResponse {
  status: string;
  data: WorkspaceData[];
}

export const workspacesDataMock: WorkspaceData[] = [
  {
    id: 'Workspace-1',
    name: 'WORKSPACE-ANZ-1',
    region: Region.America,
    instances: {
      running: 0,
      stopped: 0,
      pending: 0,
    },
  },
  {
    id: 'Workspace-2',
    name: 'WORKSPACE-ANZ-2',
    region: Region.America,
    instances: {
      running: 1,
      stopped: 1,
      pending: 0,
    },
  },
  {
    id: 'Workspace-3',
    name: 'WORKSPACE-ANZ-3',
    region: Region.America,
    instances: {
      running: 2,
      stopped: 2,
      pending: 0,
    },
  },
  {
    id: 'Workspace-4',
    name: 'WORKSPACE-ANZ-4',
    region: Region.America,
    instances: {
      running: 3,
      stopped: 3,
      pending: 1,
    },
  },
  {
    id: 'Workspace-5',
    name: 'WORKSPACE-ANZ-5',
    region: Region.America,
    instances: {
      running: 4,
      stopped: 0,
      pending: 2,
    },
  },
];
