import { Component, inject } from '@angular/core';
import { SidenavService } from '../../../core/services/sidenav.service';
import { SidebarData } from '../../../core/models/sidebar-data';

@Component({
  selector: 'app-data-stored-info',
  templateUrl: './data-stored-info.component.html',
  styleUrls: ['./data-stored-info.component.scss'],
})
export class DataStoredInfoComponent {
  sidenavService = inject(SidenavService);

  infoSidebarData: SidebarData = {
    title: 'help',
    icon: 'assets/images/twin-studio-logo.svg',
    subtitle: 'appstream.dataStored',
    description: 'appstream.info.FThub',
    descFeatures: 'appstream.info.capabilities',
    features: [
      {
        icon: 'ra-icon-ide-lg-notifications',
        description: 'appstream.info.mitigate',
      },
      { icon: 'ra-icon-ide-md-cloud', description: 'appstream.info.backup' },
      {
        icon: 'ra-icon-ide-md-cloud',
        description: 'appstream.info.visibility',
      },
    ],
  };

  onCloseSidenav(): void {
    this.sidenavService.close();
  }
}
