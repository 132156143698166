import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkstationFilesComponent } from './workstation-files.component';
import { TranslateModule } from '@ngx-translate/core';
import { RaUiLoadingSpinnerModule } from '@ra-web-tech-ui-toolkit/indicators/loading-spinner';
import { AgGridModule } from '@ag-grid-community/angular';

@NgModule({
  declarations: [WorkstationFilesComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RaUiLoadingSpinnerModule,
    AgGridModule,
  ],
  exports: [WorkstationFilesComponent],
})
export class WorkstationFilesModule {}
