import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StorageService implements OnDestroy {
  private onSubject = new Subject<{ key: string; value: string }>();
  public changes = this.onSubject.asObservable().pipe(share());

  ngOnDestroy() {
    this.stop();
  }

  public start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }
  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage ) {
      let cookieValue;
      let eventKey;
      cookieValue = event.newValue ? event.newValue : '';
      eventKey = event.key ? event.key : '';
      this.onSubject.next({ key: eventKey, value: cookieValue });
    }
  }

  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
    this.onSubject.complete();
  }
}
