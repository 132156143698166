import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TenantInfo } from '../../../../core/models/cs/tenant-info.model';
import {
  CreateAndSyncProjectsPayload,
  FileData,
  FilesToAddNewVersion,
  NewProject,
  PushChangesPayload,
  Region,
  Session,
} from '../../../../core/models/twin-studio.model';
import {
  Column,
  FileViewForDisplay,
  isArrayOfFileViewForDisplay,
  ProjectTableHelper,
  ProjectViewForDisplay,
  validateCommmitMessages,
  validateProjectName,
} from '../../../../shared/utility/project-table-helper';
import {
  CheckboxToolTip,
  DataWithId,
} from 'src/app/core/models/table-templates.model';
import { Solution } from '../../../../core/models/vault/solution.model';
import {
  FolderMenuSelectorItem,
  MenuSelectorItem,
  SolutionMenuSelectorItem,
} from '../../../../core/models/menu-selector.model';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { WorkstationExplorer } from '../../../../core/models/workstation-explorer.model';
import { ProjectData } from '../../../../core/models/vault/project.model';
import { map } from 'rxjs/operators';
import { ProjectService } from '../../../../core/services/vault/project.service';
import { SolutionService } from '../../../../core/services/vault/solution.service';
import { FileHelper } from '../../../../shared/utility/file.helper';
import { PushService } from '../../../../core/services/push/push.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '../../../../core/services/snackbar/snackbar.service';
import { MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { SidebarData } from '../../../../core/models/sidebar-data';
import { SidePanelService } from '../../../../core/services/side-panel/side-panel.service';

export const infoSidebarData: SidebarData = {
  title: 'help',
  icon: 'assets/images/twin-studio-logo.svg',
  subtitle: 'appstream.dataStored',
  description: 'appstream.info.FThub',
  descFeatures: 'appstream.info.capabilities',
  features: [
    {
      icon: 'ra-icon-ide-lg-notifications',
      description: 'appstream.info.mitigate',
    },
    { icon: 'ra-icon-ide-md-cloud', description: 'appstream.info.backup' },
    {
      icon: 'ra-icon-ide-md-cloud',
      description: 'appstream.info.visibility',
    },
  ],
};

@Component({
  selector: 'app-unknown-projects',
  templateUrl: './unknown-projects.component.html',
  styleUrls: ['./unknown-projects.component.scss'],
})
export class UnknownProjectsComponent implements OnInit, OnChanges, OnDestroy {
  private readonly destroy: Subject<void> = new Subject<void>();
  @Input() currentTenant!: TenantInfo;
  @Input() sessionData?: Session;
  @Output() cancelEmitter = new EventEmitter<boolean>();
  @Input() displayCancel = true;

  solutions: SolutionMenuSelectorItem[] = [];

  columns: Array<Column> = [];
  disableItems: Array<DataWithId> = [];
  checkboxToolTipItems: Array<CheckboxToolTip> = [];
  projects!: ProjectViewForDisplay[];
  solutionSelected: Solution;
  displayLoading = false;
  displayFullPageLoading = false;
  displayTable = false;
  currentFolder: string;
  selectedRegion: Region;
  folders: FolderMenuSelectorItem[] = [];
  workstationModel: WorkstationExplorer | undefined;
  selectedItems: string[];
  historySelectedItems: string[] = [];
  displayWarningConflicts = false;
  vaultId: string;
  filesFromFolders: FileData[] = [];
  displayLoadingTable = false;
  listProjects: ProjectData[] = [];
  dataTable: FileViewForDisplay[] = [];
  projectsSelected: FileViewForDisplay[] = [];
  snackBar?: MatSnackBarRef<TextOnlySnackBar>;
  infoSidebarData: SidebarData = infoSidebarData;
  completed: boolean = false;

  @ViewChild('infoRef', { static: true, read: TemplateRef })
  private readonly infoRef: TemplateRef<any> | undefined;

  constructor(
    private readonly projectService: ProjectService,
    private readonly solutionService: SolutionService,
    private readonly pushService: PushService,
    private readonly translateService: TranslateService,
    private readonly snackbarService: SnackbarService,
    private readonly sidePanelService: SidePanelService
  ) {}

  ngOnInit(): void {
    this.setColumns();
    this.setInitLocation();
    this.setSolutions();
  }

  private setColumns(): void {
    this.columns = ProjectTableHelper.getUnknownProjectsColumns();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentTenant || changes.sessionData) {
      this.setSolutions();
      this.setInitLocation();
    }
  }

  setSolutions(): Subscription | null {
    if (this.currentTenant) {
      return this.solutionService
        .getSolutions(this.currentTenant.id)
        .pipe(
          map((solutions: Solution[]) => {
            this.solutions = solutions.map((solution) => {
              return {
                displayText: solution.name,
                solution: solution,
              };
            });
          })
        )
        .subscribe();
    }
    return null;
  }

  loadProjectsFromSolution(): void {
    this.projectService
      .getProjectList(this.solutionSelected.solutionId, this.currentTenant.id)
      .pipe(
        map((projects) => {
          this.listProjects = projects;
        }),
        takeUntil(this.destroy.asObservable())
      )
      .subscribe();
  }

  onSolutionChanged(item: MenuSelectorItem): void {
    this.solutionSelected = undefined;
    this.displayTable = false;
    this.historySelectedItems = [];
    this.filesFromFolders = [];
    this.setInitLocation();
    const selectedSolution: SolutionMenuSelectorItem =
      item as SolutionMenuSelectorItem;
    this.solutionSelected = selectedSolution.solution;
    this.loadProjectsFromSolution();
  }

  setInitLocation(): void {
    if (this.sessionData) {
      this.vaultId = this.sessionData.vaultId;
      this.selectedRegion = this.sessionData.region;
    } else if (this.currentTenant) {
      this.vaultId = this.currentTenant.id;
    }
    this.workstationModel = {
      currentTenant: this.currentTenant,
      sessionData: this.sessionData,
      initLocation: ['vault'],
      excludeFolderSolutions: true,
      onlyFolders: false,
    };
  }

  onRegionSelected(region: Region): void {
    this.selectedRegion = region;
  }

  onFolderLocationUpdated(currentPath: string): void {
    this.currentFolder = currentPath;
  }

  onSelectionUpdated(selectedItems: string[]): void {
    this.selectedItems = selectedItems.length === 0 ? undefined : selectedItems;
    if (this.selectedItems === undefined) {
      this.displayTable = false;
      this.dataTable = [];
      this.filesFromFolders = [];
      this.historySelectedItems = [];
    }
    if (this.selectedItems?.length > 0) {
      this.displayTable = true;
      this.organizeDataTable(this.selectedItems);
    }
  }

  organizeDataTable(listFiles: string[]): void {
    this.clearFileHistory(listFiles);
    const newData = this.convertPathToFileView(listFiles);
    this.dataTable = ProjectTableHelper.getFilesView(
      [...this.filesFromFolders, ...newData],
      this.listProjects
    );
    this.filesFromFolders = [...this.filesFromFolders, ...newData];
    this.displayLoadingTable = false;
    this.displayTable = true;
  }

  clearFileHistory(listFilePaths: string[]): void {
    if (listFilePaths.length < this.historySelectedItems.length) {
      this.historySelectedItems = this.historySelectedItems.filter((item) =>
        listFilePaths.includes(item)
      );
      const fileNames = this.historySelectedItems.map((filePath) =>
        filePath.split(/[\\/]/).pop()
      );
      this.filesFromFolders = this.filesFromFolders.filter((item) =>
        fileNames.includes(item.fileName)
      );
    }
  }

  convertPathToFileView(listPathFiles: string[]): FileData[] {
    const newData: FileData[] = [];
    listPathFiles.forEach((pathFile): void => {
      const alreadySelected = this.historySelectedItems.find(
        (x) => x === pathFile
      );
      if (!alreadySelected) {
        //add new row
        const fileName = pathFile.split(/[\\/]/).pop();
        const newRow: FileData = {
          fileName: fileName,
          projectName: '',
          description: '',
          commitMessage: '',
          filePath: pathFile,
          solutionId: this.solutionSelected.solutionId,
          solutionName: this.solutionSelected.name,
          projectId: undefined,
        };
        this.historySelectedItems.push(pathFile);
        newData.push(newRow);
      }
    });
    return newData;
  }

  onItemSelectionChanged(projects: unknown): void {
    if (isArrayOfFileViewForDisplay(projects)) {
      this.completed =
        validateCommmitMessages(projects) && validateProjectName(projects);
      this.projectsSelected = projects;
    }
  }

  pushProjects(): void {
    const newProjectsToCreate = this.projectsSelected.filter(function (x) {
      return x.file.projectId === undefined || x.file.projectId === null;
    });
    const projectFiles = newProjectsToCreate.map((p) =>
      FileHelper.convertUnknownProjectToNew(p.file)
    );
    if (projectFiles.length > 0) {
      this.createNewProjects(projectFiles);
    }

    const projectsToAddVersion = this.projectsSelected.filter(function (x) {
      return !(x.file.projectId === undefined || x.file.projectId === null);
    });
    const projects = projectsToAddVersion.map((p) =>
      FileHelper.convertUnknownProjectToAddVersion(p.file)
    );
    if (projects.length > 0) {
      this.addNewVersionToProjects(projects);
    }
    this.displayTable = false;
    this.projectsSelected = [];
    this.completed = false;
  }

  createNewProjects(projectFiles: NewProject[]): void {
    this.displayFullPageLoading = true;
    const createProjectsPayload: CreateAndSyncProjectsPayload = {
      projectsToCreate: projectFiles,
      region: this.selectedRegion,
    };
    this.pushService
      .createAndSyncProjects(this.vaultId, createProjectsPayload)
      .subscribe({
        next: (response) => {
          this.displayFullPageLoading = false;
          //remove projects from projects-summary
          this.projectsSelected = this.projectsSelected.filter(
            (item) =>
              !projectFiles.some(
                (otherItem) => item.file.filePath === otherItem.filePath
              )
          );

          const msg = this.translateService.instant(
            'menu.upload.projectsUploaded'
          );
          this.snackBar = this.snackbarService.openSnackBarWithAction(
            msg,
            undefined
          );
        },
        error: (error: unknown) => {
          this.showError();
        },
      });
  }

  addNewVersionToProjects(projectsToAddVersion: FilesToAddNewVersion[]): void {
    this.displayFullPageLoading = true;
    const projectsToAddVersionPayload: PushChangesPayload = {
      projectsNewVersion: projectsToAddVersion,
      region: this.selectedRegion,
    };
    this.pushService
      .pushChanges(this.vaultId, projectsToAddVersionPayload)
      .subscribe({
        next: (response) => {
          this.displayFullPageLoading = false;
          //remove projects from projects-summary
          this.projectsSelected = this.projectsSelected.filter(
            (item) =>
              !projectsToAddVersion.some(
                (otherItem) => item.file.filePath === otherItem.filePath
              )
          );

          const msg = this.translateService.instant(
            'menu.upload.projectsUploaded'
          );
          this.snackBar = this.snackbarService.openSnackBarWithAction(
            msg,
            undefined
          );
        },
        error: (error: unknown) => {
          this.showError();
        },
      });
  }

  showError(): void {
    this.displayFullPageLoading = false;
    const msg = this.translateService.instant('appstream.errorMessageProcess');
    this.snackBar = this.snackbarService.openSnackBarWithAction(msg, undefined);
  }

  onCancel(): void {
    this.cancelEmitter.emit(true);
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

  onContinue(): void {
    if (this.projectsSelected.length > 0) {
      this.pushProjects();
    }
  }

  openPanel(): void {
    if (this.infoRef) {
      this.sidePanelService.open(this.infoRef);
    }
  }

  closePanel(): void {
    this.sidePanelService.close();
  }
}
