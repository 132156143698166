import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PushService } from '../../../core/services/push/push.service';
import { map, Subscription } from 'rxjs';
import { Region, Session } from '../../../core/models/twin-studio.model';
import {
  WorkspaceContentPayload,
  WorkstationExplorer,
} from '../../../core/models/workstation-explorer.model';
import { TenantId } from '../../../core/models/cs/tenant-info.model';
import { getImagePathForUnknownProject } from '../../../core/services/vault/view-status/project-file-utility';

export const rootFolder = 'Home Folder';
export const vaultFolder = 'vault';

@Component({
  selector: 'app-workstation-explorer',
  templateUrl: './workstation-explorer.component.html',
  styleUrls: ['./workstation-explorer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkstationExplorerComponent implements OnInit, OnChanges {
  @Input() workstationModel: WorkstationExplorer;
  @Output() itemSelectedEmitter = new EventEmitter<string[]>();
  @Output() currentPathEmitter = new EventEmitter<string>();
  @Output() regionSelectedEmitter = new EventEmitter<Region>();

  loadingContent: boolean = false;

  currentPath: string;
  currentDisplayedItems: string[];
  filterItems: string[];
  selectedRegion?: Region;
  tenantId: TenantId;
  selectedItems: string[] = [];
  breadcrumb: string[];

  constructor(private readonly pushService: PushService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.workstationModel) {
      this.selectedItems = [];
      this.filterItems = undefined;
      this.currentPath = `${this.workstationModel.initLocation.join('/')}/`;
      this.breadcrumb = this.workstationModel.initLocation;
      this.breadcrumb.unshift(rootFolder);
      if (this.workstationModel?.sessionData) {
        this.setInitLocation(this.workstationModel.sessionData);
      } else if (this.workstationModel.currentTenant) {
        this.tenantId = this.workstationModel?.currentTenant.id;
        this.selectedRegion = undefined;
      }
    }
  }

  setInitLocation(sessionData: Session): Subscription {
    this.tenantId = sessionData.tenantId;
    this.selectedRegion = sessionData.region;
    const payload = this.setPayload(sessionData.region);
    return this.listWorkspaceContent(payload);
  }

  onRegionSelected(region: Region): Subscription {
    const payload = this.setPayload(region);
    this.regionSelectedEmitter.emit(region);
    return this.listWorkspaceContent(payload);
  }

  setPayload(region: Region): WorkspaceContentPayload {
    this.selectedRegion = region;
    return {
      path: this.currentPath,
      region: region,
      onlyFolders: this.workstationModel.onlyFolders,
    };
  }

  onSelectingCrumb(item: string, index: number): Subscription | boolean {
    if (index === this.breadcrumb.length) {
      return null;
    } else {
      if (index === 0) {
        this.breadcrumb = [rootFolder];
        this.currentPath = '';
      } else {
        const position = index + 1;
        this.breadcrumb = this.breadcrumb.slice(0, position);
        this.currentPath =
          this.breadcrumb.join('/').replace(`${rootFolder}/`, '') + '/';
      }
      const payload = this.setPayload(this.selectedRegion);
      return this.listWorkspaceContent(payload);
    }
  }

  isItemDisabled(item: string): boolean {
    return (
      this.selectedItems.length > 0 &&
      this.workstationModel.onlyFolders &&
      !this.selectedItems.find((x) => x.includes(item))
    );
  }

  onFilteredData(filteredData: string[]): void {
    this.filterItems = filteredData;
  }

  onOpenFolder(item: string): Subscription {
    this.breadcrumb.push(item);
    this.currentPath = this.currentPath.concat(item) + '/';
    const payload = this.setPayload(this.selectedRegion);
    return this.listWorkspaceContent(payload);
  }

  onItemSelected(item: string): void {
    const itemLocation = this.currentPath.concat(item);
    if (this.selectedItems.find((x) => x === itemLocation)) {
      this.selectedItems = this.selectedItems.filter((x) => x !== itemLocation);
    } else {
      this.selectedItems.push(itemLocation);
    }
    this.itemSelectedEmitter.emit(this.selectedItems);
  }

  listWorkspaceContent(payload: WorkspaceContentPayload): Subscription {
    this.loadingContent = true;
    return this.pushService
      .listWorkspaceContent(this.tenantId, payload)
      .pipe(
        map((sources: string[]) => {
          if (
            this.workstationModel.excludeFolderSolutions &&
            this.currentPath === `${vaultFolder}/`
          ) {
            this.currentDisplayedItems = sources.filter(
              (x) => !x.includes('solutions/')
            );
          } else if (this.currentPath === '') {
            this.currentDisplayedItems = sources.filter((x) => x !== '.vault/');
          } else {
            this.currentDisplayedItems = sources;
          }
          this.currentPathEmitter.emit(this.currentPath);
          this.loadingContent = false;
        })
      )
      .subscribe();
  }

  getImageForItem(filenName: string): string {
    return getImagePathForUnknownProject(filenName);
  }

  isItemSelected(item: string): boolean {
    const itemLocation = this.currentPath.concat(item);
    return this.selectedItems.find((x) => x === itemLocation) !== undefined;
  }
}
