import { createReducer, on } from '@ngrx/store';
import { LocalizationActions } from './action-types';

export interface LocalizationState {
  lang: string;
  availableLangs: string[];
}

export const initialState: LocalizationState = {
  // Deutsch translations are not yet available. Once the translations
  // are ready to go 'de' can be added to the available list as
  // ['en', 'es', 'de']
  availableLangs: ['en', 'es'],
  lang: 'en',
};

export const localizationReducer = createReducer(
  initialState,

  on(
    LocalizationActions.appRefreshed,
    LocalizationActions.switchLang,
    (state, action) => {
      return {
        availableLangs: state.availableLangs,
        lang: action.lang,
      };
    }
  )
);
