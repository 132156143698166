import { TwinStudioConfiguration } from '../app/core/services/config/config.service';
import { EnvironmentNames } from '../app/shared/utility/app-utility';

const csApiBaseUrl = 'https://home.lemans-sandbox.rockwellautomation.com';

const appConfiguration: TwinStudioConfiguration = {
  production: false,
  name: EnvironmentNames.Sandbox,
  csApiBaseUrl: csApiBaseUrl,
  ftHubConnection: 'MyRockwellSaml',
  csPortalUrl: 'https://home.lemans-sandbox.rockwellautomation.com/',
  notificationsBaseUrl:
    'https://api-notifications.lemans-sandbox.rockwellautomation.com',
  authConfig: {
    domain: 'factorytalkhub-dev.us.auth0.com',
    clientId: 'DkK4RdMPz2yDF37iujo6UCrpUeK66Doq',
    audience: 'https://lemans.notifications',
    redirectUri:
      'https://twinstudio.lemans-sandbox.rockwellautomation.com/callback',
    scope: 'twinstudio',
    httpInterceptor: {
      // note that interceptor for the CS API is automatically configured by CommonHeader component.
      allowedList: [
        {
          uri: 'https://api.lemans-sandbox.rockwellautomation.com/*',
          tokenOptions: {
            audience: 'https://lemans.lcore',
          },
        },
      ],
    },
  },
  returnToAfterLogout: `${csApiBaseUrl}/logout`,
  appBaseUrl: 'https://twinstudio.lemans-sandbox.rockwellautomation.com',
  lCoreApiBaseUrl: 'https://api.lemans-sandbox.rockwellautomation.com',
  notificationApiUrl:
    'https://api-notifications.lemans-sandbox.rockwellautomation.com',
  onlineHelp:
    'https://www.rockwellautomation.com/docs/en/factorytalk-twin-studio/current/factorytalk-twin-studio-ditamap.html',
  gettingStarted:
    'https://www.rockwellautomation.com/docs/en/factorytalk-twin-studio/current/factorytalk-twin-studio-ditamap/getting-started.html',
  releaseNotes:
    'https://www.rockwellautomation.com/docs/en/factorytalk-twin-studio/current/factorytalk-twin-studio-ditamap/factorytalk-twin-studio-release-notes.html',
  contactSupport: 'https://rockwellautomation.custhelp.com/app/get_support',
  privacyPolicy:
    'https://www.rockwellautomation.com/en-us/company/about-us/legal-notices/privacy-and-cookiespolicy.html',
  legal:
    'https://www.rockwellautomation.com/en-us/company/about-us/legal-notices.html',
  termsOfUse:
    'https://www.rockwellautomation.com/en-us/company/about-us/legal-notices/terms-and-conditionsofaccess.html',
  licenseAgreement:
    'https://www.rockwellautomation.com/en-us/company/about-us/legal-notices/end-user-license-agreement.html',
  factoryTalkHubBaseUrl: 'https://home.lemans-sandbox.rockwellautomation.com',
  auth0ConnectionType: '',
  harnessSDK: '76c9a00f-4dd6-4bd6-a160-b311fb539cfc',
  logixLarge: '3',
  logixLargeCpu: '8',
  logixLargeMemory: '32',
  arenaLarge: '6',
  arenaLargeCpu: '8',
  arenaLargeMemory: '32',
  arenaMedium: '5',
  arenaMediumCpu: '4',
  arenaMediumMemory: '16',
  arenaSmall: '4',
  arenaSmallCpu: '2',
  arenaSmallMemory: '8',
  emulateLarge: '10',
  emulateLargeCpu: '64',
  emulateLargeMemory: '256',
  emulateMedium: '8',
  emulateMediumCpu: '32',
  emulateMediumMemory: '128',
  emulateSmall: '6',
  emulateSmallCpu: '8',
  emulateSmallMemory: '32',
  ftraSmall: '1',
  ftraSmallCpu: '1',
  ftraSmallMemory: '2',
};

export const environment = {
  production: false,
  config: appConfiguration,
};
