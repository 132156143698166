<div class="data-container">
  <div data-ng-app>
    <div
      data-ng-controller="progress-bar-controller"
      class="progress-bar-controller"
    >
      <div class="progress-bar-container">
        <div
          class="progress-bar"
          [ngStyle]="{ 'width.%': progress | json }"
        ></div>
      </div>
    </div>
  </div>

  <div
    *ngIf="uploadCompleted"
    id="upload-complete-process"
    class="upload-complete-process"
  >
    <img
      class="img_complete"
      id="img_complete"
      src="../../../assets/images/icons/complete-green-checkbox-circle.svg"
    />
  </div>
</div>
