import { Component, OnInit } from '@angular/core';
import {
  KindFromTenantServices,
  kindFromCsToSessionKind,
} from '../../core/models/twin-studio.model';
import {
  ApplicationCard,
  ApplicationCardEvent,
} from '../../core/models/application-card';
import { Service, TenantInfo } from '../../core/models/cs/tenant-info.model';
import { ApplicationCardAction } from '../dashboard/application-card/application-card.component';
import { TranslateService } from '@ngx-translate/core';
import { TenantInfoFacade } from '../../core/reducers/tenant-info/tenant-info.facade';
import { SessionFacade } from '../../core/reducers/session/session.facade';
import { tap } from 'rxjs';
import * as _ from 'lodash';

export const designHub = 'Design Hub';
export const applications: ApplicationCard[] = [
  {
    kind: KindFromTenantServices.Emulate3d,
    id: 'emulate3d',
    name: 'Emulate 3D',
    hub: designHub,
    logo: 'emulate-3d-final-logo.svg',
    bannerArtwork: 'BannerEmulate3D600x100.svg',
    isActive: false,
  },
  {
    kind: KindFromTenantServices.Arena,
    id: 'arena',
    name: 'Arena',
    hub: designHub,
    logo: 'arena-final-logo.svg',
    bannerArtwork: 'BannerArena600x100.svg',
    isActive: false,
  },
  {
    kind: KindFromTenantServices.Logix,
    name: 'Studio 5000 Logix Designer™',
    id: 'Logix5000',
    hub: designHub,
    logo: 'logix-designer-final-logo.svg',
    bannerArtwork: 'BannerStudio600x100.svg',
    isActive: false,
  },
];

@Component({
  selector: 'app-quick-access',
  templateUrl: './quick-access.component.html',
  styleUrls: ['./quick-access.component.scss'],
})
export class QuickAccessComponent implements OnInit {
  currentTenant: TenantInfo;
  servicesAvailable!: Service[];
  visibleApps!: ApplicationCard[];
  cardAction = ApplicationCardAction;
  filteredData: any;
  showOnboardingFrame: boolean = true;
  hasCredits = false;
  apps = applications;

  tenantServices$ = this.tenantInfoFacade.currentTenant$.pipe(
    tap((currentTenant) => {
      this.currentTenant = currentTenant;
      this.servicesAvailable = currentTenant.services;
      this.checkServices();
    })
  );
  constructor(
    private readonly translateService: TranslateService,
    private readonly tenantInfoFacade: TenantInfoFacade,
    private readonly sessionFacade: SessionFacade
  ) {}

  ngOnInit(): void {}

  checkServices(): void {
    this.visibleApps = [];

    applications.forEach((application) => {
      const applicationFound = this.servicesAvailable.find(
        (x) => x.kind === application.kind
      );
      application.isActive = !!applicationFound;

      application.description = this.translateService.instant(
        `appDescriptions.${application.kind}`
      );
      this.visibleApps.push(application);
    });
    this.hasCredits = !!applications.find((x) => x.isActive);
    this.apps = _.cloneDeep(applications);
  }

  cardClicked(event: ApplicationCardEvent): void {
    if (event.card.isActive) {
      switch (event.action) {
        case this.cardAction.LOGO:
          this.launchApp(event.card);
          break;
        case this.cardAction.INFO:
          break;
        default:
          break;
      }
    }
  }

  launchApp(card: ApplicationCard): void {
    const sessionKind = kindFromCsToSessionKind.get(card.kind);
    const preloadSessionData = {
      tenantId: this.currentTenant.id,
      tenantName: this.currentTenant.name,
      sessionKind,
      files: [],
      previousPath: '',
      openNewTab: true,
    };

    this.sessionFacade.preloadBrowseSelection(preloadSessionData);
  }
}
