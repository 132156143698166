import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  AnimationType,
  LoadingSpinnerDefinedSize,
  LoadingSpinnerSize,
} from '@ra-web-tech-ui-toolkit/indicators/loading-spinner';
import { PushService } from '../../../core/services/push/push.service';
import { Subject, lastValueFrom } from 'rxjs';
import {
  Region,
  WorkstationFiles,
} from '../../../core/models/twin-studio.model';
import { WorkspaceService } from '../../../core/services/workspace/workspace.service';
import { GridOptions, ICellRendererParams } from '@ag-grid-community/core';
import { TranslateService } from '@ngx-translate/core';
import { RecentFilesTable } from '../../../core/models/table-templates.model';
import { getImagePathForUnknownProject } from '../../../core/services/vault/view-status/project-file-utility';

@Component({
  selector: 'app-workstation-files',
  templateUrl: './workstation-files.component.html',
  styleUrls: ['./workstation-files.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WorkstationFilesComponent implements OnInit {
  public gridOptions!: GridOptions;
  public gridApi!: any;
  isGridReady$ = new Subject();
  public recentFilesData!: RecentFilesTable[];

  public isLoading: boolean = true;

  public size: LoadingSpinnerSize = LoadingSpinnerDefinedSize.XLarge;
  public animationType = AnimationType.Spinner;
  public animationDuration: number = 1;
  public dataAvailable: boolean = false;

  @Output() fileSelected = new EventEmitter<RecentFilesTable[]>();
  @Input() region: string = 'America';
  @Input() tenantId: string = '';

  files: WorkstationFiles[] = [];

  constructor(
    private readonly pushService: PushService,
    private readonly workspaceService: WorkspaceService,
    private readonly translateService: TranslateService,
    private readonly cd: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    const workspaces = await lastValueFrom(
      this.workspaceService.getWorkspaces(this.tenantId)
    );
    for (const workspace of workspaces.data) {
      const workstations = await lastValueFrom(
        this.workspaceService.getWorkstations(
          this.tenantId,
          this.region as Region,
          workspace.id
        )
      );
      for (const workstation of workstations.workstations) {
        const files = await lastValueFrom(
          this.pushService.getWorkstationFiles(
            this.tenantId,
            this.region as Region,
            workstation.workstationId
          )
        );
        for (const file of files) {
          if (file.size > 0) {
            const relativeFilePath = file.filePath.split('/');
            const instanceId = relativeFilePath.shift() ?? '';
            relativeFilePath.unshift(workstation.name);
            relativeFilePath.unshift(workspace.name);
            relativeFilePath.unshift(instanceId);
            file.filePath = relativeFilePath.join('/');
            file.fileName = file.filePath.split('/').pop() ?? '';

            file.regionAndWorkstationId =
              this.region + ':' + workstation.workstationId;

            this.files.push(file);
          }
        }
      }
    }

    this.dataAvailable = this.files.length > 0;
    this.recentFilesData = this.files.map((file) => {
      const instanceId = file.filePath.split('/').shift() ?? '';
      const instanceIdPath =
        instanceId + '/' + file.filePath.split('/').slice(3).join('/');
      file.filePath = file.filePath.replace(instanceId + '/', '');
      return {
        filename: file.fileName,
        path: file.filePath,
        iconFilename: getImagePathForUnknownProject(file.fileName),
        pk: instanceIdPath,
        sk: file.regionAndWorkstationId,
      } as RecentFilesTable;
    });
    this.initializeAGGrid();
    this.updateRowSource();
    this.isLoading = false;
    this.cd.detectChanges();
  }

  private initializeAGGrid(): void {
    this.gridOptions = {
      columnDefs: [
        {
          width: 30,
          cellStyle: { border: 'none' },
          headerCheckboxSelection: true,
          checkboxSelection: true,
          headerClass: 'cell-header-checkbox header-checkbox',
          cellClass: 'cell-header-checkbox',
        },
        {
          headerName: this.translateService.instant('columnsTable.fileName'),
          field: 'filename',
          cellRenderer: this.getFilenameIcon,
          flex: 1,
          suppressMovable: true,
          sortable: true,
          filter: true,
          cellClass: 'cell-text-push-vault',
          cellStyle: { border: 'none' },
          filterParams: {
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
            ],
            trimInput: true,
            filterPlaceholder: this.translateService.instant(
              'pushToVault.filters.name'
            ),
            maxNumConditions: 1,
          },
        },
        {
          headerName: this.translateService.instant('pushToVault.path'),
          field: 'path',
          cellClass: 'cell-text-push-vault',
          cellStyle: { border: 'none' },
          suppressMovable: true,
          sortable: true,
          flex: 1,
        },
      ],

      defaultColDef: {
        editable: false,
        filter: false,
        resizable: true,
      },

      suppressContextMenu: true,
      suppressCellFocus: true,
      rowSelection: 'multiple',
      domLayout: 'autoHeight',
      icons: {
        menu: '<span class="ag-icon ra-icon-filter ra-icon-ide-md-filter active-filter"></span>',
      },
    };
  }

  getFilenameIcon = (params: ICellRendererParams): HTMLSpanElement => {
    const filename: string = params.value;
    const eGui = document.createElement('span');
    eGui.setAttribute(
      'style',
      'display:inline-block;overflow:hidden;text-overflow:ellipsis;'
    );
    eGui.innerHTML =
      params.data.iconFilename === undefined
        ? `<div class="cell-row">${filename}</div>`
        : `<div class="cell-row"><img width="20px" src="${params.data.iconFilename}" style="margin-right: 10px" /><span class="text">${filename}</span></div>`;
    return eGui;
  };

  updateRowSource(): void {
    if (this.recentFilesData) {
      this.gridApi?.setRowData(this.recentFilesData);
    }
  }

  onSelectionChanged(): void {
    const selectedRows = this.gridApi.getSelectedRows();
    this.fileSelected.emit(selectedRows as RecentFilesTable[]);
  }

  onGridReady(event: any): void {
    this.gridApi = event.api;
    this.isGridReady$.next(true);
    this.gridApi.sizeColumnsToFit();
    this.updateRowSource();
  }
}
