import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  IGainsightCustomEvent,
  IGainsightUserProfile,
} from 'src/app/core/models/gainsight.model';
import {
  resetSession,
  setOrganizationContext,
  pushUserProfile,
  pushCustomEvent,
} from './gainsight.actions';

@Injectable({
  providedIn: 'root',
})
export class GainsightFacade {
  constructor(private readonly store: Store) {}

  pushUserProfile(userProfile: IGainsightUserProfile): void {
    this.store.dispatch(
      pushUserProfile({ payload: { userProfile: userProfile } })
    );
  }

  resetSession(): void {
    this.store.dispatch(resetSession());
  }

  setOrganizationContext(tenantId: string, tenantName: string): void {
    this.store.dispatch(
      setOrganizationContext({
        payload: { tenantId: tenantId, tenantName: tenantName },
      })
    );
  }

  pushCustomEvent(gainsightCustomEvent: IGainsightCustomEvent): void {
    this.store.dispatch(pushCustomEvent({ gainsightCustomEvent }));
  }
}
