import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, distinctUntilChanged, skipWhile } from 'rxjs';
import {
  FeatureFlag,
  FlagValue,
  FeatureFlagUpdate,
  FeatureFlagTarget,
} from './feature-flag.model';
import { selectFlags } from './feature-flag.selectors';
import {
  setFeatureFlags,
  updateFeatureFlag,
  deleteFeatureFlag,
  initializeTarget,
} from './feature-flag.actions';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagFacade {
  isInitialized = false;
  flags$ = this.store
    .select(selectFlags)
    .pipe(skipWhile((flags) => flags === undefined));

  constructor(private readonly store: Store) {}

  getFlagValue(flag: FeatureFlag): Observable<FlagValue> {
    return this.flags$.pipe(
      map((flags) => (flags ? flags[flag] : undefined)),
      distinctUntilChanged()
    );
  }

  setFeatureFlags(flags: Record<string, FlagValue>): void {
    this.store.dispatch(setFeatureFlags({ flags: flags }));
  }

  updateFeatureFlag(update: FeatureFlagUpdate): void {
    this.store.dispatch(updateFeatureFlag({ payload: update }));
  }

  deleteFeatureFlag(flag: FeatureFlag): void {
    this.store.dispatch(deleteFeatureFlag({ payload: flag }));
  }

  initializeTarget(target: FeatureFlagTarget): void {
    if (!this.isInitialized) {
      this.store.dispatch(initializeTarget({ payload: target }));
      this.isInitialized = true;
    }
  }
}
