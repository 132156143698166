import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoleActionsState } from './role-actions.reducer';

export const selectRoleActionsState =
  createFeatureSelector<RoleActionsState>('roleActions');

export const selectRoleState = createSelector(
  selectRoleActionsState,
  (state: RoleActionsState) => state.roleActions?.role
);

export const selectAllowedActionsState = createSelector(
  selectRoleActionsState,
  (state: RoleActionsState) => state.roleActions?.allowedActions
);
