<ra-ui-list
  [items]="listItems"
  [config]="config"
  (onEmptyStateButtonClick)="emitEmptyStateButtonClicked()"
>
  <ng-template #suffix class="row" let-item="item" let-control="control">
    <i
      *ngIf="item.data.finishedLoading && !item.data.error"
      class="ra-icon-ide-md-color-check"
    ></i>
    <i
      *ngIf="item.data.finishedLoading && item.data.error"
      class="ra-icon-ide-md-color-error"
    ></i>
    <i
      *ngIf="item.data.finishedLoading && allowDelete"
      (click)="removeItem(item)"
      class="ra-icon-ide-md-delete"
    ></i>
    <ra-ui-loading-spinner
      *ngIf="!item.data.finishedLoading"
    ></ra-ui-loading-spinner>
  </ng-template>
</ra-ui-list>
