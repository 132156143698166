/* eslint-disable no-console */
import { Injectable, OnDestroy } from '@angular/core';
import {
  initialize,
  Event,
  Target,
  Options,
  Result,
  Evaluation,
  VariationValue,
} from '@harnessio/ff-javascript-client-sdk';
import {
  FeatureFlag,
  FeatureFlagTarget,
} from '../../../core/reducers/feature-flag/feature-flag.model';
import { FeatureFlagFacade } from './../../../core/reducers/feature-flag/feature-flag.facade';
import {
  ConfigService,
  TwinStudioConfiguration,
} from '../config/config.service';

export type HarnessInitializer = (
  apiKey: string,
  target: Target,
  options?: Options
) => Result;

@Injectable({
  providedIn: 'root',
})
export class HarnessService implements OnDestroy {
  changeFeed: Result | undefined;

  constructor(
    private readonly featureFlagFacade: FeatureFlagFacade,
    private readonly configService: ConfigService<TwinStudioConfiguration>
  ) {}

  getChangeFeed(harnessSdk: string, target: FeatureFlagTarget): Result {
    return initialize(harnessSdk, target);
  }

  initializeTarget(featureFlagsTarget: FeatureFlagTarget): void {
    const changeFeed: Result = this.getChangeFeed(
      this.configService.config.harnessSDK,
      featureFlagsTarget
    );
    changeFeed.on(Event.ERROR, (err) => {
      console.warn(
        'Unknown failure in feature SDK. All experimental features disabled.',
        err
      );
      console.error('Feature flag service failed');
    });
    changeFeed.on(Event.READY, this.onReadyCallback);
    changeFeed.on(Event.CHANGED, this.onChangeCallback);
    this.changeFeed = changeFeed;
  }

  ngOnDestroy(): void {
    this.changeFeed?.close();
  }

  onReadyCallback: (flags: Record<string, VariationValue>) => void = (
    flags: Record<string, VariationValue>
  ) => {
    this.featureFlagFacade.setFeatureFlags(flags);
  };

  onChangeCallback: (change: Evaluation) => void = (change: Evaluation) => {
    if (change.deleted) {
      this.featureFlagFacade.deleteFeatureFlag(change.flag as FeatureFlag);
    } else {
      this.featureFlagFacade.updateFeatureFlag({
        flag: change.flag as FeatureFlag,
        value: change.value,
      });
    }
  };
}

export class HarnessServiceError extends Error {}
