<section class="info-container">
  <section class="header">
    <h1>{{ infoData.title | translate }}</h1>
    <ra-ui-icon-button
      icon="ra-icon-ide-md-cross"
      id="close-panel-button"
      [config]="buttonConfig"
      (onClick)="closePanel.emit()"
    ></ra-ui-icon-button>
  </section>
  <img class="logo" src="{{ infoData.icon }}" alt="twin-studio-logo" />
  <section>
    <p>{{ infoData.subtitle | translate }}</p>
    <p>{{ infoData.description | translate }}</p>
    <b>{{ infoData.descFeatures | translate }}</b>
  </section>
  <section *ngFor="let feature of infoData.features">
    <div class="feature">
      <i class="{{ feature.icon }}"> </i>
      <p>{{ feature.description | translate }}</p>
    </div>
  </section>
</section>
