import {
  Injectable,
  ViewContainerRef,
  TemplateRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav';
import { isEmptyObject } from '../../../shared/utility/app-utility';

@Injectable({
  providedIn: 'root',
})
export class SidePanelService {
  private panel: MatSidenav = {} as MatSidenav;
  private vcf: ViewContainerRef = {} as ViewContainerRef;

  @Output() clickSettings = new EventEmitter();

  constructor() {}

  setPanel(sidenav: MatSidenav): void {
    this.panel = sidenav;
  }

  setContentVcf(viewContainerRef: ViewContainerRef): void {
    this.vcf = viewContainerRef;
  }

  private createView(template: TemplateRef<any>) {
    this.vcf.clear();
    this.vcf.createEmbeddedView(template);
  }

  open(
    template: TemplateRef<any>,
    position: 'end' | 'start' = 'end',
    mode: 'over' | 'side' | 'push' = 'over'
  ): Promise<MatDrawerToggleResult> {
    this.createView(template);
    this.panel.position = position;
    this.panel.mode = mode;
    return this.panel.open();
  }

  close(): Promise<MatDrawerToggleResult> {
    if (isEmptyObject(this.panel)) {
      return Promise.resolve('close' as MatDrawerToggleResult);
    }
    return this.panel.close();
  }

  toggle(): Promise<MatDrawerToggleResult> {
    return this.panel.toggle();
  }
}
