import { Component, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input() message?: string;
  @Input() width: string = '500px';
  @Input() height: string = '500px';

  options: AnimationOptions = {
    path: '/assets/animations/loading.json',
  };

  styles: Partial<CSSStyleDeclaration> = {
    maxWidth: '500px',
    display: 'block',
    margin: 'auto',
  };

  constructor() {}
}
