import { Component, Inject } from '@angular/core';
import { STEPPER_ITEM_DATA } from '@ra-web-tech-ui-toolkit/common-views/stepper';
import { UploadFileModel } from '../../../core/models/upload-file.model';
import { IListItem } from '@ra-web-tech-ui-toolkit/lists/list';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent {
  listItems: IListItem[] = [];
  location: string = '';

  constructor(@Inject(STEPPER_ITEM_DATA) public viewData: any) {
    this.location = this.viewData.getLocation();
    this.listItems = this.convertToListItems(viewData.getUploadedFiles());
  }

  convertToListItems(uploadFiles: UploadFileModel[]): IListItem[] {
    return uploadFiles.map((uploadFile) => {
      return {
        label: uploadFile.file.name,
        data: {
          finishedLoading: uploadFile.isUploadCompleted,
          error: uploadFile.uploadError,
        },
      };
    });
  }
}
