import { createReducer, on } from '@ngrx/store';
import { SessionActions } from './action-types';
import * as _ from 'lodash';
import {
  Session,
  SessionFile,
  SessionKind,
} from '../../models/twin-studio.model';
import { TenantId } from '../../models/cs/tenant-info.model';
import { SolutionId } from '../../models/vault/solution.model';

export interface PreloadSessionData {
  tenantId: TenantId;
  tenantName: string;
  sessionKind: SessionKind;
  files: SessionFile[];
  solutionId?: SolutionId;
  solutionName?: string;
  previousPath: string;
  openNewTab: boolean;
}

export type SessionsActiveRecord = Record<string, Session>;
export type PreloadSessionDataRecord = Record<string, PreloadSessionData>;
export const localStorageSessionInfo = 'local_store_session_info';

export interface SessionState {
  sessionsActive: SessionsActiveRecord;
  preloadSessionData: PreloadSessionDataRecord;
  preUploadedFiles: string[];
}

export const initialSessionState: SessionState = getLocalSessionStorageState(
  true
) ?? {
  sessionsActive: {},
  preloadSessionData: {},
  preUploadedFiles: [],
};

export const sessionReducer = createReducer(
  initialSessionState,

  on(SessionActions.preloadBrowseSelection, (state, action) => {
    const sessionLocal = getLocalSessionStorageState();
    let sessionState: SessionState = _.cloneDeep(state);
    if (sessionLocal) {
      sessionState = _.cloneDeep(sessionLocal);
    }

    const browseSelectionRec: PreloadSessionDataRecord = _.cloneDeep(
      sessionState.preloadSessionData
    );
    const preloadSessionData = action.preloadSessionData;
    const idRecord = [
      preloadSessionData.tenantId,
      preloadSessionData.sessionKind,
    ].join('|');
    browseSelectionRec[idRecord] = {
      tenantId: preloadSessionData.tenantId,
      tenantName: preloadSessionData.tenantName,
      sessionKind: preloadSessionData.sessionKind,
      files: preloadSessionData.files,
      solutionId: preloadSessionData.solutionId,
      solutionName: preloadSessionData.solutionName,
      previousPath: preloadSessionData.previousPath,
      openNewTab: preloadSessionData.openNewTab,
    };
    const sessionStateResult = {
      ...state,
      preloadSessionData: browseSelectionRec,
    };
    saveToLocalSessionStorage(sessionStateResult);
    return sessionStateResult;
  }),

  on(SessionActions.startSession, (state, action) => {
    const sessionLocal = getLocalSessionStorageState();
    let sessionState: SessionState = _.cloneDeep(state);
    if (sessionLocal) {
      sessionState = _.cloneDeep(sessionLocal);
    }

    // Adding new session data
    const sessionsActive = sessionState.sessionsActive;
    const sessionReceived = action.sessionData;
    const idSesionRecord = [
      sessionReceived.tenantId,
      sessionReceived.kind,
      sessionReceived.tier.tier,
    ].join('|');
    sessionsActive[idSesionRecord] = action.sessionData;

    const sessionStateResult = {
      ...state,
      sessionsActive,
    };
    saveToLocalSessionStorage(sessionStateResult);
    return sessionStateResult;
  }),

  on(SessionActions.updateDataSession, (state, action) => {
    const sessionsRec: SessionsActiveRecord = _.cloneDeep(state.sessionsActive);
    const session = action.sessionData;

    const idRecord = [session.tenantId, session.kind, session.tier.tier].join(
      '|'
    );
    if (sessionsRec[idRecord]) {
      sessionsRec[idRecord] = session;
    }
    const sessionStateResult = {
      ...state,
      sessionsActive: sessionsRec,
    };
    saveToLocalSessionStorage(sessionStateResult);
    return sessionStateResult;
  }),

  on(SessionActions.endSession, (state, action) => {
    const sessionsRec = _.cloneDeep(state.sessionsActive);
    const session = action.sessionData;
    const idRecord = [session.tenantId, session.kind, session.tier.tier].join(
      '|'
    );
    if (sessionsRec[idRecord]) {
      delete sessionsRec[idRecord];
    }
    const sessionState = {
      ...state,
      sessionsActive: sessionsRec,
    };
    saveToLocalSessionStorage(sessionState);
    return sessionState;
  }),

  on(SessionActions.preUploadFilesToSession, (state, action): SessionState => {
    return {
      ...state,
      preUploadedFiles: action.fileNames,
    };
  }),

  on(SessionActions.fileUploadedCompleted, (state, action) => {
    let preUploadedFiles = _.cloneDeep(state.preUploadedFiles);
    preUploadedFiles = preUploadedFiles.filter((x) => x !== action.fileName);
    return {
      ...state,
      preUploadedFiles: preUploadedFiles,
    };
  })
);

export function getLocalSessionStorageState(
  resetStreamUrl = false
): SessionState | undefined {
  const sessionState = localStorage.getItem(localStorageSessionInfo);
  if (sessionState) {
    const obj: SessionState = JSON.parse(sessionState) as SessionState;
    if (resetStreamUrl) {
      Object.keys(obj.sessionsActive).forEach((key) => {
        obj.sessionsActive[key].initurl = undefined;
      });
    }
    return obj;
  }
  return undefined;
}

export function saveToLocalSessionStorage(value: SessionState): void {
  return localStorage.setItem(localStorageSessionInfo, JSON.stringify(value));
}

export function clearLocalSessionStorage(): void {
  return localStorage.removeItem(localStorageSessionInfo);
}
