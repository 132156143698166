import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

export enum SideBarItemSelection {
  Home = 'Dashboard',
  OpenUpload = 'Clone/Upload',
  Push = 'Push to Vault',
  Instances = 'Workstation Instances',
  UploadToQuickAccess = 'Upload',
  Pull = 'Pull from Vault',
}

export interface SidebarItem {
  text: SideBarItemSelection;
  icon: string;
  navigateTo?: string;
  id: string;
  //Only use when component does not need to redirect
  emitOnredirect?: boolean;
}
@Component({
  selector: 'app-sidebar-options',
  templateUrl: './sidebar-options.component.html',
  styleUrls: ['./sidebar-options.component.scss'],
})
export class SidebarOptionsComponent implements OnInit {
  @Input() currentSelection!: SideBarItemSelection;
  @Input() sidebarItems!: SidebarItem[];
  @Output() emitOptionEvent = new EventEmitter<SidebarItem>();

  constructor(private readonly router: Router) {}

  ngOnInit(): void {}

  onNavigate(event: SidebarItem): void {
    this.currentSelection = event.text;
    if (event.emitOnredirect) {
      this.emitOptionEvent.emit(event);
    } else {
      this.router.navigate([event.navigateTo]);
    }
  }
}
