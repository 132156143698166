import { Action, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { FeatureFlagState } from './feature-flag.model';
import {
  deleteFeatureFlag,
  setFeatureFlags,
  updateFeatureFlag,
} from './feature-flag.actions';

export const featureFlagsInitialState: FeatureFlagState = {};

export const featureFlagsReducer = createReducer(
  featureFlagsInitialState,
  on(setFeatureFlags, (state: FeatureFlagState, { flags }) => {
    return { ...state, flags: flags };
  }),
  on(
    updateFeatureFlag,
    (state: FeatureFlagState, { payload: harnessUpdate }) => {
      const flags = _.clone(state.flags);
      if (flags) {
        flags[harnessUpdate.flag] = harnessUpdate.value;
      }
      return { ...state, flags: flags };
    }
  ),
  on(deleteFeatureFlag, (state: FeatureFlagState, { payload: flag }) => {
    const flags = _.clone(state.flags);
    if (flags) {
      delete flags[flag];
    }
    return { ...state, flags: flags };
  })
);
