import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebounceClickDirective } from './debounce-click/debounce-click.directive';
import { PrimaryButtonComponent } from './primary-button/primary-button.component';
import { PrimaryButtonFlourishComponent } from './primary-button-flourish/primary-button-flourish.component';
import { SecondaryButtonFlourishComponent } from './secondary-button-flourish/secondary-button-flourish.component';
import { SecondaryButtonComponent } from './secondary-button/secondary-button.component';
import { RaUiButtonModule } from '@ra-web-tech-ui-toolkit/buttons/button';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';

@NgModule({
  declarations: [
    DebounceClickDirective,
    PrimaryButtonComponent,
    PrimaryButtonFlourishComponent,
    SecondaryButtonFlourishComponent,
    SecondaryButtonComponent,
  ],
  imports: [
    CommonModule,
    RaUiButtonModule,
    MatButtonModule,
    MatIconModule,
    MatStepperModule,
  ],
  exports: [
    PrimaryButtonComponent,
    PrimaryButtonFlourishComponent,
    SecondaryButtonFlourishComponent,
    SecondaryButtonComponent,
  ],
})
export class SharedButtonModule {}
