import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryComponent } from './summary.component';
import { TitleStepModule } from '../../../shared/components/title-step/title-step.module';
import { TranslateModule } from '@ngx-translate/core';
import { FileListModule } from '../../../shared/components/file-list/file-list.module';

@NgModule({
  declarations: [SummaryComponent],
  imports: [CommonModule, TitleStepModule, TranslateModule, FileListModule],
})
export class SummaryModule {}
