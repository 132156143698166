<ra-ui-card
  *ngIf="workspace"
  id="workspace-tile-{{ workspace.name }}"
  class="workstation-tile"
  [config]="config"
  (menuItemClicked)="emitEvent($event)"
  (onTextButtonClick)="openWorkspacePage()"
>
  <div class="card-body" (click)="openWorkspacePage()">
    <p>{{ "workspaceTile.text" | translate }}</p>
    <div class="statuses">
      <div
        [class]="
          workspace.instances.pending > 0
            ? 'status-card-narrow running'
            : 'status-card-wide running'
        "
      >
        <p class="text">{{ "workspaceTile.running" | translate }}</p>
        <strong class="number">{{ workspace.instances.running }}</strong>
      </div>
      <div
        [class]="
          workspace.instances.pending > 0
            ? 'status-card-narrow stopped'
            : 'status-card-wide stopped'
        "
      >
        <p class="text">{{ "workspaceTile.stopped" | translate }}</p>
        <strong class="number">{{ workspace.instances.stopped }}</strong>
      </div>
      <div
        class="status-card-narrow pending"
        *ngIf="workspace.instances.pending > 0"
      >
        <p class="text">{{ "workspaceTile.pendingStatus" | translate }}</p>
        <strong class="number">{{ workspace.instances.pending }}</strong>
      </div>
    </div>
  </div>
</ra-ui-card>
