<app-title-step
  [iconTitle]="'ra-icon-ide-md-pull'"
  [labelTitle]="'pullFromVault.stepOneTitle' | translate"
  [numberStep]="1"
  [subtitle]="'pullFromVault.stepOneSubtitleEmpty' | translate"
>
</app-title-step>
<div class="content">
  <div *ngIf="!isConnected && isLoading" class="loading">
    <img
      id="loading-recent-files"
      src="/assets/images/loading-recent-files.svg"
      alt="loading-recent-files"
    />
    <p id="title-loading" class="title-loading">
      {{ "pullFromVault.loading" | translate }}
    </p>
    <ra-ui-loading-spinner
      id="loading-spinner"
      [size]="size"
      [animationType]="animationType"
      [animationDuration]="animationDuration"
    >
    </ra-ui-loading-spinner>
  </div>
  <div *ngIf="!isConnected && !isLoading" class="empty-recent-files">
    <img
      id="empty-no-signal"
      src="/assets/images/no-signal.svg"
      alt="empty-folder"
    />
    <p id="title-empty" class="title-empty">
      {{ "pullFromVault.emptyTitle" | translate }}
    </p>
    <p id="subtitle-empty" class="subtitle-empty">
      {{ "pullFromVault.emptySubtitle" | translate }}
    </p>
  </div>
  <div *ngIf="repositories$ | async">
    <div *ngIf="isConnected && repositories" class="layout">
      <app-select-repository
        [repositories]="repositories"
        (repositorySelected)="isAssociatedProjectsEnabled($event)"
      ></app-select-repository>
      <app-associated-projects
        [repository]="repositorySelected"
        (projectsChecked)="onRepositoryFilesChecked($event)"
      ></app-associated-projects>
      <app-selected-files
        [repositoriesFilesSelected]="repositoryFilesSelected"
        (removeProject)="onRemoveRepositoryFiles($event)"
      ></app-selected-files>
    </div>
  </div>
</div>
