import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  CreateWorkspacePayload,
  EditWorkspacePayload,
} from '../../models/workspace-creation.model';
import { catchError, map, Observable, of } from 'rxjs';
import {
  ConfigService,
  TwinStudioConfiguration,
} from '../config/config.service';
import { LCoreResponse } from '../../models/lcore-response.model';
import { TenantId } from '../../models/cs/tenant-info.model';
import { GetWorkspacesResponse } from '../../models/workspace';
import { GetWorkspaceResponse } from '../../models/workspace-edit.model';
import { WorkspaceDetails } from '../../models/twin-studio.model';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  constructor(
    private readonly configService: ConfigService<TwinStudioConfiguration>,
    private readonly http: HttpClient
  ) {}

  getWorkspaces(tenantId: TenantId): Observable<GetWorkspacesResponse> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/twinstudio/tenants/${tenantId}/workspaces`;
    return this.http.get<GetWorkspacesResponse>(url).pipe(
      map((x) => {
        return x;
      }),
      catchError((error: unknown) => {
        return of({
          status: 'failed',
          data: [],
        } as GetWorkspacesResponse);
      })
    );
  }

  createWorkspace(
    tenantId: string,
    workspace: CreateWorkspacePayload
  ): Observable<string> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/twinstudio/tenants/${tenantId}/workspaces`;
    return this.http.post<LCoreResponse<string>>(url, workspace).pipe(
      map((x) => {
        return x.data;
      }),
      catchError(() => {
        return of('Failed to create workspace');
      })
    );
  }

  getWorkspace(
    tenantId: TenantId,
    region: string,
    workspaceId: string
  ): Observable<GetWorkspaceResponse> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/twinstudio/tenants/${tenantId}/workspaces/${region}:${workspaceId}`;
    return this.http.get<LCoreResponse<GetWorkspaceResponse>>(url).pipe(
      map<LCoreResponse<GetWorkspaceResponse>, GetWorkspaceResponse>(
        (response) => {
          return response.data;
        }
      ),
      catchError(() => {
        return of({} as GetWorkspaceResponse);
      })
    );
  }

  updateWorkspace(
    tenantId: string,
    workspace: EditWorkspacePayload,
    region: string,
    workspaceId: string
  ): Observable<string> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/twinstudio/tenants/${tenantId}/workspaces/${region}:${workspaceId}`;
    const body = workspace;
    return this.http.put<LCoreResponse<string>>(url, body).pipe(
      map((x) => {
        return x.data;
      }),
      catchError(() => {
        return of('Failed to edit workspace');
      })
    );
  }

  deleteWorkspace(
    tenantId: TenantId,
    region: string,
    workspaceId: string
  ): Observable<string> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/twinstudio/tenants/${tenantId}/workspaces/${region}:${workspaceId}`;
    return this.http.delete<LCoreResponse<string>>(url).pipe(
      map((x) => {
        return x.data;
      }),
      catchError(() => {
        return of('Failed to delete workspace');
      })
    );
  }

  getWorkstations(
    tenantId: TenantId,
    region: string,
    workspaceId: string
  ): Observable<WorkspaceDetails> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/twinstudio/tenants/${tenantId}/workspaces/${region}:${workspaceId}/workstations`;
    return this.http.get<LCoreResponse<WorkspaceDetails>>(url).pipe(
      map<LCoreResponse<WorkspaceDetails>, WorkspaceDetails>((response) => {
        return response.data;
      }),
      catchError(() => {
        return of({} as WorkspaceDetails);
      })
    );
  }

  reserveQuota(
    region: string,
    tenantId: string,
    quotaUuid: string
  ): Observable<string> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/twinstudio/tenants/${tenantId}/workspaces/quota/${region}:${quotaUuid}`;

    return this.http.post<LCoreResponse<string>>(url, {}).pipe(
      map((x) => {
        return x.data;
      }),
      catchError(() => {
        return of('Failed to reserve quota');
      })
    );
  }
}
