<div class="dashboard-content">
  <mat-tab-group class="custom-tab-group">
    <mat-tab
      id="tabVaultProjects"
      label="{{ 'menu.push.tabVaultProjects' | translate }}"
    >
      <div class="tab-content">
        <app-projects-to-vault
          [sessionData]="sessionData"
          [currentTenant]="currentTenant"
          (cancelEmitter)="onContinue()"
          [displayCancel]="displayCancel"
        >
        </app-projects-to-vault>
      </div>
    </mat-tab>
    <mat-tab
      id="tabUnknownProjects"
      label="{{ 'menu.push.tabUnknownProjects' | translate }}"
    >
      <div class="tab-content">
        <app-unknown-projects
          [sessionData]="sessionData"
          [currentTenant]="currentTenant"
          (cancelEmitter)="onContinue()"
          [displayCancel]="displayCancel"
        >
        </app-unknown-projects>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
