import { createAction, props } from '@ngrx/store';
import { UserProfile } from './user-profile.model';

export const loginSuccess = createAction(
  '[App Page] User Login Success',
  props<{ user: UserProfile }>()
);

export const isLogged = createAction('[App Page] User Logged');

export const callback = createAction(
  '[Common Services] Callback',
  props<{ user: UserProfile }>()
);

export const logout = createAction('[App page] Logout');
