import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridTableComponent } from './ag-grid-table.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedButtonModule } from '../buttons/shared-button.module';

@NgModule({
  declarations: [AgGridTableComponent],
  imports: [CommonModule, AgGridModule, TranslateModule, SharedButtonModule],
  exports: [AgGridTableComponent],
})
export class AgGridTableModule {}
