import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ActionButtonStyles,
  NotificationType,
} from '@ra-web-tech-ui-toolkit/cdk/types';
import {
  IStepperButton,
  IStepperButtonsProvider,
  IStepperConfig,
  IStepperItem,
  StepperComponent,
} from '@ra-web-tech-ui-toolkit/common-views/stepper';
import { Observable, Subject, forkJoin, takeUntil } from 'rxjs';
import {
  DialogService,
  IDialogConfig,
} from '@ra-web-tech-ui-toolkit/popups/dialog';
import { SideBarItemSelection } from 'src/app/features/navigation/sidebar/sidebar-options/sidebar-options.component';
import { SelectFilesComponent } from './select-files/select-files.component';
import { SelectLocationComponent } from './select-location/select-location.component';
import { SummaryComponent } from './summary/summary.component';
import { UploadFileModel } from '../../core/models/upload-file.model';
import { FileUploadService } from '../../core/services/file-upload/file-upload.service';
import { PushService } from '../../core/services/push/push.service';
import { TenantInfoFacade } from '../../core/reducers/tenant-info/tenant-info.facade';
import {
  FileUploadResult,
  PushChangesPayload,
  PushChangesResponse,
  Region,
} from '../../core/models/twin-studio.model';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from '../../core/services/sidenav.service';

@Component({
  selector: 'app-quick-access-upload',
  templateUrl: './quick-access-upload.component.html',
  styleUrls: ['./quick-access-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuickAccessUploadComponent
implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('sidenav') sidenav!: MatSidenav;
  @ViewChild('stepper') stepperCmp!: StepperComponent;
  @Output() continueWithSession = new EventEmitter<boolean>();
  @Output() goToDashboard = new EventEmitter<SideBarItemSelection>();
  private readonly destroy$: Subject<void> = new Subject();

  filesUploadedToTs: UploadFileModel[] = [];
  location!: string;
  uploadToQuickAccessStarted: boolean = false;
  tenantId: string | undefined;

  firstStepLabel = this.translateSvc.instant('quickAccessUpload.selectFiles');
  secondStepLabel = this.translateSvc.instant(
    'quickAccessUpload.selectLocation'
  );
  thirdStepLabel = this.translateSvc.instant('summary');
  uploadButtonLabel = this.translateSvc.instant('quickAccessUpload.upload');
  uploadingButtonLabel = this.translateSvc.instant(
    'quickAccessUpload.uploading'
  );
  cancelButtonLabel = this.translateSvc.instant('cancel');
  acceptButtonLabel = this.translateSvc.instant('accept');
  nextButtonLabel = this.translateSvc.instant(
    'RA_UI_COMMON_VIEWS.STEPPER.BTN_NEXT_LABEL'
  );
  exitButtonLabel = this.translateSvc.instant(
    'RA_UI_COMMON_VIEWS.STEPPER.BTN_EXIT_LABEL'
  );
  backButtonLabel = this.translateSvc.instant(
    'RA_UI_COMMON_VIEWS.STEPPER.BTN_PREVIOUS_LABEL'
  );
  finishButtonLabel = this.translateSvc.instant(
    'RA_UI_COMMON_VIEWS.STEPPER.BTN_FINISH_LABEL'
  );
  exitDialogTitle = this.translateSvc.instant('quickAccessUpload.exitTitle');
  exitDialogMessage = this.translateSvc.instant(
    'quickAccessUpload.exitMessage'
  );

  items!: IStepperItem[];
  stepperConfig: IStepperConfig = {
    enableSummary: false,
  };
  buttonsStep!: IStepperButton[];
  buttonsProvider!: IStepperButtonsProvider;

  dialogConfig: IDialogConfig = {
    title: this.exitDialogTitle,
    message: this.exitDialogMessage,
    buttons: [
      {
        label: this.cancelButtonLabel,
        buttonStyle: ActionButtonStyles.Main,
      },
      {
        label: this.acceptButtonLabel,
        buttonStyle: ActionButtonStyles.Outlined,
      },
    ],
    messageType: NotificationType.Error,
    showCloseIconButton: false,
    hideTitleIcon: false,
  };

  constructor(
    private readonly translateSvc: TranslateService,
    private readonly dialogService: DialogService,
    private readonly fileUploadService: FileUploadService,
    private readonly pushService: PushService,
    private readonly tenantInfoFacade: TenantInfoFacade,
    private readonly sidenavService: SidenavService
  ) {
    this.tenantInfoFacade.tenantId$
      .pipe(takeUntil(this.destroy$))
      .subscribe((tenantId) => (this.tenantId = tenantId));
  }

  ngOnInit(): void {
    this.initializeStepper();
  }

  ngAfterViewInit(): void {
    this.subscribeStepperButtonClick();
    this.sidenavService.setSidenav(this.sidenav);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initializeStepper(): void {
    this.items = this.getSteps();
    this.updateButtonsProvider();
  }

  setFilesUploadedToTs = (files: UploadFileModel[]): void => {
    this.filesUploadedToTs = files;
    this.updateButtonsProvider();
  };

  getFilesUploadedToTs = (): UploadFileModel[] => {
    return this.filesUploadedToTs;
  };

  getLocation = (): string => {
    return this.location;
  };

  setLocation = (location: string): void => {
    this.location = location;
    this.updateButtonsProvider();
  };

  getUploadStarted = (): boolean => {
    return this.uploadToQuickAccessStarted;
  };

  getSteps(): IStepperItem[] {
    return [
      {
        title: this.firstStepLabel,
        component: SelectFilesComponent,
        data: {
          currentFiles: this.getFilesUploadedToTs,
          setFilesUploadedToTs: this.setFilesUploadedToTs,
        },
      },
      {
        title: this.secondStepLabel,
        component: SelectLocationComponent,
        data: {
          setLocation: this.setLocation,
          getUploadStarted: this.getUploadStarted,
        },
      },
      {
        title: this.thirdStepLabel,
        component: SummaryComponent,
        data: {
          getLocation: this.getLocation,
          getUploadedFiles: this.getFilesUploadedToTs,
        },
      },
    ];
  }

  getStepButton(step: number): IStepperButton[] {
    this.buttonsStep = [];
    switch (step) {
      case 0:
        this.buttonsStep = [
          {
            label: this.nextButtonLabel,
            buttonStyle: ActionButtonStyles.Main,
            disabled:
              this.filesUploadedToTs.length === 0 ||
              this.filesUploadedToTs.length > 10,
          },
          {
            label: this.exitButtonLabel,
            buttonStyle: ActionButtonStyles.Outlined,
          },
        ];
        break;
      case 1:
        this.buttonsStep = [
          {
            label: this.backButtonLabel,
            buttonStyle: ActionButtonStyles.Outlined,
            disabled: this.uploadToQuickAccessStarted,
          },
          this.uploadToQuickAccessStarted
            ? {
              label: this.uploadingButtonLabel,
              buttonStyle: ActionButtonStyles.Main,
              disabled: true,
            }
            : {
              label: this.uploadButtonLabel,
              buttonStyle: ActionButtonStyles.Main,
              disabled: this.location === undefined,
            },
          {
            label: this.exitButtonLabel,
            buttonStyle: ActionButtonStyles.Outlined,
          },
        ];
        break;
      case 2:
        this.buttonsStep = [
          {
            label: this.finishButtonLabel,
            buttonStyle: ActionButtonStyles.Main,
          },
        ];
        break;
      default:
        this.buttonsStep = [
          {
            label: this.exitButtonLabel,
            buttonStyle: ActionButtonStyles.Outlined,
          },
        ];
        break;
    }
    return this.buttonsStep;
  }

  private subscribeStepperButtonClick() {
    this.stepperCmp?.onButtonClick
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        if (event.button.label === this.nextButtonLabel) {
          this.stepperCmp.moveToNext();
        }
        if (event.button.label === this.uploadButtonLabel) {
          this.stepperCmp.stepperControl.disableItem(this.stepperCmp.items[0]);
          this.uploadToQuickAccessStarted = true;
          this.updateButtonsProvider();
          this.getSasUrlsForUpload();
        }
        if (event.button.label === this.backButtonLabel) {
          this.stepperCmp.moveToPrevious();
        }
        if (event.button.label === this.exitButtonLabel) {
          this.openDialog();
        }
        if (event.button.label === this.finishButtonLabel) {
          this.goToDashboard.emit(SideBarItemSelection.Home);
        }
      });
  }

  updateButtonsProvider(): void {
    this.buttonsProvider = {
      getButtons: (selectedIndex: number) => {
        return this.getStepButton(selectedIndex);
      },
    };
  }

  openDialog(): void {
    const dialogRef = this.dialogService.openWarningDialog(this.dialogConfig);
    dialogRef.componentInstance.onClick.subscribe((data) => {
      if (data.label === this.acceptButtonLabel) {
        this.goToDashboard.emit(SideBarItemSelection.Home);
      }
      if (data.label === this.cancelButtonLabel) {
        this.continueWithSession.emit(true);
      }
    });
  }

  getSasUrlsForUpload(): void {
    if (this.tenantId) {
      const payload: PushChangesPayload = {
        region: Region.America,
        filesToUpload: this.filesUploadedToTs.map(
          (file) => `${this.location}${file.file.name}`
        ),
      };

      this.pushService
        .pushChanges(this.tenantId, payload)
        .subscribe((response: PushChangesResponse) => {
          if (response.urlFilesToUpload) {
            this.uploadFilesToQuickAccess(response.urlFilesToUpload);
          }
        });
    }
  }

  uploadFilesToQuickAccess(sasUrls: FileUploadResult[]): void {
    const uploadRequests: Observable<any>[] = [];

    this.filesUploadedToTs.forEach((file) => {
      const sasUrl = sasUrls.find(
        (url) => url.filename === `${this.location}${file.file.name}`
      );
      sasUrls = sasUrls.filter((url) => url !== sasUrl);
      file.sasUrl = sasUrl?.returnedUrl;

      uploadRequests.push(this.fileUploadService.uploadBlobFile(file));
    });

    forkJoin(uploadRequests).subscribe((uploadResponses) => {
      uploadResponses.forEach((uploadResponse) => {
        const index = this.filesUploadedToTs.findIndex(
          (tsFile) => tsFile.sasUrl === uploadResponse.url
        );
        this.filesUploadedToTs[index].isUploadCompleted = true;
        this.filesUploadedToTs[index].uploadError =
          uploadResponse.status !== 200;
      });

      // Go to summary once upload is complete
      this.stepperCmp.changeStep(2);
      this.stepperCmp.stepperControl.disableItem(this.stepperCmp.items[0]);
      this.stepperCmp.stepperControl.disableItem(this.stepperCmp.items[1]);
    });
  }
}
