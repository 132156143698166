import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TenantInfoActions } from './action-types';
import { switchMap, map } from 'rxjs/operators';
import { RoleActionsService } from '../../services/vault/role-actions.service';
import { RoleActionsFacade } from '../role-actions/role-actions.facade';

@Injectable()
export class TenantInfoEffects {
  tenantSwitched$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TenantInfoActions.setTenantInfoSuccess),
        switchMap((result) => {
          return this.roleActionsService.getVaultRoleActions(
            result.tenantInfo.id
          );
        }),
        map((roleActions) => {
          this.roleActionsFacade.setRoleActions(roleActions);
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly roleActionsService: RoleActionsService,
    private readonly roleActionsFacade: RoleActionsFacade
  ) {}
}
