import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ConfigService,
  TwinStudioConfiguration,
} from '../config/config.service';
import { Observable } from 'rxjs';
import { TenantId } from '../../models/cs/tenant-info.model';
import { LCoreResponse } from '../../models/lcore-response.model';
import { VaultRoleActions } from '../../models/vault/role-actions.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RoleActionsService {
  constructor(
    private readonly http: HttpClient,
    private readonly configService: ConfigService<TwinStudioConfiguration>
  ) {}

  getVaultRoleActions(tenantId: TenantId): Observable<VaultRoleActions> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/vaults/${tenantId}/allowedactions`;
    return this.http
      .get<LCoreResponse<VaultRoleActions>>(url)
      .pipe(map((response) => response.data));
  }
}
