import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PullFromVaultComponent } from './pull-from-vault.component';
import { RaUiStepperModule } from '@ra-web-tech-ui-toolkit/common-views/stepper';
import { RaUiButtonModule } from '@ra-web-tech-ui-toolkit/buttons/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DataStoredInfoModule } from '../../shared/components/data-stored-info/data-stored-info.module';
import { SelectLocationModule } from './select-location/select-location.module';
import { SelectProjectModule } from './select-project/select-project.module';
import { SummaryModule } from './summary/summary.module';

@NgModule({
  declarations: [PullFromVaultComponent],
  imports: [
    CommonModule,
    RaUiStepperModule,
    RaUiButtonModule,
    MatSidenavModule,
    DataStoredInfoModule,
    SelectLocationModule,
    SelectProjectModule,
    SummaryModule,
  ],
  exports: [PullFromVaultComponent],
})
export class PullFromVaultModule {}
