import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './reducers';
export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectIsLoggedIn = createSelector(
  selectAuthState,
  (state) => !!state.user
);

export const selectIsLoggedOut = createSelector(
  selectIsLoggedIn,
  (loggedIn) => !loggedIn
);

export const selectUserInfo = createSelector(
  selectAuthState,
  (state) => state.user
);
