import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss'],
})
export class PrimaryButtonComponent implements OnInit {
  @Input() isDisabled: boolean = false;
  @Input() label: string = '';
  @Input() routerLink: string = '';
  @Input() widthPx: number = 0;
  @Input() debounceTime: number = 500;
  @Input() icon: string = '';
  @Input() isMatStepperNext: boolean = false;
  @Input() id: string = 'primaryButton';
  @Output() clickEvent = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    this.label = this.label?.toUpperCase();
  }

  onClickButton(event: any): void {
    this.clickEvent.emit(event);
  }
}
