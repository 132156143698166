import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PushComponent } from './push.component';
import { MaterialModule } from '../../../material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ProjectsToVaultModule } from './projects-to-vault/projects-to-vault.module';
import { UnknownProjectsModule } from './unknown-projects/unknown-projects.module';
import { SharedButtonModule } from '../../../shared/components/buttons/shared-button.module';
import { MatSidenavModule } from '@angular/material/sidenav';

@NgModule({
  declarations: [PushComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    ProjectsToVaultModule,
    UnknownProjectsModule,
    SharedButtonModule,
    MatSidenavModule,
  ],
  exports: [PushComponent],
})
export class PushModule {}
