import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UploadFileModel } from '../../../../../core/models/upload-file.model';
import { FileUploadService } from '../../../../../core/services/file-upload/file-upload.service';
import { tap } from 'rxjs/operators';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { SessionFacade } from '../../../../../core/reducers/session/session.facade';

@Component({
  selector: 'app-file-upload-progress',
  templateUrl: './file-upload-progress.component.html',
  styleUrls: ['./file-upload-progress.component.scss'],
})
export class FileUploadProgressComponent implements OnInit, OnChanges {
  @Input() uploadData: UploadFileModel;
  progress: number = 25;
  uploadCompleted: boolean = false;

  constructor(
    private readonly fileUploadService: FileUploadService,
    private readonly sessionFacade: SessionFacade
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.uploadData) {
      this.initUploadProcess();
    }
  }

  initUploadProcess(): void {
    this.fileUploadService
      .uploadBlobFile(this.uploadData)
      .pipe(
        tap((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.UploadProgress: {
              const value = Math.round((event.loaded / event.total) * 100);
              this.progress = value;
              break;
            }
            case HttpEventType.Response: {
              this.progress = 100;
              this.uploadCompleted = true;
              this.sessionFacade.fileUploadedCompleted(this.uploadData.id);
              break;
            }
          }
        })
      )
      .subscribe();
  }
}
