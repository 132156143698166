import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { setRoleActions } from './role-actions.actions';
import {
  selectRoleState,
  selectAllowedActionsState,
} from './role-actions.selector';
import {
  VaultRoleActions,
  Role,
  ViewActions,
} from '../../models/vault/role-actions.model';

@Injectable({
  providedIn: 'root',
})
export class RoleActionsFacade {
  selectRole$: Observable<Role | undefined> =
    this.store.select(selectRoleState);
  selectAllowedActions$: Observable<ViewActions[] | undefined> =
    this.store.select(selectAllowedActionsState);

  constructor(private readonly store: Store) {}

  setRoleActions(roleActions: VaultRoleActions): void {
    this.store.dispatch(setRoleActions({ roleActions: roleActions }));
  }
}
