<div id="loading">
  <!-- wrapping the lottie diagram and message in a div to prevent unnecessary scroll bar -->
  <ng-lottie
    [options]="options"
    [styles]="styles"
    [width]="width"
    [height]="height"
  ></ng-lottie>
  <div class="message-info" *ngIf="message">
    {{ message }}
  </div>
</div>
