import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipIfTruncatedDirective } from './tooltipIfTruncated.directive';

@NgModule({
  declarations: [TooltipIfTruncatedDirective],
  imports: [CommonModule],
  exports: [TooltipIfTruncatedDirective],
})
export class TooltipIfTruncatedModule {}
