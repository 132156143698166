import { createReducer, on } from '@ngrx/store';
import { AuthActions } from '../action-types';
import { UserProfile } from '../user-profile.model';

export interface AuthState {
  user?: UserProfile;
}

export const initialAuthState: AuthState = {
  user: undefined,
};

export const authReducer = createReducer(
  initialAuthState,

  on(AuthActions.loginSuccess, (state, action) => ({
    user: action.user,
  })),

  on(AuthActions.isLogged, (state, action) => {
    return initialAuthState;
  }),

  on(AuthActions.callback, (state, action) => {
    return {
      ...state,
      user: action.user,
    };
  }),

  on(AuthActions.logout, (state, action) => {
    return initialAuthState;
  })
);
