import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectData } from '../../../core/models/vault/project.model';
import { DataProject } from '../../../core/models/twin-studio.model';

@Component({
  selector: 'app-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss'],
})
export class DropdownListComponent implements OnInit {
  @Input() dataArray: ProjectData[];
  @Output() projectIdSelected = new EventEmitter<DataProject>();

  constructor() {}

  ngOnInit(): void {}

  onSelectProject(project: ProjectData): void {
    const value = {
      projectId: project.projectId,
      projectName: project.name,
    };
    this.projectIdSelected.emit(value);
  }

  onSelectChooseOne(): void {
    const value = {
      projectId: '',
      projectName: '',
    };
    this.projectIdSelected.emit(value);
  }
}
