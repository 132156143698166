export interface VaultRoleActions {
  role: Role;
  allowedActions: ViewActions[];
}

export enum Role {
  PremiumAdmin = 'PremiumAdmin',
  Admin = 'Admin',
  PremiumContributor = 'PremiumContributor',
  Contributor = 'Contributor',
  PremiumConsumer = 'PremiumConsumer',
  Consumer = 'Consumer',
  PremiumReader = 'PremiumReader',
  Reader = 'Reader',
}

export enum ViewActions {
  VaultAdministrate = 'Vault:Administrate',
  VaultManageRoles = 'Vault:ManageRoles',
  VaultManageTeams = 'Vault:ManageTeams',
  VaultTransferOwnership = 'Vault:TransferOwnership',
  SolutionCreate = 'Solution:Create',
  SolutionDelete = 'Solution:Delete',
  SolutionEdit = 'Solution:Edit',
  SolutionMove = 'Solution:Move',
  SolutionList = 'Solution:List',
  SolutionViewAnalysis = 'Solution:ViewAnalysis',
  ProjectCreate = 'Project:Create',
  ProjectDelete = 'Project:Delete',
  ProjectEdit = 'Project:Edit',
  ProjectMove = 'Project:Move',
  ProjectList = 'Project:List',
  ProjectViewAnalysis = 'Project:ViewAnalysis',
  ProjectUpgradeFile = 'Project:UpgradeFile',
  ProjectEditFile = 'Project:EditFile',
  ProjectDownloadFile = 'Project:DownloadFile',
  VaultView = 'Vault:View',
  FolderCreate = 'Folder:Create',
  FolderDelete = 'Folder:Delete',
  FolderEdit = 'Folder:Edit',
  FolderList = 'Folder:List',
  FolderMove = 'Folder:Move',
}
