<div *ngIf="repositories" class="repositories-container">
  <p>Select repository</p>
  <ra-ui-search-input
    #searchInput
    id="search-repository"
    [config]="searchConfig"
    (searchTextChange)="filterByText($event)"
  >
  </ra-ui-search-input>
  <ra-ui-radio-button
    [items]="repositoriesView"
    [config]="config"
    (valueChange)="isRepositorySelected($event)"
  >
  </ra-ui-radio-button>
</div>
