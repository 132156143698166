import {
  Component,
  Input,
  Output,
  OnInit,
  OnDestroy,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import {
  ITreeNode,
  ITreeNodeState,
  TreeModel,
  TreeComponent,
} from '@ra-web-tech-ui-toolkit/lists/tree';
import { PushService } from '../../../core/services/push/push.service';
import { TenantInfoFacade } from '../../../core/reducers/tenant-info/tenant-info.facade';
import { Observable, Subscription } from 'rxjs';
import { WorkspaceContentPayload } from '../../../core/models/workstation-explorer.model';
import { Region } from '../../../core/models/twin-studio.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home-folder',
  templateUrl: './home-folder.component.html',
  styleUrls: ['./home-folder.component.scss'],
})
export class HomeFolderComponent implements OnInit, OnDestroy {
  @Input() region: Region = Region.America;
  @Input() showFiles: boolean = true;
  @Output() fileSelected = new EventEmitter<string>();
  @Output() folderSelected = new EventEmitter<string>();
  @ViewChild('treeComponent', { static: true }) treeComponent?: TreeComponent;
  private readonly subscriptions = new Subscription();
  public tenantId: string | undefined;
  public homeFolder: string = this.translateService.instant(
    'menu.push.homeFolderTab'
  );
  public loadingContent: string = this.translateService.instant(
    'homeFolder.loadingContent'
  );
  public emptyFolder: string = this.translateService.instant(
    'homeFolder.folderIsEmpty'
  );
  public folderIcon: string = 'ra-icon-ide-sm-folder';
  public initialTreeModel: TreeModel = [
    {
      label: this.homeFolder,
      children: [{ label: this.loadingContent }],
      icon: this.folderIcon,
    },
  ];

  constructor(
    private readonly pushService: PushService,
    private readonly tenantInfoFacade: TenantInfoFacade,
    private readonly translateService: TranslateService
  ) {
    this.subscriptions.add(this.subscribeOnTenantId());
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public addChildren(treeNode: ITreeNode, children: TreeModel): void {
    this.treeComponent?.dataSource.addChildren(treeNode, children);
  }

  public removeNode(node: ITreeNode): void {
    this.treeComponent?.dataSource.removeNode(node);
  }

  public getParent(treeNode: ITreeNode): ITreeNode | undefined {
    return this.treeComponent?.dataSource.getParent(treeNode);
  }

  public listWorkspaceContent(
    tenantId: string,
    region: Region,
    path: string
  ): Observable<string[]> {
    const payload: WorkspaceContentPayload = {
      region: region,
      path: path,
    };

    return this.pushService.listWorkspaceContent(tenantId, payload);
  }

  public mapToTreeModel(workspaceContent: string[]): TreeModel {
    const treeModel: TreeModel = [];

    workspaceContent.forEach((content) => {
      const lastChar = content[content.length - 1];

      if (lastChar === '/' && content !== '.vault/') {
        treeModel.push({
          label: content.substring(0, content.length - 1),
          // Folders need to have at least one child to appear as a folder and be expandable
          children: [{ label: this.loadingContent }],
          icon: 'ra-icon-ide-sm-folder',
        });
      } else if (lastChar !== '/' && this.showFiles) {
        treeModel.push({
          label: content,
          icon: '',
        });
      }
    });

    return treeModel.length === 0 ? [{ label: this.emptyFolder }] : treeModel;
  }

  public setHomeFolder(tenantId: string, region: Region, path: string): void {
    this.listWorkspaceContent(tenantId, region, path).subscribe(
      (workspaceContent) => {
        if (this.treeComponent) {
          this.updateNodeChildren(
            this.treeComponent.tree[0],
            this.mapToTreeModel(workspaceContent)
          );
        }
      }
    );
  }

  public subscribeOnTenantId(): Subscription {
    return this.tenantInfoFacade.tenantId$.subscribe((tenantId) => {
      this.tenantId = tenantId;

      if (this.tenantId !== undefined) {
        this.setHomeFolder(this.tenantId, this.region, '');
      }
    });
  }

  public onTreeNodeSelected(event: ITreeNodeState): void {
    if (
      event.value &&
      event.node.children === undefined &&
      event.node.label !== this.loadingContent &&
      event.node.label !== this.emptyFolder
    ) {
      this.fileSelected.emit(this.getTreeNodePath(event.node));
    } else if (event.value && event.node.children !== undefined) {
      this.folderSelected.emit(this.getTreeNodePath(event.node));
    }
  }

  public onTreeNodeExpanded(event: ITreeNodeState): void {
    const expandedNode = event.value ? event.node : undefined;

    if (
      expandedNode !== undefined &&
      expandedNode.children?.length === 1 &&
      expandedNode.children[0].label === this.loadingContent
    ) {
      const treeNodePath: string = this.getTreeNodePath(event.node);

      if (this.tenantId !== undefined) {
        this.listWorkspaceContent(
          this.tenantId,
          this.region,
          treeNodePath
        ).subscribe((workspaceContent) => {
          const children: TreeModel = this.mapToTreeModel(workspaceContent);
          this.updateNodeChildren(expandedNode, children);
        });
      }
    }
  }

  public updateNodeChildren(node: ITreeNode, children: TreeModel): void {
    if (node.children !== undefined) {
      node.children.forEach((child) => {
        if (child.label === this.loadingContent) {
          this.removeNode(child);
        }
      });
    }

    this.addChildren(node, children);
  }

  public getTreeNodePath(treeNode: ITreeNode): string {
    let path: string = '';
    let currentNode: ITreeNode | undefined = treeNode;

    while (currentNode !== undefined) {
      const isFolder: boolean = currentNode.children !== undefined;
      path = isFolder
        ? currentNode.label + '/' + path
        : currentNode.label + path;

      currentNode = this.getParent(currentNode);
    }

    // Remove the first node (Home Folder) from the path
    // This first node was needed to initialize/update the tree, but is not needed when requesting workspace content
    if (path.startsWith(this.homeFolder + '/')) {
      path = path.substring((this.homeFolder + '/').length);
    }

    return path;
  }
}
