import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  ApplicationCard,
  ApplicationCardDetails,
} from 'src/app/core/models/application-card';
import {
  TooltipPosition,
  ITooltipConfig,
} from '@ra-web-tech-ui-toolkit/common-utils/tooltip';
import { ICardConfig } from '@ra-web-tech-ui-toolkit/layout/card';
import {
  DialogService,
  IDialogContentProjection,
} from '@ra-web-tech-ui-toolkit/popups/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ActionButtonStyles } from '@ra-web-tech-ui-toolkit/cdk/types';
import { KindFromTenantServices } from '../../../core/models/twin-studio.model';

export enum ApplicationCardAction {
  LOGO = 'logo',
  INFO = 'info',
}

@Component({
  selector: 'app-application-card',
  templateUrl: './application-card.component.html',
  styleUrls: ['./application-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ApplicationCardComponent implements OnInit, OnChanges {
  @Input() applicationCard!: ApplicationCard;
  @Output() clickAction = new EventEmitter();

  config!: ICardConfig;
  readonly cardAction = ApplicationCardAction;
  readonly assets = '/assets/images/';
  readonly tooltipConfig: ITooltipConfig = {
    position: TooltipPosition.Bottom,
    maxWidth: 170,
  };
  applicationDetails!: ApplicationCardDetails;
  subtitle = 'applicationsDetails.subtitle';

  applicationsDetails: ApplicationCardDetails[] = [
    {
      kind: KindFromTenantServices.Emulate3d,
      title: this.translateSvc.instant('applicationsDetails.emulateTitle'),
      icon: 'ra-icon-ide-lg-simulation-project',
      image: `${this.assets}emulate3DDetails.svg`,
      text: this.translateSvc.instant('applicationsDetails.emulateText'),
      subtitle: this.translateSvc.instant(this.subtitle),
      software: [
        this.translateSvc.instant('applicationsDetails.preInstalled.logix'),
        this.translateSvc.instant('applicationsDetails.preInstalled.echo'),
        this.translateSvc.instant('applicationsDetails.preInstalled.viewme'),
        this.translateSvc.instant(
          'applicationsDetails.preInstalled.viewDesigner'
        ),
      ],
    },
    {
      kind: KindFromTenantServices.Logix,
      title: this.translateSvc.instant('applicationsDetails.logixTitle'),
      icon: 'ra-icon-ide-lg-echo-snap',
      image: `${this.assets}studio5000LogixDetails.svg`,
      text: this.translateSvc.instant('applicationsDetails.logixText'),
      subtitle: this.translateSvc.instant(this.subtitle),
      software: [
        this.translateSvc.instant('applicationsDetails.preInstalled.logix'),
        this.translateSvc.instant('applicationsDetails.preInstalled.echo'),
      ],
    },
    {
      kind: KindFromTenantServices.Arena,
      title: this.translateSvc.instant('applicationsDetails.arenaTitle'),
      icon: 'ra-icon-ide-lg-arena',
      image: `${this.assets}arenaDetails.svg`,
      text: this.translateSvc.instant('applicationsDetails.arenaText'),
      subtitle: this.translateSvc.instant(this.subtitle),
      software: [
        this.translateSvc.instant('applicationsDetails.preInstalled.arena'),
      ],
    },
  ];

  get logo(): string {
    return `${this.assets}${this.applicationCard.logo}`;
  }

  clickedCard(action: ApplicationCardAction): void {
    const data = {
      action,
      card: this.applicationCard,
    };
    this.clickAction.emit(data);
  }

  clickedInfo(content?: IDialogContentProjection): void {
    const dialogConfig = {
      title: '',
      message: '',
      hideTitleIcon: true,
      buttons: [
        {
          label: this.translateSvc.instant('launch'),
          buttonStyle: ActionButtonStyles.Main,
        },
        {
          label: this.translateSvc.instant(
            'RA_UI_COMMON_VIEWS.STEPPER.BTN_CLOSE_LABEL'
          ),
          buttonStyle: ActionButtonStyles.Outlined,
        },
      ],
      showCloseIconButton: true,
      contentProjection: content,
    };
    const dialogRef = this.dialogService.openDialog(dialogConfig);
    dialogRef.componentInstance.onClick.subscribe((data) => {
      if (data.buttonStyle === 'main') {
        this.clickedCard(this.cardAction.LOGO);
      }
    });
  }

  getConfiguration(): ICardConfig {
    const config: ICardConfig = {
      width: '210px',
      height: '220px',
      header: {
        title: this.applicationCard.name,
      },
      footer: this.applicationCard.isActive
        ? {
          textButtons: [{ label: this.translateSvc.instant('launch') }],
          icons: ['ra-icon-ide-sm-open'],
          iconButtons: [{ icon: 'ra-icon-ide-md-info' }],
        }
        : {},
    };

    if (!this.applicationCard.isActive) {
      config.header.iconButtons = [
        {
          icon: 'ra-icon-ide-md-lock ra-common-icon',
        },
      ];
    }

    return config;
  }

  constructor(
    private readonly dialogService: DialogService,
    private readonly translateSvc: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.applicationCard) {
      this.applicationDetails = this.applicationsDetails.find(
        (app) => app.kind === this.applicationCard.kind
      ) as ApplicationCardDetails;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.applicationCard) {
      this.config = this.getConfiguration();
    }
  }
}
