import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectLocationComponent } from './select-location.component';
import { TitleStepModule } from '../../../shared/components/title-step/title-step.module';
import { TranslateModule } from '@ngx-translate/core';
import { HomeFolderModule } from '../../../shared/components/home-folder/home-folder.module';
import { RaUiProgressBarModule } from '@ra-web-tech-ui-toolkit/indicators/progress-bar';

@NgModule({
  declarations: [SelectLocationComponent],
  imports: [
    CommonModule,
    TitleStepModule,
    TranslateModule,
    HomeFolderModule,
    RaUiProgressBarModule,
  ],
  exports: [SelectLocationComponent],
})
export class SelectLocationModule {}
