import { createSelector } from '@ngrx/store';
import { FeatureFlagState } from './feature-flag.model';
import { AppState } from '..';

export const selectStateFeatureFlags = (state: AppState): FeatureFlagState => {
  return state.featureFlags;
};

export const selectFlags = createSelector(
  selectStateFeatureFlags,
  (featureFlagsState: FeatureFlagState) => {
    return featureFlagsState.flags;
  }
);
