import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { RepositoryFilesSelected } from '../../associated-projects/associated-projects.component';
import { Nullable } from '@ra-web-tech-ui-toolkit/cdk/types';
import {
  ITooltipConfig,
  TooltipPosition,
} from '@ra-web-tech-ui-toolkit/common-utils/tooltip';

export interface RemoveFile {
  repositoryId: string;
  projectId: string;
}

@Component({
  selector: 'app-display-files',
  templateUrl: './display-files.component.html',
  styleUrls: ['./display-files.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DisplayFilesComponent {
  @Input() files!: RepositoryFilesSelected;
  @Output() removeProject = new EventEmitter<RemoveFile>();
  prefix: Nullable<string>;
  tooltipConfig: ITooltipConfig = {
    position: TooltipPosition.Top,
    maxWidth: 199,
  };

  onClickRemove(projectId: string, repositoryId: string): void {
    this.removeProject.emit({
      repositoryId: repositoryId,
      projectId: projectId,
    } as RemoveFile);
  }
}
