import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { ContentHostDirective } from './content-host.directive';
import { Content } from './content';

@Component({
  selector: 'app-content-host',
  templateUrl: './content-host.component.html',
})
export class ContentHostComponent implements OnInit, OnDestroy {
  @Input() items: Content[];
  @Output() hostEvent = new EventEmitter<any>();
  @ViewChild(ContentHostDirective, { static: true })
    contentHost: ContentHostDirective;

  currentAdIndex = -1;
  component: ComponentRef<Content>;

  constructor(private readonly resolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    this.loadComponent();
  }

  /**
   * Loads the hosted component, capable of loading an array of components, incrementing with each
   * subsequent call.
   */
  loadComponent(): void {
    this.currentAdIndex = (this.currentAdIndex + 1) % this.items.length;

    const content = this.items[this.currentAdIndex];
    const factory = this.resolver.resolveComponentFactory(content.component);
    const viewContainerRef = this.contentHost.viewContainerRef;

    viewContainerRef.clear();
    this.component = viewContainerRef.createComponent<Content>(factory);
    this.component.instance.data = content.data;

    // Subscribes to the hosted component's event
    this.component.instance.hostEvent?.subscribe((ev) => {
      this.onHostEvent(ev);
    });
  }

  ngOnDestroy(): void {
    // this.component.instance.hostEvent.unsubscribe();
  }

  /**
   * Catches event from hosted content and bubbles it up to its parent.
   */
  onHostEvent($event: any): void {
    this.hostEvent.emit($event);
  }
}
