import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Region } from '../../models/twin-studio.model';
import { TenantId } from '../../models/cs/tenant-info.model';
import {
  ConfigService,
  TwinStudioConfiguration,
} from '../config/config.service';
import { catchError, map, Observable, of } from 'rxjs';
import { GetQuotaResponse } from '../../models/quota.model';

@Injectable({
  providedIn: 'root',
})
export class QuotaService {
  constructor(
    private readonly http: HttpClient,
    private readonly configService: ConfigService<TwinStudioConfiguration>
  ) {}

  // TODO implement getQuota for workspaces function

  getQuotaForWorkspaces(tenantId: TenantId): Observable<GetQuotaResponse> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/twinstudio/tenants/${tenantId}/workspaces/quota`;
    return this.http.get<GetQuotaResponse>(url).pipe(
      map((x) => {
        return x;
      }),
      catchError((error: unknown) => {
        return of({
          status: 'failed',
          data: [],
        } as GetQuotaResponse);
      })
    );
  }

  // TODO implement getQuota for workstations by Region

  getQuotaForWorkstation(region: Region): void {}
}
