import { Component, Inject } from '@angular/core';
import { STEPPER_ITEM_DATA } from '@ra-web-tech-ui-toolkit/common-views/stepper';
import { ProgressBarMode } from '@ra-web-tech-ui-toolkit/indicators/progress-bar';

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss'],
})
export class SelectLocationComponent {
  mode: ProgressBarMode = ProgressBarMode.Indeterminate;

  constructor(@Inject(STEPPER_ITEM_DATA) public viewData: any) {}

  setLocation(location: string): void {
    this.viewData.setLocation(location);
  }

  uploadStarted(): boolean {
    return this.viewData.getUploadStarted();
  }
}
