import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, skipWhile } from 'rxjs';
import { AuthActions } from './action-types';
import {
  selectIsLoggedIn,
  selectIsLoggedOut,
  selectUserInfo,
} from './auth.selectors';
import { UserProfile } from './user-profile.model';

@Injectable({
  providedIn: 'root',
})
export class AuthFacade {
  selectIsLoggedIn: Observable<boolean> = this.store
    .select(selectIsLoggedIn)
    .pipe(skipWhile((loggedOn) => loggedOn === undefined));
  selectIsLoggedOut: Observable<boolean> = this.store.select(selectIsLoggedOut);
  userInfo$: Observable<UserProfile> = this.store.select(selectUserInfo);

  constructor(private readonly store: Store) {}

  loginSuccess(user: UserProfile): void {
    this.store.dispatch(AuthActions.loginSuccess({ user }));
  }

  callback(user: UserProfile): void {
    this.store.dispatch(AuthActions.callback({ user }));
  }

  logout(): void {
    this.store.dispatch(AuthActions.logout());
  }
}
