import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { SelectFilesComponent } from './select-files.component';
import { RaUiNavigationTabModule } from '@ra-web-tech-ui-toolkit/navigation/navigation-tab';
import { RaUiNavTabItemModule } from '@ra-web-tech-ui-toolkit/navigation/cdk';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from '@ag-grid-community/angular';
import { TitleStepModule } from '../../../shared/components/title-step/title-step.module';
import { SelectedFilesModule } from './selected-files/selected-files.module';
import { HomeFolderModule } from '../../../shared/components/home-folder/home-folder.module';
import { RaUiLoadingSpinnerModule } from '@ra-web-tech-ui-toolkit/indicators/loading-spinner';
import { WorkstationFilesModule } from '../../../shared/components/workstation-files/workstation-files.module';

@NgModule({
  declarations: [SelectFilesComponent],
  imports: [
    CommonModule,
    RaUiNavigationTabModule,
    RaUiNavTabItemModule,
    TranslateModule,
    AgGridModule,
    TitleStepModule,
    SelectedFilesModule,
    HomeFolderModule,
    AsyncPipe,
    RaUiLoadingSpinnerModule,
    WorkstationFilesModule,
  ],
})
export class SelectFilesModule {}
