import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { GridOptions, ICellRendererParams } from '@ag-grid-community/core';
import { Observable, Subject, tap } from 'rxjs';
import { PushService } from '../../../core/services/push/push.service';
import { TranslateService } from '@ngx-translate/core';
import {
  GetRecentFilesResponse,
  Region,
} from '../../../core/models/twin-studio.model';
import {
  STEPPER_ITEM_DATA,
  StepperComponent,
} from '@ra-web-tech-ui-toolkit/common-views/stepper';
import { TenantId } from '../../../core/models/cs/tenant-info.model';
import {
  getFilename,
  getImagePathForUnknownProject,
} from '../../../core/services/vault/view-status/project-file-utility';

import { RecentFilesTable } from '../../../core/models/table-templates.model';
import { PushToVaultFormData } from '../../../core/models/upload-file.model';
import {
  AnimationType,
  LoadingSpinnerDefinedSize,
  LoadingSpinnerSize,
} from '@ra-web-tech-ui-toolkit/indicators/loading-spinner';

@Component({
  selector: 'app-select-files',
  templateUrl: './select-files.component.html',
  styleUrls: ['./select-files.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectFilesComponent implements OnInit {
  @Input() labelTitle!: string;
  public region: string = this.stepperCmp.items[0].data.region;
  public tenantId: TenantId = this.stepperCmp.items[0].data.tenantId;
  public numberStep: number = this.stepperCmp.items.length === 4 ? 2 : 1;
  public buttonPosition: number = this.stepperCmp.items[0].data
    .isSelectRegionDisabled
    ? 0
    : 1;
  public size: LoadingSpinnerSize = LoadingSpinnerDefinedSize.XLarge;
  public animationType = AnimationType.Spinner;
  public animationDuration: number = 1;
  public recentFilesData!: RecentFilesTable[];
  public isDataAvailable: boolean = false;
  public isLoading = true;
  public gridApi!: any;
  public gridOptions!: GridOptions;
  isGridReady$ = new Subject();

  recentFiles$: Observable<GetRecentFilesResponse>;

  constructor(
    @Inject(STEPPER_ITEM_DATA) public viewFormData: PushToVaultFormData,
    @Optional() readonly stepperCmp: StepperComponent,
    private readonly pushService: PushService,
    private readonly translateService: TranslateService,
    private readonly cd: ChangeDetectorRef
  ) {
    this.recentFiles$ = this.pushService
      .getRecentFiles(this.tenantId, this.region as Region)
      .pipe(
        tap((response: GetRecentFilesResponse) => {
          const recentFilesList: RecentFilesTable[] = [];
          const list = response.recentFileList;
          list.forEach((item) => {
            const path = item.sk;
            const iconPath = getImagePathForUnknownProject(item.sk);
            const filename = getFilename(item.sk);
            const recentFile: RecentFilesTable = {
              iconFilename: iconPath,
              filename: filename,
              path: path,
              pk: item.pk,
              sk: item.sk,
            };
            recentFilesList.push(recentFile);
          });
          this.isDataAvailable = recentFilesList.length > 0;
          this.recentFilesData = recentFilesList;
          this.initializeAGGrid();
          this.updateRowSource();
          this.isLoading = false;
          cd.detectChanges();
        })
      );
  }

  ngOnInit(): void {}

  private initializeAGGrid(): void {
    this.gridOptions = {
      columnDefs: [
        {
          width: 30,
          cellStyle: { border: 'none' },
          headerCheckboxSelection: true,
          checkboxSelection: true,
          headerClass: 'cell-header-checkbox header-checkbox',
          cellClass: 'cell-header-checkbox',
        },
        {
          headerName: this.translateService.instant('columnsTable.fileName'),
          field: 'filename',
          cellRenderer: this.getFilenameIcon,
          flex: 1,
          suppressMovable: true,
          sortable: true,
          filter: true,
          cellClass: 'cell-text-push-vault',
          cellStyle: { border: 'none' },
          filterParams: {
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
            ],
            trimInput: true,
            filterPlaceholder: this.translateService.instant(
              'pushToVault.filters.name'
            ),
            maxNumConditions: 1,
          },
        },
        {
          headerName: this.translateService.instant('pushToVault.path'),
          field: 'path',
          cellClass: 'cell-text-push-vault',
          cellStyle: { border: 'none' },
          suppressMovable: true,
          sortable: true,
          flex: 1,
        },
      ],

      defaultColDef: {
        editable: false,
        filter: false,
        resizable: true,
      },

      suppressContextMenu: true,
      suppressCellFocus: true,
      rowSelection: 'multiple',
      domLayout: 'autoHeight',
      icons: {
        menu: '<span class="ag-icon ra-icon-filter ra-icon-ide-md-filter active-filter"></span>',
      },
    };
  }

  onGridReady(event: any): void {
    this.gridApi = event.api;
    this.isGridReady$.next(true);
    this.gridApi.sizeColumnsToFit();
    this.updateRowSource();
  }

  updateRowSource(): void {
    if (this.recentFilesData) {
      this.gridApi?.setRowData(this.recentFilesData);
    }
  }

  getFilenameIcon = (params: ICellRendererParams): HTMLSpanElement => {
    const filename: string = params.value;
    const eGui = document.createElement('span');
    eGui.setAttribute(
      'style',
      'display:inline-block;overflow:hidden;text-overflow:ellipsis;'
    );
    eGui.innerHTML =
      params.data.iconFilename === undefined
        ? `<div class="cell-row">${filename}</div>`
        : `<div class="cell-row"><img width="20px" src="${params.data.iconFilename}" style="margin-right: 10px" /><span class="text">${filename}</span></div>`;
    return eGui;
  };

  onSelectionChanged(): void {
    const selectedRows = this.gridApi.getSelectedRows();
    this.viewFormData.files = selectedRows as RecentFilesTable[];
  }

  onRemoveFile(filenameToRemove: string): void {
    this.gridApi.getSelectedNodes().forEach((node: any) => {
      if (node.data.filename === filenameToRemove) {
        node.setSelected(false);
      }
    });
    this.gridApi.redrawRows();
  }

  onHomeFolderFileSelected(filepath: string): void {
    const filePathParts: string[] = filepath.split('/');
    const file = {
      filename: filePathParts[filePathParts.length - 1],
      path: filepath,
      iconFilename: getImagePathForUnknownProject(
        filePathParts[filePathParts.length - 1]
      ),
      pk: '',
      sk: '',
    } as RecentFilesTable;
    this.viewFormData.files = [...this.viewFormData.files, file];
  }

  onWorkspacesFileSelected(files: RecentFilesTable[]): void {
    this.viewFormData.files = files;
  }
}
