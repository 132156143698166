<app-title-step
  [labelTitle]="'quickAccessUpload.upload' | translate"
  [numberStep]="1"
  [subtitle]="'quickAccessUpload.selectFilesSubtitle' | translate"
  [iconTitle]="'ra-icon-ide-sm-upload'"
></app-title-step>
<div
  class="file-input-container"
  [class]="currentFiles.length === 0 ? 'center-empty' : ''"
>
  <ra-ui-file-input
    #fileInput
    [(value)]="currentFiles"
    [hidden]="currentFiles.length === 0"
    [config]="inputFileConfig"
    [label]="'quickAccessUpload.fileInputLabel' | translate"
    [hintMessage]="fileInputHintMessage"
    [buttonLabel]="'fileInput.browse' | translate"
    [dndMessage]="'fileInput.dndMessage' | translate"
    [errorMessage]="currentFiles.length <= maxFiles ? '' : tooManyFilesMessage"
    [disabled]="disabled"
    (valueChange)="onValueChange($event)"
  ></ra-ui-file-input>
  <app-file-list
    *ngIf="currentFiles.length === 0"
    [listItems]="[]"
    [config]="fileListConfig"
    (emptyStateButtonClicked)="openFileExplorer()"
  ></app-file-list>
</div>
