import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  setOrganizationContext,
  setOrganizationContextSuccess,
  resetSession,
  resetSessionSuccess,
  pushUserProfile,
  pushUserProfileSuccess,
  pushCustomEvent,
  pushCustomEventSuccess,
} from './gainsight.actions';
import { IGainsightOrganizationContext } from '../../models/gainsight.model';
import { GainsightService } from '../../services/gainsight/gainsight.service';

@Injectable({
  providedIn: 'root',
})
export class GainsightEffects {
  pushUserProfile$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(pushUserProfile),
      map((payload) => {
        this.gainsightService.pushUserProfile(payload.payload.userProfile);
        return pushUserProfileSuccess();
      })
    );
  });

  resetSession$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(resetSession),
      map(() => {
        this.gainsightService.reset();
        return resetSessionSuccess();
      })
    );
  });

  setOrganizationContext$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(setOrganizationContext),
      map((payload) => {
        const context: IGainsightOrganizationContext = {
          organizationId: payload.payload.tenantId,
          organizationName: payload.payload.tenantName,
        };
        this.gainsightService.setGlobalContext(context);
        return setOrganizationContextSuccess();
      })
    );
  });

  pushCustomEvent$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(pushCustomEvent),
      map((payload) => {
        this.gainsightService.pushCustomEvent(payload.gainsightCustomEvent);
        return pushCustomEventSuccess();
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly gainsightService: GainsightService
  ) {}
}
