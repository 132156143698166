<div class="selected-files-container">
  <p [class]="repositoriesFilesSelected.length === 0 ? 'disabled' : ''">
    Selected files
  </p>
  <ra-ui-search-input
    #searchInput
    id="search-selected-files"
    [config]="searchConfig"
    [disabled]="repositoriesFilesSelected.length > 0 ? false : true"
    (searchTextChange)="filterByText($event)"
  >
  </ra-ui-search-input>
  <div *ngIf="repositoriesFilesSelected.length === 0" class="empty-files">
    <img
      id="empty-folder"
      src="/assets/images/no-files.svg"
      alt="empty-folder"
    />
    <p id="title-empty" class="title-empty">
      {{ "pullFromVault.noFilesTitle" | translate }}
    </p>
    <p id="subtitle-empty" class="subtitle-empty">
      {{ "pullFromVault.noFilesText" | translate }}
    </p>
  </div>
  <div *ngIf="repositoriesFilesSelected.length > 0">
    <div *ngFor="let repository of repositoriesFilesSelected">
      <p class="repository-name">{{ repository.repositoryName }}</p>
      <app-display-files
        [files]="repository"
        (removeProject)="onRemoveProject($event)"
      ></app-display-files>
    </div>
  </div>
</div>
