import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TenantInfo } from 'src/app/core/models/cs/tenant-info.model';
import { Session } from '../../../core/models/twin-studio.model';
import { SessionFacade } from '../../../core/reducers/session/session.facade';
import { Router } from '@angular/router';

@Component({
  selector: 'app-push',
  templateUrl: './push.component.html',
  styleUrls: ['./push.component.scss', '../workstation-menu.component.scss'],
})
export class PushComponent implements OnInit {
  @Input() currentTenant: TenantInfo;
  @Input() sessionData?: Session;
  @Input() displayCancel = true;
  @Output() continueWithSession = new EventEmitter<boolean>();

  constructor(
    private readonly sessionFacade: SessionFacade,
    private readonly router: Router
  ) {}

  ngOnInit(): void {}

  onContinue(): void {
    if (this.sessionData !== undefined) {
      this.continueWithSession.emit(true);
    } else {
      this.router.navigate(['/']);
    }
  }
}
