import { Component, inject, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ThemeColor } from '@ra-web-tech-ui-toolkit/cdk/types';
import { SidenavService } from '../../../core/services/sidenav.service';

@Component({
  selector: 'app-title-step',
  templateUrl: './title-step.component.html',
  styleUrls: ['./title-step.component.scss'],
})
export class TitleStepComponent implements OnInit {
  @Input() labelTitle!: string;
  @Input() iconTitle!: string;
  @Input() subtitle!: string;
  @Input() numberStep!: number;
  @Input() region!: string;
  regionTranslate: string = '';

  translateService = inject(TranslateService);
  howIsMyDataStored: string = this.translateService.instant(
    'appstream.dataStored'
  );
  buttonColor = ThemeColor.Primary;
  sidenavService = inject(SidenavService);

  ngOnInit(): void {
    this.regionTranslate = 'regions.' + this.region;
  }

  onOpenSidenav(): void {
    this.sidenavService.toggle();
  }
}
