import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  ISearchInputConfig,
  ISearchInputText,
} from '@ra-web-tech-ui-toolkit/form-system/search-input';
import { RepositoryFilesSelected } from '../associated-projects/associated-projects.component';
import { RemoveFile } from './display-files/display-files.component';

@Component({
  selector: 'app-selected-files',
  templateUrl: './selected-files.component.html',
  styleUrls: ['./selected-files.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectedFilesComponent {
  @Input() repositoriesFilesSelected!: RepositoryFilesSelected[];
  @Output() removeProject = new EventEmitter<RemoveFile>();
  searchInputText: ISearchInputText = {
    searchInputLabel: 'Search for ...',
  };

  searchConfig: ISearchInputConfig = {
    searchInputText: this.searchInputText,
  };

  filterByText(criteria: string): void {}

  onRemoveProject(removeProject: RemoveFile): void {
    this.removeProject.emit(removeProject);
  }
}
