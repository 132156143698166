import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectRegionComponent } from './select-region.component';
import { RaUiSelectModule } from '@ra-web-tech-ui-toolkit/form-system/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RaUiFormGridModule } from '@ra-web-tech-ui-toolkit/grid/form-grid';
import { TranslateModule } from '@ngx-translate/core';
import { TitleStepModule } from '../../../shared/components/title-step/title-step.module';
import { RaUiFormModule } from '@ra-web-tech-ui-toolkit/form-system/form';

@NgModule({
  declarations: [SelectRegionComponent],
  imports: [
    CommonModule,
    RaUiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    RaUiFormGridModule,
    TranslateModule,
    TitleStepModule,
    RaUiFormModule,
  ],
})
export class SelectRegionModule {}
