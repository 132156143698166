import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, map, of } from 'rxjs';
import {
  EndSessionResponse,
  Session,
  StartSessionPayload,
  StartSessionResponse,
} from '../../models/twin-studio.model';
import { LCoreResponse } from '../../models/lcore-response.model';
import {
  ConfigService,
  TwinStudioConfiguration,
} from '../config/config.service';
import { SessionState } from '../../reducers/session/session.reducer';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(
    private readonly http: HttpClient,
    private readonly configService: ConfigService<TwinStudioConfiguration>
  ) {}

  startSession(sessionData: Session): Observable<StartSessionResponse> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/vaults/${sessionData.vaultId}/appsession/${sessionData.kind}/start`;
    const body: StartSessionPayload = {
      tier: sessionData.tier.tier,
      projects: sessionData.files,
      region: sessionData.region,
    };
    if (sessionData.workspace !== undefined) {
      body.workspace = sessionData.workspace;
    }
    if (sessionData.solutionName !== undefined) {
      body.solutionName = sessionData.solutionName;
    }
    return this.http.post<LCoreResponse<StartSessionResponse>>(url, body).pipe(
      map((x) => {
        return x.data;
      })
    );
  }

  endSession(session: Session): Observable<string> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/vaults/${session.tenantId}/appsession/${session.kind}/stop`;
    const body = {
      tier: session.tier.tier,
      region: session.region,
    };
    return this.http.post<LCoreResponse<EndSessionResponse>>(url, body).pipe(
      map((x) => {
        return x.data.ok;
      })
    );
  }

  endAllSessions(sessionState: SessionState): Observable<string[]> {
    const sessionsActive = sessionState.sessionsActive;
    const sessions = Object.values(sessionsActive);
    if (sessions.length > 0) {
      return forkJoin(
        sessions.map((session) => {
          return this.endSession(session);
        })
      );
    } else {
      return of(['']);
    }
  }
}
