import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { isNumber } from 'lodash-es';
import {
  DataWithId,
  CheckboxToolTip,
  Templates,
} from '../../core/models/table-templates.model';
import { Column } from '../../shared/utility/project-table-helper';
import { DataProject, Instance } from '../../core/models/twin-studio.model';
import {
  BADGE_STATUS_COLORS,
  BadgePosition,
  BadgeSize,
} from '@ra-web-tech-ui-toolkit/indicators/badge';

export type TableSortingDataAccessor<T> = (
  data: T,
  header: string
) => string | number;

const defaultSortingDataAccessor = (
  data: any,
  header: string
): string | number => {
  const val = data[header];
  if (typeof val === 'string' || val instanceof String) {
    return val.toLowerCase();
  }
  if (isNumber(val)) {
    return val.valueOf();
  }
  return '';
};

/**
 * Enforces requirement that all data coming through here as
 * an 'id' of type string.
 */

@Component({
  selector: 'app-design-hub-table',
  templateUrl: './design-hub-table.component.html',
  styleUrls: ['./design-hub-table.component.scss'],
})
export class DesignHubTableComponent implements OnInit, OnChanges {
  private _columns: Column[] = [];

  @ViewChild(MatSort, { static: false })
  set matSortChildView(value: MatSort | undefined) {
    if (value) {
      this.setupSorting(value);
    }
  }

  @Input() filterText: string | undefined;
  @Input() filter: boolean = true;
  @Input() showHeaderCheckbox: boolean = true;
  @Input() isRadioButtonTable: boolean = false;
  @Input() sortingDataAccessor: TableSortingDataAccessor<any> | undefined;
  @Input() tableData: Array<DataWithId> = [];
  @Input() disableCheckboxIds: Array<DataWithId> = [];
  @Input() checkboxToolTips: Array<CheckboxToolTip> = [];
  @Input() isInstancesTable: boolean = false;

  @Output() instanceSelected = new EventEmitter<Instance>();

  templateEnum = Templates;
  get columns(): Column[] {
    return this._columns;
  }

  @Input() set columns(columns: Column[]) {
    this._columns = columns;
    this.setColumns(columns);
  }

  @Output() itemsSelected = new EventEmitter<unknown>();

  tableColumns: string[] = [];
  dataSource!: MatTableDataSource<unknown>;
  checkboxes!: SelectionModel<unknown>;

  selectedBadgeBlueColor = BADGE_STATUS_COLORS.Information;
  selectedBadgeGrayColor = BADGE_STATUS_COLORS.Status;
  selectedBadgePosition: BadgePosition = BadgePosition.After;
  selectedBadgeSize: BadgeSize = BadgeSize.Small;

  constructor() {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.refreshTable();
    this.checkboxes = new SelectionModel<unknown>(true, [], true);
    this.checkboxes.changed.subscribe(() => {
      this.onCheckboxesChanged();
    });
  }

  ngOnChanges(changes: { [InputName: string]: SimpleChange }): void {
    if (
      !changes.tableData?.firstChange &&
      changes?.tableData?.previousValue !== changes?.tableData?.currentValue
    ) {
      this.refreshTable();

      // Find and uncheck any checked items that no longer exist in the dataset.
      const gone = _.differenceWith(
        this.checkboxes.selected,
        changes?.tableData?.currentValue,
        (a: string | number, b: DataWithId) => a === b.id
      );

      this.checkboxes.deselect(...gone);
    }
  }

  refreshTable(): void {
    this.dataSource.data = this.tableData;
  }

  private setColumns(columns: Column[]): void {
    this.tableColumns = columns.map((c) => c.columnDef);
  }

  public doFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLocaleLowerCase();
  }

  isDisable(id: string | number): boolean {
    return this.disableCheckboxIds.some((ob) => ob.id === id);
  }

  getToolTip(id: string | number): string {
    const item = this.checkboxToolTips.find((x): boolean => {
      return x.id.id === id;
    });
    if (item) {
      return item.message;
    }
    return '';
  }

  allChecked(): boolean {
    const disableItems = this.disableCheckboxIds.length;
    const checkboxSelected = this.checkboxes?.selected.length ?? 0;
    return (
      this.checkboxes?.hasValue() &&
      (this.dataSource?.data.length ?? 0) - disableItems ===
        ((disableItems > 0 && checkboxSelected === 1) ||
        (disableItems === 0 && checkboxSelected > 0)
          ? checkboxSelected
          : checkboxSelected - 1) //Extra checkbox from header
    );
  }

  allDisabled(): boolean {
    const unchecked = _.differenceWith(
      this.dataSource.data,
      this.disableCheckboxIds,
      (a: DataWithId, b: DataWithId) => a.id === b.id
    );

    return unchecked.length === 0;
  }

  selectAll(): void {
    if (this.allChecked()) {
      this.checkboxes.clear();
    } else {
      // Only select checkboxes that aren't disabled.
      const selectThese = _.differenceWith(
        this.dataSource.data,
        this.disableCheckboxIds,
        (a: DataWithId, b: DataWithId) => a.id === b.id
      );

      this.checkboxes.select(...selectThese.map((data: DataWithId) => data.id));
    }
  }

  private setupSorting(matSort: MatSort): void {
    this.dataSource.sortingDataAccessor =
      this.sortingDataAccessor ?? defaultSortingDataAccessor;
    this.dataSource.sort = matSort;
  }

  private onCheckboxesChanged(): void {
    const selected = this.checkboxes.selected.map(
      (selection: string | number) =>
        this.dataSource.data.find((entry: DataWithId) => entry.id === selection)
    );
    this.itemsSelected.emit(selected);
  }

  // Handle the Enter key pressed event here
  onEnterPressed(
    event: any,
    noteValue: string,
    rowid: any,
    columnHeader: string
  ): void {
    this.updateRow(noteValue, rowid, columnHeader);
  }

  private updateRow(value: string, rowId: string, dataField: string): void {
    // @ts-expect-error
    const index = this.dataSource.data.findIndex((item) => item.id === rowId);
    if (index >= 0) {
      // Update the properties of the row with the updated values
      // @ts-expect-error
      this.dataSource.data[index].file[dataField] = value.trim();
      if (dataField === 'projectName') {
        // @ts-expect-error
        this.dataSource.data[index].file.projectId = null;
      }
      this.dataSource.data = [...this.dataSource.data];
    }
    this.onCheckboxesChanged();
  }

  selectProject(
    dataProject: DataProject,
    rowId: string,
    dataField: string
  ): void {
    // @ts-expect-error
    const index = this.dataSource.data.findIndex((item) => item.id === rowId);
    if (index >= 0) {
      // Update the properties of the row with the updated values
      // @ts-expect-error
      this.dataSource.data[index].file.projectName =
        dataProject.projectName.trim();
      // @ts-expect-error
      this.dataSource.data[index].file.projectId =
        dataProject.projectId !== '' ? dataProject.projectId : null;
      this.dataSource.data = [...this.dataSource.data];
      this.onCheckboxesChanged();
    }
  }

  selectInstance(instance: Instance): void {
    this.instanceSelected.emit(instance);
  }
}
