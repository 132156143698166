import { Component, Optional, ViewEncapsulation } from '@angular/core';
import { StepperComponent } from '@ra-web-tech-ui-toolkit/common-views/stepper';
import { RepositoryFilesSelected } from '../select-project/associated-projects/associated-projects.component';
import {
  ITreeConfig,
  ITreeInitializedEvent,
  ITreeNode,
  RaUiTreeControl,
  TreeModel,
} from '@ra-web-tech-ui-toolkit/lists/tree';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SummaryComponent {
  isConnected: boolean = false;
  repositories: RepositoryFilesSelected[] =
    this.stepperCmp.items[1].data.filesPulled;
  treeModel!: TreeModel;
  treeControl!: RaUiTreeControl;
  config: ITreeConfig = {
    isNodeIconEnabled: false,
  };

  constructor(@Optional() readonly stepperCmp: StepperComponent) {
    this.treeModel = this.repositories.map((repo) => {
      const treeNode: ITreeNode = {
        label: repo.repositoryName,
        icon: 'ra-icon-ide-lg-folder',
        isParent: true,
        children: repo.filesSelected.map((project) => {
          const treeNode: ITreeNode = {
            label: project.projectName,
            iconProject: project.icon,
            projectId: project.projectId,
            projectFile: project.file,
            checked: false,
            showSuffix: true,
            isParent: false,
          };
          return treeNode;
        }),
      };
      return treeNode;
    });
  }

  onTreeInitialized(event: ITreeInitializedEvent): void {
    this.treeControl = event.control;
    this.treeControl.expand(this.treeModel[0]);
  }
}
