import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectedFilesComponent } from './selected-files.component';
import { RaUiSearchInputModule } from '@ra-web-tech-ui-toolkit/form-system/search-input';
import { TranslateModule } from '@ngx-translate/core';
import { DisplayFilesModule } from './display-files/display-files.module';

@NgModule({
  declarations: [SelectedFilesComponent],
  imports: [
    CommonModule,
    RaUiSearchInputModule,
    TranslateModule,
    DisplayFilesModule,
  ],
  exports: [SelectedFilesComponent],
})
export class SelectedFilesModule {}
