import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Content } from '../content-host/content';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  isDisabled = false;
  isPanel = false;
  isPrompt = false;
  response = {};

  // Used to calculate content height in template
  titleHeightPixels = 72;
  actionHeightPixels = 84;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      headerIcon: string;
      cancelText: string;
      confirmText: string;
      message: string;
      content: Content[];
      title: string;
      width: number;
      height: number;
      isPanel: boolean;
      isDisabled: boolean;
      needsToTakeAction: boolean;
      isPrompt: boolean;
    },
    private readonly mdDialogRef: MatDialogRef<ConfirmDialogComponent>,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'close-x',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/icons/close-x.svg'
      )
    );

    if (data.headerIcon) {
      iconRegistry.addSvgIcon(
        'header-icon',
        sanitizer.bypassSecurityTrustResourceUrl(data.headerIcon)
      );
    }
  }

  @HostListener('keydown.esc')
  onEsc(): void {
    if (!this.data.needsToTakeAction) {
      this.close(false);
    }
  }

  ngOnInit(): void {
    this.isPanel = this.data.isPanel;
    this.isPrompt = this.data.isPrompt ?? false;
    this.isDisabled = this.data.isDisabled ?? false;
  }

  cancel(): void {
    this.close(false);
  }

  close(value: any): void {
    this.mdDialogRef.close(value);
  }

  confirm(): void {
    if (!this.isDisabled) {
      const result = this.response ? this.response : true;
      this.close(result);
    }
  }

  onHostEvent($event: any): void {
    this.isDisabled = $event.isDisabled;
    if (!this.isDisabled && $event.data) {
      this.response = $event.data;
    }
  }
}
