import { Injectable } from '@angular/core';
import { SideBarItemSelection } from '../../../features/navigation/sidebar/sidebar-options/sidebar-options.component';

@Injectable({
  providedIn: 'root',
})
export class SideBarService {
  public currentSideBarSelection: SideBarItemSelection =
    SideBarItemSelection.Home;

  constructor() {}
}
