import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownListComponent } from './dropdown-list.component';
import { MaterialModule } from '../../../material.module';

@NgModule({
  declarations: [DropdownListComponent],
  imports: [CommonModule, MaterialModule],
  exports: [DropdownListComponent],
})
export class DropdownListModule {}
