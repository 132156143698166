import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SessionActions } from './action-types';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  ConfigService,
  TwinStudioConfiguration,
} from 'src/app/core/services/config/config.service';

@Injectable()
export class SessionEffects {
  preLoadSession$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SessionActions.preloadBrowseSelection),
        tap((action) => {
          const sesionKind = action.preloadSessionData.sessionKind;
          const tenantId = action.preloadSessionData.tenantId;
          const newRelativeUrl = `prelaunch/${sesionKind}/${tenantId}`;
          // appBaseUrl
          if (action.preloadSessionData.openNewTab) {
            window.open(
              `${this.configService.config.appBaseUrl}/${newRelativeUrl}`,
              '_blank'
            );
          } else {
            this.router.navigate([`/prelaunch/${sesionKind}/${tenantId}`]);
          }
        })
      );
    },
    { dispatch: false }
  );

  startSession$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SessionActions.startSession),
        tap((action) => {
          const sesionKind = action.sessionData.kind;
          const tenantId = action.sessionData.tenantId;
          const tier = action.sessionData.tier.tier;
          this.router.navigate([
            `/workstation/${tenantId}/${sesionKind}/${tier}`,
          ]);
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly configService: ConfigService<TwinStudioConfiguration>
  ) {}
}
