import { Component, Inject, Output, Type, EventEmitter } from '@angular/core';

@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class Content {
  // eslint-disable-line @angular-eslint/component-class-suffix
  @Output() hostEvent = new EventEmitter();
  constructor(
    public component: Type<any>,
    @Inject(Component) public data: any
  ) {}
}
