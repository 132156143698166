import { Injectable } from '@angular/core';
import {
  Project,
  ProjStatusLabels,
  ProjType,
  FileTypes,
  ProjViewStatus,
  ProjStatusColors,
} from '../../../models/vault/project.model';
import { LogixViewStatus } from './logix-viewstatus';
import { ViewStatusHelper } from './project-viewstatus';
import { FtdsViewstatus } from './ftds-viewstatus';
import { OtherViewStatus } from './other-viewstatus';

/**
 * Project type to main file type dictionary
 * This dictionary is located here for now since this is the only place needed
 */
export const projTypeFileType: Partial<Record<ProjType, FileTypes>> = {
  [ProjType.Arena]: FileTypes.DOE,
  [ProjType.Asset]: FileTypes.ASSET,
  [ProjType.Emulate3D]: FileTypes.DEMO3D,
  [ProjType.FTDesignStudio]: FileTypes.FTDSExport,
  [ProjType.LogixDesigner]: FileTypes.ACD,
  [ProjType.ViewDesigner]: FileTypes.VPD,
  [ProjType.Eplan]: FileTypes.ACD, // TODO update when supported
  [ProjType.Optix]: FileTypes.ACD, // TODO update when supported
};

@Injectable({
  providedIn: 'root',
})
export class ProjectViewStatusService {
  constructor() {}

  getProjectStatusLabel(project: Project): ProjStatusLabels {
    const fileType = projTypeFileType[project.type] as FileTypes;
    return this.getViewStatusHelper(fileType).getProjectStatusLabel(project);
  }

  getProjViewStatus(project: Project): ProjViewStatus {
    let status = ProjStatusLabels.Error;
    const fileType = projTypeFileType[project.type] as FileTypes;
    status = this.getViewStatusHelper(fileType).getProjectStatusLabel(project);
    return {
      label: status,
      color: this.getViewStatusHelper(fileType).getColorByStatus(status),
    };
  }

  getStatusColor(project: Project, status: ProjStatusLabels): ProjStatusColors {
    const fileType = projTypeFileType[project.type] as FileTypes;
    return this.getViewStatusHelper(fileType).getColorByStatus(status);
  }

  private getViewStatusHelper(type: FileTypes): ViewStatusHelper {
    switch (type) {
      case FileTypes.ACD:
        return new LogixViewStatus();
      case FileTypes.FTDSExport:
        return new FtdsViewstatus();
      case FileTypes.DEMO3D:
      case FileTypes.DOE:
      case FileTypes.VPD:
      case FileTypes.ASSET:
        return new OtherViewStatus();
      default:
        throw new Error(`ViewStatusHelper not supported`);
    }
  }
}
