import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  SidebarItem,
  SideBarItemSelection,
} from './sidebar-options/sidebar-options.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() headerSvgPath!: string;
  @Input() sidebarItems!: SidebarItem[];
  @Input() currentSelection!: SideBarItemSelection;
  @Output() emitOptionEvent = new EventEmitter<SidebarItem>();

  collapsed: boolean = false;

  constructor() {}

  toggleSidebar(): void {
    this.collapsed = !this.collapsed;
  }

  onEmitOptionEvent(optionEvent: SidebarItem): void {
    this.emitOptionEvent.emit(optionEvent);
  }
}
