/* eslint-disable max-len */

import {
  failed,
  failedResult,
  Result,
  succeeded,
  succeededResult,
} from './result';

/**
 * If _input_ is successful, unwraps the value and passes it into _fn_,
 * returning the result.  If _input_ is not successful, returns it.
 *
 * @param fn - The function to invoke on _input.value_ when _input_ is
 * successful.
 * @param input - The input Result.
 * @return Either the passed-through failure Result or the Result returned from
 * _fn_.
 */
export function bindResult<TInputSuccess, TOutputSuccess, TError>(
  fn: (x: TInputSuccess) => Result<TOutputSuccess, TError>,
  input: Result<TInputSuccess, TError>
): Result<TOutputSuccess, TError> {
  if (succeeded(input)) {
    return fn(input.value);
  } else {
    return input;
  }
}

/**
 * When _input_ is successful, maps the wrapped value using _fn_.
 *
 * @param fn - Function that maps the wrapped success value to another value.
 * @param input - The input Result.
 * @return Either the mapped successful Result or the passed-through failure
 * Result.
 */
export function mapSuccess<TInputSuccess, TOutputSuccess, TError>(
  fn: (input: TInputSuccess) => TOutputSuccess,
  input: Result<TInputSuccess, TError>
): Result<TOutputSuccess, TError> {
  if (succeeded(input)) {
    const mappedValue = fn(input.value);
    return succeededResult(mappedValue);
  } else {
    return input;
  }
}

/**
 * When _input_ is a failure, maps the wrapped error using _fn_.
 *
 * @param fn - Function that maps the wrapped error value to another value.
 * @param input - The input Result.
 * @return Either the passed-through successful Result or the mapped error
 * Result.
 */
export function mapError<TSuccess, TInputError, TOutputError>(
  fn: (input: TInputError) => TOutputError,
  input: Result<TSuccess, TInputError>
): Result<TSuccess, TOutputError> {
  if (succeeded(input)) {
    return input;
  } else {
    const mappedError = fn(input.error);
    return failedResult(mappedError);
  }
}

/**
 * Maps values from a source collection until a failed mapping occurs.  If a
 * failure occurs, the mapping stops immediately.
 *
 * @param srcCollection - The source collection
 * @param mappingFunc - The mapping function. Each element from _srcCollection_
 * is run through this function and it must return a successful result wrapping
 * the mapped value or a failure result wrapping the error.
 * @return A successful result wrapping an array of the mapped values or a
 * failure result wrapping the first failure encountered.
 */
export function mapWhileSuccessful<TInput, TOutput, TError>(
  srcCollection: Array<TInput>,
  mappingFunc: (curItem: TInput) => Result<TOutput, TError>
): Result<Array<TOutput>, TError> {
  return srcCollection.reduce<Result<Array<TOutput>, TError>>(
    (acc, curItem) => {
      // If we have already failed, just return the error.
      if (failed(acc)) {
        return acc;
      }

      // We have not yet failed, so process the current item.
      const res = mappingFunc(curItem);
      if (succeeded(res)) {
        acc.value.push(res.value);
        return acc;
      } else {
        return res;
      }
    },
    succeededResult([])
  );
}

////////////////////////////////////////////////////////////////////////////////
// executeWhileSuccessful()
////////////////////////////////////////////////////////////////////////////////

// Decoder for type parameter names:
// T - Because all type parameters must begin with "T"
// [A-Z] - Ordinal
// [SE] - Success/Error
//
// Examples:
// TAS - The type fnA returns when successful
// TBE - The type fnB returns when failed

export function executeWhileSuccessful<TAS, TAE>(
  fnA: () => Result<TAS, TAE>
): Result<[TAS], TAE>;

export function executeWhileSuccessful<TAS, TAE, TBS, TBE>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>
): Result<[TAS, TBS], TAE | TBE>;

export function executeWhileSuccessful<TAS, TAE, TBS, TBE, TCS, TCE>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>
): Result<[TAS, TBS, TCS], TAE | TBE | TCE>;

export function executeWhileSuccessful<TAS, TAE, TBS, TBE, TCS, TCE, TDS, TDE>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>
): Result<[TAS, TBS, TCS, TDS], TAE | TBE | TCE | TDE>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>
): Result<[TAS, TBS, TCS, TDS, TES], TAE | TBE | TCE | TDE | TEE>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>
): Result<[TAS, TBS, TCS, TDS, TES, TFS], TAE | TBE | TCE | TDE | TEE | TFE>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>
): Result<
  [TAS, TBS, TCS, TDS, TES, TFS, TGS],
  TAE | TBE | TCE | TDE | TEE | TFE | TGE
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>
): Result<
  [TAS, TBS, TCS, TDS, TES, TFS, TGS, THS],
  TAE | TBE | TCE | TDE | TEE | TFE | TGE | THE
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE,
  TIS,
  TIE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>,
  fnI: () => Result<TIS, TIE>
): Result<
  [TAS, TBS, TCS, TDS, TES, TFS, TGS, THS, TIS],
  TAE | TBE | TCE | TDE | TEE | TFE | TGE | THE | TIE
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE,
  TIS,
  TIE,
  TJS,
  TJE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>,
  fnI: () => Result<TIS, TIE>,
  fnJ: () => Result<TJS, TJE>
): Result<
  [TAS, TBS, TCS, TDS, TES, TFS, TGS, THS, TIS, TJS],
  TAE | TBE | TCE | TDE | TEE | TFE | TGE | THE | TIE | TJE
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE,
  TIS,
  TIE,
  TJS,
  TJE,
  TKS,
  TKE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>,
  fnI: () => Result<TIS, TIE>,
  fnJ: () => Result<TJS, TJE>,
  fnK: () => Result<TKS, TKE>
): Result<
  [TAS, TBS, TCS, TDS, TES, TFS, TGS, THS, TIS, TJS, TKS],
  TAE | TBE | TCE | TDE | TEE | TFE | TGE | THE | TIE | TJE | TKE
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE,
  TIS,
  TIE,
  TJS,
  TJE,
  TKS,
  TKE,
  TLS,
  TLE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>,
  fnI: () => Result<TIS, TIE>,
  fnJ: () => Result<TJS, TJE>,
  fnK: () => Result<TKS, TKE>,
  fnL: () => Result<TLS, TLE>
): Result<
  [TAS, TBS, TCS, TDS, TES, TFS, TGS, THS, TIS, TJS, TKS, TLS],
  TAE | TBE | TCE | TDE | TEE | TFE | TGE | THE | TIE | TJE | TKE | TLE
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE,
  TIS,
  TIE,
  TJS,
  TJE,
  TKS,
  TKE,
  TLS,
  TLE,
  TMS,
  TME
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>,
  fnI: () => Result<TIS, TIE>,
  fnJ: () => Result<TJS, TJE>,
  fnK: () => Result<TKS, TKE>,
  fnL: () => Result<TLS, TLE>,
  fnM: () => Result<TMS, TME>
): Result<
  [TAS, TBS, TCS, TDS, TES, TFS, TGS, THS, TIS, TJS, TKS, TLS, TMS],
  TAE | TBE | TCE | TDE | TEE | TFE | TGE | THE | TIE | TJE | TKE | TLE | TME
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE,
  TIS,
  TIE,
  TJS,
  TJE,
  TKS,
  TKE,
  TLS,
  TLE,
  TMS,
  TME,
  TNS,
  TNE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>,
  fnI: () => Result<TIS, TIE>,
  fnJ: () => Result<TJS, TJE>,
  fnK: () => Result<TKS, TKE>,
  fnL: () => Result<TLS, TLE>,
  fnM: () => Result<TMS, TME>,
  fnN: () => Result<TNS, TNE>
): Result<
  [TAS, TBS, TCS, TDS, TES, TFS, TGS, THS, TIS, TJS, TKS, TLS, TMS, TNS],
  | TAE
  | TBE
  | TCE
  | TDE
  | TEE
  | TFE
  | TGE
  | THE
  | TIE
  | TJE
  | TKE
  | TLE
  | TME
  | TNE
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE,
  TIS,
  TIE,
  TJS,
  TJE,
  TKS,
  TKE,
  TLS,
  TLE,
  TMS,
  TME,
  TNS,
  TNE,
  TOS,
  TOE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>,
  fnI: () => Result<TIS, TIE>,
  fnJ: () => Result<TJS, TJE>,
  fnK: () => Result<TKS, TKE>,
  fnL: () => Result<TLS, TLE>,
  fnM: () => Result<TMS, TME>,
  fnN: () => Result<TNS, TNE>,
  fnO: () => Result<TOS, TOE>
): Result<
  [TAS, TBS, TCS, TDS, TES, TFS, TGS, THS, TIS, TJS, TKS, TLS, TMS, TNS, TOS],
  | TAE
  | TBE
  | TCE
  | TDE
  | TEE
  | TFE
  | TGE
  | THE
  | TIE
  | TJE
  | TKE
  | TLE
  | TME
  | TNE
  | TOE
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE,
  TIS,
  TIE,
  TJS,
  TJE,
  TKS,
  TKE,
  TLS,
  TLE,
  TMS,
  TME,
  TNS,
  TNE,
  TOS,
  TOE,
  TPS,
  TPE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>,
  fnI: () => Result<TIS, TIE>,
  fnJ: () => Result<TJS, TJE>,
  fnK: () => Result<TKS, TKE>,
  fnL: () => Result<TLS, TLE>,
  fnM: () => Result<TMS, TME>,
  fnN: () => Result<TNS, TNE>,
  fnO: () => Result<TOS, TOE>,
  fnP: () => Result<TPS, TPE>
): Result<
  [
    TAS,
    TBS,
    TCS,
    TDS,
    TES,
    TFS,
    TGS,
    THS,
    TIS,
    TJS,
    TKS,
    TLS,
    TMS,
    TNS,
    TOS,
    TPS
  ],
  | TAE
  | TBE
  | TCE
  | TDE
  | TEE
  | TFE
  | TGE
  | THE
  | TIE
  | TJE
  | TKE
  | TLE
  | TME
  | TNE
  | TOE
  | TPE
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE,
  TIS,
  TIE,
  TJS,
  TJE,
  TKS,
  TKE,
  TLS,
  TLE,
  TMS,
  TME,
  TNS,
  TNE,
  TOS,
  TOE,
  TPS,
  TPE,
  TQS,
  TQE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>,
  fnI: () => Result<TIS, TIE>,
  fnJ: () => Result<TJS, TJE>,
  fnK: () => Result<TKS, TKE>,
  fnL: () => Result<TLS, TLE>,
  fnM: () => Result<TMS, TME>,
  fnN: () => Result<TNS, TNE>,
  fnO: () => Result<TOS, TOE>,
  fnP: () => Result<TPS, TPE>,
  fnQ: () => Result<TQS, TQE>
): Result<
  [
    TAS,
    TBS,
    TCS,
    TDS,
    TES,
    TFS,
    TGS,
    THS,
    TIS,
    TJS,
    TKS,
    TLS,
    TMS,
    TNS,
    TOS,
    TPS,
    TQS
  ],
  | TAE
  | TBE
  | TCE
  | TDE
  | TEE
  | TFE
  | TGE
  | THE
  | TIE
  | TJE
  | TKE
  | TLE
  | TME
  | TNE
  | TOE
  | TPE
  | TQE
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE,
  TIS,
  TIE,
  TJS,
  TJE,
  TKS,
  TKE,
  TLS,
  TLE,
  TMS,
  TME,
  TNS,
  TNE,
  TOS,
  TOE,
  TPS,
  TPE,
  TQS,
  TQE,
  TRS,
  TRE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>,
  fnI: () => Result<TIS, TIE>,
  fnJ: () => Result<TJS, TJE>,
  fnK: () => Result<TKS, TKE>,
  fnL: () => Result<TLS, TLE>,
  fnM: () => Result<TMS, TME>,
  fnN: () => Result<TNS, TNE>,
  fnO: () => Result<TOS, TOE>,
  fnP: () => Result<TPS, TPE>,
  fnQ: () => Result<TQS, TQE>,
  fnR: () => Result<TRS, TRE>
): Result<
  [
    TAS,
    TBS,
    TCS,
    TDS,
    TES,
    TFS,
    TGS,
    THS,
    TIS,
    TJS,
    TKS,
    TLS,
    TMS,
    TNS,
    TOS,
    TPS,
    TQS,
    TRS
  ],
  | TAE
  | TBE
  | TCE
  | TDE
  | TEE
  | TFE
  | TGE
  | THE
  | TIE
  | TJE
  | TKE
  | TLE
  | TME
  | TNE
  | TOE
  | TPE
  | TQE
  | TRE
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE,
  TIS,
  TIE,
  TJS,
  TJE,
  TKS,
  TKE,
  TLS,
  TLE,
  TMS,
  TME,
  TNS,
  TNE,
  TOS,
  TOE,
  TPS,
  TPE,
  TQS,
  TQE,
  TRS,
  TRE,
  TSS,
  TSE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>,
  fnI: () => Result<TIS, TIE>,
  fnJ: () => Result<TJS, TJE>,
  fnK: () => Result<TKS, TKE>,
  fnL: () => Result<TLS, TLE>,
  fnM: () => Result<TMS, TME>,
  fnN: () => Result<TNS, TNE>,
  fnO: () => Result<TOS, TOE>,
  fnP: () => Result<TPS, TPE>,
  fnQ: () => Result<TQS, TQE>,
  fnR: () => Result<TRS, TRE>,
  fnS: () => Result<TSS, TSE>
): Result<
  [
    TAS,
    TBS,
    TCS,
    TDS,
    TES,
    TFS,
    TGS,
    THS,
    TIS,
    TJS,
    TKS,
    TLS,
    TMS,
    TNS,
    TOS,
    TPS,
    TQS,
    TRS,
    TSS
  ],
  | TAE
  | TBE
  | TCE
  | TDE
  | TEE
  | TFE
  | TGE
  | THE
  | TIE
  | TJE
  | TKE
  | TLE
  | TME
  | TNE
  | TOE
  | TPE
  | TQE
  | TRE
  | TSE
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE,
  TIS,
  TIE,
  TJS,
  TJE,
  TKS,
  TKE,
  TLS,
  TLE,
  TMS,
  TME,
  TNS,
  TNE,
  TOS,
  TOE,
  TPS,
  TPE,
  TQS,
  TQE,
  TRS,
  TRE,
  TSS,
  TSE,
  TTS,
  TTE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>,
  fnI: () => Result<TIS, TIE>,
  fnJ: () => Result<TJS, TJE>,
  fnK: () => Result<TKS, TKE>,
  fnL: () => Result<TLS, TLE>,
  fnM: () => Result<TMS, TME>,
  fnN: () => Result<TNS, TNE>,
  fnO: () => Result<TOS, TOE>,
  fnP: () => Result<TPS, TPE>,
  fnQ: () => Result<TQS, TQE>,
  fnR: () => Result<TRS, TRE>,
  fnS: () => Result<TSS, TSE>,
  fnT: () => Result<TTS, TTE>
): Result<
  [
    TAS,
    TBS,
    TCS,
    TDS,
    TES,
    TFS,
    TGS,
    THS,
    TIS,
    TJS,
    TKS,
    TLS,
    TMS,
    TNS,
    TOS,
    TPS,
    TQS,
    TRS,
    TSS,
    TTS
  ],
  | TAE
  | TBE
  | TCE
  | TDE
  | TEE
  | TFE
  | TGE
  | THE
  | TIE
  | TJE
  | TKE
  | TLE
  | TME
  | TNE
  | TOE
  | TPE
  | TQE
  | TRE
  | TSE
  | TTE
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE,
  TIS,
  TIE,
  TJS,
  TJE,
  TKS,
  TKE,
  TLS,
  TLE,
  TMS,
  TME,
  TNS,
  TNE,
  TOS,
  TOE,
  TPS,
  TPE,
  TQS,
  TQE,
  TRS,
  TRE,
  TSS,
  TSE,
  TTS,
  TTE,
  TUS,
  TUE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>,
  fnI: () => Result<TIS, TIE>,
  fnJ: () => Result<TJS, TJE>,
  fnK: () => Result<TKS, TKE>,
  fnL: () => Result<TLS, TLE>,
  fnM: () => Result<TMS, TME>,
  fnN: () => Result<TNS, TNE>,
  fnO: () => Result<TOS, TOE>,
  fnP: () => Result<TPS, TPE>,
  fnQ: () => Result<TQS, TQE>,
  fnR: () => Result<TRS, TRE>,
  fnS: () => Result<TSS, TSE>,
  fnT: () => Result<TTS, TTE>,
  fnU: () => Result<TUS, TUE>
): Result<
  [
    TAS,
    TBS,
    TCS,
    TDS,
    TES,
    TFS,
    TGS,
    THS,
    TIS,
    TJS,
    TKS,
    TLS,
    TMS,
    TNS,
    TOS,
    TPS,
    TQS,
    TRS,
    TSS,
    TTS,
    TUS
  ],
  | TAE
  | TBE
  | TCE
  | TDE
  | TEE
  | TFE
  | TGE
  | THE
  | TIE
  | TJE
  | TKE
  | TLE
  | TME
  | TNE
  | TOE
  | TPE
  | TQE
  | TRE
  | TSE
  | TTE
  | TUE
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE,
  TIS,
  TIE,
  TJS,
  TJE,
  TKS,
  TKE,
  TLS,
  TLE,
  TMS,
  TME,
  TNS,
  TNE,
  TOS,
  TOE,
  TPS,
  TPE,
  TQS,
  TQE,
  TRS,
  TRE,
  TSS,
  TSE,
  TTS,
  TTE,
  TUS,
  TUE,
  TVS,
  TVE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>,
  fnI: () => Result<TIS, TIE>,
  fnJ: () => Result<TJS, TJE>,
  fnK: () => Result<TKS, TKE>,
  fnL: () => Result<TLS, TLE>,
  fnM: () => Result<TMS, TME>,
  fnN: () => Result<TNS, TNE>,
  fnO: () => Result<TOS, TOE>,
  fnP: () => Result<TPS, TPE>,
  fnQ: () => Result<TQS, TQE>,
  fnR: () => Result<TRS, TRE>,
  fnS: () => Result<TSS, TSE>,
  fnT: () => Result<TTS, TTE>,
  fnU: () => Result<TUS, TUE>,
  fnV: () => Result<TVS, TVE>
): Result<
  [
    TAS,
    TBS,
    TCS,
    TDS,
    TES,
    TFS,
    TGS,
    THS,
    TIS,
    TJS,
    TKS,
    TLS,
    TMS,
    TNS,
    TOS,
    TPS,
    TQS,
    TRS,
    TSS,
    TTS,
    TUS,
    TVS
  ],
  | TAE
  | TBE
  | TCE
  | TDE
  | TEE
  | TFE
  | TGE
  | THE
  | TIE
  | TJE
  | TKE
  | TLE
  | TME
  | TNE
  | TOE
  | TPE
  | TQE
  | TRE
  | TSE
  | TTE
  | TUE
  | TVE
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE,
  TIS,
  TIE,
  TJS,
  TJE,
  TKS,
  TKE,
  TLS,
  TLE,
  TMS,
  TME,
  TNS,
  TNE,
  TOS,
  TOE,
  TPS,
  TPE,
  TQS,
  TQE,
  TRS,
  TRE,
  TSS,
  TSE,
  TTS,
  TTE,
  TUS,
  TUE,
  TVS,
  TVE,
  TWS,
  TWE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>,
  fnI: () => Result<TIS, TIE>,
  fnJ: () => Result<TJS, TJE>,
  fnK: () => Result<TKS, TKE>,
  fnL: () => Result<TLS, TLE>,
  fnM: () => Result<TMS, TME>,
  fnN: () => Result<TNS, TNE>,
  fnO: () => Result<TOS, TOE>,
  fnP: () => Result<TPS, TPE>,
  fnQ: () => Result<TQS, TQE>,
  fnR: () => Result<TRS, TRE>,
  fnS: () => Result<TSS, TSE>,
  fnT: () => Result<TTS, TTE>,
  fnU: () => Result<TUS, TUE>,
  fnV: () => Result<TVS, TVE>,
  fnW: () => Result<TWS, TWE>
): Result<
  [
    TAS,
    TBS,
    TCS,
    TDS,
    TES,
    TFS,
    TGS,
    THS,
    TIS,
    TJS,
    TKS,
    TLS,
    TMS,
    TNS,
    TOS,
    TPS,
    TQS,
    TRS,
    TSS,
    TTS,
    TUS,
    TVS,
    TWS
  ],
  | TAE
  | TBE
  | TCE
  | TDE
  | TEE
  | TFE
  | TGE
  | THE
  | TIE
  | TJE
  | TKE
  | TLE
  | TME
  | TNE
  | TOE
  | TPE
  | TQE
  | TRE
  | TSE
  | TTE
  | TUE
  | TVE
  | TWE
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE,
  TIS,
  TIE,
  TJS,
  TJE,
  TKS,
  TKE,
  TLS,
  TLE,
  TMS,
  TME,
  TNS,
  TNE,
  TOS,
  TOE,
  TPS,
  TPE,
  TQS,
  TQE,
  TRS,
  TRE,
  TSS,
  TSE,
  TTS,
  TTE,
  TUS,
  TUE,
  TVS,
  TVE,
  TWS,
  TWE,
  TXS,
  TXE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>,
  fnI: () => Result<TIS, TIE>,
  fnJ: () => Result<TJS, TJE>,
  fnK: () => Result<TKS, TKE>,
  fnL: () => Result<TLS, TLE>,
  fnM: () => Result<TMS, TME>,
  fnN: () => Result<TNS, TNE>,
  fnO: () => Result<TOS, TOE>,
  fnP: () => Result<TPS, TPE>,
  fnQ: () => Result<TQS, TQE>,
  fnR: () => Result<TRS, TRE>,
  fnS: () => Result<TSS, TSE>,
  fnT: () => Result<TTS, TTE>,
  fnU: () => Result<TUS, TUE>,
  fnV: () => Result<TVS, TVE>,
  fnW: () => Result<TWS, TWE>,
  fnX: () => Result<TXS, TXE>
): Result<
  [
    TAS,
    TBS,
    TCS,
    TDS,
    TES,
    TFS,
    TGS,
    THS,
    TIS,
    TJS,
    TKS,
    TLS,
    TMS,
    TNS,
    TOS,
    TPS,
    TQS,
    TRS,
    TSS,
    TTS,
    TUS,
    TVS,
    TWS,
    TXS
  ],
  | TAE
  | TBE
  | TCE
  | TDE
  | TEE
  | TFE
  | TGE
  | THE
  | TIE
  | TJE
  | TKE
  | TLE
  | TME
  | TNE
  | TOE
  | TPE
  | TQE
  | TRE
  | TSE
  | TTE
  | TUE
  | TVE
  | TWE
  | TXE
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE,
  TIS,
  TIE,
  TJS,
  TJE,
  TKS,
  TKE,
  TLS,
  TLE,
  TMS,
  TME,
  TNS,
  TNE,
  TOS,
  TOE,
  TPS,
  TPE,
  TQS,
  TQE,
  TRS,
  TRE,
  TSS,
  TSE,
  TTS,
  TTE,
  TUS,
  TUE,
  TVS,
  TVE,
  TWS,
  TWE,
  TXS,
  TXE,
  TYS,
  TYE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>,
  fnI: () => Result<TIS, TIE>,
  fnJ: () => Result<TJS, TJE>,
  fnK: () => Result<TKS, TKE>,
  fnL: () => Result<TLS, TLE>,
  fnM: () => Result<TMS, TME>,
  fnN: () => Result<TNS, TNE>,
  fnO: () => Result<TOS, TOE>,
  fnP: () => Result<TPS, TPE>,
  fnQ: () => Result<TQS, TQE>,
  fnR: () => Result<TRS, TRE>,
  fnS: () => Result<TSS, TSE>,
  fnT: () => Result<TTS, TTE>,
  fnU: () => Result<TUS, TUE>,
  fnV: () => Result<TVS, TVE>,
  fnW: () => Result<TWS, TWE>,
  fnX: () => Result<TXS, TXE>,
  fnY: () => Result<TYS, TYE>
): Result<
  [
    TAS,
    TBS,
    TCS,
    TDS,
    TES,
    TFS,
    TGS,
    THS,
    TIS,
    TJS,
    TKS,
    TLS,
    TMS,
    TNS,
    TOS,
    TPS,
    TQS,
    TRS,
    TSS,
    TTS,
    TUS,
    TVS,
    TWS,
    TXS,
    TYS
  ],
  | TAE
  | TBE
  | TCE
  | TDE
  | TEE
  | TFE
  | TGE
  | THE
  | TIE
  | TJE
  | TKE
  | TLE
  | TME
  | TNE
  | TOE
  | TPE
  | TQE
  | TRE
  | TSE
  | TTE
  | TUE
  | TVE
  | TWE
  | TXE
  | TYE
>;

export function executeWhileSuccessful<
  TAS,
  TAE,
  TBS,
  TBE,
  TCS,
  TCE,
  TDS,
  TDE,
  TES,
  TEE,
  TFS,
  TFE,
  TGS,
  TGE,
  THS,
  THE,
  TIS,
  TIE,
  TJS,
  TJE,
  TKS,
  TKE,
  TLS,
  TLE,
  TMS,
  TME,
  TNS,
  TNE,
  TOS,
  TOE,
  TPS,
  TPE,
  TQS,
  TQE,
  TRS,
  TRE,
  TSS,
  TSE,
  TTS,
  TTE,
  TUS,
  TUE,
  TVS,
  TVE,
  TWS,
  TWE,
  TXS,
  TXE,
  TYS,
  TYE,
  TZS,
  TZE
>(
  fnA: () => Result<TAS, TAE>,
  fnB: () => Result<TBS, TBE>,
  fnC: () => Result<TCS, TCE>,
  fnD: () => Result<TDS, TDE>,
  fnE: () => Result<TES, TEE>,
  fnF: () => Result<TFS, TFE>,
  fnG: () => Result<TGS, TGE>,
  fnH: () => Result<THS, THE>,
  fnI: () => Result<TIS, TIE>,
  fnJ: () => Result<TJS, TJE>,
  fnK: () => Result<TKS, TKE>,
  fnL: () => Result<TLS, TLE>,
  fnM: () => Result<TMS, TME>,
  fnN: () => Result<TNS, TNE>,
  fnO: () => Result<TOS, TOE>,
  fnP: () => Result<TPS, TPE>,
  fnQ: () => Result<TQS, TQE>,
  fnR: () => Result<TRS, TRE>,
  fnS: () => Result<TSS, TSE>,
  fnT: () => Result<TTS, TTE>,
  fnU: () => Result<TUS, TUE>,
  fnV: () => Result<TVS, TVE>,
  fnW: () => Result<TWS, TWE>,
  fnX: () => Result<TXS, TXE>,
  fnY: () => Result<TYS, TYE>,
  fnZ: () => Result<TZS, TZE>
): Result<
  [
    TAS,
    TBS,
    TCS,
    TDS,
    TES,
    TFS,
    TGS,
    THS,
    TIS,
    TJS,
    TKS,
    TLS,
    TMS,
    TNS,
    TOS,
    TPS,
    TQS,
    TRS,
    TSS,
    TTS,
    TUS,
    TVS,
    TWS,
    TXS,
    TYS,
    TZS
  ],
  | TAE
  | TBE
  | TCE
  | TDE
  | TEE
  | TFE
  | TGE
  | THE
  | TIE
  | TJE
  | TKE
  | TLE
  | TME
  | TNE
  | TOE
  | TPE
  | TQE
  | TRE
  | TSE
  | TTE
  | TUE
  | TVE
  | TWE
  | TXE
  | TYE
  | TZE
>;

export function executeWhileSuccessful(
  // eslint-disable-next-line @typescript-eslint/ban-types
  ...funcs: Array<Function>
): Result<Array<unknown>, unknown> {
  return funcs.reduce<Result<Array<unknown>, unknown>>((acc, curFn) => {
    // If we have already failed, just return the error.
    if (failed(acc)) {
      return acc;
    }

    // We have not failed yet, so execute the current function.
    const res = curFn();
    if (succeeded(res)) {
      acc.value.push(res.value);
      return acc;
    } else {
      return res;
    }
  }, succeededResult([]));
}
