import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectFilesComponent } from './select-files.component';
import { TitleStepModule } from '../../../shared/components/title-step/title-step.module';
import { TranslateModule } from '@ngx-translate/core';
import { RaUiFileInputModule } from '@ra-web-tech-ui-toolkit/form-system/file-input';
import { FileListModule } from '../../../shared/components/file-list/file-list.module';

@NgModule({
  declarations: [SelectFilesComponent],
  imports: [
    CommonModule,
    TitleStepModule,
    TranslateModule,
    RaUiFileInputModule,
    FileListModule,
  ],
})
export class SelectFilesModule {}
