import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WorkspaceState } from '../../models/workspace-creation.model';

export const selectWorkspaceState =
  createFeatureSelector<WorkspaceState>('workspace');

export const selectActiveWorkspace = createSelector(
  selectWorkspaceState,
  (state: WorkspaceState) => {
    return state.activeWorkspace;
  }
);

export const selectWorkspaceDetails = createSelector(
  selectWorkspaceState,
  (state: WorkspaceState) => {
    return state.workspaceDetails;
  }
);

export const selectWorkstationsIdToUpdateStatus = createSelector(
  selectWorkspaceState,
  (state: WorkspaceState) => {
    return state.workstationsIds;
  }
);
