import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationCardComponent } from './application-card.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RaUiTooltipModule } from '@ra-web-tech-ui-toolkit/common-utils/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { RaUiCardModule } from '@ra-web-tech-ui-toolkit/layout/card';
import { RaUiDialogModule } from '@ra-web-tech-ui-toolkit/popups/dialog';

@NgModule({
  declarations: [ApplicationCardComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    RaUiTooltipModule,
    RaUiCardModule,
    TranslateModule,
    RaUiDialogModule,
  ],
  exports: [ApplicationCardComponent],
})
export class ApplicationCardModule {}
