import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IListConfig, IListItem } from '@ra-web-tech-ui-toolkit/lists/list';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
})
export class FileListComponent {
  @Input() listItems: IListItem[] = [];
  @Input() allowDelete: boolean = false;
  @Input() config!: IListConfig;
  @Output() fileRemoved = new EventEmitter();
  @Output() emptyStateButtonClicked = new EventEmitter();

  removeItem(listItem: IListItem): void {
    this.fileRemoved.emit(listItem);
  }

  emitEmptyStateButtonClicked(): void {
    this.emptyStateButtonClicked.emit();
  }
}
