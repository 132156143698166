import {
  Project,
  ProjectData,
  ProjType,
  projTypeLabel,
  ProjectStatus,
} from '../../core/models/vault/project.model';
import {
  DataWithId,
  CheckboxToolTip,
} from 'src/app/core/models/table-templates.model';
import { FileHelper } from './file.helper';
import {
  FileData,
  Instance,
  SessionKind,
} from '../../core/models/twin-studio.model';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import {
  activeFileExtension,
  getImagePathForUnknownProject,
  projTypeSvgIconDict,
} from 'src/app/core/services/vault/view-status/project-file-utility';
import { UploadFileModel } from '../../core/models/upload-file.model';
import { Templates } from '../../core/models/table-templates.model';

const columnDate = 'vaultBrowseComponent.date';
const columnName = 'vaultBrowseComponent.name';
const columnDescription = 'vaultBrowseComponent.description';
const columnOwner = 'vaultBrowseComponent.modifiedBy';
const columnFileName = 'columnsTable.fileName';
const columnProjectName = 'columnsTable.projectName';
const columnCommitMessage = 'columnsTable.commitMessage';
const columnAssociateProject = 'columnsTable.associateProject';
const columnInstanceName = 'columnsTable.instanceName';
const columnInstanceTier = 'columnsTable.instanceTier';
const columnInstanceStatus = 'columnsTable.instanceStatus';

// eslint-disable-next-line @typescript-eslint/naming-convention
const DATE_FORMAT = 'yyyy-MM-dd';

export interface Column {
  columnDef: string;
  header: string;
  customType?: Templates | undefined;
  isEditable?: boolean | undefined;
  cell: (element: any) => string | any | undefined;
}

export interface ProjectViewForDisplay {
  id: string;
  iconPath?: string;
  displayWarning?: boolean;
  project: ProjectData;
}

export interface FileViewForDisplay {
  id: string;
  iconPath?: string;
  displayWarning?: boolean;
  file: FileData;
  listProjects?: ProjectData[];
}

export class ProjectTableHelper {
  public static getEmulate3DColumnsBrowse(datePipe: DatePipe): Array<Column> {
    return [
      {
        columnDef: 'controls',
        header: '',
        cell: (element: ProjectViewForDisplay) => '',
      },
      {
        columnDef: 'name',
        header: columnName,
        cell: (element: ProjectViewForDisplay) => `${element.project.name}`,
      },
      {
        columnDef: 'description',
        header: columnDescription,
        cell: (element: ProjectViewForDisplay) =>
          `${
            element.project.description &&
            element.project.description.length > 0
              ? element.project.description
              : 'N/A'
          }`,
      },
      {
        columnDef: 'type',
        header: 'vaultBrowseComponent.type',
        cell: (element: ProjectViewForDisplay) =>
          `${projTypeLabel[element.project.type]}`,
      },
      {
        columnDef: 'version',
        header: 'vaultBrowseComponent.version',
        cell: (element: ProjectViewForDisplay) =>
          element.project.type === ProjType.LogixDesigner
            ? `${element.project.activeProgram?.logixRevision ?? 0}`
            : 'N/A',
      },
      {
        columnDef: 'date',
        header: columnDate,
        cell: (element: ProjectViewForDisplay) =>
          `${
            datePipe.transform(
              element.project.activeProgram?.uploadedDate ?? '',
              DATE_FORMAT
            ) ?? ''
          }`,
      },
    ];
  }

  public static getLogixColumnsBrowse(datePipe: DatePipe): Array<Column> {
    return [
      {
        columnDef: 'controls',
        header: '',
        cell: (element: ProjectViewForDisplay) => '',
      },
      {
        columnDef: 'name',
        header: columnName,
        cell: (element: ProjectViewForDisplay) => `${element.project.name}`,
      },
      {
        columnDef: 'description',
        header: columnDescription,
        cell: (element: ProjectViewForDisplay) =>
          `${
            element.project.description &&
            element.project.description.length > 0
              ? element.project.description
              : 'N/A'
          }`,
      },
      {
        columnDef: 'date',
        header: columnDate,
        cell: (element: ProjectViewForDisplay) =>
          `${
            datePipe.transform(
              element.project.activeProgram?.uploadedDate ?? '',
              DATE_FORMAT
            ) ?? ''
          }`,
      },
      {
        columnDef: 'version',
        header: 'vaultBrowseComponent.version',
        cell: (element: ProjectViewForDisplay) =>
          `${element.project.activeProgram?.logixRevision ?? 0}`,
      },
      {
        columnDef: 'controller',
        header: 'vaultBrowseComponent.controller',
        cell: (element: ProjectViewForDisplay) =>
          `${element.project.activeProgram?.controllerType?.toString() ?? ''}`,
      },
    ];
  }

  public static getArenaColumnsBrowse(datePipe: DatePipe): Array<Column> {
    return [
      {
        columnDef: 'controls',
        header: '',
        cell: (element: ProjectViewForDisplay) => '',
      },
      {
        columnDef: 'name',
        header: columnName,
        cell: (element: ProjectViewForDisplay) => `${element.project.name}`,
      },
      {
        columnDef: 'description',
        header: columnDescription,
        cell: (element: ProjectViewForDisplay) =>
          `${
            element.project.description &&
            element.project.description.length > 0
              ? element.project.description
              : 'N/A'
          }`,
      },
      {
        columnDef: 'date',
        header: columnDate,
        cell: (element: ProjectViewForDisplay) =>
          `${
            datePipe.transform(
              element.project.activeProgram?.uploadedDate ?? '',
              DATE_FORMAT
            ) ?? ''
          }`,
      },
    ];
  }

  public static getUploadFileColumns(): Array<Column> {
    return [
      {
        columnDef: 'name',
        header: columnName,
        cell: (element: UploadFileModel) => `${element.name}`,
      },
      {
        columnDef: 'progressbar',
        customType: Templates.ProgressUploadTemplate,
        header: 'progress',
        cell: (element: UploadFileModel) => element,
      },
    ];
  }

  public static getInstancesColumns(): Array<Column> {
    return [
      {
        columnDef: 'radio',
        header: '',
        cell: (element: Instance) => '',
      },
      {
        columnDef: 'instanceName',
        header: columnInstanceName,
        cell: (element: Instance) => `${element.name}`,
      },
      {
        columnDef: 'tier',
        header: columnInstanceTier,
        cell: (element: Instance) => `${element.tier}`,
      },
      {
        columnDef: 'instanceStatus',
        header: columnInstanceStatus,
        cell: (element: Instance) => `${element.status}`,
      },
    ];
  }

  public static getAllProjectsColumns(datePipe: DatePipe): Array<Column> {
    return [
      {
        columnDef: 'controls',
        header: '',
        cell: (element: ProjectViewForDisplay) => '',
      },
      {
        columnDef: 'name',
        header: columnName,
        cell: (element: ProjectViewForDisplay) => `${element.project.name}`,
      },
      {
        columnDef: 'date',
        header: columnDate,
        cell: (element: ProjectViewForDisplay) =>
          `${
            datePipe.transform(
              element.project.activeProgram?.uploadedDate ?? '',
              DATE_FORMAT
            ) ?? ''
          }`,
      },
      {
        columnDef: 'owner',
        header: columnOwner,
        cell: (element: ProjectViewForDisplay) =>
          `${element.project.activeProgram?.userProfile?.email ?? ''}`,
      },
      {
        columnDef: 'type',
        header: 'vaultBrowseComponent.type',
        cell: (element: ProjectViewForDisplay) =>
          `${projTypeLabel[element.project.type]}`,
      },
    ];
  }

  public static getUnknownProjectsColumns(): Array<Column> {
    return [
      {
        columnDef: 'controls',
        header: '',
        cell: (element: FileViewForDisplay) => '',
      },
      {
        columnDef: 'fileName',
        header: columnFileName,
        cell: (element: FileViewForDisplay) => `${element.file.fileName}`,
      },
      {
        columnDef: 'associateProject',
        customType: Templates.ProjectListTemplate,
        header: columnAssociateProject,
        cell: (element: FileViewForDisplay) => element.listProjects,
      },
      {
        columnDef: 'projectName',
        header: columnProjectName,
        isEditable: true,
        cell: (element: FileViewForDisplay) => `${element.file.projectName}`,
      },
      {
        columnDef: 'description',
        header: 'projectDescriptionLabel',
        isEditable: true,
        cell: (element: FileViewForDisplay) => `${element.file.description}`,
      },
      {
        columnDef: 'commitMessage',
        header: columnCommitMessage,
        isEditable: true,
        cell: (element: FileViewForDisplay) => `${element.file.commitMessage}`,
      },
    ];
  }

  public static getPushToVaultColumns(): Array<Column> {
    return [
      {
        columnDef: 'controls',
        header: '',
        cell: (element: FileViewForDisplay) => '',
      },
      {
        columnDef: 'fileName',
        header: columnFileName,
        cell: (element: FileViewForDisplay) => `${element.file.fileName}`,
      },
      {
        columnDef: 'projectName',
        header: columnProjectName,
        cell: (element: FileViewForDisplay) => `${element.file.projectName}`,
      },
      {
        columnDef: 'commitMessage',
        header: columnCommitMessage,
        isEditable: true,
        cell: (element: FileViewForDisplay) => `${element.file.commitMessage}`,
      },
    ];
  }

  public static getEmulate3dDisableItems(
    projects: ProjectData[]
  ): Array<DataWithId> {
    return projects
      .filter(
        (p) =>
          p.type === ProjType.LogixDesigner &&
          (p.activeProgram?.logixRevision ?? 0) < 33
      )
      .map((p) => {
        return { id: p.name + '_' + p.projectId };
      });
  }

  public static getLogixDisableItems(
    projects: ProjectData[]
  ): Array<DataWithId> {
    return projects
      .filter(
        (p) =>
          p.type === ProjType.LogixDesigner &&
          (p.activeProgram?.logixRevision ?? 0) < 20
      )
      .map((p) => {
        return { id: p.name + '_' + p.projectId };
      });
  }

  public static getcheckboxToolTipItems(
    disableItems: Array<DataWithId>,
    message: string
  ): Array<CheckboxToolTip> {
    return disableItems.map((p) => {
      return {
        id: p as DataWithId,
        message,
      };
    });
  }

  public static getErrorMessageBySessionType(
    sessionKind: SessionKind,
    projectsSelelected: ProjectViewForDisplay[],
    translateService: TranslateService
  ): string | null {
    switch (sessionKind) {
      case SessionKind.Logix: {
        const logixP = FileHelper.getLogixProjects(projectsSelelected);
        const echoP = FileHelper.getEchoProjects(projectsSelelected);
        if (logixP.length > 5 || echoP.length > 5) {
          return translateService.instant('browsepage.errorLogixValidation');
        }
        return null;
        break;
      }
      case SessionKind.Arena: {
        const arenaP = FileHelper.getArenaProjects(projectsSelelected);
        if (arenaP.length > 5) {
          return translateService.instant('browsepage.errorArenaValidation');
        }
        return null;
        break;
      }
      case SessionKind.Emulate3d: {
        const emulateP = FileHelper.getEmulateProjects(projectsSelelected);
        const logixP = FileHelper.getLogixProjects(projectsSelelected);
        const echoP = FileHelper.getEchoProjects(projectsSelelected);
        const viewDesignerP =
          FileHelper.getViewDesignerProjects(projectsSelelected);
        const viewMeP = FileHelper.getViewMeProjects(projectsSelelected);

        if (
          emulateP.length > 5 ||
          logixP.length > 5 ||
          echoP.length > 5 ||
          viewDesignerP.length > 5 ||
          viewMeP.length > 5
        ) {
          return translateService.instant('browsepage.errorEmulateValidation');
        }
        return null;
        break;
      }
      default:
        return null;
    }
  }

  public static getProjectsView(
    projects: ProjectData[],
    assetTypes: string[],
    projectTypes: ProjType[],
    onlyReadyStatus = false
  ): ProjectViewForDisplay[] {
    return projects
      .filter((project) =>
        project.status === ProjectStatus.Ready && onlyReadyStatus
          ? true
          : projectTypes.includes(project.type) ||
            assetTypes.includes(activeFileExtension(project))
      )
      .map((pv) => {
        return {
          project: pv,
          id: pv.name + '_' + pv.projectId,
          iconPath: projTypeSvgIconDict[pv.type],
          icon: undefined,
        };
      });
  }

  public static getFilesView(
    files: FileData[],
    listProjects?: ProjectData[]
  ): FileViewForDisplay[] {
    return files.map((vFile, index) => {
      return {
        file: vFile,
        id: index + '-' + vFile.fileName,
        iconPath: getImagePathForUnknownProject(vFile.fileName),
        listProjects: listProjects,
        icon: undefined,
      };
    });
  }
}

export function isArrayOfProjectViewForDisplay(
  object: any
): object is ProjectViewForDisplay[] {
  return (
    Array.isArray(object) &&
    (object.length === 0 ||
      (object.length > 0 &&
        typeof object[0].id === 'string' &&
        object[0].project instanceof Project))
  );
}

export function isArrayOfFileViewForDisplay(
  object: any
): object is FileViewForDisplay[] {
  return (
    Array.isArray(object) &&
    (object.length === 0 ||
      (object.length > 0 && typeof object[0].id === 'string'))
  );
}

export function validateCommmitMessages(
  projects: FileViewForDisplay[]
): boolean {
  let valid = true;
  if (projects.length > 0) {
    projects.forEach((project) => {
      valid =
        valid &&
        project.file.commitMessage !== undefined &&
        project.file.commitMessage !== null &&
        project.file.commitMessage !== '';
    });
    return valid;
  } else {
    return false;
  }
}

export function validateProjectName(projects: FileViewForDisplay[]): boolean {
  let valid = true;
  if (projects.length > 0) {
    projects.forEach((project) => {
      valid =
        valid &&
        project.file.projectName !== undefined &&
        project.file.projectName !== null &&
        project.file.projectName !== '';
    });
    return valid;
  } else {
    return false;
  }
}
