<app-title-step
  [labelTitle]="'quickAccessUpload.uploadSummary' | translate"
  [iconTitle]="'ra-icon-ide-sm-upload'"
></app-title-step>
<div class="step-subtitle">
  <p>
    {{ "3. " + ("quickAccessUpload.summarySubtitle" | translate) }}
    <b>{{ ("menu.push.homeFolderTab" | translate) + "/" }}{{ location }}</b>
  </p>
</div>
<div class="uploaded-files-container">
  <app-file-list [listItems]="listItems"></app-file-list>
</div>
