import { NgModule } from '@angular/core';
import { SidebarHeaderComponent } from './sidebar-header.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [SidebarHeaderComponent],
  imports: [CommonModule],
  exports: [SidebarHeaderComponent],
})
export class SidebarHeaderModule {}
