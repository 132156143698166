<div class="region-selector" *ngIf="!workstationModel?.sessionData">
  <app-region-selector (regionSelectedEmitter)="onRegionSelected($event)">
  </app-region-selector>
  <div class="search-input">
    <app-filter
      [dataSource]="currentDisplayedItems"
      (filterEmitter)="onFilteredData($event)"
    ></app-filter>
  </div>
</div>

<div *ngIf="currentDisplayedItems" class="explorer-wrapper">
  <div *ngIf="workstationModel.onlyFolders">
    <p class="select-info-title">
      {{ "menu.upload.local.files.title" | translate }}
    </p>
    <div class="important-info-upload">
      <i class="icon ra-icon-ide-sm-color-info"></i>
      {{ "menu.s3explorer.folders.warning" | translate }}
    </div>

    <p class="line-separator"></p>
  </div>
  <div class="breadcrumb-box">
    <div
      class="breadcrumb-item-wrapper"
      *ngFor="let b of breadcrumb; let idx = index; let last = last"
    >
      <p
        (click)="onSelectingCrumb(b, idx)"
        class="breadcrumb-item-text hover-selector {{
          last ? '' : 'breadcrumb-link'
        }}"
      >
        {{ b }}
      </p>
      <i
        *ngIf="!last"
        class="breadcrum-custom-arrow icon ra-icon-ide-sm-caret-right"
      ></i>
    </div>
  </div>

  <div *ngIf="loadingContent" class="folder-item-search">
    <app-loading [height]="'178px'"></app-loading>
  </div>

  <div class="folder-content" *ngIf="!loadingContent">
    <div class="center-wrapper" *ngIf="currentDisplayedItems.length === 0">
      <p *ngIf="!workstationModel.onlyFolders" class="empty-folder-placeholder">
        {{ "menu.s3explorer.folders.empty" | translate }}
      </p>
      <p *ngIf="workstationModel.onlyFolders" class="empty-folder-placeholder">
        {{ "menu.s3explorer.folders.emptyUpload" | translate }}
      </p>
    </div>
    <div
      *ngFor="let item of filterItems ?? currentDisplayedItems"
      class="{{
        (isItemSelected(item) ? 'folder-item item-selected' : 'folder-item') +
          ' ' +
          (isItemDisabled(item) ? 'disable-item' : '')
      }}"
    >
      <div class="checkbox-item-selector">
        <mat-checkbox
          [disabled]="isItemDisabled(item)"
          *ngIf="
            (!workstationModel.onlyFolders && !item.includes('/')) ||
            (workstationModel.onlyFolders && item.includes('/'))
          "
          id="{{ 'id-' + item }}"
          [checked]="isItemSelected(item)"
          (change)="onItemSelected(item)"
          class="center-check-label checked-background-color"
        >
        </mat-checkbox>
      </div>
      <div>
        <i
          *ngIf="item.includes('/')"
          class="breadcrum-custom-arrow icon ra-icon-ide-sm-folder"
        ></i>
        <img
          class="file-icon-type"
          *ngIf="!item.includes('/')"
          src="{{ getImageForItem(item) }}"
        />
      </div>
      <div
        (click)="
          item.includes('/')
            ? onOpenFolder(item.substring(0, item.length - 1))
            : onItemSelected(item)
        "
        title="{{ item }}"
        class="div-item-text-value"
      >
        <p class="item-text-value">
          {{ item.includes("/") ? item.substring(0, item.length - 1) : item }}
        </p>
      </div>
    </div>
  </div>
</div>
