// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace WebsocketConnection {
  export interface UpdateInfo {
    state: State;
    details: string;
    date: Date;
  }

  export enum State {
    Connected = 'connected',
    Disconnected = 'disconnected',
    Reconnected = 'reconnected',
    Closed = 'closed',
    None = 'undefined',
  }

  export const stateColor: Record<State, string> = {
    [State.Connected]: '#12850A',
    [State.Disconnected]: '#FFCC00',
    [State.Reconnected]: '#12850A',
    [State.Closed]: '#FF0000',
    [State.None]: '#6b6b6b',
  };
}
