import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, Observable } from 'rxjs';
import { emptyHarnessAction, initializeTarget } from './feature-flag.actions';
import { HarnessService } from '../../services/harness/harness.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagEffects {
  initializeTarget$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(initializeTarget),
      map((payload) => {
        this.harnessService.initializeTarget(payload.payload);
        return emptyHarnessAction();
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly harnessService: HarnessService
  ) {}
}
