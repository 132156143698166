import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from '../snackbar/snackbar.service';
import {
  ConfigService,
  TwinStudioConfiguration,
} from '../config/config.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionFacade } from '../../../core/reducers/session/session.facade';
import {
  CheckConflictsPayload,
  ConflictsResponse,
  CreateAndSyncProjectsPayload,
  GetRecentFilesResponse,
  ProjectMetadata,
  PushChangesPayload,
  PushChangesPayloadWorkstations,
  PushChangesResponse,
  Region,
  SessionKind,
  SyncResponse,
  WorkstationFiles,
} from '../../models/twin-studio.model';
import { Observable, map, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LCoreResponse } from '../../models/lcore-response.model';
import { VaultId } from '../../models/vault/vault.model';
import { WorkspaceContentPayload } from '../../models/workstation-explorer.model';
import { LoggerService } from '../logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class PushService {
  constructor(
    private readonly http: HttpClient,
    private readonly snackbarService: SnackbarService,
    private readonly configService: ConfigService<TwinStudioConfiguration>,
    private readonly translateService: TranslateService,
    private readonly sessionFacade: SessionFacade,
    private readonly loggerService: LoggerService
  ) {}

  // Default logix kind since universal entitlement groups all services kind
  checkConflicts(
    vaultId: VaultId,
    body: CheckConflictsPayload,
    sessionKind = SessionKind.Logix
  ): Observable<ConflictsResponse> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/vaults/${vaultId}/appsession/${sessionKind}/checkconflicts`;
    return this.http.post<LCoreResponse<ConflictsResponse>>(url, body).pipe(
      map((x) => {
        return x.data;
      }),
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError((err: HttpErrorResponse) => this.handleError(err))
    );
  }

  // Default logix kind since universal entitlement groups all services kind
  pushChanges(
    vaultId: VaultId,
    body: PushChangesPayload,
    sessionKind = SessionKind.Logix
  ): Observable<PushChangesResponse> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/vaults/${vaultId}/appsession/${sessionKind}/pushChanges`;
    return this.http.post<LCoreResponse<PushChangesResponse>>(url, body).pipe(
      map((x) => {
        return x.data;
      }),
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError((err: HttpErrorResponse) => this.handleError(err))
    );
  }

  pushChangesWorkstations(
    regionAndWorkstation: string,
    tenantId: string,
    body: PushChangesPayloadWorkstations
  ): Observable<PushChangesResponse> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/twinstudio/tenants/${tenantId}/workstations/sync/${regionAndWorkstation}`;
    return this.http
      .post<LCoreResponse<PushChangesResponse>>(url, {
        projectsNewVersion: [body],
      })
      .pipe(
        map((x) => {
          return x.data;
        }),
        // eslint-disable-next-line rxjs/no-implicit-any-catch
        catchError((err: HttpErrorResponse) => this.handleError(err))
      );
  }

  // Default logix kind since universal entitlement groups all services kind
  listWorkspaceContent(
    vaultId: VaultId,
    body: WorkspaceContentPayload,
    sessionKind = SessionKind.Logix
  ): Observable<string[]> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/vaults/${vaultId}/appsession/${sessionKind}/listWorkspaceContent`;
    return this.http.post<LCoreResponse<string[]>>(url, body).pipe(
      map((x) => {
        return x.data;
      }),
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError((err: HttpErrorResponse) => this.handleError(err))
    );
  }

  createAndSyncProjects(
    vaultId: VaultId,
    body: CreateAndSyncProjectsPayload,
    sessionKind = SessionKind.Logix
  ): Observable<SyncResponse> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/vaults/${vaultId}/appsession/${sessionKind}/projectsync`;
    return this.http.post<LCoreResponse<SyncResponse>>(url, body).pipe(
      map((x) => {
        return x.data;
      }),
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError((err: HttpErrorResponse) => this.handleError(err))
    );
  }

  // Default logix kind since universal entitlement groups all services kind
  listSolutionContent(
    vaultId: VaultId,
    body: WorkspaceContentPayload,
    sessionKind = SessionKind.Logix
  ): Observable<ProjectMetadata[]> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/vaults/${vaultId}/appsession/${sessionKind}/listSolutionContent`;
    return this.http.post<LCoreResponse<ProjectMetadata[]>>(url, body).pipe(
      map((x) => {
        return x.data;
      }),
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError((err: HttpErrorResponse) => this.handleError(err))
    );
  }

  getRecentFiles(
    vaultId: VaultId,
    region: Region,
    sessionKind = SessionKind.Logix
  ): Observable<GetRecentFilesResponse> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/vaults/${vaultId}/appsession/${sessionKind}:${region}/recentfiles`;
    return this.http.get<LCoreResponse<GetRecentFilesResponse>>(url).pipe(
      map((x) => {
        return x.data;
      }),
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError((err: HttpErrorResponse) => this.handleError(err))
    );
  }

  getWorkstationFiles(
    tenantId: string,
    region: Region,
    workstationId: string
  ): Observable<WorkstationFiles[]> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/twinstudio/tenants/${tenantId}/workstations/files/${region}:${workstationId}`;
    return this.http.get<LCoreResponse<WorkstationFiles[]>>(url).pipe(
      map((x) => {
        return x.data;
      }),
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError((err: HttpErrorResponse) => this.handleError(err))
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (!error.status || error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      const msg = error.error ?? error.message ?? error;
      this.loggerService.error(`An error occurred: `, msg);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      this.loggerService.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      () =>
        new Error(
          'Something bad happened; please try again later. - push service'
        )
    );
  }
}
