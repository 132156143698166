import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { LocalizationActions } from './action-types';

@Injectable()
export class LocalizationEffects {
  setTranslateServiceLanguage$ = createEffect(
    () =>
    { return this.actions$.pipe(
      ofType(
        LocalizationActions.appInit,
        LocalizationActions.switchLang,
        LocalizationActions.appRefreshed
      ),
      tap((language: { lang: string }) => {
        localStorage.setItem('lang', language?.lang);
        this.translate.use(language?.lang);
      })
    ); },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly translate: TranslateService
  ) {}
}
