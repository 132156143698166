import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  SearchInputData,
  SearchInputConfig,
  SearchInputComponent,
  SearchInputFlatListPipe,
} from '@ra-web-tech-ui-toolkit/form-system/search-input';
import { TranslateService } from '@ngx-translate/core';
import { FilterDataSource } from '../../../core/models/filter.model';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
})
export class FilterComponent implements OnInit, OnChanges {
  @Input() dataSource: string[] = [];
  @Input() quickSearchLabel?: string;
  @Input() clearButtonLabel?: string;
  @Input() searchInputLabel?: string;
  @Output() filterEmitter = new EventEmitter<string[]>();

  public searchInputOutput: SearchInputData;
  public searchInputConfig: SearchInputConfig;
  public searchInput: SearchInputComponent;
  filteredData: any;

  public predicates = [
    {
      id: 'name',
      callback: (value: FilterDataSource): string => {
        return value.name;
      },
      keys: ['nam', 'nme', 'name'],
    },
  ];

  listener = this.getListener();

  getListener(): any {
    return {
      getInput: (): FilterDataSource[] => {
        if (this.dataSource) {
          return this.dataSource?.map((p) => ({ name: p }));
        } else {
          return [{ name: '' }];
        }
      },

      sendOutput: (data: SearchInputData): void => {
        this.filteredData = this.searchInputFlatList.transform(data);
        this.filterEmitter.emit(this.filteredData.map((p) => p.name));
      },
    };
  }

  onConfigChanged(): void {
    this.listener = this.getListener();
    this.searchInputConfig = new SearchInputConfig({
      searchInputText: {
        searchInputLabel: this.searchInputLabel ?? '',
        quickSearchLabel:
          this.quickSearchLabel ??
          this.translateService.instant('quickSearchLabel'),
        clearButtonLabel:
          this.clearButtonLabel ??
          this.translateService.instant('clearButtonLabel'),
      },
      predicates: this.predicates,
      openAutocompleteByClick: true,
    });
  }

  constructor(
    private readonly searchInputFlatList: SearchInputFlatListPipe,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource) {
      this.onConfigChanged();
    }
  }
}
