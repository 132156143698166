<div class="wip">
  <mat-form-field appearance="fill" id="sel-proj">
    <mat-select #selectedTenant>
      <mat-option value="" (click)="onSelectChooseOne()"></mat-option>
      <mat-option
        #matOption
        *ngFor="let project of dataArray"
        [value]="project.projectId"
        (click)="onSelectProject(project)"
      >
        {{ project.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
