import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkstationMenuComponent } from './workstation-menu.component';
import { SidebarModule } from '../navigation/sidebar/sidebar.module';
import { QuickAccessUploadModule } from '../quick-access-upload/quick-access-upload.module';
import { PushModule } from './push/push.module';
import { PushToVaultModule } from 'src/app/features/push-to-vault/push-to-vault.module';
import { PullFromVaultModule } from '../pull-from-vault/pull-from-vault.module';

@NgModule({
  exports: [WorkstationMenuComponent],
  declarations: [WorkstationMenuComponent],
  imports: [
    CommonModule,
    PushModule,
    SidebarModule,
    QuickAccessUploadModule,
    PushToVaultModule,
    PullFromVaultModule,
  ],
})
export class WorkstationMenuModule {}
