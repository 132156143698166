import { ViewStatusHelper } from './project-viewstatus';
import {
  Project,
  ProjectStatus,
  ProjStatusLabels,
} from '../../../models/vault/project.model';
import { isFileErrored } from './project-file-utility';
export class OtherViewStatus extends ViewStatusHelper {
  constructor() {
    super();
  }

  getProjectStatusLabel(project: Project): ProjStatusLabels {
    if (this.isErrorViewStatus(project)) {
      return ProjStatusLabels.Error;
    }

    if (this.isReadyViewStatus(project)) {
      return ProjStatusLabels.Ready;
    }

    if (this.isUploadingViewStatus(project)) {
      return ProjStatusLabels.Uploading;
    }

    return ProjStatusLabels.Unavailable;
  }

  private isErrorViewStatus(project: Project): boolean {
    const hasFileError =
      project.activeProgram?.files?.some((f) => isFileErrored(f)) === true;
    return (
      project.status === ProjectStatus.Error ||
      project.status === ProjectStatus.UploadError ||
      hasFileError
    );
  }

  private isReadyViewStatus(project: Project): boolean {
    return (
      project.status === ProjectStatus.Ready ||
      project.status === ProjectStatus.ReadyWithErrors
    );
  }

  private isUploadingViewStatus(project: Project): boolean {
    return (
      project.status === ProjectStatus.Uploading ||
      project.status === ProjectStatus.Ready
    );
  }
}
