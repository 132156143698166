import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceEditComponent } from './workspace-edit.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../../material.module';
import { SharedButtonModule } from '../../../shared/components/buttons/shared-button.module';
import { RaUiInputModule } from '@ra-web-tech-ui-toolkit/form-system/input';
import { AgGridModule } from '@ag-grid-community/angular';
import { RaUiLabelContainerModule } from '@ra-web-tech-ui-toolkit/common-utils/label-container';
import { RaUiStaticTextModule } from '@ra-web-tech-ui-toolkit/common-utils/static-text';
import { RaUiFormGridModule } from '@ra-web-tech-ui-toolkit/grid/form-grid';
import { RaUiFormModule } from '@ra-web-tech-ui-toolkit/form-system/form';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RaUiButtonModule } from '@ra-web-tech-ui-toolkit/buttons/button';
import { RaUiToastModule } from '@ra-web-tech-ui-toolkit/popups/toast';
import { RaUiDialogModule } from '@ra-web-tech-ui-toolkit/popups/dialog';
import { AgGridTableModule } from '../../../shared/components/ag-grid-table/ag-grid-table.module';
import { LoadingModule } from 'src/app/shared/components/loading/loading.module';

ModuleRegistry.registerModules([ClientSideRowModelModule]);
@NgModule({
  declarations: [WorkspaceEditComponent],
  imports: [
    AgGridModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    SharedButtonModule,
    RaUiInputModule,
    RaUiLabelContainerModule,
    RaUiStaticTextModule,
    RaUiFormGridModule,
    RaUiFormModule,
    RaUiButtonModule,
    RaUiToastModule,
    RaUiDialogModule,
    AgGridTableModule,
    LoadingModule,
  ],
  exports: [WorkspaceEditComponent],
})
export class WorkspaceEditModule {}
