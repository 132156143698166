import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardMenuComponent } from './dashboard-menu.component';
import { WorkspaceListModule } from '../workspace-list/workspace-list.module';
import { QuickAccessModule } from '../quick-access/quick-access.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DashboardMenuComponent],
  imports: [
    CommonModule,
    QuickAccessModule,
    WorkspaceListModule,
    TranslateModule,
  ],
  exports: [DashboardMenuComponent],
})
export class DashboardMenuModule {}
