<div
  *ngIf="tenantServices$ | async as tenantService"
  class="quick-access-content"
>
  <div class="top">
    <div class="title">
      <p>{{ "quickAccess" | translate }}</p>
    </div>
  </div>
  <div class="bottom">
    <app-application-card
      *ngFor="let app of apps"
      [applicationCard]="app"
      (clickAction)="cardClicked($event)"
    >
    </app-application-card>
  </div>
</div>
