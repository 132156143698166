import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavBarTheme, NavItem } from '@rockwell-automation-inc/layout';
import { TenantId, TenantInfo } from '../../core/models/cs/tenant-info.model';
import {
  Observable,
  lastValueFrom,
  BehaviorSubject,
  Subscription,
  skipWhile,
  first,
  combineLatest,
} from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Router } from '@angular/router';
import { TenantInfoService } from '../../core/services/tenant-info.service';
import { TenantInfoFacade } from '../../core/reducers/tenant-info/tenant-info.facade';
import { map } from 'rxjs/operators';
import { Role } from '../../core/models/vault/role-actions.model';
import { NavbarItemsService } from '../../core/services/navbar-items/navbar-items.service';
import { NotificationService } from '../../core/services/navbar/notification.service';
import {
  Notification,
  NotificationCount,
  NotificationPage,
} from '../../core/services/signal-r/notification.model';
import { RoleActionsFacade } from '../../core/reducers/role-actions/role-actions.facade';
import { Organization } from '@rockwell-automation-inc/common-utils';
import { SideBarService } from '../../core/services/side-bar/side-bar.service';
import { SideBarItemSelection } from '../../features/navigation/sidebar/sidebar-options/sidebar-options.component';
import { CommonNavbarService } from '../../core/services/navbar/commonNavbar.service';
import {
  ConfigService,
  TwinStudioConfiguration,
} from '../../core/services/config/config.service';

export enum CsNavbarNotifActions {
  CLEARALL = 'CLEARALL',
  DELETE = 'DELETE',
  MARKASREAD = 'MARKASREAD',
  MARKASUNREAD = 'MARKASUNREAD',
  MARKALLASREAD = 'MARKALLASREAD',
}

@Component({
  selector: 'app-common-navbar-wrapper',
  templateUrl: './common-navbar-wrapper.component.html',
})
export class CommonNavbarWrapperComponent implements OnInit, OnDestroy {
  isAuthenticated$: Observable<boolean> | undefined;
  isAuthenticated!: boolean;
  currentTenantName$ = new BehaviorSubject<string>('');
  theme = NavBarTheme.DARK;
  helpItems: NavItem[] = [];
  navItems: NavItem[] = [];
  homeMenuTitle: string = 'HOME';
  enableThemeSwitcher = true; //FeatureFlag enable later
  subscriptions$ = new Subscription();
  notifications$: Observable<Notification[]>;
  notificationsCount$: Observable<NotificationCount>;
  unreadNotificationsCount$: Observable<number>;
  tenants$ = new BehaviorSubject<Organization[]>([]);
  selectedTenant$: Observable<string | undefined>;
  accountMenu$ = new BehaviorSubject<any>({
    name: '',
    email: '',
  });
  userId$ = new BehaviorSubject<string>('');
  private readonly subscriptions = new Subscription();

  get logoTypeClass(): string {
    return this.theme === NavBarTheme.DARK
      ? 'ra-logo-cs-nav-dark'
      : 'ra-logo-cs-nav-light';
  }

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly tenantInfoService: TenantInfoService,
    private readonly tenantInfoFacade: TenantInfoFacade,
    private readonly navbarItemsService: NavbarItemsService,
    private readonly notificationService: NotificationService,
    private readonly roleActionsFacade: RoleActionsFacade,
    readonly commonNavbarService: CommonNavbarService,
    private readonly sideBarService: SideBarService,
    private readonly configService: ConfigService<TwinStudioConfiguration>
  ) {
    this.subscriptions.add(this.subscribeOnTenantInfo());
    this.notifications$ = notificationService.notifications$;
    this.notificationsCount$ = notificationService.notificationsCount$;
    this.unreadNotificationsCount$ = this.notificationsCount$.pipe(
      map((nc) => nc.unreadNotifications)
    );
  }

  ngOnInit(): void {
    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.isAuthenticated$.subscribe((selectIsLoggedIn) => {
      if (selectIsLoggedIn) {
        this.isAuthenticated = true;
      } else {
        sessionStorage.setItem('returnUrlTwin', window.location.href);
        this.authService.login();
      }
    });
  }

  onLogout(): void {
    this.authService.logout();
  }

  goToHome(): void {
    window.open(this.configService.config.factoryTalkHubBaseUrl, '_blank');
  }

  changeOrg($event: { tenantId: TenantId }): void {
    this.tenantInfoService
      .getTenantInfo($event.tenantId)
      .subscribe((tenant) => {
        this.tenantInfoFacade.updateSelection(tenant);
        this.currentTenantName$.next(tenant.name);
        this.sideBarService.currentSideBarSelection = SideBarItemSelection.Home;
        sessionStorage.setItem(
          'SideBarItemSelection',
          this.sideBarService.currentSideBarSelection
        );
        this.router.navigate([``]);
      });
  }

  onThemeChanged(theme: NavBarTheme): void {
    this.theme = theme;
  }

  onMenuItem($event: NavItem): void {
    if ($event?.route) {
      window.open($event.route, '_blank');
    }
  }

  setNavItems(
    userId: string,
    tenantId: string | undefined,
    role: Role | undefined
  ): void {
    this.helpItems = this.navbarItemsService.getHelpItems();

    if (role === Role.Admin && userId !== tenantId) {
      this.navItems = this.navbarItemsService.getAdminNavItems();
    } else {
      this.navItems = this.navbarItemsService.getBasicNavItems();
    }
  }

  async onNotificationAction($event: {
    action: CsNavbarNotifActions;
    data: string;
  }): Promise<void> {
    switch ($event.action) {
      case CsNavbarNotifActions.DELETE:
        await lastValueFrom(
          this.notificationService.deleteNotification($event.data)
        );
        break;
      case CsNavbarNotifActions.CLEARALL:
        await lastValueFrom(this.notificationService.clearNotifications());
        break;
      case CsNavbarNotifActions.MARKASREAD:
        await this.notificationService.updateNotificationReadStatus(
          $event.data,
          true
        );
        break;
      case CsNavbarNotifActions.MARKASUNREAD:
        await this.notificationService.updateNotificationReadStatus(
          $event.data,
          false
        );
        break;
      case CsNavbarNotifActions.MARKALLASREAD:
        await this.notificationService.markAllNotificationsAsRead();
        break;
      default:
        break;
    }
  }

  notificationPageLookupBinding() {
    return (perPage: number, page: number): Observable<NotificationPage> => {
      return this.notificationService.getNotificationPage(page, perPage);
    };
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private subscribeOnTenantInfo(): Subscription {
    return combineLatest([
      this.authService.user$.pipe(first((user) => user !== undefined)),
      this.tenantInfoService.getAvailableTenants(),
      this.tenantInfoFacade.currentTenant$.pipe(
        skipWhile((current) => current === undefined)
      ),
      this.roleActionsFacade.selectRole$,
    ]).subscribe(([user, tenants, currentTenant, role]) => {
      this.accountMenu$.next({
        name: user.name,
        email: user.email,
      });

      this.userId$.next(user.userId);
      this.tenants$.next(tenants);
      this.currentTenantName$.next((currentTenant as TenantInfo).name);
      this.setNavItems(user.userId, currentTenant?.id, role);
    });
  }
}
