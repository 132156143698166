import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ITooltipConfig } from '@ra-web-tech-ui-toolkit/common-utils/tooltip';

@Component({
  selector: 'app-tooltip-message',
  templateUrl: './tooltip-message.component.html',
  styleUrls: ['./tooltip-message.component.scss'],
})
export class TooltipMessageComponent implements ICellRendererAngularComp {
  params: any;
  tooltipConfig: ITooltipConfig = {
    maxWidth: 200,
    position: 'Bottom',
  };
  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
}
