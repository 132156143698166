<div class="associated-projects-container">
  <p [class]="!repository ? 'disabled' : ''">Associated projects (#)</p>
  <ra-ui-search-input
    #searchInput
    id="search-associated-projects"
    [config]="searchConfig"
    (searchTextChange)="filterByText($event)"
    [disabled]="repository ? false : true"
  >
  </ra-ui-search-input>
  <div *ngIf="!repository" class="empty-projects">
    <img
      id="empty-folder"
      src="/assets/images/empty-folder.svg"
      alt="empty-folder"
    />
    <p id="title-empty" class="title-empty">
      {{ "pullFromVault.noRepositoriesTitle" | translate }}
    </p>
    <p id="subtitle-empty" class="subtitle-empty">
      {{ "pullFromVault.noRepositoriesText" | translate }}
    </p>
  </div>
  <div *ngIf="repository" class="projects">
    <ra-ui-tree
      *ngIf="treeModel"
      id="id-repository-projects-tree"
      [(tree)]="treeModel"
      (treeInitialized)="onTreeInitialized($event)"
      (treeChange)="onTreeChange($event)"
      [config]="config"
    >
      <ng-template #node let-data="model" let-control="treeControl">
        <p
          id="id-repository-name"
          *ngIf="data.label === repository.name"
          class="repository-name-text"
        >
          {{ data.label }}
        </p>
        <ra-ui-checkbox
          *ngIf="data.label !== repository.name"
          id="'id-'{{ data.label }}"
          [label]="data.label"
          [value]="data.checked"
          [style.width.px]="180"
          [config]="checkboxConfig"
          (valueChange)="onChecked(data, $event)"
          (click)="onNodeClicked($event)"
        ></ra-ui-checkbox>
      </ng-template>
      <ng-template #suffix let-data="model">
        <mat-icon
          *ngIf="data.showSuffix"
          id="'id-icon-'{{ data.label }}"
          [class]="data.iconProject"
        ></mat-icon>
      </ng-template>
    </ra-ui-tree>
  </div>
</div>
