import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Workspace } from '../../models/workspace-creation.model';
import {
  selectActiveWorkspace,
  selectWorkspaceDetails,
  selectWorkstationsIdToUpdateStatus,
} from './workspace.selectors';
import {
  setActiveWorkspace,
  clearActiveWorkspace,
  setWorkspaceDetails,
  setWorkstationsIdToUpdateStatus,
} from './workspace.actions';
import { WorkspaceDetails } from '../../models/twin-studio.model';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceFacade {
  activeWorkspace$: Observable<Workspace | undefined> = this.store.select(
    selectActiveWorkspace
  );

  workspaceDetails$: Observable<WorkspaceDetails> = this.store.select(
    selectWorkspaceDetails
  );

  workstationsIds$: Observable<string[]> = this.store.select(
    selectWorkstationsIdToUpdateStatus
  );

  constructor(private readonly store: Store) {}

  setActiveWorkspace(workspace: Workspace): void {
    this.store.dispatch(
      setActiveWorkspace({
        workspace: workspace,
      })
    );
  }

  setWorkspaceDetails(workspaceDetails: WorkspaceDetails): void {
    this.store.dispatch(
      setWorkspaceDetails({
        workspaceDetails: workspaceDetails,
      })
    );
  }

  setWorkstationsIdToUpdateStatus(workstationsIds: string[]): void {
    this.store.dispatch(
      setWorkstationsIdToUpdateStatus({
        workstationsIds: workstationsIds,
      })
    );
  }

  clearActiveWorkspace(): void {
    this.store.dispatch(clearActiveWorkspace());
  }
}
