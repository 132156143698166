import { Injectable } from '@angular/core';
import {
  HubConnection,
  HubConnectionBuilder,
  IHttpConnectionOptions,
} from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import {
  ConfigService,
  TwinStudioConfiguration,
} from '../config/config.service';
import { AuthService } from '../../auth/auth.service';
import { AuthFacade } from '../../auth/auth.facade';
import { WebsocketConnection } from '../../models/websocket-status.model';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private readonly connectionStatus: WebsocketConnection.UpdateInfo[] = [];
  private readonly statusUpdateSubject =
    new Subject<WebsocketConnection.UpdateInfo>();
  hubConnection!: HubConnection;
  bearerToken: IHttpConnectionOptions = {};
  notifyObservable = new Subject<any>();
  notifyJsonObservable = new Subject<any>();

  constructor(
    private readonly authService: AuthService,
    private readonly config: ConfigService<TwinStudioConfiguration>,
    private readonly authFacade: AuthFacade
  ) {}

  private getConnectionOptions(): IHttpConnectionOptions {
    return {
      accessTokenFactory: () => this.authService.getAccessTokenPromise(),
    };
  }

  async startConnection(): Promise<void> {
    await this.authFacade.selectIsLoggedIn.subscribe((loggedIn) => {
      if (!loggedIn) {
        return;
      }
      // Initial retry attempt delays - after we exhaust these, we'll continue retrying
      // every 60 seconds forever.
      const retryTimes = [0, 2000, 5000, 10000, 30000, 60000];
      this.hubConnection = new HubConnectionBuilder()
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: (context) => {
            this.onConnectionUpdated(
              WebsocketConnection.State.Disconnected,
              `Websocket Reconnecting ${context.retryReason.message}`
            );

            const index =
              context.previousRetryCount < retryTimes.length
                ? context.previousRetryCount
                : retryTimes.length - 1;
            return retryTimes[index];
          },
        })
        .withUrl(
          `${this.config.config.notificationApiUrl}/hub/notifications`,
          this.getConnectionOptions()
        )
        .build();

      this.hubConnection.onclose((error) => {
        const message = error ? error.message : 'connection closed';
        this.onConnectionUpdated(WebsocketConnection.State.Closed, message);
      });

      this.hubConnection.onreconnected(() => {
        this.onConnectionUpdated(
          WebsocketConnection.State.Reconnected,
          'Websocket Reconnected'
        );
      });

      return this.hubConnection
        .start()
        .then(() => {
          this.addNotifyListener();
          this.onConnectionUpdated(
            WebsocketConnection.State.Connected,
            'Connection Started'
          );
        })
        .catch((err) => {
          this.onConnectionUpdated(
            WebsocketConnection.State.Closed,
            `connection failed to start ${err}`
          );
        });
    });
  }

  private onConnectionUpdated(
    state: WebsocketConnection.State,
    details: string
  ): void {
    const update = {
      state: state,
      details: details,
      date: new Date(),
    };
    this.connectionStatus.push(update);
    this.statusUpdateSubject.next(update);
  }

  addNotifyListener(): void {
    this.hubConnection.on('Notify', (message) => {
      this.notify(message);
    });

    this.hubConnection.on('OnDomainMessageReceived', (message) => {
      this.notify(message);
    });
  }

  notify(notification: any): void {
    if (notification.customJson) {
      this.notifyJsonObservable.next(notification);
    }
    this.notifyObservable.next(notification);
  }

  getJsonNotificationObservable(): Observable<any> {
    return this.notifyJsonObservable.asObservable();
  }

  getNotificationObservable(): Observable<any> {
    return this.notifyObservable.asObservable();
  }

  getConnectionStatus(): ReadonlyArray<WebsocketConnection.UpdateInfo> {
    return this.connectionStatus;
  }

  onStatusUpdate(): Observable<WebsocketConnection.UpdateInfo> {
    return this.statusUpdateSubject.asObservable();
  }
}
