import { createAction, props } from '@ngrx/store';
import { Workspace } from '../../models/workspace-creation.model';
import { WorkspaceDetails } from '../../models/twin-studio.model';

export const setActiveWorkspace = createAction(
  '[Workspace] Set Workspace',
  props<{ workspace: Workspace }>()
);

export const setWorkspaceDetails = createAction(
  '[Workspace] Set Workspace details',
  props<{ workspaceDetails: WorkspaceDetails }>()
);

export const setWorkstationsIdToUpdateStatus = createAction(
  '[Workspace] Set Workstations To Update Status',
  props<{ workstationsIds: string[] }>()
);

export const clearActiveWorkspace = createAction('[Workspace] Clear Workspace');
