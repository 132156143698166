import { createAction, props } from '@ngrx/store';
import { TenantInfo } from '../../models/cs/tenant-info.model';

export const getTenantInfo = createAction(
  '[TENANT DATA] Get Tenant Info',
  props<{ tenantId: string }>()
);

export const setTenantInfoSuccess = createAction(
  '[TENANT DATA] Set Tenant Info',
  props<{ tenantInfo: TenantInfo }>()
);
