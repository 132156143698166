import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  ConfigService,
  TwinStudioConfiguration,
} from 'src/app/core/services/config/config.service';
import { UserProfile } from '../auth/user-profile.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private readonly configService: ConfigService<TwinStudioConfiguration>,
    private readonly http: HttpClient
  ) {}

  isEulaAccepted(): Observable<boolean> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/user`;
    return this.http.get<UserProfile>(url).pipe(
      map((res) => !res.eulaAcceptanceRequired),
      catchError((error: unknown) => {
        //In the case the current tenant not found. Redirect to FTHub
        window.location.href = this.configService.config.csPortalUrl;
        return throwError(() => error);
      })
    );
  }
}
