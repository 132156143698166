import { createReducer, on } from '@ngrx/store';
import { WorkspaceState } from '../../models/workspace-creation.model';
import {
  setActiveWorkspace,
  clearActiveWorkspace,
  setWorkspaceDetails,
  setWorkstationsIdToUpdateStatus,
} from './workspace.actions';

export const sessionStorageWorkspaceInfo = 'local_workspace_info';

export const initialWorkspaceState: WorkspaceState =
  getLocalSessionStorageState() ?? {};

export const workspaceReducer = createReducer(
  initialWorkspaceState,
  on(setActiveWorkspace, (state, { workspace }): WorkspaceState => {
    return {
      ...state,
      activeWorkspace: workspace,
    };
  }),
  on(setWorkspaceDetails, (state, { workspaceDetails }): WorkspaceState => {
    const workspaceState = {
      ...state,
      workspaceDetails: workspaceDetails,
    };
    saveToLocalSessionStorage(workspaceState);
    return workspaceState;
  }),

  on(
    setWorkstationsIdToUpdateStatus,
    (state, { workstationsIds }): WorkspaceState => {
      return {
        ...state,
        workstationsIds: workstationsIds,
      };
    }
  ),

  on(clearActiveWorkspace, (): WorkspaceState => {
    saveToLocalSessionStorage({});
    return initialWorkspaceState;
  })
);

export function getLocalSessionStorageState(): WorkspaceState | undefined {
  const sessionState = sessionStorage.getItem(sessionStorageWorkspaceInfo);
  return sessionState
    ? (JSON.parse(sessionState) as WorkspaceState)
    : undefined;
}

export function saveToLocalSessionStorage(value: WorkspaceState): void {
  return sessionStorage.setItem(
    sessionStorageWorkspaceInfo,
    JSON.stringify(value)
  );
}

export function clearLocalSessionStorage(): void {
  return sessionStorage.removeItem(sessionStorageWorkspaceInfo);
}
