import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '../logger/logger.service';
import { catchError, forkJoin, map, Observable, throwError } from 'rxjs';
import {
  PullFilesPayload,
  PullFilesProjects,
  PushChangesResponse,
  SessionKind,
} from '../../models/twin-studio.model';
import {
  ConfigService,
  TwinStudioConfiguration,
} from '../config/config.service';
import { LCoreResponse } from '../../models/lcore-response.model';

@Injectable({
  providedIn: 'root',
})
export class PullService {
  constructor(
    private readonly http: HttpClient,
    private readonly loggerService: LoggerService,
    private readonly configService: ConfigService<TwinStudioConfiguration>
  ) {}

  pullFiles(
    filesToPull: PullFilesProjects[],
    tenantId: string,
    sessionKind = SessionKind.Logix
  ): Observable<PushChangesResponse> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/vaults/${tenantId}/appsession/${sessionKind}/pushChanges`;
    const payload: PullFilesPayload[] = [
      {
        region: 'America',
        projects: filesToPull,
      },
      {
        region: 'Europe',
        projects: filesToPull,
      },
      {
        region: 'Ap',
        projects: filesToPull,
      },
    ];

    const requests = payload.map((item) => {
      return this.http.post<LCoreResponse<PushChangesResponse>>(url, item).pipe(
        map((x) => x.data),
        catchError((err: unknown) => this.handleError(err as HttpErrorResponse))
      );
    });

    return forkJoin(requests).pipe(
      map((x, i) => {
        return x[i];
      }),
      catchError((err: unknown) => this.handleError(err as HttpErrorResponse))
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (!error.status || error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      const msg = error.error ?? error.message ?? error;
      this.loggerService.error(`An error occurred: `, msg);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      this.loggerService.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      () =>
        new Error(
          'Something bad happened; please try again later. - pull service'
        )
    );
  }
}
