import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddInstanceComponent } from './add-instance.component';
import { RaUiAccordionModule } from '@ra-web-tech-ui-toolkit/layout/accordion';
import { RaUiAccordionItemModule } from '@ra-web-tech-ui-toolkit/layout/accordion-item';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { AgGridModule } from '@ag-grid-community/angular';
import { RaUiFormGridModule } from '@ra-web-tech-ui-toolkit/grid/form-grid';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { AgRemoveBtnIconComponent } from './ag-remove-btn-icon/ag-remove-btn-icon.component';
import { RaUiInputModule } from '@ra-web-tech-ui-toolkit/form-system/input';
import { RaUiCommonGridHelperModule } from '@ra-web-tech-ui-toolkit/grid/cdk';
import { RaUiChipModule } from '@ra-web-tech-ui-toolkit/buttons/chip';
import { AgChipComponent } from './ag-chip/ag-chip.component';

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

@NgModule({
  declarations: [
    AddInstanceComponent,
    AgRemoveBtnIconComponent,
    AgChipComponent,
  ],
  imports: [
    AgGridModule,
    CommonModule,
    RaUiAccordionModule,
    RaUiAccordionItemModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    RaUiFormGridModule,
    RaUiInputModule,
    RaUiCommonGridHelperModule,
    RaUiChipModule,
  ],
  exports: [AddInstanceComponent, AgRemoveBtnIconComponent],
})
export class AddInstanceModule {}
