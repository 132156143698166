import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  ActionButtonStyles,
  NotificationType,
} from '@ra-web-tech-ui-toolkit/cdk/types';
import {
  IStepperButton,
  IStepperButtonsProvider,
  IStepperConfig,
  IStepperItem,
  StepperComponent,
} from '@ra-web-tech-ui-toolkit/common-views/stepper';
import { Observable, Subject, forkJoin, takeUntil } from 'rxjs';
import { TenantInfoFacade } from 'src/app/core/reducers/tenant-info/tenant-info.facade';
import {
  Association,
  AssociationsComponent,
} from 'src/app/features/push-to-vault/associations/associations.component';
import { SelectFilesComponent } from 'src/app/features/push-to-vault/select-files/select-files.component';
import { SelectRegionComponent } from 'src/app/features/push-to-vault/select-region/select-region.component';
import {
  SummaryComponent,
  SummaryData,
} from 'src/app/features/push-to-vault/summary/summary.component';
import * as _ from 'lodash';
import {
  DialogService,
  IDialogConfig,
} from '@ra-web-tech-ui-toolkit/popups/dialog';
import { SideBarItemSelection } from 'src/app/features/navigation/sidebar/sidebar-options/sidebar-options.component';
import {
  FilesToAddNewVersion,
  FilesToAddNewVersionWorkstations,
  ProjectPushResultError,
  PushChangesPayload,
  PushChangesPayloadWorkstations,
  PushChangesResponse,
  Region,
  SessionKind,
  Tier,
} from '../../core/models/twin-studio.model';
import { SessionFacade } from '../../core/reducers/session/session.facade';
import { PushService } from 'src/app/core/services/push/push.service';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from '../../core/services/sidenav.service';

@Component({
  selector: 'app-push-to-vault',
  templateUrl: './push-to-vault.component.html',
  styleUrls: ['./push-to-vault.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PushToVaultComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sidenav') sidenav!: MatSidenav;
  @ViewChild('stepper') stepperCmp!: StepperComponent;
  @Output() continueWithSession = new EventEmitter<boolean>();
  @Output() goToDashboard = new EventEmitter<SideBarItemSelection>();
  private readonly destroy$: Subject<void> = new Subject();
  isSelectRegionDisabled!: boolean;
  summaryComponentRetryAttempts = 0;
  regionAlreadySet!: Region;
  tenantId: string | undefined = undefined;
  goHomeUrl = '/';
  isLoading = true;
  retryButtonLabel = this.translateSvc.instant(
    'pushToVault.summaryContent.retry'
  );
  firstStepLabel = this.translateSvc.instant('pushToVault.selectRegion');
  secondStepLabel = this.translateSvc.instant('pushToVault.selectFiles');
  thirdStepLabel = this.translateSvc.instant('pushToVault.associations');
  forthStepLabel = this.translateSvc.instant('pushToVault.summary');
  continueButton = this.translateSvc.instant('continueButton');
  cancelButtonLabel = this.translateSvc.instant(
    'RA_UI_COMMON_VIEWS.STEPPER.BTN_EXIT_LABEL'
  );
  backButtonLabel = this.translateSvc.instant(
    'RA_UI_COMMON_VIEWS.STEPPER.BTN_PREVIOUS_LABEL'
  );
  nextButtonLabel = this.translateSvc.instant(
    'RA_UI_COMMON_VIEWS.STEPPER.BTN_NEXT_LABEL'
  );
  finishButtonLabel = this.translateSvc.instant('pushToVault.btnPushToVault');
  closeButtonLabel = this.translateSvc.instant(
    'RA_UI_COMMON_VIEWS.STEPPER.BTN_CANCEL_LABEL'
  );
  snackBar?: MatSnackBarRef<TextOnlySnackBar>;
  items!: IStepperItem[];
  projectsToPush = [] as FilesToAddNewVersion[];
  projectsToPushWorkstations = [] as FilesToAddNewVersionWorkstations[];
  stepperConfig: IStepperConfig = {
    enableSummary: false,
  };
  buttonsStep!: IStepperButton[];
  buttonsProvider!: IStepperButtonsProvider;

  dialogConfig: IDialogConfig = {
    title: this.translateSvc.instant('pushToVault.exitDialog'),
    message: this.translateSvc.instant('pushToVault.exitMessage'),
    buttons: [
      {
        label: this.translateSvc.instant(
          'RA_UI_COMMON_VIEWS.STEPPER.BTN_EXIT_LABEL'
        ),
        buttonStyle: ActionButtonStyles.Main,
      },
      {
        label: this.translateSvc.instant(
          'RA_UI_COMMON_VIEWS.STEPPER.BTN_CLOSE_LABEL'
        ),
        buttonStyle: ActionButtonStyles.Outlined,
      },
    ],
    messageType: NotificationType.Error,
    showCloseIconButton: false,
    hideTitleIcon: false,
  };

  constructor(
    private readonly translateSvc: TranslateService,
    private readonly tenantInfoFacade: TenantInfoFacade,
    private readonly activatedRoute: ActivatedRoute,
    private readonly dialogService: DialogService,
    private readonly sessionFacade: SessionFacade,
    private readonly pushService: PushService,
    private readonly snackbarService: SnackbarService,
    private readonly sidenavService: SidenavService
  ) {
    this.tenantInfoFacade.tenantId$
      .pipe(takeUntil(this.destroy$))
      .subscribe((tenantId) => (this.tenantId = tenantId));
  }

  ngOnInit(): void {
    this.initializeStepper();
    this.isLoading = false;
  }

  ngAfterViewInit(): void {
    this.subscribeStepperButtonClick();
    this.sidenavService.setSidenav(this.sidenav);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initializeStepper(): void {
    this.isSelectRegionDisabled = false;
    this.sessionFacade.sessionData$.subscribe((sessionData) => {
      const tenantId = this.activatedRoute.snapshot.paramMap.get('tenantId');
      const sessionKind = this.activatedRoute.snapshot.paramMap.get(
        'sessionKind'
      ) as SessionKind;
      const tier = this.activatedRoute.snapshot.paramMap.get('tier') as Tier;
      const idRecord = [tenantId, sessionKind, tier].join('|');
      if (sessionData[idRecord]) {
        this.regionAlreadySet = sessionData[idRecord].region;
        this.isSelectRegionDisabled = true;
      }
    });
    this.items = this.getSteps();
    this.buttonsProvider = {
      getButtons: (selectedIndex: number) => {
        return this.getStepButton(selectedIndex);
      },
    };
  }

  getSteps(): IStepperItem[] {
    const steps = [];
    if (!this.isSelectRegionDisabled) {
      steps.push({
        title: this.firstStepLabel,
        component: SelectRegionComponent,
        data: {
          tenantId: this.tenantId,
          region: '',
        },
      });
    }
    steps.push(
      {
        title: this.secondStepLabel,
        component: SelectFilesComponent,
        data: {
          tenantId: this.tenantId,
          region: this.regionAlreadySet,
          files: [],
          isSelectRegionDisabled: this.isSelectRegionDisabled,
        },
      },
      {
        title: this.thirdStepLabel,
        component: AssociationsComponent,
        data: {
          tenantId: this.tenantId,
          region: this.regionAlreadySet,
          isSelectRegionDisabled: this.isSelectRegionDisabled,
          associations: [],
        },
      },
      {
        title: this.forthStepLabel,
        component: SummaryComponent,
        data: {
          failedFiles: [],
          isSelectRegionDisabled: this.isSelectRegionDisabled,
          retryAttempts: this.summaryComponentRetryAttempts,
          files: [],
        },
      }
    );
    return steps;
  }

  getStepButton(step: number): IStepperButton[] {
    this.buttonsStep = [];
    switch (step) {
      case 1:
        this.buttonsStep = [
          {
            label: this.backButtonLabel,
            buttonStyle: ActionButtonStyles.Outlined,
          },
          !this.isSelectRegionDisabled
            ? {
              label: this.nextButtonLabel,
              buttonStyle: ActionButtonStyles.Main,
              disabled: true,
            }
            : {
              label: this.finishButtonLabel,
              buttonStyle: ActionButtonStyles.Main,
              disabled: true,
            },
          {
            label: this.cancelButtonLabel,
            buttonStyle: ActionButtonStyles.Outlined,
          },
        ];
        break;
      case 2:
        this.buttonsStep.push({
          label: this.backButtonLabel,
          buttonStyle: ActionButtonStyles.Outlined,
        });
        if (!this.isSelectRegionDisabled) {
          this.buttonsStep.push({
            label: this.finishButtonLabel,
            buttonStyle: ActionButtonStyles.Main,
            disabled: true,
          });
          this.buttonsStep.push({
            label: this.cancelButtonLabel,
            buttonStyle: ActionButtonStyles.Outlined,
          });
        } else {
          this.buttonsStep.push({
            label: this.closeButtonLabel,
            buttonStyle: ActionButtonStyles.Main,
          });
        }
        break;
      case 3:
        this.buttonsStep = [
          {
            label: this.backButtonLabel,
            buttonStyle: ActionButtonStyles.Outlined,
          },
          {
            label: this.closeButtonLabel,
            buttonStyle: ActionButtonStyles.Main,
          },
        ];
        break;

      default:
        this.buttonsStep = [
          {
            label: this.nextButtonLabel,
            buttonStyle: ActionButtonStyles.Main,
            disabled: true,
          },
          {
            label: this.cancelButtonLabel,
            buttonStyle: ActionButtonStyles.Outlined,
          },
        ];
        break;
    }
    return this.buttonsStep;
  }

  private subscribeStepperButtonClick() {
    this.stepperCmp?.onButtonClick
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        if (event.button.label === this.nextButtonLabel) {
          this.stepperCmp.moveToNext();
        }
        if (event.button.label === this.backButtonLabel) {
          this.stepperCmp.moveToPrevious();
        }
        if (
          _.isEqual(event.button.label, this.cancelButtonLabel) ||
          _.isEqual(event.button.label, this.closeButtonLabel)
        ) {
          this.openDialog();
        }
        if (_.isEqual(event.button.label, this.finishButtonLabel)) {
          this.projectsToPush = [];
          const indexNumberStep: number = this.isSelectRegionDisabled ? 1 : 2;
          this.stepperCmp.items[indexNumberStep].data.associations.forEach(
            (association: Association) => {
              if (!association.workstation) {
                const projectToPush: FilesToAddNewVersion = {
                  fileName: association.fileName,
                  filePath: association.path,
                  projectId: association.associatedProject.projectId,
                  solutionName: association.associatedRepo.name,
                  note: association.commitMessage,
                };
                this.projectsToPush.push(projectToPush);
              } else {
                const projectToPush: FilesToAddNewVersionWorkstations = {
                  fileName: association.fileName,
                  filePath: association.path,
                  projectId: association.associatedProject.projectId,
                  solutionId: association.associatedRepo.solutionId,
                  regionAndWorkstationId: association.regionAndWorkstationId,
                  note: association.commitMessage,
                };
                this.projectsToPushWorkstations.push(projectToPush);
              }
            }
          );
          this.onPushToVault(
            this.projectsToPush,
            this.projectsToPushWorkstations,
            indexNumberStep
          );
        }
        if (_.isEqual(event.button.label, this.retryButtonLabel)) {
          this.summaryComponentRetry();
        }
      });
  }

  openDialog(): void {
    const dialogRef = this.dialogService.openWarningDialog(this.dialogConfig);
    dialogRef.componentInstance.onClick.subscribe((data) => {
      if (data.buttonStyle === 'main' && !this.isSelectRegionDisabled) {
        this.goToDashboard.emit(SideBarItemSelection.Home);
      }
      if (data.buttonStyle === 'main' && this.isSelectRegionDisabled) {
        this.continueWithSession.emit(true);
      }
    });
  }

  private getRegion(): string {
    return _.get(this.stepperCmp, 'items[0].data.region', '');
  }

  summaryComponentRetry(): void {
    const indexNumberStep: number = this.isSelectRegionDisabled ? 1 : 2;
    this.summaryComponentRetryAttempts++;
    this.stepperCmp.items[this.stepperCmp.items.length - 1].data.retryAttempts =
      this.summaryComponentRetryAttempts;
    if (this.summaryComponentRetryAttempts >= 3) {
      this.stepperCmp._disabledButtons[1] = true;
      this.stepperCmp._disabledButtons[0] = true;
    } else {
      this.onPushToVault(
        this.projectsToPush,
        this.projectsToPushWorkstations,
        indexNumberStep
      );
    }
  }

  onPushToVault(
    projectsToAddVersion: FilesToAddNewVersion[],
    projectsToAddVersionWorkstations: FilesToAddNewVersionWorkstations[],
    indexNumberStep: number
  ): void {
    this.regionAlreadySet = this.getRegion() as Region;
    const projectsToAddVersionPayload: PushChangesPayload = {
      projectsNewVersion: projectsToAddVersion,
      region: this.regionAlreadySet,
    };

    if (projectsToAddVersionWorkstations.length === 0) {
      this.pushService
        .pushChanges(this.tenantId as string, projectsToAddVersionPayload)
        .subscribe({
          next: (response: PushChangesResponse) => {
            let msg = '';
            if (
              (response.projectsNotUploaded as ProjectPushResultError[])
                .length > 0
            ) {
              const files: SummaryData[] = [];
              (
                response.projectsNotUploaded as ProjectPushResultError[]
              ).forEach((proj: ProjectPushResultError) => {
                const association: Association = this.stepperCmp.items[
                  indexNumberStep
                ].data.associations.find(
                  (association: Association) =>
                    association.associatedProject.projectId === proj.projectId
                );
                const failedProject: SummaryData = {
                  associatedProject: association.associatedProject.name,
                  associatedRepo: association.associatedRepo.name,
                  commitMessage: association.commitMessage,
                  fileName: association.fileName,
                  status: 'failed',
                };
                files.push(failedProject);
              });
              this.stepperCmp.items[indexNumberStep + 1].data.files = files;
              msg = this.translateSvc.instant('pushToVault.snackbarFailed');
              this.snackBar = this.snackbarService.openSnackBarWithAction(
                msg,
                undefined
              );
            } else {
              const files: SummaryData[] = [];
              this.stepperCmp.items[indexNumberStep].data.associations.forEach(
                (associaton: Association) => {
                  const succesProject: SummaryData = {
                    associatedProject: associaton.associatedProject.name,
                    associatedRepo: associaton.associatedRepo.name,
                    commitMessage: associaton.commitMessage,
                    fileName: associaton.fileName,
                    status: 'success',
                  };
                  files.push(succesProject);
                }
              );
              this.stepperCmp.items[indexNumberStep + 1].data.files = files;
              msg = this.translateSvc.instant('pushToVault.snackbarSuccess');
              this.snackBar = this.snackbarService.openSnackBarWithAction(
                msg,
                undefined
              );
            }
            this.stepperCmp.moveToNext();
          },
          error: (error: unknown) => {
            this.showError();
          },
        });
    } else {
      const requests: Observable<any>[] = [];
      for (const element of projectsToAddVersionWorkstations) {
        const project = element;
        const body: PushChangesPayloadWorkstations = {
          projectId: project.projectId,
          fileName: project.fileName,
          filePath: project.filePath,
          solutionId: project.solutionId,
          note: project.note,
        };
        const request = this.pushService.pushChangesWorkstations(
          project.regionAndWorkstationId,
          this.tenantId ?? '',
          body
        );
        requests.push(request);
      }

      forkJoin(requests).subscribe((results) => {
        let associations: Association[] =
          this.stepperCmp.items[indexNumberStep].data.associations;
        const files: SummaryData[] = [];
        results.forEach((response) => {
          if (response.projectsNotUploaded.length > 0) {
            const failed = projectsToAddVersionWorkstations.find(
              (obj) => obj.filePath === response.projectsNotUploaded[0].filePath
            );
            const association: Association = this.stepperCmp.items[
              indexNumberStep
            ].data.associations.find(
              (association: Association) =>
                association.associatedProject.projectId === failed?.projectId
            );

            const failedPush = {
              status: 'failed',
              fileName: association.fileName,
              associatedRepo: association.associatedRepo.name,
              associatedProject: association.associatedProject.name,
              commitMessage: association.commitMessage,
            };

            const msg = this.translateSvc.instant('pushToVault.snackbarFailed');
            this.snackBar = this.snackbarService.openSnackBarWithAction(msg);

            files.push(failedPush);
            associations = associations.filter(
              (association: Association) =>
                association.fileName !== failedPush.fileName
            );
          } else {
            const msg = this.translateSvc.instant(
              'pushToVault.snackbarSuccess'
            );
            this.snackBar = this.snackbarService.openSnackBarWithAction(msg);
          }

          associations.forEach((association: Association) => {
            const succesProject: SummaryData = {
              associatedProject: association.associatedProject.name,
              associatedRepo: association.associatedRepo.name,
              commitMessage: association.commitMessage,
              fileName: association.fileName,
              status: 'success',
            };
            files.push(succesProject);
          });
        });
        this.stepperCmp.items[indexNumberStep + 1].data.files = files;
        this.stepperCmp.moveToNext();
      });
    }
  }

  showError(): void {
    const msg = this.translateSvc.instant('appstream.errorMessageProcess');
    this.snackBar = this.snackbarService.openSnackBarWithAction(msg, undefined);
  }
}
