<div class="sidebar-options">
  <div *ngFor="let item of sidebarItems">
    <div
      id="{{ item.id }}"
      (click)="onNavigate(item)"
      class="{{
        'option ' + (currentSelection === item.text ? 'selected' : '')
      }}"
    >
      <i class="{{ 'icon ' + item.icon }}"></i>
      <p>{{ item.text }}</p>
    </div>
  </div>
</div>
