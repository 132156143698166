import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { SelectProjectComponent } from './select-project.component';
import { TitleStepModule } from '../../../shared/components/title-step/title-step.module';
import { TranslateModule } from '@ngx-translate/core';
import { SelectRepositoryModule } from './select-repository/select-repository.module';
import { AssociatedProjectsModule } from './associated-projects/associated-projects.module';
import { SelectedFilesModule } from './selected-files/selected-files.module';
import { RaUiLoadingSpinnerModule } from '@ra-web-tech-ui-toolkit/indicators/loading-spinner';

@NgModule({
  declarations: [SelectProjectComponent],
  imports: [
    CommonModule,
    TitleStepModule,
    TranslateModule,
    SelectRepositoryModule,
    AssociatedProjectsModule,
    SelectedFilesModule,
    AsyncPipe,
    RaUiLoadingSpinnerModule,
  ],
  exports: [SelectProjectComponent],
})
export class SelectProjectModule {}
