import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileListComponent } from './file-list.component';
import { RaUiListModule } from '@ra-web-tech-ui-toolkit/lists/list';
import { RaUiLoadingSpinnerModule } from '@ra-web-tech-ui-toolkit/indicators/loading-spinner';

@NgModule({
  declarations: [FileListComponent],
  imports: [CommonModule, RaUiListModule, RaUiLoadingSpinnerModule],
  exports: [FileListComponent],
})
export class FileListModule {}
