import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashed-layout',
  templateUrl: './dashed-layout.component.html',
  styleUrls: ['./dashed-layout.component.scss'],
})
export class DashedLayoutComponent implements OnInit {
  @Input() text: string = '';
  @Input() image: string = '';
  @Input() title: string = '';
  @Input() height: string = '';
  @Input() isDisabled: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
