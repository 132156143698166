import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryComponent } from './summary.component';
import { TitleStepModule } from '../../../shared/components/title-step/title-step.module';
import { TranslateModule } from '@ngx-translate/core';
import { RaUiTreeModule } from '@ra-web-tech-ui-toolkit/lists/tree';

@NgModule({
  declarations: [SummaryComponent],
  imports: [CommonModule, TitleStepModule, TranslateModule, RaUiTreeModule],
  exports: [SummaryComponent],
})
export class SummaryModule {}
