import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ISearchInputConfig,
  ISearchInputText,
} from '@ra-web-tech-ui-toolkit/form-system/search-input';
import { Repository } from '../select-project.component';
import {
  ITreeConfig,
  ITreeInitializedEvent,
  ITreeNode,
  RaUiTreeControl,
  TreeComponent,
  TreeModel,
} from '@ra-web-tech-ui-toolkit/lists/tree';
import {
  ProjectData,
  ProjectId,
} from '../../../../core/models/vault/project.model';
import { SolutionId } from '../../../../core/models/vault/solution.model';
import { ICheckboxConfig } from '@ra-web-tech-ui-toolkit/form-system/checkbox';

export interface FileSelected {
  projectId: ProjectId;
  projectName: string;
  historyId: any[];
  file: string;
  icon: string;
}

export interface RepositoryFilesSelected {
  repositoryId: SolutionId;
  repositoryName: string;
  filesSelected: FileSelected[];
}

@Component({
  selector: 'app-associated-projects',
  templateUrl: './associated-projects.component.html',
  styleUrls: ['./associated-projects.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssociatedProjectsComponent implements OnChanges, OnInit {
  @Input() repository!: Repository;
  @Output() projectsChecked = new EventEmitter<RepositoryFilesSelected>();
  @ViewChild(TreeComponent, { static: false }) tree!: TreeComponent;

  searchInputText: ISearchInputText = {
    searchInputLabel: 'Search for ...',
  };

  searchConfig: ISearchInputConfig = {
    searchInputText: this.searchInputText,
  };

  config: ITreeConfig = {
    isNodeIconEnabled: false,
  };

  checkboxConfig: ICheckboxConfig = {
    disableSidePaddings: true,
    disableTooltip: false,
  };
  treeModel!: TreeModel;
  treeControl!: RaUiTreeControl;

  repositoryFilesSelected!: RepositoryFilesSelected;

  originalProjects!: ProjectData[];

  ngOnChanges(changes: SimpleChanges): void {
    if (this.repository) {
      this.repository.projects = this.repository.projects.filter(
        (project) => project.projectHistory.length > 0
      );
      this.originalProjects = this.repository.projects;
      this.setTreeModel();
      this.repositoryFilesSelected = {
        repositoryId: this.repository.solutionId,
        repositoryName: this.repository.name,
        filesSelected: [],
      };
    }
  }

  ngOnInit(): void {}

  setTreeModel(): void {
    this.treeModel = [
      {
        label: this.repository.name,
        icon: 'ra-icon-ide-lg-folder',
        children: this.repository.projects.map((project) => {
          const treeNode: ITreeNode = {
            label: project.name,
            iconProject: this.getIcon(project.projectHistory[0].fileName),
            projectId: project.projectId,
            historyId: project.projectHistory[0].historyId,
            projectFile: project.projectHistory[0].fileName,
            checked: false,
            showSuffix: true,
          };
          return treeNode;
        }),
      },
    ];
  }

  getIcon(fileName: string): string {
    if (fileName.includes('.')) {
      const extention = fileName.split('.')[1].toLowerCase();
      if (extention === 'l5x' || extention === 'acd' || extention === 'l5k') {
        return 'ra-icon-ide-lg-controller-project';
      }
      if (extention === 'doe') {
        return 'ra-icon-ide-lg-arena';
      }
      if (extention === 'demo3d') {
        return 'ra-icon-ide-lg-simulation-project';
      }
      if (extention === 'jpg' || extention === 'png' || extention === 'svg') {
        return 'ra-icon-ide-lg-img';
      }
      if (extention === 'pdf') {
        return 'ra-icon-ide-lg-pdf';
      }
      return 'ra-icon-ide-lg-unknown-asset';
    } else {
      return 'ra-icon-ide-lg-unknown-asset';
    }
  }

  onTreeInitialized(event: ITreeInitializedEvent): void {
    this.treeControl = event.control;
    // this.treeControl.expand(this.treeModel[0]);
    // console.log('se inicializó el arbol');
  }

  onTreeChange(newTreeModel: TreeModel): void {}

  filterByText(criteria: string): void {
    if (criteria === '') {
      this.repository.projects = this.originalProjects;
      this.setTreeModel();
    } else {
      this.repository.projects = this.originalProjects.filter((project) =>
        project.name.toLowerCase().includes(criteria.toLowerCase())
      );
      this.setTreeModel();
    }
  }

  onChecked(model: ITreeNode, val: boolean): void {
    model.checked = val;
    if (model.checked) {
      this.repositoryFilesSelected.filesSelected.push({
        projectName: model.label as string,
        file: model.projectFile,
        historyId: model.historyId,
        projectId: model.projectId,
        icon: model.iconProject,
      });
      this.projectsChecked.emit(this.repositoryFilesSelected);
    } else {
      this.repositoryFilesSelected.filesSelected =
        this.repositoryFilesSelected.filesSelected.filter(
          (project) => project.projectId !== model.projectId
        );
      this.projectsChecked.emit(this.repositoryFilesSelected);
    }
  }

  onNodeClicked(event: MouseEvent): void {
    event.stopPropagation();
  }
}
