import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FileViewForDisplay,
  isArrayOfFileViewForDisplay,
  ProjectTableHelper,
  validateCommmitMessages,
} from '../../../../shared/utility/project-table-helper';
import {
  combineLatest,
  map,
  of,
  Subject,
  Subscription,
  takeWhile,
  tap,
} from 'rxjs';
import {
  TenantId,
  TenantInfo,
} from '../../../../core/models/cs/tenant-info.model';
import {
  CreateAndSyncProjectsPayload,
  FileData,
  ProjectMetadata,
  Region,
  Session,
} from '../../../../core/models/twin-studio.model';
import {
  CheckboxToolTip,
  DataWithId,
} from 'src/app/core/models/table-templates.model';
import { DatePipe } from '@angular/common';
import { MenuSelectorItem } from '../../../../core/models/menu-selector.model';
import {
  projExtension,
  ProjType,
} from '../../../../core/models/vault/project.model';
import {
  WorkspaceContentPayload,
  WorkstationExplorer,
} from '../../../../core/models/workstation-explorer.model';
import { PushService } from '../../../../core/services/push/push.service';
import { getFileExtension } from '../../../../shared/utility/app-utility';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '../../../../core/services/snackbar/snackbar.service';
import { MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { SidebarData } from '../../../../core/models/sidebar-data';
import { SidePanelService } from '../../../../core/services/side-panel/side-panel.service';
import { infoSidebarData } from '../unknown-projects/unknown-projects.component';
import { DialogService } from '../../../../core/services/dialog.service';
import { FileHelper } from '../../../../shared/utility/file.helper';
import { switchMap } from 'rxjs/operators';

export const pathSolutions: string = 'vault/solutions/';

@Component({
  selector: 'app-projects-to-vault',
  templateUrl: './projects-to-vault.component.html',
  styleUrls: ['./projects-to-vault.component.scss'],
})
export class ProjectsToVaultComponent implements OnInit, OnChanges, OnDestroy {
  private readonly destroy: Subject<void> = new Subject<void>();
  @Input() currentTenant!: TenantInfo;
  @Input() sessionData?: Session;
  @Output() cancelEmitter = new EventEmitter<boolean>();
  @Input() displayCancel = true;

  columns: Array<{
    columnDef: string;
    header: string;
    cell: (element: FileViewForDisplay) => string;
  }> = [];
  disableItems: Array<DataWithId> = [];
  checkboxToolTipItems: Array<CheckboxToolTip> = [];
  projects: FileData[] = [];
  projectsSelected: FileViewForDisplay[] = [];
  completed: boolean = false;
  displayLoadingTable = false;
  displayFullPageLoading = false;
  displayTable = false;
  selectedRegion: Region;
  folderSolutionSelected: string;
  folders: MenuSelectorItem[] = [];
  dataTable: FileViewForDisplay[] = [];
  currentPath: string;
  tenantId: TenantId;
  workstationModel: WorkstationExplorer;
  loadingContent: boolean = false;
  currentDisplayedItems: string[];
  snackBar?: MatSnackBarRef<TextOnlySnackBar>;
  infoSidebarData: SidebarData = infoSidebarData;
  @ViewChild('infoRef', { static: true, read: TemplateRef })
  private readonly infoRef: TemplateRef<any> | undefined;

  constructor(
    private readonly datePipe: DatePipe,
    private readonly dialogService: DialogService,
    private readonly pushService: PushService,
    private readonly translateService: TranslateService,
    private readonly snackbarService: SnackbarService,
    private readonly sidePanelService: SidePanelService
  ) {}

  ngOnInit(): void {
    this.currentPath = pathSolutions;
    this.tenantId = this.currentTenant.id;
    if (this.sessionData) {
      this.selectedRegion = this.sessionData.region;
    }
    this.setColumns();
  }

  private setColumns(): void {
    this.columns = ProjectTableHelper.getPushToVaultColumns();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sessionData) {
      this.displayTable = false;
      if (changes.sessionData?.currentValue !== undefined) {
        this.tenantId = this.currentTenant.id;
        this.selectedRegion = this.sessionData.region;
        this.currentPath = pathSolutions;
        const payload = this.setPayload();
        this.listSolutionsFromTwin(payload);
      }
    }
    if (changes.currentTenant || changes.sessionData) {
      this.displayTable = false;
      this.tenantId = this.currentTenant.id;
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

  organizeDataSolutionFolders(): void {
    this.folders = undefined;
    this.folders = this.currentDisplayedItems.map((data) => {
      return {
        displayText: data.replace('/', ''),
      };
    });
  }

  onItemSelectionChanged(projects: unknown): void {
    if (isArrayOfFileViewForDisplay(projects)) {
      this.completed = validateCommmitMessages(projects);
      this.projectsSelected = projects;
    }
  }

  setPayload(): WorkspaceContentPayload {
    return {
      path: this.currentPath,
      region: this.selectedRegion,
      onlyFolders: true,
    };
  }

  onRegionSelected(region: Region): Subscription {
    this.folderSolutionSelected = '';
    this.projectsSelected = [];
    this.currentPath = pathSolutions;
    this.selectedRegion = region;
    const payload = this.setPayload();
    return this.listSolutionsFromTwin(payload);
  }

  setWorkstationModel(): void {
    this.workstationModel = {
      currentTenant: this.currentTenant,
      sessionData: this.sessionData,
      initLocation: ['vault'],
      excludeFolderSolutions: true,
      onlyFolders: false,
    };
  }

  onRefresh(): void {
    this.folderSolutionSelected = '';
    this.projectsSelected = [];
    this.currentPath = pathSolutions;
    const payload = this.setPayload();
    this.listSolutionsFromTwin(payload); //search folders in selected region
  }

  listSolutionsFromTwin(payload: WorkspaceContentPayload): Subscription {
    this.loadingContent = true;
    this.displayTable = false;
    return this.pushService
      .listWorkspaceContent(this.tenantId, payload)
      .pipe(
        map((sources: string[]) => {
          this.currentDisplayedItems = sources;
          this.loadingContent = false;
          this.organizeDataSolutionFolders();
        })
      )
      .subscribe();
  }

  onFolderChanged(item: MenuSelectorItem): void {
    this.projectsSelected = [];
    this.displayLoadingTable = true;
    this.folderSolutionSelected = item.displayText;
    this.currentPath = this.folderSolutionSelected;
    const payload = this.setPayload();
    this.listSolutionContent(payload);
  }

  //get files of the solution folder endpoint
  listSolutionContent(payload: WorkspaceContentPayload): void {
    this.displayTable = false;
    this.pushService
      .listSolutionContent(this.tenantId, payload)
      .pipe(
        switchMap((projectsMetadata) => {
          const filteredToReady = ProjectTableHelper.getFilesView(
            this.convertMetadataToFileView(projectsMetadata)
          );
          return combineLatest([of(filteredToReady)]);
        }),
        tap(([filesViewForDisplay]) => {
          this.dataTable = filesViewForDisplay;
          this.displayLoadingTable = false;
          this.displayTable = true;
        }),
        takeWhile((payload) => !payload)
      )
      .subscribe();
  }

  convertMetadataToFileView(projectsMetadata: ProjectMetadata[]): FileData[] {
    return projectsMetadata.map((metadata) => {
      const projectFileName = metadata.projectFileName;
      const type =
        projExtension[getFileExtension(projectFileName)] ?? ProjType.Asset;
      return {
        fileName: projectFileName,
        projectName: metadata.projectName,
        commitMessage: '',
        type: type,
        solutionName: metadata.solutionName,
        projectId: metadata.projectId,
        historyId: metadata.historyId,
        etag: metadata.eTag,
      };
    });
  }

  ourSortingDataAccessor = (
    item: FileViewForDisplay,
    property: string
  ): string | number => {
    switch (property) {
      case 'fileName':
        return item.file.fileName;

      case 'projectName':
        return item.file.projectName;

      case 'revisionHistory':
        return item.file.commitMessage ?? '';

      default:
        return '';
    }
  };

  onContinue(): void {
    if (this.projectsSelected.length > 0) {
      this.pushProjects();
    }
  }

  pushProjects(): void {
    const projects = this.projectsSelected.map((p) =>
      FileHelper.convertFileDataToFileSync(p.file)
    );
    this.displayFullPageLoading = true;
    const projectsAddNewVersion: CreateAndSyncProjectsPayload = {
      projects: projects,
      region: this.selectedRegion,
    };
    this.pushService
      .createAndSyncProjects(this.tenantId, projectsAddNewVersion)
      .subscribe({
        next: (response) => {
          this.displayFullPageLoading = false;
          this.projectsSelected = this.projectsSelected.filter(
            (item) =>
              !projects.some(
                (otherItem) => item.file.projectId === otherItem.projectId
              )
          );
          this.projectsSelected = [];
          const msg = this.translateService.instant(
            'menu.upload.projectsUploaded'
          );
          this.snackBar = this.snackbarService.openSnackBarWithAction(
            msg,
            undefined
          );
          this.dataTable = [];
          this.displayTable = false;
          this.completed = false;
        },
        error: (error: unknown) => {
          this.displayFullPageLoading = false;
          const msg = this.translateService.instant(
            'appstream.errorMessageProcess'
          );
          this.snackBar = this.snackbarService.openSnackBarWithAction(
            msg,
            undefined
          );
        },
      });
  }

  onCancel(): void {
    this.cancelEmitter.emit(true);
  }

  openPanel(): void {
    if (this.infoRef) {
      this.sidePanelService.open(this.infoRef);
    }
  }

  closePanel(): void {
    this.sidePanelService.close();
  }

  onCompleted(completed: boolean): void {
    this.completed = completed;
  }
}
