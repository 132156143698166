import { NgModule } from '@angular/core';
import { InfoSidebarComponent } from './info-sidebar.component';
import { TranslateModule } from '@ngx-translate/core';
import { RaUiButtonModule } from '@ra-web-tech-ui-toolkit/buttons/button';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [InfoSidebarComponent],
  imports: [CommonModule, RaUiButtonModule, TranslateModule],
  exports: [InfoSidebarComponent],
})
export class InfoSidebarModule {}
