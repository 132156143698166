/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import {
  Observable,
  throwError,
  BehaviorSubject,
  lastValueFrom,
  Subject,
} from 'rxjs';
import {
  ConfigService,
  TwinStudioConfiguration,
} from '../config/config.service';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { SignalRService } from '../signal-r/signal-r.service';
import {
  NotificationCount,
  NotificationPage,
  Notification,
} from '../signal-r/notification.model';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
} from 'rxjs/operators';
import { NoConentApiResponse } from '@rockwell-automation-inc/layout';
import { LoggerService } from '../logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  MAX_NOTIFICATIONS_COUNT = 100;
  private notifications: Notification[] = []; // cache notifications so we can control updates
  private readonly notificationsSubject: BehaviorSubject<Notification[]> =
    new BehaviorSubject(this.notifications);
  readonly notifications$: Observable<Notification[]>;
  private readonly notificationsCountSubject: BehaviorSubject<NotificationCount> =
    new BehaviorSubject({ unreadNotifications: 0, totalNotifications: 0 });
  readonly notificationsCount$: Observable<NotificationCount>;
  private readonly notificationReceivedSubject: Subject<Notification>;
  readonly notificationReceived$: Observable<Notification>;
  readonly unreadNotifications$: Observable<number>;

  constructor(
    private readonly configService: ConfigService<TwinStudioConfiguration>,
    private readonly http: HttpClient,
    private readonly signalrService: SignalRService,
    private readonly loggerService: LoggerService
  ) {
    this.notifications$ = this.notificationsSubject.asObservable();
    this.notificationsCount$ = this.notificationsCountSubject.asObservable();
    this.notificationReceivedSubject = new Subject();
    this.notificationReceived$ =
      this.notificationReceivedSubject.asObservable();
    this.unreadNotifications$ = this.notificationsCount$.pipe(
      map((nc) => nc.unreadNotifications)
    );
  }

  private initializeNotifications(): Observable<void> {
    return this.getNotificationPage(1).pipe(
      map((page: NotificationPage) => {
        this.notifications = page.records;
        this.notificationsSubject.next(this.notifications);
      }),
      /* eslint-disable rxjs/no-unbound-methods */
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (!error.status || error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      const msg = error.error ?? error.message ?? error;
      this.loggerService.error(`An error occurred: `, msg);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later - notfication service.'
    );
  }

  // The first page contains the most recent notifications. Records array starts with the most recent notification.
  getNotificationPage(
    page: number = 1,
    perPage: number = this.MAX_NOTIFICATIONS_COUNT
  ): Observable<NotificationPage> {
    const url = `${this.configService.config.notificationApiUrl}/api/messages?Page=${page}&PerPage=${perPage}`;
    return this.http.get<NotificationPage>(url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  private deleteNotificationHelper(id?: string): Observable<void> {
    const url = `${this.configService.config.notificationApiUrl}/api/messages/${
      id ?? ''
    }`;
    return this.http.delete(url).pipe(map((_) => {}));
  }

  deleteNotification(id: string): Observable<void> {
    return this.deleteNotificationHelper(id).pipe(
      map(() => {
        this.notifications = this.notifications.filter((n) => n.id !== id);
        this.notificationsSubject.next(this.notifications);
      })
    );
  }

  clearNotifications(): Observable<void> {
    return this.deleteNotificationHelper().pipe(
      mergeMap(() => {
        this.notifications = [];
        this.notificationsSubject.next(this.notifications);
        return this.refreshNotificationsCount();
      })
    );
  }

  refreshNotificationsCount(): Observable<void> {
    const url = `${this.configService.config.notificationApiUrl}/api/messages/count`;
    return this.http.get(url).pipe(
      map((count: NotificationCount) => {
        this.notificationsCountSubject.next(count);
      })
    );
  }

  /**
   * Update the notification read state.
   *
   * @param id notification id
   * @param updateIsRead should be marked as read or unread
   */
  async updateNotificationReadStatus(
    id: string,
    updateIsRead: boolean
  ): Promise<void> {
    // first mark the notification read in server to handle refresh
    await lastValueFrom(this._updateNotificationReadStatus(id, updateIsRead));
    // now mark the local copy
    const notification = this.notifications.find((n) => n.id === id);
    if (notification) {
      notification.isRead = updateIsRead;
    }
    this.notificationsSubject.next(this.notifications);
    // update the total
    await lastValueFrom(this.refreshNotificationsCount());
  }

  /**
   * Mark all notifications as read.
   */
  async markAllNotificationsAsRead(): Promise<void> {
    // first mark all the notifications read in server to handle refresh
    await lastValueFrom(this._markAllNotificationsAsRead());
    this.notifications = this.notifications.map((n) => {
      n.isRead = true;
      return n;
    });
    // now mark the local copy
    this.notificationsSubject.next(this.notifications);
    // update the total
    await lastValueFrom(this.refreshNotificationsCount());
  }

  /**
   * Update the notification read state. Note because http post is blocked
   * for the notification api, patch is being used. (see RALMCSUE-2611)
   *
   * @param id notification id
   * @param updateIsRead should be marked as read or unread
   */
  _updateNotificationReadStatus(
    id: string,
    updateIsRead: boolean
  ): Observable<void> {
    const url = `${this.configService.config.notificationApiUrl}/api/messages/${id}`;
    const body = {
      isRead: updateIsRead,
    };
    const headers = new HttpHeaders().set('show_spinner', 'false');
    return this.http
      .patch<NoConentApiResponse>(url, body, {
        observe: 'response',
        headers: headers,
      })
      .pipe(map((_) => {}));
  }

  /**
   * Mark all notifications as read. Note because http post is blocked
   * for the notification api, patch is being used. (see RALMCSUE-2611)
   */
  _markAllNotificationsAsRead(): Observable<void> {
    const url = `${this.configService.config.notificationApiUrl}/api/messages/`;
    const body = {
      isRead: true,
    };
    return this.http
      .patch<NoConentApiResponse>(url, body, { observe: 'response' })
      .pipe(map((_) => {}));
  }

  //ALREADY REVIEW---------------

  /**
   * Handle notication item that has been added or updated
   *
   * @param notification item sent by the server
   */
  onNotificationReceived(notification: Notification): void {
    // First check to see if this is an update to the notification itself
    // such as 'mark as read'. If there was a 'mark ALL as read' opertaion
    // we will recieve a notification for every single notification item on
    // the server. We don't want to add theses notifications because it will
    // endup popping all the notifications in the list until it rolls around
    // to the first MAX_NOTIFICATIONS_COUNT notications again.
    if (
      notification.updatedOn !== undefined &&
      notification.updatedOn !== null
    ) {
      return;
    }

    // there is a race between the initial notification list query and the notifications pushed to the client.
    // if we observe a duplicate message, ignore...
    const isDuplicate = this.notifications.find(
      (x) => x.id === notification.id
    );
    if (isDuplicate) {
      return;
    }

    if (this.notifications.length >= this.MAX_NOTIFICATIONS_COUNT) {
      this.notifications.pop(); // remove last element => oldest
    }
    this.notifications.unshift(notification); // add new element => newest
    this.notificationsSubject.next(this.notifications);
    this.notificationReceivedSubject.next(notification);
  }

  /**
   * initialize is to used to prime service content.
   * WARN: only call when authenticated or requests will block.
   * @param userId user Id used for notifications
   */
  async initialize(): Promise<void> {
    const initializeNotifications = lastValueFrom(
      this.initializeNotifications()
    );
    const initNotificationsCount = lastValueFrom(
      this.refreshNotificationsCount()
    );
    await Promise.all([initializeNotifications, initNotificationsCount]);
    // WARN: this is a long running subscription used to process notifications
    this.signalrService
      .getNotificationObservable()
      .pipe(
        filter(
          (notification): notification is Notification =>
            notification.id !== undefined
        )
      )
      .subscribe((notification) => {
        this.onNotificationReceived(notification);
      });

    // WARN: this a long running subscription used to update the notification count
    this.notifications$
      .pipe(
        debounceTime(2000),
        map(() => {
          lastValueFrom(this.refreshNotificationsCount());
        })
      )
      .subscribe();
  }
}
