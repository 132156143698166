import { createAction, props } from '@ngrx/store';
import { PreloadSessionData } from './session.reducer';
import { Session } from '../../models/twin-studio.model';

export const preloadBrowseSelection = createAction(
  '[Browse Page] Preload browse selection',
  props<{
    preloadSessionData: PreloadSessionData;
  }>()
);

export const startSession = createAction(
  '[prelaunch Page] Start Session in a workstation',
  props<{
    preloadSessionData: PreloadSessionData;
    sessionData: Session;
  }>()
);

export const updateDataSession = createAction(
  '[App Page] Update Session Info',
  props<{
    sessionData: Session;
  }>()
);

export const endSession = createAction(
  '[App Page] End Session in a workstation',
  props<{
    sessionData: Session;
  }>()
);

export const preUploadFilesToSession = createAction(
  '[Upload Page] Pre upload Files',
  props<{
    fileNames: string[];
  }>()
);

export const fileUploadedCompleted = createAction(
  '[Upload Page] Files uploaded completed',
  props<{
    fileName: string;
  }>()
);
