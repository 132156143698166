import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PushToVaultComponent } from './push-to-vault.component';
import { RaUiStepperModule } from '@ra-web-tech-ui-toolkit/common-views/stepper';
import { RaUiButtonModule } from '@ra-web-tech-ui-toolkit/buttons/button';
import { SelectRegionModule } from 'src/app/features/push-to-vault/select-region/select-region.module';
import { SelectFilesModule } from 'src/app/features/push-to-vault/select-files/select-files.module';
import { AssociationsModule } from 'src/app/features/push-to-vault/associations/associations.module';
import { SummaryModule } from 'src/app/features/push-to-vault/summary/summary.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DataStoredInfoModule } from '../../shared/components/data-stored-info/data-stored-info.module';

@NgModule({
  declarations: [PushToVaultComponent],
  imports: [
    CommonModule,
    RaUiStepperModule,
    RaUiButtonModule,
    SelectRegionModule,
    SelectFilesModule,
    AssociationsModule,
    SummaryModule,
    MatSidenavModule,
    DataStoredInfoModule,
  ],
  exports: [PushToVaultComponent],
})
export class PushToVaultModule {}
