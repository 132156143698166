<mat-sidenav-container [hasBackdrop]="true">
  <mat-sidenav #sidenav [mode]="'over'" [position]="'end'">
    <app-data-stored-info></app-data-stored-info>
  </mat-sidenav>
  <mat-sidenav-content class="push-to-vault-container">
    <ra-ui-stepper
      #stepper
      id="push-to-vault-stepper"
      class="stepper"
      [items]="items"
      [config]="stepperConfig"
      [buttonsProvider]="buttonsProvider"
    >
    </ra-ui-stepper>
  </mat-sidenav-content>
</mat-sidenav-container>
