import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import { AuthFacade } from '../auth.facade';
import { combineLatest, tap } from 'rxjs';

@Component({
  selector: 'app-auth-callback',
  template: '<div *ngIf="(user$ | async)"></div>',
})
export class AuthCallbackComponent {
  isAuthenticated$ = this.authService.isAuthenticated$;
  user$ = this.authService.user$;
  accessToken$ = this.authService.getAccessToken$();

  afterAll$ = combineLatest([this.isAuthenticated$, this.user$]).pipe(
    tap(([isAuthenticated, userProfile]) => {
      if (isAuthenticated) {
        this.authFacade.callback(userProfile);
      }
    })
  );

  constructor(
    private readonly authService: AuthService,
    private readonly authFacade: AuthFacade
  ) {}
}
