import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar-header',
  templateUrl: './sidebar-header.component.html',
  styleUrls: ['./sidebar-header.component.scss'],
})
export class SidebarHeaderComponent implements OnInit {
  @Input() headerSvgPath!: string;

  constructor() {}

  ngOnInit(): void {}
}
