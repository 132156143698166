import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkstationInstancesComponent } from './workstation-instances.component';
import { SharedButtonModule } from '../../shared/components/buttons/shared-button.module';
import { TranslateModule } from '@ngx-translate/core';
import { FilterModule } from '../../shared/components/filter/filter.module';
import { RaUiSelectModule } from '@ra-web-tech-ui-toolkit/form-system/select';
import { RaUiPaginationModule } from '@ra-web-tech-ui-toolkit/layout/pagination';
import { DesignHubTableModule } from '../design-hub-table/design-hub-table.module';
@NgModule({
  declarations: [WorkstationInstancesComponent],
  imports: [
    CommonModule,
    DesignHubTableModule,
    SharedButtonModule,
    TranslateModule,
    FilterModule,
    RaUiSelectModule,
    RaUiPaginationModule,
  ],
  exports: [WorkstationInstancesComponent],
})
export class WorkstationInstancesModule {}
