import { Component, Input, OnInit } from '@angular/core';
import { ErrorContentData } from '../../../core/models/error-content-data.model';

@Component({
  selector: 'app-error-content',
  templateUrl: './error-content.component.html',
  styleUrls: ['./error-content.component.scss'],
})
export class ErrorContentComponent implements OnInit {
  @Input() data: ErrorContentData;

  message: string;

  constructor() {}

  ngOnInit(): void {
    this.message = this.data.originalMessage;
  }
}
