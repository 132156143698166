import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from '@ag-grid-community/core';

@Component({
  selector: 'app-ag-grid-table',
  templateUrl: './ag-grid-table.component.html',
  styleUrls: ['./ag-grid-table.component.scss'],
})
export class AgGridTableComponent implements OnInit, OnChanges {
  @Input() columnDef: ColDef[] = [];
  @Input() rowData: any[] = [];
  @Input() addAllowed: boolean = false;
  @Input() editAllowed: boolean = false;
  @Input() deleteAllowed: boolean = false;
  @Input() editCondition: (row: any) => boolean = () => false;
  @Input() deleteCondition: (row: any) => boolean = () => false;
  @Output() addRow = new EventEmitter<true>();
  @Output() editRow = new EventEmitter<any>();
  @Output() deleteRow = new EventEmitter<any>();

  public gridApi!: GridApi<any>;
  gridOptions: GridOptions;
  selectedRow: any = null;
  editDisabled: boolean = true;
  deleteDisabled: boolean = true;

  constructor() {}

  ngOnInit(): void {
    this.gridOptions = {
      columnDefs: this.columnDef,
      rowData: this.rowData,
      rowSelection: 'single',
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.gridApi) {
      this.gridApi.setRowData(this.rowData);
    }
  }

  onGridReady(params: GridReadyEvent<any>): void {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  }

  onSelectionChanged(): void {
    const selectedRows = this.gridApi.getSelectedRows();

    if (selectedRows.length === 1) {
      this.selectedRow = selectedRows[0] as any;
      this.editDisabled = !this.editCondition(this.selectedRow);
      this.deleteDisabled = !this.deleteCondition(this.selectedRow);
    } else {
      this.editDisabled = true;
      this.deleteDisabled = true;
    }
  }

  onAddRow(): void {
    this.addRow.emit(true);
  }

  onEditRow(): void {
    if (this.selectedRow !== null) {
      this.editRow.emit(this.selectedRow);
    }
  }

  onDeleteRow(): void {
    if (this.selectedRow !== null) {
      this.deleteRow.emit(this.selectedRow);
    }
  }
}
