import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceListComponent } from './workspace-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { WorkspaceTileModule } from '../../shared/components/workspace-tile/workspace-tile.module';
import { SharedButtonModule } from '../../shared/components/buttons/shared-button.module';
import { RaUiButtonModule } from '@ra-web-tech-ui-toolkit/buttons/button';
import { RaUiSearchInputModule } from '@ra-web-tech-ui-toolkit/form-system/search-input';
import { RaUiSelectModule } from '@ra-web-tech-ui-toolkit/form-system/select';
import { MatDialogModule } from '@angular/material/dialog';
import { DashedLayoutModule } from '../../shared/components/dashed-layout/dashed-layout.module';
import { LoadingModule } from '../../shared/components/loading/loading.module';
import { RaUiDialogModule } from '@ra-web-tech-ui-toolkit/popups/dialog';
import { RaUiLoadingSpinnerModule } from '@ra-web-tech-ui-toolkit/indicators/loading-spinner';
import { RaUiToastModule } from '@ra-web-tech-ui-toolkit/popups/toast';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { WorkspaceEditModule } from './workspace-edit/workspace-edit.module';
import { RaUiTooltipModule } from '@ra-web-tech-ui-toolkit/common-utils/tooltip';

@NgModule({
  declarations: [WorkspaceListComponent],
  imports: [
    CommonModule,
    TranslateModule,
    WorkspaceTileModule,
    MatInputModule,
    MatFormFieldModule,
    SharedButtonModule,
    RaUiButtonModule,
    RaUiSearchInputModule,
    RaUiSelectModule,
    MatDialogModule,
    DashedLayoutModule,
    LoadingModule,
    RaUiDialogModule,
    RaUiToastModule,
    RaUiLoadingSpinnerModule,
    WorkspaceEditModule,
    RaUiTooltipModule,
  ],
  exports: [WorkspaceListComponent],
})
export class WorkspaceListModule {}
