/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  appInit,
  appRefreshed,
} from '../../core/reducers/localization/localization.actions';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class InitAppService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly store: Store,
    private readonly router: Router
  ) {}

  async init(): Promise<any> {
    try {
      await this.setLang();
    } catch (err) {
      console.info('error initializing twin studio application', err);
    } finally {
      // manually fire app initialized and trigger the first navigation
      this.router.initialNavigation();
    }
  }

  setLang(): void {
    this.translateService.addLangs(['en', 'es']);
    this.translateService.setDefaultLang('en');
    const lang = localStorage.getItem('lang');
    if (lang) {
      this.store.dispatch(appRefreshed({ lang: lang }));
    } else {
      this.store.dispatch(appInit({ lang: 'en' }));
    }
  }
}
