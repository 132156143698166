import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { AssociationsComponent } from './associations.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from '@ag-grid-community/angular';
import { ModuleRegistry } from '@ag-grid-community/core';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { LoadingModule } from 'src/app/shared/components/loading/loading.module';
import { RaUiCommonGridHelperModule } from '@ra-web-tech-ui-toolkit/grid/cdk';
import { RaUiSearchInputModule } from '@ra-web-tech-ui-toolkit/form-system/search-input';
import { TooltipMessageModule } from 'src/app/features/push-to-vault/associations/tooltip-message/tooltip-message.module';
import { TitleStepModule } from 'src/app/shared/components/title-step/title-step.module';

ModuleRegistry.registerModules([RichSelectModule]);
@NgModule({
  declarations: [AssociationsComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TitleStepModule,
    AgGridModule,
    LoadingModule,
    AsyncPipe,
    RaUiCommonGridHelperModule,
    RaUiSearchInputModule,
    TooltipMessageModule,
  ],
})
export class AssociationsModule {}
