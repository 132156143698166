import { createAction, props } from '@ngrx/store';
import {
  IGainsightCustomEvent,
  IGainsightUserProfile,
} from '../../models/gainsight.model';

export const setOrganizationContext = createAction(
  '[GAINSIGHT] Set Organization Context',
  props<{ payload: { tenantId: string; tenantName: string } }>()
);
export const setOrganizationContextSuccess = createAction(
  '[GAINSIGHT] Set Organization Context Success'
);

export const resetSession = createAction('[GAINSIGHT] Reset/Logout Session ');
export const resetSessionSuccess = createAction(
  '[GAINSIGHT] Reset/Logout Session Success'
);

export const pushUserProfile = createAction(
  '[GAINSIGHT] Push User Profile',
  props<{ payload: { userProfile: IGainsightUserProfile } }>()
);
export const pushUserProfileSuccess = createAction(
  '[GAINSIGHT] Push User Profile Success'
);

export const pushCustomEvent = createAction(
  '[GAINSIGHT] Push Custom Event',
  props<{ gainsightCustomEvent: IGainsightCustomEvent }>()
);
export const pushCustomEventSuccess = createAction(
  '[GAINSIGHT] Push Custom Event Success'
);
