<app-loading *ngIf="displayFullPageLoading"></app-loading>
<div *ngIf="!displayFullPageLoading">
  <div class="header-push-projects">
    <h1>{{ "menu.push.vaultProjects.title" | translate }}</h1>
    <p class="header-description">
      {{ "menu.push.vaultProjects.description" | translate }}
    </p>
  </div>
  <div class="selectors-row">
    <app-region-selector
      *ngIf="!sessionData"
      (regionSelectedEmitter)="onRegionSelected($event)"
    ></app-region-selector>

    <div *ngIf="loadingContent" class="folder-item-search">
      <mat-spinner
        *ngIf="loadingContent"
        [diameter]="32"
        class="folder-spinner"
      ></mat-spinner>
    </div>

    <app-menu-selector
      *ngIf="!loadingContent"
      [inputId]="'folders-selector'"
      [data]="folders"
      [label]="'menu.push.folderSelect' | translate"
      [placeholderLabel]="'vaultBrowseComponent.findSolution' | translate"
      [noEntriesFoundLabel]="
        'vaultBrowseComponent.solutionNotFound' | translate
      "
      (selectionChanged)="onFolderChanged($event)"
    >
    </app-menu-selector>

    <div class="refresh-item">
      <i class="icon ra-icon-ide-md-refresh" (click)="onRefresh()"> </i>
      <span class="refresh-title" (click)="onRefresh()">{{
        "menu.push.refreshItem" | translate
      }}</span>
    </div>
  </div>
  <div class="content-column">
    <div class="content-row">
      <div class="table-area">
        <app-loading *ngIf="displayLoadingTable"></app-loading>
        <div
          class="center-wrapper"
          *ngIf="displayTable && dataTable.length === 0"
        >
          <p class="empty-folder-placeholder">
            {{ "menu.push.emptySolution" | translate }}
          </p>
        </div>
        <app-design-hub-table
          *ngIf="displayTable && dataTable.length > 0"
          [filterText]="'vaultBrowseComponent.filterByName' | translate"
          [showHeaderCheckbox]="true"
          [disableCheckboxIds]="disableItems"
          [checkboxToolTips]="checkboxToolTipItems"
          [sortingDataAccessor]="ourSortingDataAccessor"
          [tableData]="dataTable"
          [columns]="columns"
          (itemsSelected)="onItemSelectionChanged($event)"
        >
        </app-design-hub-table>
      </div>
    </div>
  </div>

  <div class="button-row">
    <div class="button-left">
      <div id="help-button" class="help-item" (click)="openPanel()">
        <i class="ra-icon-ide-md-info-filled help-icon"> </i>
        <span class="help-title">{{ "appstream.dataStored" | translate }}</span>
      </div>
    </div>

    <div class="button-right">
      <app-secondary-button
        [id]="'cancel-button'"
        *ngIf="displayCancel"
        (clickEvent)="onCancel()"
        [label]="'backButton' | translate"
      >
      </app-secondary-button>

      <app-primary-button
        [id]="'push-button'"
        (clickEvent)="onContinue()"
        [label]="'pushButton' | translate"
        [isDisabled]="!completed"
      >
      </app-primary-button>
    </div>
  </div>
</div>

<ng-template #infoRef>
  <app-info-sidebar [infoData]="infoSidebarData" (closePanel)="closePanel()">
  </app-info-sidebar>
</ng-template>
