export interface DataWithId {
  id: string | number;
}

export interface CheckboxToolTip {
  id: DataWithId;
  message: string;
}

export enum Templates {
  ProjectListTemplate = 'ProjectListTemplate',
  ProgressUploadTemplate = 'ProgressUploadTemplate',
}

export interface RecentFilesTable {
  iconFilename: string;
  filename: string;
  path: string;
  pk: string;
  sk: string;
}

export const recentFilesPath = 'C://Home Folder/';
