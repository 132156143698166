<div class="ag-grid-table">
  <div class="table-header white-background">
    <div
      id="add-row"
      class="add-row action-cursor"
      *ngIf="addAllowed"
      (click)="onAddRow()"
    >
      <i class="ra-icon-ide-md-plus"></i>
    </div>
    <div class="row justify-right">
      <app-primary-button-flourish
        id="edit-row"
        class="button"
        *ngIf="editAllowed"
        [label]="'edit' | translate"
        [isDisabled]="editDisabled"
        (clickEvent)="onEditRow()"
      >
      </app-primary-button-flourish>
      <app-primary-button-flourish
        id="delete-row"
        class="button"
        *ngIf="deleteAllowed"
        [label]="'delete' | translate"
        [isDisabled]="deleteDisabled"
        (clickEvent)="onDeleteRow()"
      >
      </app-primary-button-flourish>
    </div>
  </div>
  <ag-grid-angular
    class="ag-theme-rockwell table-size"
    raUiFormGridStyling
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    (selectionChanged)="onSelectionChanged()"
  ></ag-grid-angular>
</div>
