import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectedFilesComponent } from './selected-files.component';
import { TranslateModule } from '@ngx-translate/core';
import { RaUiSearchInputModule } from '@ra-web-tech-ui-toolkit/form-system/search-input';
import { RaUiInputModule } from '@ra-web-tech-ui-toolkit/form-system/input';
import { MaterialModule } from '../../../../material.module';
import { RaUiCheckboxModule } from '@ra-web-tech-ui-toolkit/form-system/checkbox';
import { AddInstanceModule } from '../../../create-workstation/add-instance/add-instance.module';
import { RaUiTooltipModule } from '@ra-web-tech-ui-toolkit/common-utils/tooltip';

@NgModule({
  declarations: [SelectedFilesComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RaUiSearchInputModule,
    RaUiInputModule,
    MaterialModule,
    RaUiCheckboxModule,
    AddInstanceModule,
    RaUiTooltipModule,
  ],
  exports: [SelectedFilesComponent],
})
export class SelectedFilesModule {}
