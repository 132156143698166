import { isInteger } from 'lodash-es';
import {
  failed,
  failedResult,
  Result,
  succeededResult,
} from 'src/app/shared/utility/result';
import { mapWhileSuccessful } from './result-helpers';
import { StringEnum } from './typeUtil';

/**
 * A function that validates an input of type TInput (typically a DTO type).  If
 * valid, it returns a successful Result of type TSuccess (typically a domain
 * type).  If validation fails, it returns a failure Result containing a
 * descriptive error message.  The propName parameter is only used to provide
 * more descriptive error messages.
 */
export type ValidatorFn<TInput, TSuccess> = (
  val: TInput,
  propName: string
) => Result<TSuccess, string>;

/**
 * Makes sure the specified value is a string and is not 0-length.
 *
 * @param strVal - The value to validate
 * @param propName - The value's name (to be used in returned error message)
 * @returns On success, a result containing the validated string.  On failure, a
 * result containing an error string.
 */
export function validateString(
  strVal: unknown,
  propName: string
): Result<string, string> {
  if (typeof strVal !== 'string' || strVal.length === 0) {
    return failedResult(`Illegal ${propName}: ${strVal}`);
  }
  return succeededResult(strVal);
}

/**
 * Validates the specified date/time string and converts it to a Date instance.
 *
 * @param dateStr - The string to validate
 * @param propName - The value's name (to be used in returned error message)
 * @returns On success, a result containing the validated Date.  On failure, a
 * result containing an error message.
 */
export function validateDateString(
  dateStr: string,
  propName: string
): Result<Date, string> {
  dateStr = dateStr.trim();
  const stringValidation = validateString(dateStr, propName);
  if (failed(stringValidation)) {
    return stringValidation;
  }

  const milliseconds = Date.parse(dateStr);
  if (Number.isNaN(milliseconds)) {
    return failedResult(`Illegal ${propName} date string: "${dateStr}"`);
  }

  return succeededResult(new Date(milliseconds));
}

/**
 * Makes sure the specified value is a positive (> 0) integer number.
 *
 * @param val - The value to validate
 * @param propName - The value's name (to be used in returned error message)
 * @returns On success, a result containing the validated number.  On failure, a
 * result containing an error string.
 */
export function validatePositiveInteger(
  val: unknown,
  propName: string
): Result<number, string> {
  if (typeof val !== 'number' || !isInteger(val) || val === 0 || val < 0) {
    return failedResult(`Illegal ${propName}: ${val}`);
  }
  return succeededResult(val);
}

/**
 * Makes sure the specified value is a valid boolean.
 *
 * @param boolVal - The value to validate
 * @param propName - The value's name (to be used in returned error message)
 * @returns On success, a result containing the validated boolean.  On failure,
 * a result containing an error string.
 */
export function validateBool(
  boolVal: unknown,
  propName: string
): Result<boolean, string> {
  if (typeof boolVal !== 'boolean') {
    return failedResult(`Illegal ${propName}: ${boolVal}`);
  }
  return succeededResult(boolVal);
}

/**
 * Wraps another validator function and allows it to also accept `undefined` as
 * a possible value.
 *
 * @param baseValidator - Validator function that can validate the value when
 * the value is not undefined.
 * @return Description
 */
export function validateOptional<TInput, TSuccess>(
  baseValidator: ValidatorFn<TInput, TSuccess>
): ValidatorFn<TInput | undefined, TSuccess | undefined> {
  return (val: TInput | undefined, propName: string) => {
    if (val === undefined) {
      return succeededResult(undefined);
    }

    return baseValidator(val, propName);
  };
}

/**
 * Validates an array that is potentially undefined.
 *
 * @param arr - The optional array to validate
 * @param propName - The property name for the array (for potential error
 * message)
 * @param mapFn - A function that validates each input array item (e.g. a DTO)
 *   and if valid returns the output type (e.g. a domain object)
 * @returns If the specified array is undefined, a successful result with
 * undefined is returned.  If an array was specified, a result for the mapping
 * operation is returned.
 */
export function validateOptionalArray<TInput, TOutput>(
  arr: undefined | Array<TInput>,
  propName: string,
  mapFn: (input: TInput) => Result<TOutput, string>
): Result<undefined | Array<TOutput>, string> {
  if (arr === undefined) {
    return succeededResult(undefined);
  }

  const result = mapWhileSuccessful(arr, mapFn);
  if (failed(result)) {
    return failedResult(
      `Invalid item within ${propName} array: ${result.error}`
    );
  }

  return result;
}

/**
 * Creates a validator function that will validate whether values belong to the
 * specified string based enumeration.  Even though it may seem redundant, this
 * function needs both the *type* (of the enumerated values) and the *value* of
 * the enumeration.  Therefore, the type parameter will match the enumeration
 * value passed in.
 *
 * @example
 * enum Direction {
 *     Up = "UP",
 *     Down = "DOWN",
 *     Left = "LEFT",
 *     Right = "RIGHT"
 * }
 * const myJson = { direction: "DOWN" };
 * const validateDirection = getEnumValidator<Direction>(Direction);
 * const directionValidationResult = validateDirection(myJson.direction, "direction");
 * if (succeeded(directionValidationResult)) {
 *   // directionValidationResult.result has type Direction here.
 * }
 *
 * @param enumType - The enumeration defining possible values
 * @returns A validator function that will validate an input string to see if it
 * corresponds to one of the enumerated values in `enumType`.
 */
export function getEnumValidator<TEnumVal>(
  enumType: StringEnum
): ValidatorFn<string, TEnumVal> {
  const validateEnum: ValidatorFn<string, TEnumVal> = (
    strVal: string,
    propName: string
  ): Result<TEnumVal, string> => {
    const values = Object.values(enumType);
    if (values.includes(strVal)) {
      return succeededResult(strVal as unknown as TEnumVal);
    } else {
      return failedResult(
        `Invalid enumerated value for ${propName}. Must be one of ${values} but saw "${strVal}".`
      );
    }
  };
  return validateEnum;
}
