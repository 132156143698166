import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { LegacyButtonStyle } from '@ra-web-tech-ui-toolkit/buttons/button';
import {
  ISearchInputConfig,
  ISearchInputText,
} from '@ra-web-tech-ui-toolkit/form-system/search-input';
import { WorkspaceService } from '../../core/services/workspace/workspace.service';
import { WorkspaceFacade } from '../../core/reducers/workspace/workspace.facade';
import { Router } from '@angular/router';
import {
  DialogService,
  IDialogConfig,
  DialogComponent,
  IDialogContentProjection,
} from '@ra-web-tech-ui-toolkit/popups/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  IToastConfig,
  ToastConfig,
  ToastDirective,
} from '@ra-web-tech-ui-toolkit/popups/toast';
import {
  ActionButtonStyles,
  NotificationType,
  ThemeColor,
  ValueType,
} from '@ra-web-tech-ui-toolkit/cdk/types';
import {
  AnimationType,
  LoadingSpinnerDefinedSize,
  LoadingSpinnerSize,
} from '@ra-web-tech-ui-toolkit/indicators/loading-spinner';
import { OverlayPosition } from '@ra-web-tech-ui-toolkit/cdk/utils';
import { MatDialogRef } from '@angular/material/dialog';
import { WorkspaceData } from '../../core/models/workspace';
import {
  Observable,
  Subscription,
  combineLatest,
  concatMap,
  filter,
  firstValueFrom,
  of,
  tap,
} from 'rxjs';
import { Workspace } from '../../core/models/workspace-creation.model';
import { SignalRService } from '../../core/services/signal-r/signal-r.service';
import { Notification } from '../../core/services/signal-r/notification.model';
import {
  WorkspaceDetails,
  WorkstationStatusNotification,
} from '../../core/models/twin-studio.model';
import { ITooltipConfig } from '@ra-web-tech-ui-toolkit/common-utils/tooltip';
import { QuotaService } from '../../core/services/quota/quota.service';

@Component({
  selector: 'app-workspace-list',
  templateUrl: './workspace-list.component.html',
  styleUrls: ['./workspace-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WorkspaceListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() tenantId: string | undefined = undefined;
  @ViewChild(ToastDirective, { static: true }) toastRef!: ToastDirective;
  dashedLayoutHeight: string = '300px';
  dashedLayoutImage: string = 'assets/images/monitor.svg';
  public tooltipConfig: ITooltipConfig = {
    position: 'Bottom',
  };
  private readonly subscriptions = new Subscription();
  btnConfig = LegacyButtonStyle.Icon;
  primaryColor = ThemeColor.Primary;
  searchInputText: ISearchInputText = {
    searchInputLabel: this.translateService.instant('workspaceList.filter'),
  };
  searchConfig: ISearchInputConfig = {
    searchInputText: this.searchInputText,
  };
  selectStatus = [
    { value: 'all', label: 'All' },
    { value: 'stopped', label: 'Stopped' },
    { value: 'runing', label: 'Runing' },
  ];
  selectedStatus = this.selectStatus[0].value;

  dialogConfig!: IDialogConfig;
  dialogRef!: MatDialogRef<DialogComponent>;
  deleteWord: string = '';
  isDisabledButton: boolean = true;
  indexToDelete: number = -1;
  isQuotaAvailable!: boolean;

  spinnerAnimation: AnimationType = AnimationType.Spinner;
  spinnerSize: LoadingSpinnerSize = LoadingSpinnerDefinedSize.Large;

  options: IToastConfig = {
    delay: 5000,
    position: OverlayPosition.BottomCenter,
  };
  toastConfig: ToastConfig = new ToastConfig(this.options);
  workspaceList!: WorkspaceData[];
  workspaceListOriginal!: WorkspaceData[];
  isLoading: boolean = true;
  editingWorkspace: boolean = false;

  constructor(
    private readonly workspaceService: WorkspaceService,
    private readonly quotaService: QuotaService,
    private readonly workspaceFacade: WorkspaceFacade,
    private readonly router: Router,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly signalRService: SignalRService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(this.subscribeOnGetQuotaResponse());
    this.subscriptions.add(this.subscribeOnWorkspaceListResponse());
    this.subscriptions.add(this.subscribeOnWorkspaceEvents());
    this.subscriptions.add(this.subscribeOnWorkstationEvents());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.tenantId) {
      this.isLoading = true;
      this.onRefresh();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private subscribeOnGetQuotaResponse(): Subscription {
    return combineLatest([
      this.quotaService.getQuotaForWorkspaces(this.tenantId as string),
    ]).subscribe(([quotaResponse]) => {
      if (quotaResponse.status === 'success') {
        const data = quotaResponse.data;
        this.isQuotaAvailable =
          data[0].quota > 0 || data[1].quota > 0 || data[2].quota > 0;
      }
    });
  }

  private subscribeOnWorkspaceListResponse(): Subscription {
    return combineLatest([
      this.workspaceService.getWorkspaces(this.tenantId as string),
    ]).subscribe(([workspaceData]) => {
      if (workspaceData.status === 'success') {
        this.workspaceList = workspaceData.data;
        this.workspaceListOriginal = workspaceData.data;
        this.isLoading = false;
      }
    });
  }

  private subscribeOnWorkspaceEvents(): Subscription {
    const parseJson = (notification: Notification): Observable<any> => {
      const parsedJson = JSON.parse(notification.customJson);

      if (
        parsedJson.workspaceCreated &&
        parsedJson.tenantId === this.tenantId
      ) {
        return of(parsedJson.workspaceCreated as Workspace);
      }

      return of(undefined);
    };
    return this.signalRService
      .getJsonNotificationObservable()
      .pipe(
        concatMap((notification) =>
          parseJson(notification).pipe(
            filter((workspace) => workspace !== undefined),
            tap((workspace: WorkspaceData) => {
              this.onRefresh();
            })
          )
        )
      )
      .subscribe();
  }

  private subscribeOnWorkstationEvents(): Subscription {
    const parseJson = (notification: Notification): Observable<any> => {
      const parsedJson = JSON.parse(notification.customJson);
      if (parsedJson.workstationStatusUpdated) {
        return of(
          parsedJson.workstationStatusUpdated as WorkstationStatusNotification
        );
      }
      return of(undefined);
    };

    return this.signalRService
      .getJsonNotificationObservable()
      .pipe(
        concatMap((notification) =>
          parseJson(notification).pipe(
            filter((data) => data !== undefined),
            tap((notification: WorkstationStatusNotification) => {
              this.onRefresh();
            })
          )
        )
      )
      .subscribe();
  }

  onRefresh(): void {
    const respose$ = this.workspaceService
      .getWorkspaces(this.tenantId as string)
      .pipe(
        tap((response) => {
          if (response.status === 'success') {
            this.workspaceList = response.data;
            this.isLoading = false;
          }
        })
      );
    this.subscriptions.add(respose$.subscribe());
  }

  onCreateWorkspace(): void {
    this.router.navigateByUrl('workspace/create');
  }

  onOpenWorkspace(workspace: WorkspaceData): void {
    this.workspaceService
      .getWorkstations(this.tenantId as string, workspace.region, workspace.id)
      .subscribe((data: WorkspaceDetails) => {
        data.workspaceName = workspace.name;
        data.region = workspace.region;
        data.tenantId = this.tenantId as string;
        this.workspaceFacade.setWorkspaceDetails(data);
        this.router.navigateByUrl('workspace/details');
      });
  }

  onEditWorkspace(workspace: WorkspaceData): void {
    const activeWorkspace: Workspace = {
      id: workspace.id,
      name: workspace.name,
      region: workspace.region,
      securityGroupId: '',
      subnetIds: [''],
      workstations: [],
    };
    this.workspaceFacade.setActiveWorkspace(activeWorkspace);
    //this.router.navigateByUrl('workspace/edit');
    this.editingWorkspace = true;
  }

  onDeleteWorkspace(
    workspace: WorkspaceData,
    content?: IDialogContentProjection
  ): void | MatDialogRef<DialogComponent> {
    if (
      workspace.instances.running === 0 &&
      workspace.instances.stopped === 0 &&
      workspace.instances.pending === 0
    ) {
      this.dialogConfig = {
        title: '',
        message: '',
        buttons: [],
        showCloseIconButton: true,
        hideTitleIcon: true,
        contentProjection: content,
      };
      this.dialogRef = this.dialogService.openDialog(this.dialogConfig);
    } else {
      this.dialogConfig = {
        title: this.translateService.instant('workspace.errorDeleting'),
        message: this.translateService.instant('workspace.dialogMessage3'),
        buttons: [
          {
            label: this.translateService.instant('workspace.understood'),
            buttonStyle: ActionButtonStyles.Main,
          },
        ],
        messageType: NotificationType.Error,
        showCloseIconButton: false,
        hideTitleIcon: false,
      };
      return this.dialogService.openErrorDialog(this.dialogConfig);
    }
  }

  async onSubmitDialog(workspace: WorkspaceData, index: number): Promise<void> {
    this.dialogRef?.close();
    this.deleteWord = '';
    this.indexToDelete = index;
    const response = await firstValueFrom(
      this.workspaceService.deleteWorkspace(
        this.tenantId as string,
        workspace.region,
        workspace.id
      )
    );
    this.processResponse(response, this.indexToDelete);
  }

  processResponse(response: string, index: number): any {
    if (response === 'Workspace is being deleted!') {
      const workspaceToDelete = this.workspaceList[index];
      this.workspaceList = this.workspaceList.filter(
        (workspace) => workspace.id !== workspaceToDelete.id
      );
      this.indexToDelete = -1;
      this.showSnackbar(workspaceToDelete.name);
    } else {
      this.indexToDelete = -1;
      this.dialogConfig = {
        title: this.translateService.instant('workspace.errorDeleting'),
        message: `${this.translateService.instant(
          'workspace.dialogMessage4'
        )} ${response} ${this.translateService.instant(
          'workspace.dialogMessage5'
        )}`,
        buttons: [
          {
            label: this.translateService.instant('workspace.ok'),
            buttonStyle: ActionButtonStyles.Main,
          },
        ],
        messageType: NotificationType.Error,
        showCloseIconButton: false,
        hideTitleIcon: false,
      };
      return this.dialogService.openErrorDialog(this.dialogConfig);
    }
  }

  onCancelDialog(): void {
    this.dialogRef?.close();
    this.deleteWord = '';
  }

  isDisabled(event: any): void {
    this.deleteWord = event.target.value;
    this.isDisabledButton = this.deleteWord !== 'delete';
  }

  showSnackbar(workspaceName: string): void {
    const message: string = `${this.translateService.instant(
      'workspace.snackbar1'
    )} ${workspaceName} ${this.translateService.instant(
      'workspace.snackbar2'
    )}`;
    this.toastRef.addToast(message, NotificationType.Success);
  }

  filterByStatus(status: ValueType): void {
    if (status === this.selectStatus[0].value) {
      this.workspaceList = this.workspaceListOriginal;
    }
    if (status === this.selectStatus[1].value) {
      this.workspaceList = this.workspaceListOriginal.filter(
        (workspace) => workspace.instances.stopped > 0
      );
    }
    if (status === this.selectStatus[2].value) {
      this.workspaceList = this.workspaceListOriginal.filter(
        (workspace) => workspace.instances.running > 0
      );
    }
  }

  filterByText(criteria: string): void {
    if (criteria === '') {
      this.workspaceList = this.workspaceListOriginal;
    } else {
      this.workspaceList = this.workspaceListOriginal.filter(
        (workspace) =>
          workspace.name.toLowerCase().includes(criteria.toLowerCase()) ||
          workspace.region.toLowerCase().includes(criteria.toLowerCase())
      );
    }
  }
}
