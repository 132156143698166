import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  RegionSelectorItem,
  MenuSelectorItem,
} from '../../../core/models/menu-selector.model';
import { Region } from '../../../core/models/twin-studio.model';

@Component({
  selector: 'app-region-selector',
  templateUrl: './region-selector.component.html',
})
export class RegionSelectorComponent implements OnInit {
  @Output() regionSelectedEmitter = new EventEmitter<Region>();
  regions: RegionSelectorItem[] = [
    {
      value: Region.America,
      displayText: 'America',
    },
    {
      value: Region.Europe,
      displayText: 'Europe',
    },
    {
      value: Region.Asia,
      displayText: 'Asia-Pacific',
    },
  ];
  constructor() {}

  ngOnInit(): void {}

  onRegionChanged(item: MenuSelectorItem): void {
    const regionSelected: RegionSelectorItem = item as RegionSelectorItem;
    this.regionSelectedEmitter.emit(regionSelected.value);
  }
}
