import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { RecentFilesTable } from '../../../../core/models/table-templates.model';
import { FilterDataSource } from '../../../../core/models/filter.model';
import {
  SearchInputData,
  SearchInputFlatListPipe,
} from '@ra-web-tech-ui-toolkit/form-system/search-input';
import { TranslateService } from '@ngx-translate/core';
import { Nullable } from '@ra-web-tech-ui-toolkit/cdk/types';
import { Subject } from 'rxjs';
import {
  ITooltipConfig,
  TooltipPosition,
} from '@ra-web-tech-ui-toolkit/common-utils/tooltip';
import {
  STEPPER_ITEM_DATA,
  StepperComponent,
} from '@ra-web-tech-ui-toolkit/common-views/stepper';
import { PushToVaultFormData } from 'src/app/core/models/upload-file.model';

@Component({
  selector: 'app-selected-files',
  templateUrl: './selected-files.component.html',
  styleUrls: ['./selected-files.component.scss'],
})
export class SelectedFilesComponent implements OnInit, OnChanges {
  @Input() files!: RecentFilesTable[];
  @Input() buttonPosition!: number;
  @Output() removeFile = new EventEmitter<string>();
  public filesFiltered!: RecentFilesTable[];
  public isDataAvailable: boolean = false;
  public prefixByTemplate: boolean;
  public suffixByTemplate: boolean;

  public prefix: Nullable<string>;
  public suffix = new Subject<Nullable<string>>();
  public prefixByString = false;
  public suffixByString = false;
  readonly tooltipConfig: ITooltipConfig = {
    position: TooltipPosition.Top,
    maxWidth: 199,
  };
  title: string = '';

  filteredData: any;
  public searchInputOutput: SearchInputData;
  public predicates = [
    {
      id: 'filename',
      callback: (value: any): string => {
        return value.filename;
      },
      keys: ['f', 'flnm', 'filename'],
    },
    {
      id: 'path',
      callback: (value: any): string => {
        return value.path;
      },
      keys: ['p', 'pth', 'path'],
    },
  ];

  listener = this.getListener();

  getListener(): any {
    return {
      getInput: (): FilterDataSource[] => {
        if (this.files) {
          return this.files;
        } else {
          return [{ name: '' }];
        }
      },

      sendOutput: (data: SearchInputData): void => {
        this.filteredData = this.searchInputFlatList.transform(data);
        this.updateDataFiltered(this.filteredData);
      },
    };
  }
  ngOnInit(): void {
    this.filesFiltered = this.files;
    this.title = this.translateService.instant(
      'pushToVault.filesSelectedTitle',
      {
        counter: this.files?.length,
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.files) {
      this.isDataAvailable = this.files?.length > 0;
      this.enableNextButton();
      this.filesFiltered = this.files;
      this.title = this.translateService.instant(
        'pushToVault.filesSelectedTitle',
        {
          counter: this.files?.length,
        }
      );
      this.listener = this.getListener();
    }
  }
  constructor(
    @Inject(STEPPER_ITEM_DATA) public viewFormData: PushToVaultFormData,
    @Optional() private readonly stepperCmp: StepperComponent,
    private readonly translateService: TranslateService,
    private readonly searchInputFlatList: SearchInputFlatListPipe
  ) {}

  updateDataFiltered(data: RecentFilesTable[]): void {
    this.filesFiltered = data;
  }

  onClickRemove(filenameToRemove: string): void {
    this.files = this.files.filter(
      (item) => item.filename !== filenameToRemove
    );
    this.filesFiltered = this.filesFiltered.filter(
      (item) => item.filename !== filenameToRemove
    );
    this.viewFormData.files = this.files;
    this.removeFile.emit(filenameToRemove);
  }

  enableNextButton(): void {
    if (this.isDataAvailable) {
      this.stepperCmp._disabledButtons[this.buttonPosition] = false;
    } else {
      this.stepperCmp._disabledButtons[this.buttonPosition] = true;
    }
  }
}
