<nav [ngClass]="[collapsed ? 'collapsed sidebar' : 'uncollapsed sidebar']">
  <div class="toggle">
    <i
      id="hide-sidebar-button"
      class="cursor ra-icon-ide-md-hide-menu"
      *ngIf="!collapsed"
      (click)="toggleSidebar()"
    ></i>
    <i
      id="open-sidebar-button"
      class="cursor ra-icon-ide-md-open-menu"
      *ngIf="collapsed"
      (click)="toggleSidebar()"
    ></i>
  </div>
  <hr />
  <div *ngIf="!collapsed">
    <app-sidebar-header [headerSvgPath]="headerSvgPath"></app-sidebar-header>
    <app-sidebar-options
      [currentSelection]="currentSelection"
      [sidebarItems]="sidebarItems"
      (emitOptionEvent)="onEmitOptionEvent($event)"
    ></app-sidebar-options>
    <!-- TEMP. Removed
          <sidebar-notice></sidebar-notice>
        <sidebar-meter class="footer"></sidebar-meter>
      -->
  </div>
</nav>
