<div class="table-section" *ngIf="dataSource">
  <div *ngIf="filter" class="col-lg-12">
    <mat-form-field class="custom-filter">
      <mat-label>{{
        filterText ? filterText : ("filter" | translate)
      }}</mat-label>
      <input
        matInput
        id="input-filter"
        (keyup)="doFilter($any($event.target).value)"
        autocomplete="off"
        #input
      />
    </mat-form-field>
  </div>

  <div class="table-container">
    <table
      #table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-mdc-elevation-z0"
    >
      <div
        *ngFor="let column of columns; let i = index"
        [matColumnDef]="column.columnDef"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [disabled]="column.columnDef === 'controls'"
          [class]="
            column.columnDef === 'radio'
              ? 'radio-width'
              : column.columnDef === 'instanceName'
              ? 'instance-name-width'
              : ''
          "
        >
          <div
            *ngIf="column.columnDef === 'controls' && showHeaderCheckbox"
            class="table-controls"
          >
            <mat-checkbox
              id="{{ 'checkbox-table-select-all' }}"
              [indeterminate]="checkboxes.hasValue() && !allChecked()"
              [checked]="allChecked()"
              (change)="selectAll()"
              [disabled]="allDisabled()"
              class="center-check-label checked-background-color"
            >
            </mat-checkbox>
          </div>
          <div *ngIf="column.columnDef !== 'controls'">
            {{ column.header | translate }}
          </div>
        </th>

        <td mat-cell *matCellDef="let row">
          <div *ngIf="column.columnDef === 'controls'" class="table-controls">
            <mat-checkbox
              id="{{ 'checkbox-table-' + row.id }}"
              (click)="$event.stopPropagation()"
              matTooltip="{{ getToolTip(row.id) }}"
              [disabled]="isDisable(row.id)"
              (change)="$event ? checkboxes.toggle(row['id']) : null"
              [checked]="checkboxes.isSelected(row['id'])"
              class="center-check-label checked-background-color"
            >
            </mat-checkbox>
          </div>
          <div *ngIf="column.columnDef === 'radio'" class="table-controls">
            <mat-radio-button
              id="{{ 'checkbox-table-' + row.id }}"
              (click)="$event.stopPropagation()"
              (change)="selectInstance(row)"
              color="primary"
            >
            </mat-radio-button>
          </div>

          <div
            class="overflow"
            *ngIf="
              column.columnDef !== 'controls' &&
              column.columnDef !== 'radio' &&
              column.columnDef !== 'instanceStatus'
            "
          >
            <div class="column-controls">
              <i
                *ngIf="
                  row.icon &&
                  (column.columnDef === 'name' ||
                    column.columnDef === 'fileName')
                "
                class="{{ row.icon }} table-item-name-icon"
              ></i>
              <div>
                <div
                  class="column-mat-form"
                  *ngIf="column.columnDef === 'associateProject'"
                >
                  <ng-template
                    *ngIf="
                      column.customType === templateEnum.ProjectListTemplate
                    "
                    [ngTemplateOutlet]="listProjectTemplate"
                    [ngTemplateOutletContext]="{
                      item: column.cell(row),
                      rowId: row.id,
                      columnDef: column.columnDef
                    }"
                  ></ng-template>
                </div>
              </div>

              <img
                *ngIf="
                  row.iconPath &&
                  (column.columnDef === 'name' ||
                    column.columnDef === 'fileName' ||
                    column.columnDef === 'instanceName')
                "
                class="table-item-name-icon"
                src="{{ row.iconPath }}"
              />

              <span
                *ngIf="
                  !column.isEditable &&
                  column.columnDef !== 'progressbar' &&
                  column.columnDef !== 'associateProject'
                "
                class="column-text-name"
                id="{{ column.columnDef }}"
                [matTooltipShowDelay]="1250"
                [matTooltip]="column.cell(row)"
                appTooltipIfTruncated
                >{{ column.cell(row) }} </span
              ><img
                class="column-text-name-warning"
                *ngIf="
                  row.displayWarning &&
                  (column.columnDef === 'name' ||
                    column.columnDef === 'fileName')
                "
                src="assets/images/icons/warning-triangle-small-yellow.svg"
              />
              <mat-form-field
                *ngIf="
                  column.isEditable &&
                  !(
                    column.columnDef === 'projectName' ||
                    column.columnDef === 'description'
                  )
                "
              >
                <mat-label>{{ column.header | translate }}</mat-label>
                <input
                  #field="ngModel"
                  matInput
                  [ngModel]="column.cell(row)"
                  (keyup)="
                    onEnterPressed(
                      $event,
                      field.value,
                      row.id,
                      column.columnDef
                    )
                  "
                  (blur)="
                    onEnterPressed(
                      $event,
                      field.value,
                      row.id,
                      column.columnDef
                    )
                  "
                  maxlength="256"
                  placeholder="{{ column.header | translate }}"
                  [value]="column.cell(row)"
                  required
                />
              </mat-form-field>

              <div
                *ngIf="
                  column.isEditable &&
                  (column.columnDef === 'projectName' ||
                    column.columnDef === 'description') &&
                  row.file.projectId !== null
                "
              >
                <span
                  class="column-text-name"
                  id="{{ column.columnDef }}-noedit"
                  [matTooltipShowDelay]="1250"
                  [matTooltip]="column.cell(row)"
                  appTooltipIfTruncated
                  >{{ column.cell(row) }}
                </span>
              </div>
              <mat-form-field
                *ngIf="
                  column.isEditable &&
                  (column.columnDef === 'projectName' ||
                    column.columnDef === 'description') &&
                  (row.file.projectId === undefined ||
                    row.file.projectId === null)
                "
              >
                <mat-label>{{ column.header | translate }}</mat-label>
                <input
                  #field="ngModel"
                  matInput
                  [ngModel]="column.cell(row)"
                  (keyup)="
                    onEnterPressed(
                      $event,
                      field.value,
                      row.id,
                      column.columnDef
                    )
                  "
                  (blur)="
                    onEnterPressed(
                      $event,
                      field.value,
                      row.id,
                      column.columnDef
                    )
                  "
                  maxlength="256"
                  placeholder="{{ column.header | translate }}"
                  [value]="column.cell(row)"
                  required
                />
              </mat-form-field>
              <div *ngIf="column.columnDef === 'progressbar'">
                <ng-template
                  *ngIf="
                    column.customType === templateEnum.ProgressUploadTemplate
                  "
                  [ngTemplateOutlet]="progresUploadTemplate"
                  [ngTemplateOutletContext]="{ item: column.cell(row) }"
                ></ng-template>
              </div>
            </div>
          </div>
          <div *ngIf="column.columnDef === 'instanceStatus'">
            <div
              class="hide-badge"
              [raUiBadge]="column.cell(row)"
              [badgeConfig]="{
                position: selectedBadgePosition,
                color:
                  column.cell(row) === 'FREE'
                    ? selectedBadgeBlueColor
                    : selectedBadgeGrayColor,
                size: selectedBadgeSize
              }"
            ></div>
          </div>
        </td>
      </div>

      <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: tableColumns"
        id="tr-{{ row.id }}"
        [ngStyle]="isDisable(row.id) && { 'background-color': '#F8F8F8' }"
      ></tr>
    </table>
  </div>
</div>

<ng-template
  #listProjectTemplate
  let-item="item"
  let-rowId="rowId"
  let-columnDef="columnDef"
>
  <app-dropdown-list
    [dataArray]="item"
    (projectIdSelected)="selectProject($event, rowId, columnDef)"
  >
  </app-dropdown-list>
</ng-template>

<ng-template #progresUploadTemplate let-item="item">
  <app-file-upload-progress [uploadData]="item"></app-file-upload-progress>
</ng-template>
