import {
  FileData,
  FilesToAddNewVersion,
  FilesToSync,
  NewProject,
  SessionFile,
  SessionKind,
} from '../../core/models/twin-studio.model';
import { ProjectData, ProjType } from '../../core/models/vault/project.model';
import { ProjectViewForDisplay } from './project-table-helper';
import {
  activeFileExtension,
  getImagePath,
  getImagePathForUnknownProject,
} from 'src/app/core/services/vault/view-status/project-file-utility';

export class FileHelper {
  public static getCurrentFileName(project: ProjectData): string | null {
    const activeProgram = project.activeProgram;
    if (activeProgram) {
      if (project.type === ProjType.Arena) {
        return `${activeProgram.fileName
          .split('.')
          .slice(0, -1)
          .join('.')}.DOE`;
      }
      if (project.type === ProjType.Emulate3D) {
        return `${activeProgram.fileName
          .split('.')
          .slice(0, -1)
          .join('.')}.demo3d`;
      }
      if (project.type === ProjType.LogixDesigner) {
        return `${activeProgram.fileName
          .split('.')
          .slice(0, -1)
          .join('.')}.ACD`;
      }
      if (project.type === ProjType.ViewDesigner) {
        return `${activeProgram.fileName
          .split('.')
          .slice(0, -1)
          .join('.')}.VPD`;
      }
      if (project.type === ProjType.Asset) {
        return activeProgram.fileName;
      }
    }
    return null;
  }

  public static getTypesPerSessionKind(sessionKind: SessionKind): ProjType[] {
    if (sessionKind === SessionKind.Logix) {
      return [ProjType.LogixDesigner];
    }
    if (sessionKind === SessionKind.Arena) {
      return [ProjType.Arena];
    }
    if (sessionKind === SessionKind.Emulate3d) {
      return [
        ProjType.Emulate3D,
        ProjType.LogixDesigner,
        ProjType.ViewDesigner,
        ProjType.Asset,
      ];
    }
  }

  public static getAssetsPerSessionKind(sessionKind: SessionKind): string[] {
    if (sessionKind === SessionKind.Logix) {
      return ['echo-snap'];
    }
    if (sessionKind === SessionKind.Arena) {
      return [];
    }
    if (sessionKind === SessionKind.Emulate3d) {
      return ['mer'];
    }
  }

  public static getEmulateProjects(
    projectsSelelected: ProjectViewForDisplay[]
  ): ProjectViewForDisplay[] {
    return projectsSelelected.filter(
      (p) => p.project.type === ProjType.Emulate3D
    );
  }
  public static getLogixProjects(
    projectsSelelected: ProjectViewForDisplay[]
  ): ProjectViewForDisplay[] {
    return projectsSelelected.filter(
      (p) => p.project.type === ProjType.LogixDesigner
    );
  }
  public static getArenaProjects(
    projectsSelelected: ProjectViewForDisplay[]
  ): ProjectViewForDisplay[] {
    return projectsSelelected.filter((p) => p.project.type === ProjType.Arena);
  }
  public static getViewDesignerProjects(
    projectsSelelected: ProjectViewForDisplay[]
  ): ProjectViewForDisplay[] {
    return projectsSelelected.filter(
      (p) => p.project.type === ProjType.ViewDesigner
    );
  }
  public static getEchoProjects(
    projectsSelelected: ProjectViewForDisplay[]
  ): ProjectViewForDisplay[] {
    return projectsSelelected.filter(
      (p) => activeFileExtension(p.project) === 'echo-snap'
    );
  }
  public static getViewMeProjects(
    projectsSelelected: ProjectViewForDisplay[]
  ): ProjectViewForDisplay[] {
    return projectsSelelected.filter(
      (p) => activeFileExtension(p.project) === 'mer'
    );
  }

  public static convertProjectToSessionFile(
    project: ProjectData,
    solutionName: string
  ): SessionFile {
    const activeProgram = project.activeProgram; // should always have an active program
    const projectHistoryId = activeProgram.historyId;
    const fileName = FileHelper.getCurrentFileName(project);
    return {
      projectId: project.projectId,
      historyId: projectHistoryId,
      projectName: project.name,
      solutionName: solutionName,
      fileName: fileName,
      iconPath: getImagePath(project) ?? '',
    };
  }

  public static convertFileDataToSessionFile(project: FileData): SessionFile {
    return {
      projectId: project.projectId,
      historyId: project.historyId,
      fileName: project.fileName,
      projectName: project.projectName,
      solutionName: project.solutionName,
      iconPath: getImagePathForUnknownProject(project.fileName) ?? '',
    };
  }

  public static convertUnknownProjectToNew(project: FileData): NewProject {
    const result: NewProject = {
      name: project.projectName,
      description: project.description,
      note: project.commitMessage,
      filePath: project.filePath,
      solutionId: project.solutionId,
      solutionName: project.solutionName,
    };
    if (project.commitMessage) {
      result.note = project.commitMessage;
    }
    return result;
  }

  public static convertUnknownProjectToAddVersion(
    project: FileData
  ): FilesToAddNewVersion {
    const result: FilesToAddNewVersion = {
      projectId: project.projectId,
      fileName: project.fileName,
      filePath: project.filePath,
      solutionName: project.solutionName,
    };
    if (project.commitMessage) {
      result.note = project.commitMessage;
    }
    return result;
  }

  public static convertFileDataToFileSync(project: FileData): FilesToSync {
    const result: FilesToSync = {
      projectId: project.projectId,
      historyId: project.historyId,
      projectFileName: project.fileName,
      etag: project.etag,
      isNewFile: false,
      solutionName: project.solutionName,
    };
    if (project.commitMessage) {
      result.note = project.commitMessage;
    }
    return result;
  }

  /**
   * Validproject  extensions for uploading a project. Anything else is considered
   * an asset.
   */
  public static validProjectExtensions = [
    '.acd',
    '.l5k',
    '.l5x',
    '.doe',
    '.demo3d',
    '.vpd',
  ];
}
