import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuickAccessUploadComponent } from './quick-access-upload.component';
import { RaUiStepperModule } from '@ra-web-tech-ui-toolkit/common-views/stepper';
import { RaUiButtonModule } from '@ra-web-tech-ui-toolkit/buttons/button';
import { SelectFilesModule } from './select-files/select-files.module';
import { SelectLocationModule } from './select-location/select-location.module';
import { SummaryModule } from './summary/summary.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DataStoredInfoModule } from '../../shared/components/data-stored-info/data-stored-info.module';

@NgModule({
  declarations: [QuickAccessUploadComponent],
  imports: [
    CommonModule,
    RaUiStepperModule,
    RaUiButtonModule,
    SelectFilesModule,
    SelectLocationModule,
    SummaryModule,
    MatSidenavModule,
    DataStoredInfoModule,
  ],
  exports: [QuickAccessUploadComponent],
})
export class QuickAccessUploadModule {}
