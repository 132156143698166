/* eslint-disable sonarjs/no-duplicate-string */
import { getFileExtension } from '../../../../shared/utility/app-utility';
import { Projectfile } from '../../../models/vault/project-file.model';
import { Project, ProjType } from '../../../models/vault/project.model';

export const ignoreConvertErrors = true;

export type IconPath = string;
/**
 * Asset project subtype
 */
export enum AssetType {
  AutoCad = 'AutoCad',
  Code = 'Code',
  Excel = 'Excel',
  Graphic = 'Graphic',
  Matlab = 'Matlob',
  Media = 'Media',
  Pdf = 'Pdf',
  Powerpoint = 'Powerpoint',
  Word = 'Word',
  EchoSnapshot = 'EchoSnapshot',
  //ONLY for unknown Projects
  Arena = 'Arena',
  Emulate3D = 'Emulate3D',
  LogixDesigner = 'LogixDesigner',
}

/**
 * File extension to named asset type dictionary.
 */
export const assetExtensionDict: Partial<Record<string, AssetType>> = {
  dwg: AssetType.AutoCad,
  dfx: AssetType.AutoCad,
  css: AssetType.Code,
  xml: AssetType.Code,
  js: AssetType.Code,
  xls: AssetType.Excel,
  xlsx: AssetType.Excel,
  csv: AssetType.Excel,
  xlsm: AssetType.Excel,
  bmp: AssetType.Graphic,
  png: AssetType.Graphic,
  jpg: AssetType.Graphic,
  jpeg: AssetType.Graphic,
  gif: AssetType.Graphic,
  tif: AssetType.Graphic,
  m: AssetType.Matlab,
  mat: AssetType.Matlab,
  flac: AssetType.Media,
  mov: AssetType.Media,
  mp3: AssetType.Media,
  mp4: AssetType.Media,
  wav: AssetType.Media,
  pdf: AssetType.Pdf,
  ppt: AssetType.Powerpoint,
  pptx: AssetType.Powerpoint,
  doc: AssetType.Word,
  docx: AssetType.Word,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'echo-snap': AssetType.EchoSnapshot,

  //ONLY for unknown Projects
  doe: AssetType.Arena,
  demo3d: AssetType.Emulate3D,
  acd: AssetType.LogixDesigner,
  l5x: AssetType.LogixDesigner,
  l5k: AssetType.LogixDesigner,
};

/**
 * SVG icon / image for project types
 * @Note SVG or font icons may be used for project branding
 */
export const projTypeSvgIconDict: Partial<Record<ProjType, IconPath>> = {
  [ProjType.Arena]: 'assets/images/project_types/arena.svg',
  [ProjType.Asset]: 'assets/images/project_types/asset-default.svg',
  [ProjType.Emulate3D]: 'assets/images/project_types/e3d.svg',
  [ProjType.Eplan]: 'assets/images/project_types/eplan.svg',
  [ProjType.FTDesignStudio]: 'assets/images/project_types/ftds.svg',
  [ProjType.LogixDesigner]: 'assets/images/project_types/logix.svg',
  [ProjType.Optix]: 'assets/images/project_types/optix.svg',
  [ProjType.ViewDesigner]: 'assets/images/project_types/viewdesigner.svg',
  [ProjType.ViewSeClient]: 'assets/images/project_types/viewdesigner.svg',
  [ProjType.ViewSeServer]: 'assets/images/project_types/viewdesigner.svg',
  [ProjType.Echo]: 'assets/images/project_types/echo-snap.svg',
  [ProjType.FTRAManager]: 'assets/images/project_types/FT_AM48px.svg',
};

/**
 * SVG icon / image for asset project subtype
 */
export const assetSvgIconDict: Partial<Record<AssetType, IconPath>> = {
  [AssetType.AutoCad]: 'assets/images/project_types/asset-autocad.svg',
  [AssetType.Code]: 'assets/images/project_types/asset-code.svg',
  [AssetType.Excel]: 'assets/images/project_types/asset-excel.svg',
  [AssetType.Graphic]: 'assets/images/project_types/asset-graphic.svg',
  [AssetType.Matlab]: 'assets/images/project_types/asset-matlab.svg',
  [AssetType.Media]: 'assets/images/project_types/asset-media.svg',
  [AssetType.Pdf]: 'assets/images/project_types/asset-pdf.svg',
  [AssetType.Powerpoint]: 'assets/images/project_types/asset-powerpoint.svg',
  [AssetType.Word]: 'assets/images/project_types/asset-word.svg',

  [AssetType.EchoSnapshot]: 'assets/images/project_types/asset-echosnap.svg',
  [AssetType.Arena]: 'assets/images/project_types/arena.svg',
  [AssetType.Emulate3D]: 'assets/images/project_types/e3d.svg',
  [AssetType.LogixDesigner]: 'assets/images/project_types/logix.svg',
};

/**
 * Returns whether the Projectfile status indicates error.
 *
 * @param f The Projectfile to check.
 * @param [ignoreConvertError=true] Optional, whether to ignore convert error of non critical file types.
 * @returns True if Projectfile status indicates error, otherwise false.
 *
 */
export function isFileErrored(
  f: Projectfile,
  ignoreConvertError: boolean = true
): boolean {
  if (ignoreConvertError && f.status === 'ErrorFileConvert') {
    return !isFileErrorIgnorable(f);
  }
  return f.status.startsWith('Error');
}

/**
 * Returns whether the Projectfile status indicates success.
 *
 * @param f - The Projectfile to check.
 * @param [ignoreConvertError=true] Optional, whether to ignore convert error of non critical file types.
 * @returns True if Projectfile status indicates success, otherwise false.
 *
 */
export function isFileSucceeded(
  f: Projectfile,
  ignoreConvertError: boolean = true
): boolean {
  if (ignoreConvertError && f.status === 'ErrorFileConvert') {
    return isFileErrorIgnorable(f);
  }
  return f.status.startsWith('Success');
}

/**
 *
 * Returns whether the Proljecfile type indicates that errors can be ignored.
 *
 * @param f - The Projectfile to check.
 * @returns True if Projectfile type indicates errors can be ignored, otherwise false.
 *
 */
export function isFileErrorIgnorable(f: Projectfile): boolean {
  // Do not ignore ACD or L5X errors
  return !(
    f.fileType === 'ACD' ||
    f.fileType === 'L5X' ||
    f.fileType === 'FTDS'
  );
}

/**
 * Returns whether the Proljecfile status indicates pending operation.
 *
 * @param f - The Projectfile to check.
 * @returns True if Projectfile status indicates pending operation, otherwise false.
 *
 */
export function isFilePending(f: Projectfile): boolean {
  return f.status.startsWith('Pending');
}

/**
 * Returns extension only if file name has '.' plus an extension.
 *
 * @param project
 * @returns extension or empty string if not found
 */
export function activeFileExtension(project: Project): string {
  const fn = project.projectHistory.find((cpt) => cpt.isActive)?.fileName ?? '';
  const elements = fn.toLocaleLowerCase().split('.');
  const ext = elements.pop() ?? '';
  return elements.length > 0 ? ext : '';
}

export function getImagePath(project: Project): string | undefined {
  const assetType = assetExtensionDict[activeFileExtension(project)];
  if (project.type !== ProjType.Asset || assetType === undefined) {
    return projTypeSvgIconDict[project.type];
  }
  return assetSvgIconDict[assetType];
}

export function getImagePathForUnknownProject(fileName: string): string {
  const ext = getFileExtension(fileName);
  const assetType = assetExtensionDict[ext];
  return (
    assetSvgIconDict[assetType] ??
    'assets/images/project_types/asset-default.svg'
  );
}

export function getFilename(pathFile: string): string {
  if (!pathFile) {
    return '';
  }
  if (pathFile.includes('/')) {
    const pathSegments = pathFile.split('/');
    return pathSegments[pathSegments.length - 1];
  } else {
    return pathFile;
  }
}
