<div class="edit-workspace" *ngIf="panelIsOpen">
  <div class="edit-workspace-panel">
    <div class="edit-workspace-subpanel">
      <div class="left-right-padding">
        <div class="header header-padding">{{ activeWorkspace.name }}</div>
        <mat-form-field>
          <mat-label>
            {{ "workspace.editWorkspaceName" | translate }}
          </mat-label>
          <input
            matInput
            [placeholder]="'workspace.editWorkspaceName' | translate"
            maxlength="256"
            [(ngModel)]="workspaceName"
            required
          />
          <mat-hint align="start">
            <strong>Tip: {{ "workspace.nameTip" | translate }}</strong>
          </mat-hint>
        </mat-form-field>

        <div class="row header header-padding">
          {{ "workspace.ipAddress" | translate }}
          <i class="icon ra-icon-ide-md-info"></i>
        </div>
        <div class="row">
          <div class="column">
            <div class="description">
              {{ "workspace.ipv4CIDR" | translate }}
            </div>
            <div>{{ ipv4Cidr }}</div>
          </div>
          <div class="column">
            <div class="description">{{ "workspace.subnets" | translate }}</div>
            <div>{{ ipSubnet }}</div>
          </div>
        </div>

        <div class="header header-padding">
          {{ "workspace.editWorkspaceSG" | translate }}
        </div>
        <div
          *ngIf="!securityGroupLoaded && !inboundReady && !outboundReady"
          class="loading"
        >
          <app-loading id="loading" [width]="'300px'"></app-loading>
        </div>
        <mat-tab-group *ngIf="securityGroupLoaded">
          <mat-tab [label]="'workspace.inboundRules' | translate">
            <app-ag-grid-table
              [columnDef]="columnDef"
              [rowData]="securityGroup.inbound"
              [addAllowed]="true"
              [deleteAllowed]="true"
              [deleteCondition]="displayDeleteButton"
              (addRow)="onOpenDialogContent({ content: addRuleDialogContent })"
              (deleteRow)="deleteInboundRule($event)"
              (gridReady)="onInboundReady()"
            ></app-ag-grid-table>
          </mat-tab>
          <mat-tab [label]="'workspace.outboundRules' | translate">
            <app-ag-grid-table
              [columnDef]="columnDef"
              [rowData]="securityGroup.outbound"
              [addAllowed]="true"
              [deleteAllowed]="true"
              [deleteCondition]="displayDeleteButton"
              (addRow)="onOpenDialogContent({ content: addRuleDialogContent })"
              (deleteRow)="deleteOutboundRule($event)"
              (gridReady)="onOutboundReady()"
            ></app-ag-grid-table>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <div class="banner row top-border">
      <app-primary-button-flourish
        id="delete-workspace"
        class="left-right-padding"
        [label]="'deleteLabelButton' | translate"
        [isDisabled]="activeWorkspace.workstations.length !== 0"
        (clickEvent)="
          onOpenDialogContent({ content: deleteWorkspaceDialogContent })
        "
      >
      </app-primary-button-flourish>
      <div class="row left-right-padding">
        <app-primary-button-flourish
          id="save-workspace"
          class="button"
          [label]="'saveLabelButton' | translate"
          (clickEvent)="onSaveWorkspace()"
        >
        </app-primary-button-flourish>
        <app-primary-button-flourish
          id="close-panel"
          class="button"
          [label]="'closeLabelButton' | translate"
          (clickEvent)="onClosePanel()"
        >
        </app-primary-button-flourish>
      </div>
    </div>
  </div>
</div>
<div id="shadow" class="shadow" *ngIf="panelIsOpen"></div>
<div raUiToast [toastConfig]="toastConfig"></div>

<ng-template #deleteWorkspaceDialogContent class="dialog-content">
  <div class="dialog-title">
    <img
      src="assets/images/icons/critical-warning-icon.svg"
      alt="critical-warning"
    />
    <strong>{{ "workspace.delete" | translate }}</strong>
  </div>
  <div class="dialog-message">
    <i
      >{{ "workspace.dialogMessage1" | translate }}
      <strong>{{ activeWorkspace.name }}.</strong>
      {{ "workspace.dialogMessage2" | translate }}</i
    >
  </div>
  <div class="dialog-input">
    <mat-form-field>
      <mat-label>{{ "workspace.dialogTextLabel" | translate }}</mat-label>
      <input
        id="dialog-delete-input"
        matInput
        maxlength="256"
        placeholder="{{ 'workspace.dialogTextPlaceholder' | translate }}"
        [value]="deleteWord"
        (keyup)="deleteWorkspaceIsDisabled($event)"
        required
      />
    </mat-form-field>
  </div>
  <div class="dialog-buttons">
    <app-primary-button-flourish
      [label]="'workspace.yesDelete' | translate"
      [isDisabled]="deleteButtonIsDisabled"
      (clickEvent)="deleteWorkspace()"
    >
    </app-primary-button-flourish>
    <app-secondary-button-flourish
      [label]="'workspace.cancel' | translate"
      (clickEvent)="cancelDeleteWorkspace()"
    >
    </app-secondary-button-flourish>
  </div>
</ng-template>

<ng-template #addRuleDialogContent class="dialog-content">
  <div class="dialog-title">
    <strong>{{ "Add a new rule" | translate }}</strong>
  </div>
  <div class="dialog-input">
    <form [formGroup]="newRuleFormGroup">
      <mat-form-field>
        <mat-label>{{ "Rule Type" | translate }}</mat-label>
        <mat-select
          id="rule-type-input"
          [(ngModel)]="ruleType"
          ngDefaultControl
          formControlName="ruleType"
          (keyup)="newRuleIsDisabled($event)"
          required
        >
          <mat-option
            *ngFor="let ruleType of ruleTypes"
            id="{{ 'rule-type-option-' + ruleType.value }}"
            [value]="ruleType"
          >
            {{ ruleType.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ "Protocol" | translate }}</mat-label>
        <mat-select
          id="protocol-input"
          [(ngModel)]="protocol"
          ngDefaultControl
          formControlName="protocol"
          (keyup)="newRuleIsDisabled($event)"
          required
        >
          <mat-option
            *ngFor="let protocol of protocols"
            id="{{ 'protocol-option-' + protocol.value }}"
            [value]="protocol"
          >
            {{ protocol.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ "Port Range" | translate }}</mat-label>
        <input
          id="port-range-input"
          matInput
          maxlength="256"
          [value]="portRange"
          formControlName="port"
          (keyup)="newRuleIsDisabled($event)"
          required
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ "Destination IP" | translate }}</mat-label>
        <input
          id="address-input"
          matInput
          maxlength="256"
          [value]="ipv4Address"
          formControlName="address"
          (keyup)="newRuleIsDisabled($event)"
          required
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ "Description" | translate }}</mat-label>
        <input
          id="description-input"
          matInput
          maxlength="255"
          [value]="description"
          formControlName="description"
          (keyup)="newRuleIsDisabled($event)"
        />
      </mat-form-field>
    </form>
  </div>
  <div class="dialog-buttons">
    <app-primary-button-flourish
      [label]="'Add new rule' | translate"
      [isDisabled]="newRuleButtonIsDisabled"
      (clickEvent)="addNewRule()"
    >
    </app-primary-button-flourish>
    <app-secondary-button-flourish
      [label]="'workspace.cancel' | translate"
      (clickEvent)="cancelNewRule()"
    >
    </app-secondary-button-flourish>
  </div>
</ng-template>
