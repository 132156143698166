import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  EventEmitter,
} from '@angular/core';
import { TenantInfoFacade } from '../../../core/reducers/tenant-info/tenant-info.facade';
import { tap } from 'rxjs/operators';
import { WorkspaceFacade } from '../../../core/reducers/workspace/workspace.facade';
import {
  EditWorkspacePayload,
  Rule,
  SecurityGroup,
  Workspace,
} from '../../../core/models/workspace-creation.model';
import { lastValueFrom } from 'rxjs';
import { WorkspaceService } from '../../../core/services/workspace/workspace.service';
import {
  NotificationType,
  ActionButtonStyles,
} from '@ra-web-tech-ui-toolkit/cdk/types';
import {
  IToastConfig,
  ToastConfig,
  ToastDirective,
} from '@ra-web-tech-ui-toolkit/popups/toast';
import { OverlayPosition } from '@ra-web-tech-ui-toolkit/cdk/utils';
import { TranslateService } from '@ngx-translate/core';
import {
  DialogService,
  DialogComponent,
  IDialogConfig,
  IDialogContentProjection,
} from '@ra-web-tech-ui-toolkit/popups/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import {
  RaUiFormGroup,
  RaUiFormControl,
} from '@ra-web-tech-ui-toolkit/form-system/form';
import { Validators } from '@angular/forms';
import { cloneDeep } from 'lodash-es';
import { GetWorkspaceResponse } from '../../../core/models/workspace-edit.model';
import { descriptionRegex } from 'src/app/core/models/workspace';

interface SelectOption {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-workspace-edit',
  templateUrl: './workspace-edit.component.html',
  styleUrls: ['./workspace-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WorkspaceEditComponent implements OnInit {
  @ViewChild(ToastDirective, { static: true }) toastRef!: ToastDirective;
  @Input() panelIsOpen: boolean = false;
  @Output() panelIsOpenChange = new EventEmitter();

  tenantId: string | undefined = undefined;
  activeWorkspace!: Workspace;
  workspaceName: string = '';
  securityGroup: SecurityGroup = {
    description: '',
    inbound: [],
    outbound: [],
  };
  ipSubnet: string = '';
  ipv4Cidr: string = '';
  securityGroupLoaded = false;
  inboundReady = false;
  outboundReady = false;
  dialogRef!: MatDialogRef<DialogComponent>;
  deleteWord: string = '';
  deleteButtonIsDisabled: boolean = true;
  newRuleButtonIsDisabled: boolean = true;
  options: IToastConfig = {
    delay: 5000,
    position: OverlayPosition.BottomCenter,
  };
  toastConfig: ToastConfig = new ToastConfig(this.options);

  protocols: SelectOption[] = [
    { value: 'tpc', viewValue: 'TCP' },
    { value: 'udp', viewValue: 'UDP' },
  ];
  protocol: SelectOption = this.protocols[0];

  ruleTypes: SelectOption[] = [
    { value: 'inbound', viewValue: 'Inbound' },
    { value: 'outbound', viewValue: 'Outbound' },
  ];
  ruleType: SelectOption = this.ruleTypes[0];

  portRange: string = '';
  portRegex: string =
    '^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$';

  ipv4Address: string = '';
  ipv4Regex =
    '^([01]?\\d\\d?|2[0-4]\\d|25[0-5])(?:\\.(?:[01]?\\d\\d?|2[0-4]\\d|25[0-5])){3}(?:/[0-2]{0,1}\\d|/3[0-2]){1}$';

  descriptionRegex: string = descriptionRegex;
  description = '';

  columnDef = [
    {
      width: 30,
    },
    {
      field: 'protocol',
      headerName: 'Protocol',
      flex: 1,
      cellStyle: { border: 'none' },
    },
    {
      field: 'port',
      headerName: 'Port range',
      flex: 1,
      cellStyle: { border: 'none' },
    },
    {
      field: 'address',
      headerName: 'Destination',
      flex: 1,
      cellStyle: { border: 'none' },
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      cellStyle: { border: 'none' },
    },
  ];

  newRuleFormGroup = new RaUiFormGroup({
    ruleType: new RaUiFormControl(this.ruleType, [Validators.required]),
    protocol: new RaUiFormControl(this.protocol, [Validators.required]),
    port: new RaUiFormControl(this.portRange, [
      Validators.required,
      Validators.pattern(new RegExp(this.portRegex)),
    ]),
    address: new RaUiFormControl(this.ipv4Address, [
      Validators.required,
      Validators.pattern(new RegExp(this.ipv4Regex)),
    ]),
    description: new RaUiFormControl(this.description, [
      Validators.maxLength(255),
      Validators.minLength(0),
      Validators.pattern(new RegExp(this.descriptionRegex)),
    ]),
  });

  displayDeleteButton = (row: Rule): boolean => {
    return true;
  };

  constructor(
    private readonly tenantInfoFacade: TenantInfoFacade,
    private readonly workspaceFacade: WorkspaceFacade,
    private readonly workspaceService: WorkspaceService,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService
  ) {
    this.tenantInfoFacade.tenantId$.subscribe(
      (tenantId) => (this.tenantId = tenantId)
    );
  }

  ngOnInit(): void {
    this.initActiveWorkspace();
  }

  async initActiveWorkspace(): Promise<void> {
    const activeWorkspace$ = this.workspaceFacade.activeWorkspace$.pipe(
      tap((workspace) => {
        if (workspace) {
          this.activeWorkspace = workspace;
          this.getSecurityGroupFromLcore();
        }
      })
    );
    await lastValueFrom(activeWorkspace$);
  }

  getSecurityGroupFromLcore(): void {
    if (this.tenantId) {
      this.workspaceService
        .getWorkspace(
          this.tenantId,
          this.activeWorkspace.region,
          this.activeWorkspace.id
        )
        .subscribe((response) => {
          this.updateSecurityGroupInfo(response);
        });
    }
  }

  updateSecurityGroupInfo(response: GetWorkspaceResponse): void {
    const inboundRules = response.inbound?.filter((rule) => {
      return !(
        rule.description === 'Default Ingress rule TCP' ||
        rule.address === '10.0.0.0/16'
      );
    });
    const outboundRules = response.outbound?.filter((rule) => {
      return !(
        rule.description === 'Default Egress rule TCP' ||
        rule.address === '10.0.0.0/16'
      );
    });

    this.securityGroup.inbound = inboundRules
      ? inboundRules.map((rule) => {
        rule.protocol = rule.protocol.toUpperCase();
        return rule;
      })
      : [];
    this.securityGroup.outbound = outboundRules
      ? outboundRules.map((rule) => {
        rule.protocol = rule.protocol.toUpperCase();
        return rule;
      })
      : [];
    this.ipSubnet = response.ipSubnet ? response.ipSubnet : '';
    this.ipv4Cidr = response.ipv4Cidr ? response.ipv4Cidr : '';
    this.securityGroupLoaded = true;
  }

  isValidPort(port: string): boolean {
    const regex = new RegExp(this.portRegex);
    return regex.test(port);
  }

  isValidIPv4(ipv4: string): boolean {
    const regex = new RegExp(this.ipv4Regex);
    return regex.test(ipv4);
  }

  isValidDescription(description: string): boolean {
    const regex = new RegExp(this.descriptionRegex);
    return regex.test(description);
  }

  showSuccessSnackbar(message: string): void {
    this.toastRef.addToast(message, NotificationType.Success);
  }

  openErrorDialog(title: string, message: string): void {
    const dialogConfig = {
      title: title,
      message: message,
      buttons: [
        {
          label: this.translateService.instant('workspace.ok'),
          buttonStyle: ActionButtonStyles.Main,
        },
      ],
      messageType: NotificationType.Error,
      showCloseIconButton: false,
      hideTitleIcon: false,
    };
    this.dialogService.openDialog(dialogConfig);
  }

  onSaveWorkspace(): void {
    const editWorkspacePayload: EditWorkspacePayload = {
      name:
        this.workspaceName !== ''
          ? this.workspaceName
          : this.activeWorkspace.name,
      security: this.securityGroup,
    };

    if (this.tenantId) {
      this.workspaceService
        .updateWorkspace(
          this.tenantId,
          editWorkspacePayload,
          this.activeWorkspace.region,
          this.activeWorkspace.id
        )
        .subscribe((response) => {
          if (response === 'Workspace Updated!') {
            this.onClosePanel();
            this.showSuccessSnackbar('Workspace has been successfully saved');
          } else {
            this.openErrorDialog(
              'Failed to update workspace',
              'An error has occurred while trying to update the workspace'
            );
          }
        });
    }
  }

  cancelDeleteWorkspace(): void {
    this.dialogRef?.close();
    this.deleteWord = '';
    this.deleteButtonIsDisabled = true;
  }

  deleteWorkspaceIsDisabled(event: any): void {
    this.deleteWord = event.target.value;
    this.deleteButtonIsDisabled = this.deleteWord !== 'delete';
  }

  onOpenDialogContent(content?: IDialogContentProjection): void {
    this.newRuleFormGroup.reset();
    const dialogConfig: IDialogConfig = {
      title: '',
      message: '',
      buttons: [],
      showCloseIconButton: true,
      hideTitleIcon: true,
      contentProjection: content,
    };

    this.dialogRef = this.dialogService.openDialog(dialogConfig);
  }

  deleteWorkspace(): void {
    this.dialogRef?.close();

    if (this.tenantId) {
      this.workspaceService
        .deleteWorkspace(
          this.tenantId,
          this.activeWorkspace.region,
          this.activeWorkspace.id
        )
        .subscribe((response) => {
          if (response === 'Workspace is being deleted!') {
            this.onClosePanel();
            this.showSuccessSnackbar('Workspace is being deleted');
          } else {
            this.openErrorDialog(
              'Failed to delete workspace',
              'An error has occurred while trying to delete the workspace'
            );
          }
        });
    }
  }

  cancelNewRule(): void {
    this.dialogRef?.close();
    this.ruleType = this.ruleTypes[0];
    this.protocol = this.protocols[0];
    this.portRange = '';
    this.ipv4Address = '';
    this.description = '';
    this.newRuleButtonIsDisabled = true;
  }

  newRuleIsDisabled(event: any): void {
    this.newRuleButtonIsDisabled = this.newRuleFormGroup.status !== 'VALID';
  }

  addNewRule(): void {
    const rule: Rule = {
      protocol: this.newRuleFormGroup.value.protocol.viewValue,
      port: this.newRuleFormGroup.value.port,
      address: this.newRuleFormGroup.value.address,
      description: this.newRuleFormGroup.value.description,
    };
    if (!rule.address.includes('10.0.0.0/')) {
      if (this.newRuleFormGroup.value.ruleType.value === 'inbound') {
        this.securityGroup.inbound.push(rule);
        this.securityGroup.inbound = cloneDeep(this.securityGroup.inbound);
      } else {
        this.securityGroup.outbound.push(rule);
        this.securityGroup.outbound = cloneDeep(this.securityGroup.outbound);
      }
    }
    this.dialogRef?.close();
  }

  deleteInboundRule(deletedRule: any): void {
    this.securityGroup.inbound = this.securityGroup.inbound.filter(
      (rule) => rule !== deletedRule
    );
  }

  deleteOutboundRule(deletedRule: any): void {
    this.securityGroup.outbound = this.securityGroup.outbound.filter(
      (rule) => rule !== deletedRule
    );
  }

  onClosePanel(): void {
    this.panelIsOpenChange.emit(false);
    this.workspaceName = '';
    this.securityGroupLoaded = false;
    this.securityGroup.inbound = [];
    this.securityGroup.outbound = [];
    this.ipSubnet = '';
    this.ipv4Cidr = '';
  }

  onInboundReady(): void {
    this.inboundReady = true;
  }

  onOutboundReady(): void {
    this.outboundReady = true;
  }
}
