import {
  Project,
  ProjStatusColors,
  ProjStatusLabels,
} from '../../../models/vault/project.model';
import { statusColors } from './logix-viewstatus';

export abstract class ViewStatusHelper {
  abstract getProjectStatusLabel(project: Project): ProjStatusLabels;
  getColorByStatus(status: ProjStatusLabels): ProjStatusColors {
    return statusColors[status];
  }
}
