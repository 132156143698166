<div *ngIf="isLoading && !dataAvailable" class="loading">
  <img
    id="loading-workstation-files"
    src="/assets/images/loading-recent-files.svg"
    alt="loading-recent-files"
  />
  <p id="title-loading-workstation-files" class="title-loading">
    {{ "pushToVault.workspaces.loadingFiles" | translate }}
  </p>
  <ra-ui-loading-spinner
    id="loading-spinner-workstation-files"
    [size]="size"
    [animationType]="animationType"
    [animationDuration]="animationDuration"
  >
  </ra-ui-loading-spinner>
</div>

<div *ngIf="!dataAvailable && !isLoading" class="empty-recent-files">
  <img
    id="empty-workstation-files"
    src="/assets/images/no-signal.svg"
    alt="empty-folder"
  />
  <p id="title-empty-workstation-files" class="title-empty">
    {{ "pushToVault.workspaces.noFiles" | translate }}
  </p>
  <p id="subtitle-empty-workstation-files" class="subtitle-empty">
    {{ "pushToVault.workspaces.noFilesDesc" | translate }}
  </p>
</div>

<div>
  <ag-grid-angular
    *ngIf="dataAvailable"
    id="ag-grid-workstation-files"
    class="ag-theme-rockwell"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    (selectionChanged)="onSelectionChanged()"
  >
  </ag-grid-angular>
</div>
