<div *ngIf="!isActionButton" id="button">
  <button
    *ngIf="!isMatStepperPrevious"
    appDebounceClick
    mat-stroked-button
    id="{{ id }}"
    color="primary"
    type="button"
    class="{{ noBorder ? 'no-border ' : '' }}"
    [debounceTime]="debounceTime"
    [disabled]="isDisabled"
    [style.width]="widthPx ? widthPx + 'px' : 'fit-content'"
    (debounceClick)="onClickButton($event)"
  >
    <mat-icon *ngIf="icon" class="{{ icon }}"></mat-icon>
    {{ label }}
  </button>
  <button
    *ngIf="isMatStepperPrevious"
    appDebounceClick
    mat-stroked-button
    id="{{ id }}"
    color="primary"
    type="button"
    class="{{ noBorder ? 'no-border ' : '' }}"
    [debounceTime]="debounceTime"
    [style.width]="widthPx ? widthPx + 'px' : 'fit-content'"
    [disabled]="isDisabled"
    (debounceClick)="onClickButton($event)"
    matStepperPrevious
  >
    <mat-icon *ngIf="icon" class="{{ icon }}"></mat-icon>
    {{ label }}
  </button>
</div>
<div *ngIf="isActionButton" id="button">
  <button
    *ngIf="!isMatStepperPrevious"
    appDebounceClick
    mat-stroked-button
    id="{{ id }}"
    color="primary"
    class="action-effects {{ noBorder ? 'no-border ' : '' }}"
    type="button"
    [debounceTime]="debounceTime"
    (debounceClick)="onClickButton($event)"
  >
    <mat-icon *ngIf="icon" class="{{ icon }}"></mat-icon>
    {{ label }}
  </button>
  <button
    *ngIf="isMatStepperPrevious"
    appDebounceClick
    mat-stroked-button
    color="primary"
    id="{{ id }}"
    class="action-effects {{ noBorder ? 'no-border ' : '' }}"
    type="button"
    [debounceTime]="debounceTime"
    (debounceClick)="onClickButton($event)"
    matStepperPrevious
  >
    <mat-icon *ngIf="icon" class="{{ icon }}"></mat-icon>
    {{ label }}
  </button>
</div>
