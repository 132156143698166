/* eslint-disable sonarjs/no-duplicate-string */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonNavbarService {
  hideNavbar = false;

  constructor() {}
}
