<div *ngIf="isLoading" class="loading">
  <app-loading [width]="'200px'"></app-loading>
</div>
<div class="workspaces-container" *ngIf="!isLoading">
  <div class="top">
    <div class="top-left">
      <p>{{ "workspaces" | translate }}</p>
      <i class="ra-icon-ide-md-info pointer"></i>
    </div>
    <div class="top-right">
      <app-secondary-button
        id="refresh-button"
        [label]="'workspaceList.refresh' | translate"
        [icon]="'ra-icon-ide-md-refresh'"
        [noBorder]="true"
        (clickEvent)="onRefresh()"
        [debounceTime]="500"
      ></app-secondary-button>
      <app-primary-button-flourish
        id="create-workspace-button"
        [label]="'workspaceList.create' | translate"
        (clickEvent)="onCreateWorkspace()"
        [isDisabled]="!isQuotaAvailable"
        [raUiTooltip]="
          !isQuotaAvailable ? ('quota.btnCreateWorkspace' | translate) : ''
        "
        [tooltipConfig]="tooltipConfig"
      ></app-primary-button-flourish>
    </div>
  </div>
  <div class="filters">
    <ra-ui-search-input
      #searchInput
      id="search"
      [config]="searchConfig"
      (searchTextChange)="filterByText($event)"
    >
    </ra-ui-search-input>
    <ra-ui-select
      [items]="selectStatus"
      [value]="selectedStatus"
      label="{{ 'workspaceList.status' | translate }}"
      (valueChange)="filterByStatus($event)"
    >
    </ra-ui-select>
  </div>
  <div *ngIf="workspaceList.length > 0">
    <app-workspace-tile
      class="worstation-card-tile"
      *ngFor="let workspace of workspaceList; let i = index"
      [workspace]="workspace"
      (openWorkspace)="onOpenWorkspace(workspace)"
      (editWorkspace)="onEditWorkspace(workspace)"
      (deleteWorkspace)="
        onDeleteWorkspace(workspace, { content: dialogContent })
      "
    >
      <ng-template #dialogContent class="dialog-content">
        <div class="dialog-title">
          <img
            src="assets/images/icons/critical-warning-icon.svg"
            alt="critical-warning"
          />
          <strong>{{ "workspace.delete" | translate }}</strong>
        </div>
        <div class="dialog-message">
          <i
            >{{ "workspace.dialogMessage1" | translate }}
            <strong>{{ workspace.name }}.</strong>
            {{ "workspace.dialogMessage2" | translate }}</i
          >
        </div>
        <div class="dialog-input">
          <mat-form-field>
            <mat-label>{{ "workspace.dialogTextLabel" | translate }}</mat-label>
            <input
              id="dialog-delete-input"
              matInput
              maxlength="256"
              placeholder="{{ 'workspace.dialogTextPlaceholder' | translate }}"
              [value]="deleteWord"
              (keyup)="isDisabled($event)"
              required
            />
          </mat-form-field>
        </div>
        <div class="dialog-buttons">
          <app-primary-button-flourish
            id="submit-dialog-button"
            [label]="'workspace.yesDelete' | translate"
            [isDisabled]="isDisabledButton"
            (clickEvent)="onSubmitDialog(workspace, i)"
          >
          </app-primary-button-flourish>
          <app-secondary-button-flourish
            id="cancel-dialog-button"
            [label]="'workspace.cancel' | translate"
            (clickEvent)="onCancelDialog()"
          >
          </app-secondary-button-flourish>
        </div>
      </ng-template>
    </app-workspace-tile>
  </div>
  <app-dashed-layout
    *ngIf="workspaceList.length === 0"
    class="empty-workspace-dashboard"
    [image]="dashedLayoutImage"
    [title]="'createNewWorkspaceTitle' | translate"
    [text]="'createNewWorkspaceText' | translate"
    [height]="dashedLayoutHeight"
  >
  </app-dashed-layout>
  <div class="bottom">
    <div class="bottom-left"></div>
    <div class="bottom-right"></div>
  </div>
</div>
<div raUiToast [toastConfig]="toastConfig"></div>
<app-workspace-edit
  [panelIsOpen]="editingWorkspace"
  (panelIsOpenChange)="editingWorkspace = $event"
></app-workspace-edit>
