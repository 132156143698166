<app-title-step
  [iconTitle]="'ra-icon-ide-md-pull'"
  [labelTitle]="'pullFromVault.stepOneTitle' | translate"
  [numberStep]="2"
  [subtitle]="'pullFromVault.stepOneSubtitleEmpty' | translate"
>
</app-title-step>
<div *ngIf="repositories" class="content">
  <ra-ui-tree
    *ngIf="treeModel"
    id="id-repository-projects-tree-summary"
    [(tree)]="treeModel"
    [config]="config"
    (treeInitialized)="onTreeInitialized($event)"
  >
    <ng-template #node let-data="model" let-control="treeControl">
      <div class="template">
        <i *ngIf="!data.isParent" class="ra-icon-ide-md-color-check"></i>
        <i [class]="data.iconProject"></i>
        <p id="id-name" class="name-text">
          {{ data.label }}
        </p>
      </div>
    </ng-template>
  </ra-ui-tree>
</div>
