import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthActions } from './action-types';
import { tap, switchMap, catchError, takeWhile } from 'rxjs/operators';
import { UserProfile } from './user-profile.model';
import { Observable, combineLatest, of, throwError } from 'rxjs';
import { IGainsightUserProfile } from '../models/gainsight.model';
import { FeatureFlagFacade } from '../reducers/feature-flag/feature-flag.facade';
import { FeatureFlagTarget } from '../reducers/feature-flag/feature-flag.model';
import { GainsightFacade } from '../reducers/gainsight';
import { TenantInfoFacade } from '../reducers/tenant-info/tenant-info.facade';
import {
  ConfigService,
  TwinStudioConfiguration,
} from '../services/config/config.service';
import { TenantInfoService } from '../services/tenant-info.service';
import { UserService } from '../services/user.service';

@Injectable()
export class AuthEffects {
  login$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      switchMap((payload) => {
        const returnUrlTwin = 'returnUrlTwin';
        const returnUrl = sessionStorage.getItem(returnUrlTwin);
        if (
          returnUrl !== null &&
          returnUrl !== this.configService.config.appBaseUrl
        ) {
          sessionStorage.removeItem(returnUrlTwin);
          window.location.href = `${returnUrl}`;
        }
        return combineLatest([of(payload), this.route.queryParams]);
      }),
      switchMap(([payload, params]) => {
        return combineLatest([
          of(payload),
          of(params),
          this.userSevice.isEulaAccepted(),
        ]);
      }),
      switchMap(([payload, params, eulaAcepted]) => {
        if (!eulaAcepted) {
          window.location.href = this.configService.config.csPortalUrl;
        }
        const userProfile: UserProfile = payload.user;
        // If tenantid not passed, then take personal tenant as the default one
        const tenantId = params.tenantId
          ? params.tenantId
          : localStorage.getItem('tenantId') ?? userProfile.userId;
        const gainsightUserProfile: IGainsightUserProfile = {
          company: userProfile.company,
          email: String(userProfile.email),
          id: userProfile.userId,
          name: String(userProfile.name),
        };
        this.gainsightFacade.pushUserProfile(gainsightUserProfile);
        return combineLatest([
          of(payload),
          this.tenantInfoService.getTenantInfo(tenantId).pipe(
            catchError((error: unknown) => {
              //In the case the current tenant not found. Redirect to FTHub
              window.location.href = this.configService.config.csPortalUrl;
              return throwError(() => error);
            })
          ),
        ]);
      }),

      tap(([payload, currentTenantInfo]) => {
        if (!this.featureFlagFacade.isInitialized) {
          this.featureFlagFacade.initializeTarget({
            identifier: payload.user.email,
            name: payload.user.name,
            attributes: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              currentTenantId: currentTenantInfo.id,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              currentTenantName: currentTenantInfo.name,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              type: 'User',
            },
          } as FeatureFlagTarget);
        }
        this.tenantFacade.updateSelection(currentTenantInfo);
      }),
      // Preventing keep asking for login success since we are using switchMap
      takeWhile((payload) => !payload)
    );
  });

  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.logout),
        tap((action) => {
          localStorage.removeItem('user');
          this.router.navigateByUrl('/');
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly featureFlagFacade: FeatureFlagFacade,
    private readonly tenantFacade: TenantInfoFacade,
    private readonly tenantInfoService: TenantInfoService,
    private readonly route: ActivatedRoute,
    private readonly userSevice: UserService,
    private readonly configService: ConfigService<TwinStudioConfiguration>,
    private readonly gainsightFacade: GainsightFacade
  ) {}
}
