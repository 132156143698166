import { TenantInfoActions } from './action-types';
import { createReducer, on } from '@ngrx/store';
import { TenantInfo } from '../../models/cs/tenant-info.model';

export interface TenantInfoState {
  tenantInfo?: TenantInfo;
}

export const initialTenantInfoState: TenantInfoState = {
  tenantInfo: undefined,
};

export const tenantInfoReducer = createReducer(
  initialTenantInfoState,

  on(
    TenantInfoActions.setTenantInfoSuccess,
    (state, action): TenantInfoState => {
      localStorage.setItem('tenantId', action.tenantInfo.id);
      return {
        tenantInfo: action.tenantInfo,
      };
    }
  )
);
