import { Result, failed, succeededResult } from 'src/app/shared/utility/result';
import { SolutionId } from '../vault/solution.model';
import { VaultId } from '../vault/vault.model';
import { validateString } from 'src/app/shared/utility/validators';
import { executeWhileSuccessful } from 'src/app/shared/utility/result-helpers';
import { UpgradeOption } from '../upgrade-option.model';
import { ControllerProjectType } from '../controller-project-type.model';
import { TenantId } from '../cs/tenant-info.model';

export type ProjectId = string;
export type ProjectName = string;
export type DisplayText = string;

// This not only defines all the expected files types, but determines the order
// in which messages for unavailable files are displayed.
export enum FileTypes {
  ACD = 'ACD',
  L5K = 'L5K',
  L5X = 'L5X',
  AML = 'AML',
  RDF = 'RDF',
  FTDS = 'FTDS',
  FTDSDataSource = 'FTDS_DataSource',
  FTDSExport = 'FTDS_Export',
  DEMO3D = 'DEMO3D',
  DOE = 'DOE',
  ASSET = 'ASSET',
  VPD = 'VPD',
}

/**
 * Project status colors
 */
export enum ProjStatusColors {
  Red = '#FF0000',
  Yellow = '#FFCC00',
  Green = '#12850A',
  Gray = 'gray',
}

export enum ProjStatusLabels {
  Error = 'Error',
  Migrating = 'Migrating',
  Processing = 'Processing',
  Ready = 'Ready',
  Upgrading = 'Upgrading',
  Uploading = 'Uploading',
  Unavailable = '',
}

export interface ProjViewStatus {
  label: ProjStatusLabels;
  color: ProjStatusColors;
}

/**
 * Supported project types, defined new types here, should match 'type' property value from server payload
 */
export enum ProjType {
  Arena = 'Arena',
  Echo = 'Echo',
  Asset = 'Asset',
  Emulate3D = 'Emulate3D',
  Eplan = 'EPLAN',
  FTDesignStudio = 'FTDesignStudio',
  LogixDesigner = 'LogixDesigner',
  None = 'None',
  Optix = 'Optix',
  ViewDesigner = 'ViewDesigner',
  ViewSeClient = 'ViewSeClient',
  ViewSeServer = 'ViewSeServer',
  FTRAManager = 'FTRAManager',
}

export const projExtension: Record<string, ProjType> = {
  doe: ProjType.Arena,
  demo3d: ProjType.Emulate3D,
  ftds: ProjType.FTDesignStudio,
  acd: ProjType.LogixDesigner,
};

export enum ProjectStatus {
  Deleted = 'Deleted',
  Error = 'Error',
  Migrating = 'Migrating',
  MovedFromSolution = 'MovedFromSolution',
  Processing = 'Processing',
  Ready = 'Ready',
  ReadyWithErrors = 'ReadyWithErrors',
  Upgrading = 'Upgrading',
  UploadError = 'UploadError',
  Uploading = 'Uploading',
}

export interface ProjectDto {
  correlationId: string;
  projectId: ProjectId;
  vaultId: VaultId;
  solutionId: SolutionId;
  tenantId: TenantId;
  tenantName?: string;
  name: ProjectName;
  description: string;
  status: ProjectStatus;
  projectHistory: ControllerProjectType[];
  purgeAfterDate?: Date;
  movedToTrashDate?: Date;
  type: ProjType;
  thumbnailUrl?: string;
}

export class Project {
  constructor(
    public projectId: ProjectId,
    public vaultId: VaultId,
    public solutionId: SolutionId,
    public tenantId: TenantId,
    public name: ProjectName,
    public description: string,
    public status: ProjectStatus,
    public projectHistory: ControllerProjectType[],
    public type: ProjType,
    public tenantName?: string,
    public purgeAfterDate?: Date,
    public movedToTrashDate?: Date,
    public thumbnailUrl?: string
  ) {}

  get activeProgram(): ControllerProjectType | undefined {
    return this?.projectHistory?.find((cpt) => cpt.isActive);
  }

  public static fromDto(dto: ProjectDto): Result<Project, string | undefined> {
    const dtoValidation = executeWhileSuccessful(
      () => validateString(dto.projectId, 'projectId'),
      () => validateString(dto.vaultId, 'vaultId'),
      () => validateString(dto.solutionId, 'solutionId'),
      () => validateString(dto.tenantId, 'tenantId'),
      () => validateString(dto.name, 'name'),
      () => validateString(dto.status, 'status')
    );

    if (failed(dtoValidation)) {
      // console log here, validation occurs before interna logger init,
      // errors thrown on config validation failure will break app init.
      // eslint-disable-next-line no-console
      console.error('project:', dtoValidation);
    }

    return succeededResult(
      new Project(
        dto.projectId,
        dto.vaultId,
        dto.solutionId,
        dto.tenantId,
        dto.name,
        dto.description,
        dto.status,
        dto.projectHistory,
        dto.type,
        dto.tenantName,
        dto.purgeAfterDate,
        dto.movedToTrashDate,
        dto.thumbnailUrl
      )
    );
  }

  public static empty(type: ProjType = ProjType.LogixDesigner): Project {
    return new Project(
      '',
      '',
      '',
      '',
      'Logix',
      '',
      ProjectStatus.Processing,
      [],
      type,
      undefined,
      undefined,
      undefined,
      undefined
    );
  }

  public static emptyArena(type: ProjType = ProjType.Arena): Project {
    return new Project(
      '',
      '',
      '',
      '',
      'Arena',
      '',
      ProjectStatus.Processing,
      [],
      type,
      undefined,
      undefined,
      undefined,
      undefined
    );
  }

  public static emptyEmulate(type: ProjType = ProjType.Emulate3D): Project {
    return new Project(
      '',
      '',
      '',
      '',
      'Emulate3D',
      '',
      ProjectStatus.Processing,
      [],
      type,
      undefined,
      undefined,
      undefined,
      undefined
    );
  }

  public static emptyViewDesigner(
    type: ProjType = ProjType.ViewDesigner
  ): Project {
    return new Project(
      '',
      '',
      '',
      '',
      'ViewDesigner',
      '',
      ProjectStatus.Processing,
      [],
      type,
      undefined,
      undefined,
      undefined,
      undefined
    );
  }

  public static isProjectStatusTransisioning(
    statusProject: ProjStatusLabels | undefined
  ): boolean {
    return (
      statusProject === ProjStatusLabels.Upgrading ||
      statusProject === ProjStatusLabels.Migrating ||
      statusProject === ProjStatusLabels.Processing ||
      statusProject === ProjStatusLabels.Uploading ||
      statusProject === ProjStatusLabels.Unavailable
    );
  }
}

export class ProjectData extends Project {
  //this is just for upgrade operation logic. It's not required in the api
  controllerTypeSelected?: string;
  upgradeOptions?: UpgradeOption;
  viewStatus?: ProjStatusLabels;

  // This is for appstream when creating new files only
  isNewFile?: boolean;
  projectFileName?: string;
}

export interface Properties {
  source: string;
  exportedSource: string;
  exportedCommit: ExportedCommit;
  exportedFiles?: [];
}

export interface ExportedCommit {
  id: string;
  message: string;
  author: UserCommit;
  committer: UserCommit;
}

export interface UserCommit {
  name: string;
  email: string;
  date: Date;
}

/**
 * Labels displayed in the project view
 */
export const projTypeLabel: Record<ProjType, DisplayText> = {
  [ProjType.Arena]: 'Arena',
  [ProjType.Echo]: 'Echo',
  [ProjType.Asset]: 'Asset',
  [ProjType.Emulate3D]: 'Emulate3D',
  [ProjType.Eplan]: 'ePlan',
  [ProjType.FTDesignStudio]: 'FT Design Studio',
  [ProjType.LogixDesigner]: 'Logix Designer',
  [ProjType.Optix]: 'Optix',
  [ProjType.ViewDesigner]: 'View Designer',
  [ProjType.ViewSeClient]: 'View Se Client',
  [ProjType.ViewSeServer]: 'View Se Server',
  [ProjType.FTRAManager]: 'FT Activation Manager',
  [ProjType.None]: '', // This is used for the empty project displayed in the view
};
