import { Injectable } from '@angular/core';
import {
  ApiRouteDefinition,
  AuthConfig,
  HttpInterceptorConfig,
} from '@auth0/auth0-angular';
import { CoreConfiguration } from '@rockwell-automation-inc/service';

export interface TwinStudioConfiguration extends CoreConfiguration {
  production: boolean;
  name: string;
  appBaseUrl: string;
  ftHubConnection: string;

  /**
   * This is the configuration used for web login flow for an application that is registered with factorytalkhub.us.auth0.com identity provider.
   */
  authConfig: AuthConfiguration;
  /**
   * This is an optional auth configuration for web login flow for an application that is registered outside factorytalkhub.us.auth0.com identity provider.
   */
  customAuthConfigForLogin?: AuthConfiguration;
  /**
   * The URL where Auth0 will redirect your browser to after the logout.
   *
   * **Note**: If the `client_id` parameter is included, the
   * `returnTo` URL that is provided must be listed in the
   * Application's "Allowed Logout URLs" in the Auth0 dashboard.
   * However, if the `client_id` parameter is not included, the
   * `returnTo` URL must be listed in the "Allowed Logout URLs" at
   * the account level in the Auth0 dashboard.
   *
   * [Read more about how redirecting after logout works](https://auth0.com/docs/logout/guides/redirect-users-after-logout)
   */
  returnToAfterLogout?: string;

  lCoreApiBaseUrl: string;
  notificationApiUrl: string;
  onlineHelp: string;
  gettingStarted: string;
  releaseNotes: string;
  contactSupport: string;
  privacyPolicy: string;
  legal: string;
  termsOfUse: string;
  licenseAgreement: string;
  factoryTalkHubBaseUrl: string;
  auth0ConnectionType: string;
  harnessSDK: string;
  logixLarge: string;
  logixLargeCpu: string;
  logixLargeMemory: string;
  arenaLarge: string;
  arenaLargeCpu: string;
  arenaLargeMemory: string;
  arenaMedium: string;
  arenaMediumCpu: string;
  arenaMediumMemory: string;
  arenaSmall: string;
  arenaSmallCpu: string;
  arenaSmallMemory: string;
  emulateLarge: string;
  emulateLargeCpu: string;
  emulateLargeMemory: string;
  emulateMedium: string;
  emulateMediumCpu: string;
  emulateMediumMemory: string;
  emulateSmall: string;
  emulateSmallCpu: string;
  emulateSmallMemory: string;
  ftraSmall: string;
  ftraSmallCpu: string;
  ftraSmallMemory: string;
}

export class AuthConfiguration implements AuthConfig {
  /**
   * Your Auth0 account domain such as `'example.auth0.com'`,
   * `'example.eu.auth0.com'` or , `'example.mycompany.com'`
   * (when using [custom domains](https://auth0.com/docs/custom-domains))
   */
  domain: string = '';
  /**
   * The Client ID found on your Application settings page
   */
  clientId: string = '';
  /**
   * The default URL where Auth0 will redirect your browser to with
   * the authentication result. It must be added to the
   * "Allowed Callback URLs" field in your Auth0 Application's
   * settings. If not provided here, it should be provided in the other
   * methods that provide authentication.
   */
  redirectUri?: string;
  /**
   * The default scope to be used on authentication requests.
   * The defaultScope defined in the Auth0Client is included
   * along with this scope
   */
  scope?: string;
  /**
   * The default audience to be used for requesting API access.
   */
  audience?: string;
  /**
   * Configuration for the built-in Http Interceptor, used for
   * automatically attaching access tokens.
   */
  httpInterceptor?: HttpInterceptorConfiguration;
  /**
   * Path in your application to redirect to when the Authorization server
   * returns an error. Defaults to `/`
   */
  errorPath?: string;
  /**
   * The name of the connection configured for your application.
   * If null, it will redirect to the Auth0 Login Page and show
   * the Login Widget.
   */
  connection?: string;
  /**
   * If true, refresh tokens are used to fetch new access tokens from the Auth0 server.
   * If false, the legacy technique of using a hidden iframe and the `authorization_code` grant with `prompt=none` is used.
   * The default setting is `false`.
   *
   * **Note**: Use of refresh tokens must be enabled by an administrator on your Auth0 client application.
   */
  useRefreshToken?: boolean;
  /**
   * The location to use when storing cache data. Valid values are `memory` or `localstorage`.
   * The default setting is `memory`.
   */
  cacheLocation?: 'memory' | 'localstorage';
}

export class HttpInterceptorConfiguration implements HttpInterceptorConfig {
  allowedList: ApiRouteDefinition[] = [];
}

@Injectable({ providedIn: 'root' })
export class ConfigService<T extends TwinStudioConfiguration> {
  config!: T;
  constructor() {}
  public setConfig(config: T): any {
    this.config = config;
  }
}
