import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesignHubTableComponent } from './design-hub-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/material.module';
import { TooltipIfTruncatedModule } from '../../shared/tooltip-if-truncated/tooltipIfTruncated.module';
import { FileUploadProgressModule } from '../workstation-menu/upload/local-files/file-upload-progress/file-upload-progress.module';
import { DropdownListModule } from '../../shared/components/dropdown-list/dropdown-list.module';
import { FormsModule } from '@angular/forms';
import { RaUiBadgeModule } from '@ra-web-tech-ui-toolkit/indicators/badge';

@NgModule({
  declarations: [DesignHubTableComponent],
  imports: [
    CommonModule,
    FileUploadProgressModule,
    DropdownListModule,
    MaterialModule,
    TooltipIfTruncatedModule,
    TranslateModule,
    FormsModule,
    RaUiBadgeModule,
  ],
  exports: [DesignHubTableComponent],
})
export class DesignHubTableModule {}
