import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeFolderComponent } from './home-folder.component';
import { RaUiTreeModule } from '@ra-web-tech-ui-toolkit/lists/tree';

@NgModule({
  declarations: [HomeFolderComponent],
  imports: [CommonModule, RaUiTreeModule],
  exports: [HomeFolderComponent],
})
export class HomeFolderModule {}
