import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MenuSelectorItem } from '../../../core/models/menu-selector.model';

@Component({
  selector: 'app-menu-selector',
  templateUrl: './menu-selector.component.html',
  styleUrls: ['./menu-selector.component.scss'],
})
export class MenuSelectorComponent
implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  private readonly _onDestroy = new Subject<void>();

  @ViewChild('singleSelect', { static: true }) singleSelect!: MatSelect;

  @Input() initialSelection?: MenuSelectorItem;
  @Input() inputId: string = 'menu-selector-id';
  @Input() label!: string;
  @Input() placeholderLabel!: string;
  @Input() noEntriesFoundLabel!: string;
  @Input() data: MenuSelectorItem[] = [];
  @Input() tooltip: string = '';
  @Output() selectionChanged = new EventEmitter<MenuSelectorItem>();

  filteredData: ReplaySubject<MenuSelectorItem[]> = new ReplaySubject<
    MenuSelectorItem[]
  >(1);

  dataCtrl: FormControl = new FormControl();
  dataFilterCtrl: FormControl = new FormControl();

  constructor() {}

  /**
   * Filter the strings shown in the dropdown based on what's in the input
   * field thus far.
   */
  private filterData() {
    if (!this.data) {
      return;
    }

    let search = this.dataFilterCtrl.value;
    if (!search) {
      this.filteredData.next(this.data.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredData.next(
      this.data.filter((item) =>
        item.displayText.toLowerCase().includes(search)
      )
    );
  }

  private setInitialValue() {
    this.filteredData
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (
          a: MenuSelectorItem,
          b: MenuSelectorItem
        ) => a && b && a.displayText === b.displayText;
      });
  }

  /**
   * Things we do when our inputs change (need this for clients that may lazy
   * load their data).
   *
   * @param changes Things that have changed.
   */
  ngOnChanges(changes: SimpleChanges): void {
    // When 'data' changes, reset the current value and kick our filtered data
    // control.
    if (changes.data?.currentValue !== changes.data?.previousValue) {
      if (changes.data.previousValue) {
        this.dataCtrl.setValue(undefined);
      }

      this.filteredData.next(this.data.slice());
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit(): void {
    this.dataCtrl.setValue(
      this.data.find(
        (item) => item.displayText === this.initialSelection?.displayText
      )
    );
    this.dataFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterData();
      });
  }

  ngAfterViewInit(): void {
    this.setInitialValue();
  }

  /**
   * Called when the user makes a selection. Used to notify our client of
   * the changes.
   *
   * @param event Event carrying the new user selection.
   */
  onSelectionChanged(event: MatSelectChange): void {
    const selectedItem: MenuSelectorItem = event.value;
    if (selectedItem) {
      this.selectionChanged.emit(selectedItem);
    }
  }
}
