import {
  Project,
  ProjectStatus,
  ProjStatusLabels,
  ProjStatusColors,
} from '../../../models/vault/project.model';
import { isFileErrored } from './project-file-utility';

// If colors are the same for all projects this can be moved
// to a different location.
export const statusColors: Record<ProjStatusLabels, ProjStatusColors> = {
  [ProjStatusLabels.Error]: ProjStatusColors.Red,
  [ProjStatusLabels.Migrating]: ProjStatusColors.Gray,
  [ProjStatusLabels.Processing]: ProjStatusColors.Gray,
  [ProjStatusLabels.Ready]: ProjStatusColors.Green,
  [ProjStatusLabels.Upgrading]: ProjStatusColors.Gray,
  [ProjStatusLabels.Uploading]: ProjStatusColors.Gray,
  [ProjStatusLabels.Unavailable]: ProjStatusColors.Gray,
};

export class LogixViewStatus {
  constructor() {}

  getColorByStatus(status: ProjStatusLabels): ProjStatusColors {
    return statusColors[status];
  }

  getProjectStatusLabel(project: Project): ProjStatusLabels {
    if (this.isProcessingViewStatus(project)) {
      return ProjStatusLabels.Processing;
    }

    if (this.isErrorViewStatus(project)) {
      return ProjStatusLabels.Error;
    }

    if (this.isReadyViewStatus(project)) {
      return ProjStatusLabels.Ready;
    }

    if (this.isUploadingViewStatus(project)) {
      return ProjStatusLabels.Uploading;
    }

    if (this.isUpgradingViewStatus(project)) {
      return ProjStatusLabels.Upgrading;
    }

    if (this.isMigratingViewStatus(project)) {
      return ProjStatusLabels.Migrating;
    }

    return ProjStatusLabels.Unavailable;
  }

  private isProcessingViewStatus(project: Project): boolean {
    const hasPendingConvert =
      project.activeProgram?.files?.some((x) =>
        x.status.includes('PendingFileConvert')
      ) === true;
    return (
      project.status === ProjectStatus.Processing ||
      (project.status === ProjectStatus.Ready && hasPendingConvert)
    );
  }

  private isErrorViewStatus(project: Project): boolean {
    const hasFileError =
      project.activeProgram?.files?.some((f) => isFileErrored(f)) === true;
    return (
      project.status === ProjectStatus.Error ||
      project.status === ProjectStatus.UploadError ||
      hasFileError
    );
  }

  private isReadyViewStatus(project: Project): boolean {
    const hasRevision =
      project.activeProgram?.logixRevision?.toString().length !== undefined;
    return (
      (project.status === ProjectStatus.Ready && hasRevision) ||
      project.status === ProjectStatus.ReadyWithErrors
    );
  }

  private isUploadingViewStatus(project: Project): boolean {
    const hasRevision =
      project.activeProgram?.logixRevision?.toString().length !== undefined;
    return (
      project.status === ProjectStatus.Uploading ||
      (project.status === ProjectStatus.Ready && !hasRevision)
    );
  }

  private isUpgradingViewStatus(project: Project): boolean {
    const ftdsEnabled = project.activeProgram?.ftdsMigrationEnabled === true;
    return project.status === ProjectStatus.Upgrading && !ftdsEnabled;
  }

  private isMigratingViewStatus(project: Project): boolean {
    const ftdsEnabled = project.activeProgram?.ftdsMigrationEnabled === true;
    return project.status === ProjectStatus.Upgrading && ftdsEnabled;
  }
}
