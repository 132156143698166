import { Injectable } from '@angular/core';
import { SessionActions } from './action-types';
import {
  PreloadSessionData,
  PreloadSessionDataRecord,
  SessionsActiveRecord,
} from './session.reducer';
import { Store } from '@ngrx/store';
import {
  selectBrowseSelectionData,
  selectSessionData,
  selectPreuploadedFiles,
} from './session.selectors';
import { Observable, skipWhile } from 'rxjs';
import { Session } from '../../models/twin-studio.model';

@Injectable({
  providedIn: 'root',
})
export class SessionFacade {
  preloadSessionData$: Observable<PreloadSessionDataRecord> = this.store.select(
    selectBrowseSelectionData
  );

  preUploadedFiles$: Observable<string[]> = this.store.select(
    selectPreuploadedFiles
  );

  sessionData$: Observable<SessionsActiveRecord> = this.store
    .select(selectSessionData)
    .pipe(skipWhile((selectSessionData) => selectSessionData === undefined));

  constructor(private readonly store: Store) {}

  preloadBrowseSelection(preloadSessionData: PreloadSessionData): void {
    this.store.dispatch(
      SessionActions.preloadBrowseSelection({
        preloadSessionData: preloadSessionData,
      })
    );
  }

  startSession(
    preloadSessionData: PreloadSessionData,
    sessionData: Session
  ): void {
    this.store.dispatch(
      SessionActions.startSession({ preloadSessionData, sessionData })
    );
  }

  updateDataSession(sessionData: Session): void {
    this.store.dispatch(SessionActions.updateDataSession({ sessionData }));
  }

  endSession(sessionData: Session): void {
    this.store.dispatch(
      SessionActions.endSession({
        sessionData,
      })
    );
  }

  preUploadFilesToSession(fileNames: string[]): void {
    this.store.dispatch(
      SessionActions.preUploadFilesToSession({
        fileNames,
      })
    );
  }

  fileUploadedCompleted(fileName: string): void {
    this.store.dispatch(
      SessionActions.fileUploadedCompleted({
        fileName,
      })
    );
  }
}
