import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
import {
  IStepperButton,
  IStepperButtonsProvider,
  IStepperConfig,
  IStepperItem,
  StepperComponent,
} from '@ra-web-tech-ui-toolkit/common-views/stepper';
import { TenantInfoFacade } from '../../core/reducers/tenant-info/tenant-info.facade';
import { Subject, takeUntil } from 'rxjs';
import { SidenavService } from '../../core/services/sidenav.service';
import { SelectProjectComponent } from './select-project/select-project.component';
import { SelectLocationComponent } from './select-location/select-location.component';
import { SummaryComponent } from './summary/summary.component';
import { ActionButtonStyles } from '@ra-web-tech-ui-toolkit/cdk/types';
import { SideBarService } from '../../core/services/side-bar/side-bar.service';
import { SideBarItemSelection } from '../navigation/sidebar/sidebar-options/sidebar-options.component';
import { ActivatedRoute } from '@angular/router';
import { PullService } from '../../core/services/pull/pull.service';
import { PullFilesProjects } from '../../core/models/twin-studio.model';

@Component({
  selector: 'app-pull-from-vault',
  templateUrl: './pull-from-vault.component.html',
  styleUrls: ['./pull-from-vault.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PullFromVaultComponent
implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('sidenav') sidenav!: MatSidenav;
  @ViewChild('stepper') stepperCmp!: StepperComponent;
  @Output() continueWithSession = new EventEmitter<boolean>();

  private readonly destroy$: Subject<void> = new Subject();

  isSelectLocationDisabled: boolean = true;

  items!: IStepperItem[];
  buttonsStep!: IStepperButton[];
  buttonsProvider!: IStepperButtonsProvider;
  stepperConfig: IStepperConfig = {
    enableSummary: false,
  };

  tenantId: string | undefined = undefined;
  goHomeUrl = '/';

  firstStepLabel = this.translateSvc.instant('pullFromVault.selectProjects');
  secondStepLabel = this.translateSvc.instant('pullFromVault.selectLocation');
  thirdStepLabel = this.translateSvc.instant('pullFromVault.summary');
  nextButtonLabel = this.translateSvc.instant('pullFromVault.nextButton');
  backButtonLabel = this.translateSvc.instant('pullFromVault.backButton');
  pullButtonLabel = this.translateSvc.instant('pullFromVault.pullButton');
  exitButtonLabel = this.translateSvc.instant('pullFromVault.exitButton');
  finishButtonLabel = this.translateSvc.instant('pullFromVault.finishButton');

  constructor(
    private readonly translateSvc: TranslateService,
    private readonly tenantInfoFacade: TenantInfoFacade,
    private readonly sidenavService: SidenavService,
    public sidebarService: SideBarService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly pullService: PullService
  ) {
    this.tenantInfoFacade.tenantId$
      .pipe(takeUntil(this.destroy$))
      .subscribe((tenantId) => (this.tenantId = tenantId));
  }

  ngOnInit(): void {
    this.initializeStepper();
  }

  ngAfterViewInit(): void {
    this.subscribeStepperButtonClick();
    this.sidenavService.setSidenav(this.sidenav);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initializeStepper(): void {
    this.items = this.getSteps();
    this.buttonsProvider = {
      getButtons: (selectedIndex: number) => {
        return selectedIndex === 1 && !this.isSelectLocationDisabled
          ? this.getStepButton(selectedIndex)
          : this.getStepButton(selectedIndex + 1);
      },
    };
  }

  getSteps(): IStepperItem[] {
    const steps: IStepperItem[] = [
      {
        title: this.firstStepLabel,
        component: SelectProjectComponent,
        data: {
          tenantId: this.tenantId,
          filesToPull: [],
        },
      },
    ];
    if (!this.isSelectLocationDisabled) {
      steps.push({
        title: this.secondStepLabel,
        component: SelectLocationComponent,
        data: {
          tenantId: this.tenantId,
        },
      });
    }
    steps.push({
      title: this.thirdStepLabel,
      component: SummaryComponent,
      data: {
        tenantId: this.tenantId,
        filesPulled: [],
      },
    });
    return steps;
  }

  getStepButton(step: number): IStepperButton[] {
    this.buttonsStep = [];
    switch (step) {
      case 1:
        this.buttonsStep = [
          !this.isSelectLocationDisabled
            ? {
              label: this.nextButtonLabel,
              buttonStyle: ActionButtonStyles.Main,
              disabled: true,
            }
            : {
              label: this.pullButtonLabel,
              buttonStyle: ActionButtonStyles.Main,
              disabled: true,
            },
          {
            label: this.exitButtonLabel,
            buttonStyle: ActionButtonStyles.Outlined,
          },
        ];
        break;
      case 2:
        this.buttonsStep = [
          {
            label: this.finishButtonLabel,
            buttonStyle: ActionButtonStyles.Main,
          },
        ];
        break;

      default:
        this.buttonsStep = [
          {
            label: this.pullButtonLabel,
            buttonStyle: ActionButtonStyles.Main,
            disabled: true,
          },
          {
            label: this.exitButtonLabel,
            buttonStyle: ActionButtonStyles.Outlined,
          },
        ];
        break;
    }
    return this.buttonsStep;
  }

  private subscribeStepperButtonClick() {
    this.stepperCmp?.onButtonClick
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        if (event.button.label === this.nextButtonLabel) {
          this.stepperCmp.moveToNext();
        }
        if (event.button.label === this.backButtonLabel) {
          this.stepperCmp.moveToPrevious();
        }
        if (event.button.label === this.exitButtonLabel) {
          if (this.activatedRoute.snapshot.paramMap.get('tenantId')) {
            this.continueWithSession.emit(true);
          } else {
            this.sidebarService.currentSideBarSelection =
              SideBarItemSelection.Home;
            sessionStorage.setItem(
              'SideBarItemSelection',
              SideBarItemSelection.Home
            );
          }
        }
        if (event.button.label === this.pullButtonLabel) {
          this.pullFiles();
        }
        if (event.button.label === this.finishButtonLabel) {
          if (this.activatedRoute.snapshot.paramMap.get('tenantId')) {
            this.continueWithSession.emit(true);
          } else {
            this.sidebarService.currentSideBarSelection =
              SideBarItemSelection.Home;
            sessionStorage.setItem(
              'SideBarItemSelection',
              SideBarItemSelection.Home
            );
          }
        }
      });
  }
  pullFiles(): void {
    const payload: PullFilesProjects[] = [];

    const filesToPull = this.stepperCmp.items[0].data.filesToPull;

    filesToPull.forEach((solution: any) => {
      const solutionName = solution.repositoryName;
      solution.filesSelected.forEach((file: any) => {
        payload.push({
          projectId: file.projectId,
          projectName: file.projectName,
          historyId: file.historyId,
          fileName: file.file,
          solutionName: solutionName,
        });
      });
    });

    this.pullService
      .pullFiles(payload, this.stepperCmp.items[0].data.tenantId)
      .subscribe(() => {
        this.stepperCmp.items[
          this.stepperCmp.items.length - 1
        ].data.filesPulled = this.stepperCmp.items[0].data.filesToPull;
        this.stepperCmp.moveToNext();
      });
  }
}
