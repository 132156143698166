import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[matTooltip][appTooltipIfTruncated]',
})
export class TooltipIfTruncatedDirective implements OnInit, OnDestroy {
  private element!: HTMLElement;
  private resizeObserver!: ResizeObserver;

  constructor(
    private matTooltip: MatTooltip,
    private readonly elementRef: ElementRef<HTMLElement>
  ) {}

  private setTooltip(element: HTMLElement) {
    this.matTooltip.disabled = element.scrollWidth <= element.clientWidth;
  }

  ngOnInit(): void {
    this.element = this.elementRef.nativeElement;
    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((_) => {
        this.setTooltip(this.element);
      });
    });

    this.resizeObserver.observe(this.element);
  }

  ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.element);
  }
}
