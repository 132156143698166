import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { Validators } from '@angular/forms';
import { ISelectItem } from '@ra-web-tech-ui-toolkit/form-system/select';
import { IErrorMessages } from '@ra-web-tech-ui-toolkit/cdk/types';
import {
  RaUiFormControl,
  RaUiFormGroup,
} from '@ra-web-tech-ui-toolkit/form-system/form';
import { TranslateService } from '@ngx-translate/core';
import { Region } from '../../../core/models/twin-studio.model';
import { IInputConfig } from '@ra-web-tech-ui-toolkit/form-system/input';
import {
  STEPPER_ITEM_DATA,
  StepperComponent,
  StepperControl,
} from '@ra-web-tech-ui-toolkit/common-views/stepper';
import { WorkspaceFormData } from '../../../core/models/workspace-creation.model';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-select-region',
  templateUrl: './select-region.component.html',
  styleUrls: ['./select-region.component.scss'],
})
export class SelectRegionComponent implements OnInit, OnDestroy {
  regionSelected: any;
  formGroup = this.createFormGroup();
  inputConfig: IInputConfig = {
    enableDirtyFlag: true,
    fillParent: true,
  };
  private readonly destroy$: Subject<void> = new Subject();
  items: ISelectItem[] = [
    {
      id: 'america',
      label: 'America',
      value: Region.America,
      disabled: false,
    },
    {
      id: 'europe',
      label: 'Europe',
      value: Region.Europe,
      disabled: false,
    },
    {
      id: 'asia',
      label: 'Asia-Pacific',
      value: Region.Asia,
      disabled: false,
    },
  ];

  errorMessages: IErrorMessages = {
    required: this.translateService.instant('fieldRequired'),
  };

  constructor(
    @Inject(STEPPER_ITEM_DATA) public viewData: WorkspaceFormData,
    @Optional() private readonly stepperCmp: StepperComponent,
    private readonly stepperControl: StepperControl,
    private readonly translateService: TranslateService
  ) {
    this.subscribeFormChanges();
    this.subscribeFormStatusChanges();
    this.subscribeStepperButtonClick();
  }

  ngOnInit(): void {
    if (this.viewData) {
      this.formGroup.patchValue(this.viewData, { emitEvent: false });
      if (this.viewData.region) {
        this.stepperCmp._disabledButtons[0] = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private subscribeFormChanges() {
    this.formGroup.objectChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        _.merge(this.viewData, this.formGroup.value);
      });
  }

  private subscribeFormStatusChanges() {
    this.formGroup.statusChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((change: string) => {
        if (change === 'INVALID' || change === 'PENDING') {
          this.stepperControl.markItemAsInvalid();
        } else {
          this.stepperControl.markItemAsValid();
        }
      });
  }

  private subscribeStepperButtonClick() {
    this.stepperCmp.onButtonClick
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        if (!_.isEqual(event.button, this.stepperCmp._defaultButtons.exit)) {
          this.formGroup.markAllAsTouched();
          this.formGroup.updateValueAndValidity({ emitEvent: true });
        }
      });
  }

  private createFormGroup() {
    return new RaUiFormGroup({
      region: new RaUiFormControl(this.viewData.region, Validators.required),
    });
  }

  enableNextButton(): void {
    this.stepperCmp._disabledButtons[0] = false;
  }
}
