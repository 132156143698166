import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ConfigService,
  TwinStudioConfiguration,
} from './core/services/config/config.service';
import { ServiceModule } from '@rockwell-automation-inc/service';
import { ServiceConfigProvider } from './core/services/shared/service-config-provider';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { environment } from '../environments/environment';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { StoreModule } from '@ngrx/store';
import { reducers } from 'src/app/core/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { InitAppService } from './core/services/init-app.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MaterialModule } from './material.module';
import { MatDialogModule } from '@angular/material/dialog';
import { LayoutModule } from '@rockwell-automation-inc/layout';
import { AuthEffects } from './core/auth/auth.effects';
import { AuthenticationModule } from './core/auth/auth.module';
import { FeatureFlagEffects } from './core/reducers/feature-flag/feature-flag.effects';
import { GainsightEffects } from './core/reducers/gainsight';
import { TenantInfoEffects } from './core/reducers/tenant-info/tenant-info.effects';
import { localizationReducer } from './core/reducers/localization/localization.reducer';
import { SessionEffects } from './core/reducers/session/session.effects';
import { LocalizationEffects } from './core/reducers/localization/localization.effects';
import { SharedButtonModule } from './shared/components/buttons/shared-button.module';
import { ConfirmDialogModule } from './shared/components/confirm-dialog/confirm-dialog.module';
import { LoadingModule } from './shared/components/loading/loading.module';
import { WorkstationMenuModule } from './features/workstation-menu/workstation-menu.module';
import { InfoSidebarModule } from './shared/components/info-sidebar/info-sidebar.module';
import {
  createDefaultMultiBackend,
  SkyhookMultiBackendModule,
  HTML5ToTouch,
} from '@ra-web-tech-ui-toolkit/skyhook-multi-backend';
import { SkyhookDndModule } from '@ra-web-tech-ui-toolkit/skyhook-core';
import { TranslateHttpMultiLoader } from '@ra-web-tech-ui-toolkit/cdk/translate-http-multi-loader';
import { MainTemplateModule } from './features/main-template/main-template.module';
import { NavbarModule } from './navbar/navbar.module';

@Injectable({ providedIn: 'root' })
export class ServiceConfigFromApp implements ServiceConfigProvider {
  constructor(
    private readonly configService: ConfigService<TwinStudioConfiguration>
  ) {}
  get twinStudioConfiguration(): TwinStudioConfiguration {
    return this.configService.config;
  }
}

@Injectable()
export class AppTranslateLoader extends TranslateHttpMultiLoader {
  constructor(http: HttpClient) {
    super(http, [
      {
        prefix: '@ra-web-tech-ui-toolkit/buttons/assets/locals/',
        suffix: '.json',
      },
      {
        prefix: '@ra-web-tech-ui-toolkit/common-utils/assets/locals/',
        suffix: '.json',
      },
      {
        prefix: '@ra-web-tech-ui-toolkit/common-views/assets/locals/',
        suffix: '.json',
      },
      {
        prefix: '@ra-web-tech-ui-toolkit/form-system/assets/locals/',
        suffix: '.json',
      },
      {
        prefix: '@ra-web-tech-ui-toolkit/grid/assets/locals/',
        suffix: '.json',
      },
      {
        prefix: '@ra-web-tech-ui-toolkit/lists/assets/locals/',
        suffix: '.json',
      },
      {
        prefix: '@ra-web-tech-ui-toolkit/navigation/assets/locals/',
        suffix: '.json',
      },
      {
        prefix: '@ra-web-tech-ui-toolkit/popups/assets/locals/',
        suffix: '.json',
      },
      {
        prefix: '@rockwell-automation-inc/layout/assets/i18n-common/',
        suffix: '-US.json',
      },
      {
        prefix: 'assets/i18n/',
        suffix: '.json',
      },
    ]);
  }
}

// See documentation @ https://angular.io/api/core/APP_INITIALIZER
// WARNING: All errors need to be properly handled
function initializeApp(initializeAppServices: InitAppService): void {
  // intentionally orphan this promise
  initializeAppServices.init().catch((err) => {
    // eslint-disable-next-line no-console
    console.error('error initializing vault application', err);
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    NavbarModule,
    InfoSidebarModule,
    MainTemplateModule,
    WorkstationMenuModule,
    LayoutModule,
    ConfirmDialogModule,
    BrowserModule,
    LoadingModule,
    CommonModule,
    RouterModule,
    HttpClientModule,
    MaterialModule,
    BrowserAnimationsModule,
    MatDialogModule,
    AppRoutingModule,
    MatButtonModule,
    MatButtonToggleModule,
    AuthenticationModule,
    MaterialModule,
    AuthModule.forRoot(),
    ServiceModule.forRoot(environment.config as TwinStudioConfiguration),
    SharedButtonModule,
    StoreModule.forRoot(reducers),
    StoreModule.forFeature('localization', localizationReducer), //TODO Move this to Navbar Module when possible
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([
      AuthEffects,
      FeatureFlagEffects,
      SessionEffects,
      GainsightEffects,
      LocalizationEffects,
      TenantInfoEffects,
    ]),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: AppTranslateLoader,
      },
    }),
    LayoutModule,
    ServiceModule.forRoot(environment.config),
    SkyhookMultiBackendModule,
    SkyhookDndModule.forRoot({
      backend: createDefaultMultiBackend(),
      options: HTML5ToTouch,
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory:
        (configService: ConfigService<TwinStudioConfiguration>) => () =>
          configService.setConfig(environment.config),
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (initializeAppServices: InitAppService) => () =>
        initializeApp(initializeAppServices),
      deps: [InitAppService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
