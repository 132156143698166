import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterComponent } from './filter.component';
import {
  RaUiSearchInputModule,
  SearchInputFlatListPipe,
} from '@ra-web-tech-ui-toolkit/form-system/search-input';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [FilterComponent],
  exports: [FilterComponent],
  imports: [CommonModule, RaUiSearchInputModule, TranslateModule],
  providers: [SearchInputFlatListPipe],
})
export class FilterModule {}
