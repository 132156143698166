<div class="sm-container">
  <app-title-step
    [iconTitle]="'ra-icon-ide-md-push'"
    [labelTitle]="'pushToVault.btnPushToVault' | translate"
    [numberStep]="stepperCmp.items.length"
    [region]="stepperCmp.items[stepperCmp.items.length - 1].data.region"
    [subtitle]="'pushToVault.summary' | translate"
  >
  </app-title-step>

  <div class="tabs-filter">
    <div class="tabs">
      <ra-ui-navigation-tab
        id="navigation-tab-summary"
        [items]="tabList"
        [selectedIndex]="selectedTabIndex"
        (selectedIndexChange)="onSelectedIndexChange($event)"
      >
      </ra-ui-navigation-tab>
    </div>
    <app-filter
      class="search-input"
      id="search-input"
      [dataSource]="filterColumn"
      [searchInputLabel]="'pushToVault.summaryContent.filterLabel' | translate"
      (filterEmitter)="onFilteredData($event)"
    ></app-filter>
  </div>

  <div
    class="edge-case"
    *ngIf="
      this.stepperCmp.items[this.stepperCmp.items.length - 1].data
        .retryAttempts >= 3 && !this.successfulTabSelected
    "
  >
    <i id="icon-error" class="ra-icon-ide-sm-color-error edge-case-icon"></i>
    <ra-ui-static-text
      id="text-edge-case"
      class="edge-case-text"
      [value]="'pushToVault.summaryContent.edgeCase' | translate"
    ></ra-ui-static-text>
  </div>

  <div class="table" [hidden]="!successfulTabSelected">
    <app-ag-grid-table
      id="tableSuccess"
      [columnDef]="columnDefSuccess"
      [rowData]="successFiles"
    >
    </app-ag-grid-table>
  </div>

  <div class="table" [hidden]="successfulTabSelected">
    <app-ag-grid-table
      id="tableFailed"
      [columnDef]="columnDefFailed"
      [rowData]="failedFiles"
    >
    </app-ag-grid-table>
  </div>
</div>
