import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UploadFileModel } from '../../models/upload-file.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private readonly http: HttpClient) {}

  uploadBlobFile(uploadFile: UploadFileModel): Observable<any> {
    const file = uploadFile.file;
    const filedata = new Blob([file], { type: file.type });
    const options = {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/octet-stream',
      },
      reportProgress: true,
      observe: 'events' as 'body',
    };
    return this.http.put(uploadFile.sasUrl, filedata, options);
  }
}
