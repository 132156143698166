import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipMessageComponent } from './tooltip-message.component';
import { RaUiTooltipModule } from '@ra-web-tech-ui-toolkit/common-utils/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TooltipMessageComponent],
  imports: [CommonModule, RaUiTooltipModule, TranslateModule],
  exports: [TooltipMessageComponent],
})
export class TooltipMessageModule {}
