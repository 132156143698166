import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegionSelectorComponent } from './region-selector.component';
import { MenuSelectorModule } from 'src/app/shared/components/menu-selector/menu-selector.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [RegionSelectorComponent],
  exports: [RegionSelectorComponent],
  imports: [CommonModule, MenuSelectorModule, TranslateModule],
})
export class RegionSelectorModule {}
