import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectCurrentTenant,
  selectTenantId,
  selectTenantServices,
} from './tenant-info.selector';
import { skipWhile } from 'rxjs';
import { TenantInfoActions } from './action-types';
import { TenantInfo } from '../../models/cs/tenant-info.model';

@Injectable({
  providedIn: 'root',
})
export class TenantInfoFacade {
  tenantId$ = this.store
    .select(selectTenantId)
    .pipe(skipWhile((tenantId) => tenantId === undefined));

  tenantServices$ = this.store
    .select(selectTenantServices)
    .pipe(
      skipWhile((selectTenantServices) => selectTenantServices === undefined)
    );

  currentTenant$ = this.store
    .select(selectCurrentTenant)
    .pipe(skipWhile((currentTenant) => currentTenant === undefined));

  constructor(private readonly store: Store) {}

  updateSelection(newTenantInfo: TenantInfo): void {
    this.store.dispatch(
      TenantInfoActions.setTenantInfoSuccess({ tenantInfo: newTenantInfo })
    );
  }
}
