import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SessionState } from './session.reducer';

export const selectSessionState =
  createFeatureSelector<SessionState>('session');

export const selectBrowseSelectionData = createSelector(
  selectSessionState,
  (state: SessionState) => state.preloadSessionData
);

export const selectSessionData = createSelector(
  selectSessionState,
  (state: SessionState) => state.sessionsActive
);

export const selectPreuploadedFiles = createSelector(
  selectSessionState,
  (state: SessionState) => state.preUploadedFiles
);
