import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { SidebarData } from '../../../core/models/sidebar-data';
import { IIconButtonConfig } from '@ra-web-tech-ui-toolkit/buttons/button';
import { IconSize } from '@ra-web-tech-ui-toolkit/cdk/types';

@Component({
  selector: 'app-info-sidebar',
  templateUrl: './info-sidebar.component.html',
  styleUrls: ['./info-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InfoSidebarComponent {
  @Input() infoData!: SidebarData;
  @Output() closePanel = new EventEmitter();
  buttonConfig: IIconButtonConfig = {
    iconSize: IconSize.M,
  };
}
