import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuickAccessComponent } from './quick-access.component';
import { SharedButtonModule } from '../../shared/components/buttons/shared-button.module';
import { ApplicationCardModule } from '../dashboard/application-card/application-card.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [QuickAccessComponent],
  imports: [
    CommonModule,
    SharedButtonModule,
    ApplicationCardModule,
    TranslateModule,
  ],
  exports: [QuickAccessComponent],
})
export class QuickAccessModule {}
