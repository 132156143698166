import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsSummaryComponent } from './projects-summary.component';
import { TranslateModule } from '@ngx-translate/core';
import { RaUiTooltipModule } from '@ra-web-tech-ui-toolkit/common-utils/tooltip';

@NgModule({
  declarations: [ProjectsSummaryComponent],
  imports: [CommonModule, TranslateModule, RaUiTooltipModule],
  exports: [ProjectsSummaryComponent],
})
export class ProjectsSummaryModule {}
