import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsToVaultComponent } from './projects-to-vault.component';
import { TranslateModule } from '@ngx-translate/core';
import { DesignHubTableModule } from '../../../design-hub-table/design-hub-table.module';
import { LoadingModule } from '../../../../shared/components/confirm-dialog/loading/loading.module';
import { MaterialModule } from '../../../../material.module';
import { SharedButtonModule } from '../../../../shared/components/buttons/shared-button.module';
import { RegionSelectorModule } from '../../../../shared/components/region-selector/region-selector.module';
import { MenuSelectorModule } from '../../../../shared/components/menu-selector/menu-selector.module';
import { ProjectsSummaryModule } from '../../../projects-summary/projects-summary.module';
import { InfoSidebarModule } from '../../../../shared/components/info-sidebar/info-sidebar.module';

@NgModule({
  declarations: [ProjectsToVaultComponent],
  exports: [ProjectsToVaultComponent],
  imports: [
    CommonModule,
    TranslateModule,
    DesignHubTableModule,
    LoadingModule,
    MaterialModule,
    RegionSelectorModule,
    SharedButtonModule,
    MenuSelectorModule,
    ProjectsSummaryModule,
    InfoSidebarModule,
  ],
})
export class ProjectsToVaultModule {}
