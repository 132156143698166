import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AutoSignInComponent } from './auto-sign-in/auto-sign-in.component';

const routes: Routes = [
  {
    path: 'callback',
    component: AuthCallbackComponent,
  },
  {
    path: 'auth',
    children: [
      {
        path: 'auto-sign-in',
        component: AutoSignInComponent,
      },
    ],
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class AuthRoutingModule {}
