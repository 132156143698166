import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryComponent } from './summary.component';
import { TranslateModule } from '@ngx-translate/core';
import { RaUiStaticTextModule } from '@ra-web-tech-ui-toolkit/common-utils/static-text';
import { TitleStepModule } from '../../../shared/components/title-step/title-step.module';
import { RaUiNavigationTabModule } from '@ra-web-tech-ui-toolkit/navigation/navigation-tab';
import { RaUiSearchInputModule } from '@ra-web-tech-ui-toolkit/form-system/search-input';
import { FilterModule } from '../../../shared/components/filter/filter.module';
import { AgGridTableModule } from '../../../shared/components/ag-grid-table/ag-grid-table.module';
import { AgGridModule } from '@ag-grid-community/angular';
import { RaUiButtonModule } from '@ra-web-tech-ui-toolkit/buttons/button';

@NgModule({
  declarations: [SummaryComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RaUiStaticTextModule,
    TitleStepModule,
    RaUiNavigationTabModule,
    RaUiSearchInputModule,
    FilterModule,
    AgGridTableModule,
    AgGridModule,
    RaUiButtonModule,
  ],
})
export class SummaryModule {}
