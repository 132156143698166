import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { RouterModule } from '@angular/router';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { authReducer } from './reducers';
import { AuthGuard } from './auth.guard';

@NgModule({
  providers: [AuthGuard],
  imports: [
    AuthRoutingModule,
    CommonModule,
    RouterModule,
    StoreModule.forFeature('auth', authReducer),
  ],
  declarations: [AuthCallbackComponent],
  exports: [AuthCallbackComponent],
})
export class AuthenticationModule {}
