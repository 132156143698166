import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@rockwell-automation-inc/service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./core/auth/auth.module').then((m) => m.AuthenticationModule),
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./features/main-template/main-template.module').then(
        (m) => m.MainTemplateModule
      ),
  },
  {
    path: 'prelaunch/:sessionKind/:tenantId',
    loadChildren: () =>
      import('./features/prelaunch/prelaunch.module').then(
        (m) => m.PreLaunchModule
      ),
  },
  {
    path: 'workspace',
    loadChildren: () =>
      import('./workspace.module').then((m) => m.WorkspaceModule),
  },
  {
    path: 'workstation',
    loadChildren: () =>
      import('./workstation.module').then((m) => m.WorkstationModule),
  },
  {
    // fall back on login route for any unmatched routes
    path: '**',
    redirectTo: 'auth/login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
