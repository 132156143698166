import { Component, OnInit } from '@angular/core';
import { Service } from 'src/app/core/models/cs/tenant-info.model';
import { TenantInfoFacade } from 'src/app/core/reducers/tenant-info/tenant-info.facade';
import { tap } from 'rxjs/operators';
import {
  SidebarItem,
  SideBarItemSelection,
} from '../navigation/sidebar/sidebar-options/sidebar-options.component';
import { FeatureFlagFacade } from 'src/app/core/reducers/feature-flag/feature-flag.facade';
import { TenantInfo } from '../../core/models/cs/tenant-info.model';
import { KindFromTenantServices } from '../../core/models/twin-studio.model';
import { firstValueFrom } from 'rxjs';
import { SideBarService } from '../../core/services/side-bar/side-bar.service';

@Component({
  selector: 'app-main-template',
  templateUrl: './main-template.component.html',
  styleUrls: ['./main-template.component.scss'],
})
export class MainTemplateComponent implements OnInit {
  headerSvgPath = 'assets/images/twin-studio.svg';
  sideBarItemSelection = SideBarItemSelection;

  appName = 'FT Twin Studio';
  workspacesAvailable = false;
  isReady = false;
  newPushToVaultExp!: boolean;
  sidebarItems: SidebarItem[] = [];
  currentSidebarSelection!: SideBarItemSelection;
  servicesAvailable!: Service[];

  tenantServices$ = this.tenantInfoFacade.tenantServices$.pipe(
    tap((tenantServices) => {
      this.servicesAvailable = tenantServices;
      this.setPageData();
    })
  );

  currentTenant: TenantInfo;
  currentTenant$ = this.tenantInfoFacade.currentTenant$.pipe(
    tap((currentTenant) => {
      this.currentTenant = currentTenant;
    })
  );

  constructor(
    private readonly tenantInfoFacade: TenantInfoFacade,
    private readonly featureFlagFacade: FeatureFlagFacade,
    public sidebarService: SideBarService
  ) {
    this.featureFlagFacade.flags$.subscribe((flags) => {
      this.workspacesAvailable = flags?.twin_studio_pro as boolean;
      this.newPushToVaultExp = flags?.new_push_to_vault_exp as boolean;
      this.isReady = true;
    });
  }

  setPageData(): void {
    this.sidebarItems = [
      {
        text: SideBarItemSelection.Home,
        icon: 'ra-icon-ide-md-home',
        emitOnredirect: true,
        id: 'sidebar-home',
      },
    ];
    if (
      this.servicesAvailable.find(
        (x) =>
          x.kind === KindFromTenantServices.Logix ||
          x.kind === KindFromTenantServices.Arena ||
          x.kind === KindFromTenantServices.Emulate3d
      )
    ) {
      this.sidebarItems.push({
        text: SideBarItemSelection.UploadToQuickAccess,
        icon: 'ra-icon-ide-md-upload',
        emitOnredirect: true,
        id: 'sidebar-upload',
      });
      this.sidebarItems.push({
        text: SideBarItemSelection.Push,
        icon: 'ra-icon-ide-md-push',
        emitOnredirect: true,
        id: 'sidebar-push',
      });
      this.sidebarItems.push({
        text: SideBarItemSelection.Pull,
        icon: 'ra-icon-ide-md-pull',
        emitOnredirect: true,
        id: 'sidebar-pull',
      });
    }

    const sideBarItemSelection = sessionStorage.getItem('SideBarItemSelection');
    if (
      sideBarItemSelection !== null &&
      this.sidebarItems.find((x) => x.text === sideBarItemSelection)
    ) {
      this.sidebarService.currentSideBarSelection =
        sideBarItemSelection as SideBarItemSelection;
    } else {
      this.sidebarService.currentSideBarSelection = SideBarItemSelection.Home;
    }
    sessionStorage.setItem(
      'SideBarItemSelection',
      this.sidebarService.currentSideBarSelection
    );
  }

  async ngOnInit(): Promise<void> {
    const flags = await firstValueFrom(this.featureFlagFacade.flags$);
    this.workspacesAvailable = flags?.twin_studio_pro as boolean;
    this.newPushToVaultExp = flags?.new_push_to_vault_exp as boolean;
    this.isReady = true;
    this.setPageData();
  }

  onEmitOptionEvent(optionEvent: SidebarItem): void {
    this.sidebarService.currentSideBarSelection = optionEvent.text;
    sessionStorage.setItem(
      'SideBarItemSelection',
      this.sidebarService.currentSideBarSelection
    );
  }

  setSidebarSelection(itemSelection: SideBarItemSelection): void {
    this.sidebarService.currentSideBarSelection = itemSelection;
  }

  onComplete(): void {}

  isServicesAvailable(): boolean {
    return this.servicesAvailable.some(
      (x) =>
        x.kind === 'Arena' || x.kind === 'Logix5000' || x.kind === 'Emulate3d'
    );
  }

  onGoToDashboard(sidebarSelection: SideBarItemSelection): void {
    this.sidebarService.currentSideBarSelection = sidebarSelection;
  }
}
