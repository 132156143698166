import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { ThemeColor } from '@ra-web-tech-ui-toolkit/cdk/types';

@Component({
  selector: 'app-secondary-button-flourish',
  templateUrl: './secondary-button-flourish.component.html',
  styleUrls: ['./secondary-button-flourish.component.scss'],
})
export class SecondaryButtonFlourishComponent implements OnInit {
  @Input() label: string = '';
  @Input() noBorder: boolean = false;
  @Input() isActionButton: boolean = false;
  @Input() icon: string = '';
  @Input() isDisabled: boolean = false;
  @Output() clickEvent = new EventEmitter<any>();

  /* current button color */
  buttonColor = ThemeColor.Primary;

  constructor() {}

  ngOnInit(): void {
    this.label = this.label;
  }

  onClickButton(event: any): void {
    this.clickEvent.emit(event);
  }
}
