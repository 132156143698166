<div *ngIf="!servicesAvailable" class="loading">
  <app-loading [width]="'400px'"></app-loading>
</div>
<div class="main" *ngIf="tenantServices$ | async as tenantService">
  <app-sidebar
    [headerSvgPath]="headerSvgPath"
    [sidebarItems]="sidebarItems"
    [currentSelection]="sidebarService.currentSideBarSelection"
    (emitOptionEvent)="onEmitOptionEvent($event)"
  >
  </app-sidebar>
  <div class="content" *ngIf="currentTenant$ | async as TenantInfo">
    <app-dashboard-menu
      *ngIf="
        sidebarService.currentSideBarSelection === sideBarItemSelection.Home
      "
      class="content-scroll"
      [currentSidebarSelection]="sidebarService.currentSideBarSelection"
      [workspacesAvailable]="
        workspacesAvailable &&
        currentTenant.name !== 'Personal Tenant' &&
        isServicesAvailable()
      "
    ></app-dashboard-menu>

    <div *ngIf="isReady">
      <app-push
        *ngIf="
          sidebarService.currentSideBarSelection ===
            sideBarItemSelection.Push && !newPushToVaultExp
        "
        [sessionData]="undefined"
        [displayCancel]="false"
        [currentTenant]="currentTenant"
        (continueWithSession)="onComplete()"
      ></app-push>

      <app-push-to-vault
        *ngIf="
          sidebarService.currentSideBarSelection ===
            sideBarItemSelection.Push && newPushToVaultExp
        "
        (goToDashboard)="onGoToDashboard($event)"
      >
      </app-push-to-vault>

      <app-quick-access-upload
        *ngIf="
          sidebarService.currentSideBarSelection ===
          sideBarItemSelection.UploadToQuickAccess
        "
        (goToDashboard)="onGoToDashboard($event)"
      >
      </app-quick-access-upload>

      <app-pull-from-vault
        *ngIf="
          sidebarService.currentSideBarSelection === sideBarItemSelection.Pull
        "
      >
      </app-pull-from-vault>
    </div>
  </div>
</div>
