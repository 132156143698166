import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssociatedProjectsComponent } from './associated-projects.component';
import { RaUiSearchInputModule } from '@ra-web-tech-ui-toolkit/form-system/search-input';
import { TranslateModule } from '@ngx-translate/core';
import { RaUiTreeModule } from '@ra-web-tech-ui-toolkit/lists/tree';
import { RaUiCheckboxModule } from '@ra-web-tech-ui-toolkit/form-system/checkbox';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [AssociatedProjectsComponent],
  imports: [
    CommonModule,
    RaUiSearchInputModule,
    TranslateModule,
    RaUiTreeModule,
    RaUiCheckboxModule,
    MatIconModule,
  ],
  exports: [AssociatedProjectsComponent],
})
export class AssociatedProjectsModule {}
