import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, Optional, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  IStepperButton,
  StepperComponent,
} from '@ra-web-tech-ui-toolkit/common-views/stepper';
import { INavigationTabItem } from '@ra-web-tech-ui-toolkit/navigation/navigation-tab';
import { getImagePathForUnknownProject } from '../../../core/services/vault/view-status/project-file-utility';
import { ActionButtonStyles } from '@ra-web-tech-ui-toolkit/cdk/types';

export interface SummaryData {
  status: string;
  fileName: string;
  associatedRepo: string;
  associatedProject: string;
  commitMessage: string;
}

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  //region translations
  summary = this.translateSvc.instant('pushToVault.summaryContent.title');
  subtitle = this.translateSvc.instant('pushToVault.summaryContent.subtitle');
  tabSuccess = this.translateSvc.instant(
    'pushToVault.summaryContent.tabSuccess'
  );
  backButtonLabel = this.translateSvc.instant(
    'RA_UI_COMMON_VIEWS.STEPPER.BTN_PREVIOUS_LABEL'
  );
  closeButtonLabel = this.translateSvc.instant(
    'RA_UI_COMMON_VIEWS.STEPPER.BTN_CANCEL_LABEL'
  );
  tabFailed = this.translateSvc.instant('pushToVault.summaryContent.tabFailed');
  retryButtonLabel = this.translateSvc.instant(
    'pushToVault.summaryContent.retry'
  );
  //endregion
  settingsLink = '#';
  selectedTabIndex = 0;
  filterColumn: string[] = [];
  filteredData: string[] = [];
  successfulTabSelected = true;

  getFilenameIcon = (params: ICellRendererParams): HTMLSpanElement => {
    const filename: string = params.value;
    const eGui = document.createElement('span');
    eGui.setAttribute(
      'style',
      'display:inline-block;overflow:hidden;text-overflow:ellipsis;'
    );
    eGui.innerHTML = `<div class="cell-row"><img width="20px" src="${getImagePathForUnknownProject(
      filename
    )}" style="margin-right: 10px" /><span class="text">${filename}</span></div>`;
    return eGui;
  };

  columnDefSuccess = [
    {
      cellRenderer: function (): string {
        return '<i class="ra-icon-ide-sm-color-check" style="margin-left: -3px;"></i>';
      },
      width: 30,
    },
    {
      field: 'fileName',
      headerName: this.translateSvc.instant('columnsTable.fileName'),
      cellRenderer: this.getFilenameIcon,
      flex: 1,
      cellStyle: { border: 'all' },
    },
    {
      field: 'associatedRepo',
      headerName: this.translateSvc.instant(
        'pushToVault.summaryContent.associatedRepo'
      ),
      flex: 1,
      cellStyle: { border: 'all' },
    },
    {
      field: 'associatedProject',
      headerName: this.translateSvc.instant(
        'pushToVault.summaryContent.associatedProject'
      ),
      flex: 1,
      cellStyle: { border: 'all' },
    },
    {
      field: 'commitMessage',
      headerName: this.translateSvc.instant('columnsTable.commitMessage'),
      flex: 1,
      cellStyle: { border: 'all' },
    },
  ];
  columnDefFailed = [
    {
      cellRenderer: function (): string {
        return '<i class="ra-icon-ide-sm-color-error" style="margin-left: -3px;"></i>';
      },
      width: 30,
    },
    {
      field: 'fileName',
      cellRenderer: this.getFilenameIcon,
      headerName: this.translateSvc.instant('columnsTable.fileName'),
      flex: 1,
      cellStyle: { border: 'all' },
    },
    {
      field: 'associatedRepo',
      editable: true,
      headerName: this.translateSvc.instant(
        'pushToVault.summaryContent.associatedRepo'
      ),
      flex: 1,
      cellStyle: { border: 'all' },
    },
    {
      field: 'associatedProject',
      headerName: this.translateSvc.instant(
        'pushToVault.summaryContent.associatedProject'
      ),
      flex: 1,
      cellStyle: { border: 'all' },
    },
    {
      field: 'commitMessage',
      headerName: this.translateSvc.instant('columnsTable.commitMessage'),
      flex: 1,
      cellStyle: { border: 'all' },
    },
  ];
  files: SummaryData[] = [];
  successFiles: SummaryData[] = [];
  failedFiles: SummaryData[] = [];
  tabList: INavigationTabItem[] = [];

  constructor(
    @Optional() readonly stepperCmp: StepperComponent,
    private readonly translateSvc: TranslateService
  ) {}

  ngOnInit(): void {
    this.files = this.stepperCmp.items[this.stepperCmp.items.length - 1].data
      .files as SummaryData[];
    this.fildeDataByStatus();
    this.initialiseFilter();
    this.updateTabList();
    this.filteredData = this.filterColumn;
    this.stepperCmp.items[this.stepperCmp.items.length - 1].data.failedFiles =
      this.failedFiles;
  }

  updateTabList(): void {
    this.tabList = [
      {
        label: ' (' + this.successFiles.length + ') ' + this.tabSuccess,
        width: 150,
        id: 'success-files-tab',
      },
      {
        label: ' (' + this.failedFiles.length + ') ' + this.tabFailed,
        width: 150,
        id: 'failed-files-tab',
      },
    ];
  }

  onFilteredData(filteredData: string[]): void {
    if (this.successfulTabSelected) {
      this.successFiles = this.files.filter(
        (file) =>
          filteredData.includes(file.fileName) && file.status === 'success'
      );
    } else {
      this.failedFiles = this.files.filter(
        (file) =>
          filteredData.includes(file.fileName) && file.status === 'failed'
      );
    }
  }

  onSelectedIndexChange(index: number): void {
    if (index === 0) {
      this.stepperCmp._disabledButtons[1] = false;
      this.stepperCmp._innerButtons = [
        {
          label: this.backButtonLabel,
          buttonStyle: ActionButtonStyles.Outlined,
        },
        {
          label: this.closeButtonLabel,
          buttonStyle: ActionButtonStyles.Main,
        },
      ] as IStepperButton[];
      this.filterColumn = Array.from(
        new Set(
          this.files
            .filter((file) => file.status === 'success')
            .map((item) => item.fileName)
        )
      );
      this.successfulTabSelected = true;
    } else {
      this.stepperCmp._disabledButtons[1] =
        this.stepperCmp.items[this.stepperCmp.items.length - 1].data
          .retryAttempts >= 3;

      this.stepperCmp._innerButtons = [
        {
          label: this.backButtonLabel,
          buttonStyle: ActionButtonStyles.Outlined,
        },
        {
          label: this.retryButtonLabel,
          buttonStyle: ActionButtonStyles.Main,
        },
        {
          label: this.closeButtonLabel,
          buttonStyle: ActionButtonStyles.Outlined,
        },
      ] as IStepperButton[];
      this.filterColumn = Array.from(
        new Set(
          this.files
            .filter((file) => file.status === 'failed')
            .map((item) => item.fileName)
        )
      );
      this.successfulTabSelected = false;
    }
    this.selectedTabIndex = index;
  }

  fildeDataByStatus(): void {
    this.successFiles = this.files.filter((file) => file.status === 'success');
    this.failedFiles = this.files.filter((file) => file.status === 'failed');
  }

  initialiseFilter(): void {
    this.filterColumn = Array.from(
      new Set(this.successFiles.map((item) => item.fileName))
    );
  }
}
