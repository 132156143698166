<div class="title-box">
  <div class="title-step">
    <div id="title-step" class="label-title">
      <i id="icon-title" class="{{ iconTitle }} icon"></i>
      {{ labelTitle }}
    </div>
    <div *ngIf="numberStep || subtitle" class="subtitle">
      <span id="step-subtitle"> {{ numberStep }}. {{ subtitle }} </span>
    </div>
  </div>
  <div class="top-right">
    <div *ngIf="region !== undefined" class="region">
      <i id="region-icon" class="ra-icon-ide-md-location-globe"></i>
      <p id="region-text" class="region-data">
        {{ regionTranslate | translate }}
      </p>
    </div>
    <ra-ui-text-button
      label="{{ howIsMyDataStored }}"
      [color]="buttonColor"
      (onClick)="onOpenSidenav()"
    ></ra-ui-text-button>
  </div>
</div>
