import { Routes, RouterModule } from '@angular/router';
import { MainTemplateComponent } from './main-template.component';
const routes: Routes = [
  {
    path: '',
    component: MainTemplateComponent,
  },
];

export const MainTemplateRoutingModule = RouterModule.forChild(routes);
