import { Injectable } from '@angular/core';
import {
  ConfigService,
  TwinStudioConfiguration,
} from '../services/config/config.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  TenantInfo,
  TenantId,
  Tenant,
  Service,
} from '../../core/models/cs/tenant-info.model';
import { map } from 'rxjs/operators';
import { LCoreResponse } from '../models/lcore-response.model';
import {
  KindFromTenantServices,
  SessionKind,
} from '../models/twin-studio.model';

@Injectable({
  providedIn: 'root',
})
export class TenantInfoService {
  constructor(
    private readonly http: HttpClient,
    private readonly configService: ConfigService<TwinStudioConfiguration>
  ) {}

  getTenantInfo(tenantId: TenantId): Observable<TenantInfo> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/user/tenants/${tenantId}`;
    return this.http
      .get<LCoreResponse<TenantInfo>>(url)
      .pipe(map((response) => response.data));
  }

  getAvailableTenants(): Observable<Tenant[]> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/user/tenants`;
    return this.http
      .get<LCoreResponse<Tenant[]>>(url)
      .pipe(map((response) => response.data));
  }

  isServiceContained(
    sessionKind: SessionKind,
    servicesAvailables: Service[]
  ): boolean {
    switch (sessionKind) {
      case SessionKind.Logix: {
        return (
          servicesAvailables.find(
            (sel: Service) => sel.kind === KindFromTenantServices.Logix
          ) !== undefined
        );
      }
      case SessionKind.Arena: {
        return (
          servicesAvailables.find(
            (sel: Service) => sel.kind === KindFromTenantServices.Arena
          ) !== undefined
        );
      }
      case SessionKind.Emulate3d: {
        return (
          servicesAvailables.find(
            (sel: Service) => sel.kind === KindFromTenantServices.Emulate3d
          ) !== undefined
        );
      }
      case SessionKind.FTAManager: {
        return (
          servicesAvailables.find(
            (sel: Service) => sel.kind === KindFromTenantServices.FTAManager
          ) !== undefined
        );
      }
      default: {
        return false;
      }
    }
  }
}
