import { Component, Optional, ViewEncapsulation } from '@angular/core';
import { TenantId } from '../../../core/models/cs/tenant-info.model';
import { StepperComponent } from '@ra-web-tech-ui-toolkit/common-views/stepper';
import { SolutionService } from '../../../core/services/vault/solution.service';
import * as _ from 'lodash';
import { Observable, tap } from 'rxjs';
import { map, mergeAll, mergeMap, toArray } from 'rxjs/operators';
import { Solution } from '../../../core/models/vault/solution.model';
import { VaultId } from '../../../core/models/vault/vault.model';
import {
  AnimationType,
  LoadingSpinnerDefinedSize,
  LoadingSpinnerSize,
} from '@ra-web-tech-ui-toolkit/indicators/loading-spinner';
import { ProjectService } from '../../../core/services/vault/project.service';
import { ProjectData } from '../../../core/models/vault/project.model';
import { RepositoryFilesSelected } from './associated-projects/associated-projects.component';
import { RemoveFile } from './selected-files/display-files/display-files.component';

export interface Repository extends Solution {
  projects: ProjectData[];
}

@Component({
  selector: 'app-select-project',
  templateUrl: './select-project.component.html',
  styleUrls: ['./select-project.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectProjectComponent {
  tenantId: TenantId = _.get(this.stepperCmp, 'items[0].data.tenantId', '');
  isConnected: boolean = false;
  isLoading: boolean = true;
  repositorySelected!: Repository;
  repositories!: Repository[];
  repositories$: Observable<Repository[]>;
  size: LoadingSpinnerSize = LoadingSpinnerDefinedSize.XLarge;
  animationType = AnimationType.Spinner;
  animationDuration: number = 1;
  repositoryFilesSelected: RepositoryFilesSelected[] = [];

  constructor(
    @Optional() private readonly stepperCmp: StepperComponent,
    private readonly solutionService: SolutionService,
    private readonly projectService: ProjectService
  ) {
    this.repositories$ = this.solutionService
      .getSolutions(this.tenantId as VaultId)
      .pipe(
        mergeAll(),
        mergeMap((solution: Solution) =>
          this.projectService
            .getProjectList(solution.solutionId, this.tenantId)
            .pipe(
              map(
                (projects) =>
                  ({ ...solution, projects: projects } as Repository)
              )
            )
        ),
        toArray(),
        tap((repositories) => {
          this.isLoading = false;
          this.isConnected = true;
          this.repositories = repositories.filter(
            (repository) => repository.projects.length > 0
          );
        })
      );
  }

  isAssociatedProjectsEnabled(repository: Repository): void {
    this.repositorySelected = repository;
  }

  onRepositoryFilesChecked(repositoryFiles: RepositoryFilesSelected): void {
    if (repositoryFiles.filesSelected.length === 0) {
      this.repositoryFilesSelected = this.repositoryFilesSelected.filter(
        (repository) => repository.repositoryId !== repositoryFiles.repositoryId
      );
    } else {
      const index = this.repositoryFilesSelected.findIndex(
        (repository) => repository.repositoryId === repositoryFiles.repositoryId
      );
      if (index === -1) {
        this.repositoryFilesSelected.push(repositoryFiles);
      } else {
        this.repositoryFilesSelected[index] = repositoryFiles;
      }
    }
    this.onFilesToPull();
    this.enableButtonPull();
  }

  onRemoveRepositoryFiles(removeFile: RemoveFile): void {
    const repositoryIndex = this.repositoryFilesSelected.findIndex(
      (repository) => repository.repositoryId === removeFile.repositoryId
    );

    if (
      this.repositoryFilesSelected[repositoryIndex].filesSelected.length === 1
    ) {
      this.repositoryFilesSelected = this.repositoryFilesSelected.filter(
        (repo) => repo.repositoryId !== removeFile.repositoryId
      );
    } else {
      this.repositoryFilesSelected[repositoryIndex].filesSelected =
        this.repositoryFilesSelected[repositoryIndex].filesSelected.filter(
          (project) => project.projectId !== removeFile.projectId
        );
    }
    this.onFilesToPull();
    this.enableButtonPull();
  }

  enableButtonPull(): void {
    if (this.repositoryFilesSelected.length > 0) {
      this.stepperCmp._disabledButtons[0] = false;
      return;
    }
    this.stepperCmp._disabledButtons[0] = true;
  }

  onFilesToPull(): void {
    this.stepperCmp.items[0].data.filesToPull = this.repositoryFilesSelected;
  }
}
