import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { WorkspaceData } from '../../../core/models/workspace';
import {
  ICardConfig,
  ICardMenuItemClickEvent,
} from '@ra-web-tech-ui-toolkit/layout/card';
import { BehaviorSubject } from 'rxjs';
import {
  IMenuConfig,
  MenuContent,
  MenuItems,
  MenuPositionHorizontal,
} from '@ra-web-tech-ui-toolkit/popups/menu';
import { TranslateService } from '@ngx-translate/core';
import { RoleActionsFacade } from '../../../core/reducers/role-actions/role-actions.facade';
import { Role } from '../../../core/models/vault/role-actions.model';

@Component({
  selector: 'app-workspace-tile',
  templateUrl: './workspace-tile.component.html',
  styleUrls: ['./workspace-tile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WorkspaceTileComponent implements OnInit, OnChanges {
  @Input() workspace!: WorkspaceData;
  @Output() openWorkspace = new EventEmitter<WorkspaceData>();
  @Output() editWorkspace = new EventEmitter<WorkspaceData>();
  @Output() deleteWorkspace = new EventEmitter<WorkspaceData>();

  config!: ICardConfig;
  editEnabled: boolean = false;
  openString: string = 'Open workspace';
  editString: string = 'Edit workspace';
  deleteString: string = 'Delete workspace';

  openWorkspaceTitle: string = this.translateService.instant('openWorkspace');
  editWorkspaceTitle: string = this.translateService.instant('editWorkspace');
  deleteWorkspaceTitle: string =
    this.translateService.instant('deleteWorkspace');

  constructor(
    private readonly translateService: TranslateService,
    private readonly roleActionsFacade: RoleActionsFacade
  ) {}

  ngOnInit(): void {
    this.roleActionsFacade.selectRole$.subscribe((role) => {
      this.editEnabled = role === Role.PremiumAdmin || role === Role.Admin;
      this.configureCard();
    });
  }

  ngOnChanges(): void {
    this.configureCard();
  }

  configureCard(): void {
    const headerMenu: MenuContent = this.editEnabled
      ? new BehaviorSubject<MenuItems>([
          // eslint-disable-next-line sonarjs/no-duplicate-string
        {
          label: this.openWorkspaceTitle,
          icon: 'ra-icon-ide-sm-open',
          id: `open-${this.workspace.name}`,
        },
        {
          label: this.editWorkspaceTitle,
          icon: 'ra-icon-ide-sm-edit',
          id: `edit-${this.workspace.name}`,
          disabled: this.workspace.instances.pending > 0,
        },
        {
          label: this.deleteWorkspaceTitle,
          icon: 'ra-icon-ide-md-delete',
          id: `delete-${this.workspace.name}`,
        },
      ])
      : new BehaviorSubject<MenuItems>([
          // eslint-disable-next-line sonarjs/no-duplicate-string
        {
          label: this.openWorkspaceTitle,
          icon: 'ra-icon-ide-sm-open',
          id: `open-${this.workspace.name}`,
        },
        {
          label: this.deleteWorkspaceTitle,
          icon: 'ra-icon-ide-md-delete',
          id: `delete-${this.workspace.name}`,
        },
      ]);

    const menuConfig: IMenuConfig = {
      position: {
        xPosition: MenuPositionHorizontal.Before,
      },
      explicit: false,
    };

    this.config = {
      width: '268px',
      height: '220px',
      header: {
        title: this.workspace.name,
        subtitle: this.workspace.region,
        iconButtons: [
          {
            icon: 'ra-icon-ide-sm-kebob',
            menuItems: headerMenu,
            menuConfig: menuConfig,
          },
        ],
      },
      footer: {
        textButtons: [{ label: this.openWorkspaceTitle }],
      },
    };
  }

  openWorkspacePage(): void {
    this.openWorkspace.emit(this.workspace);
  }

  emitEvent(event: ICardMenuItemClickEvent): void {
    switch (event.clickedMenuItem.label) {
      case this.openWorkspaceTitle: {
        this.openWorkspace.emit(this.workspace);
        break;
      }
      case this.editWorkspaceTitle: {
        this.editWorkspace.emit(this.workspace);
        break;
      }
      case this.deleteWorkspaceTitle: {
        this.deleteWorkspace.emit(this.workspace);
        break;
      }
      default: {
        break;
      }
    }
  }
}
