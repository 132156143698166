import {
  CellValueChangedEvent,
  GridOptions,
  ICellRendererParams,
  RowNode,
  ValueGetterParams,
} from '@ag-grid-community/core';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { StepperComponent } from '@ra-web-tech-ui-toolkit/common-views/stepper';
import { TenantId } from 'src/app/core/models/cs/tenant-info.model';
import * as _ from 'lodash';
import { RecentFilesTable } from 'src/app/core/models/table-templates.model';
import { InputComponent } from '@ra-web-tech-ui-toolkit/form-system/input';
import { getImagePathForUnknownProject } from 'src/app/core/services/vault/view-status/project-file-utility';
import { SolutionService } from 'src/app/core/services/vault/solution.service';
import { Solution } from 'src/app/core/models/vault/solution.model';
import { takeUntil, tap, Subject } from 'rxjs';
import { ProjectService } from 'src/app/core/services/vault/project.service';
import { ProjectData } from 'src/app/core/models/vault/project.model';
import { CommonGridHelper } from '@ra-web-tech-ui-toolkit/grid/cdk';
import { TranslateService } from '@ngx-translate/core';
import { TooltipMessageComponent } from 'src/app/features/push-to-vault/associations/tooltip-message/tooltip-message.component';

export interface Association {
  fileName: string;
  associatedRepo: Solution;
  associatedProject: ProjectData;
  commitMessage: string;
  path: string;
  workstation: boolean;
  regionAndWorkstationId: string;
}

export interface ProjectsBySolution {
  projects: ProjectData[];
  repositoryId: string;
  repositorryName: string;
}

@Component({
  selector: 'app-associations',
  templateUrl: './associations.component.html',
  styleUrls: ['./associations.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssociationsComponent implements OnInit, OnDestroy {
  @Input() isSelectedRegionDisabeld: boolean = false;
  region: string = _.get(this.stepperCmp, 'items[0].data.region', '');
  tenantId: TenantId = _.get(this.stepperCmp, 'items[0].data.tenantId', '');
  numberStep: number = _.get(this.stepperCmp, 'items', '').length === 4 ? 3 : 2;
  files: RecentFilesTable[] =
    this.stepperCmp.items[this.numberStep - 2].data.files;
  associationsMade: number = 0;
  repositories!: Solution[];
  repositories$ = this.solutionService.getSolutions(this.tenantId).pipe(
    tap((solutions: Solution[]) => {
      this.repositories = solutions;
      this.initializeAGGrid();
      this.updateRowSource();
    })
  );
  public gridApi!: any;
  gridOptions!: GridOptions;
  public commonGridHelperTable!: CommonGridHelper;
  isGridReady$ = new Subject();
  projectsBySolution: ProjectsBySolution[] = [];
  associateRepository: string = this.translateService.instant(
    'pushToVault.associateRepo'
  );
  associateProject: string = this.translateService.instant(
    'pushToVault.associateProject'
  );
  private readonly destroy$: Subject<void> = new Subject();

  constructor(
    @Optional() private readonly stepperCmp: StepperComponent,
    private readonly solutionService: SolutionService,
    private readonly projectService: ProjectService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFilenameIcon = (params: ICellRendererParams): HTMLSpanElement => {
    const filename: string = params.value;
    const eGui = document.createElement('span');
    eGui.setAttribute(
      'style',
      'display:inline-block;overflow:hidden;text-overflow:ellipsis;z-index:999'
    );
    eGui.innerHTML = `<div id="icon-${filename}" class="cell-row"><img width="20px" src="${getImagePathForUnknownProject(
      filename
    )}" style="margin-right: 10px" /><spa id="icon-text-${filename}" class="text">${filename}</spa></div>`;
    return eGui;
  };

  getRepoName = (params: ICellRendererParams): HTMLSpanElement => {
    const id = params.value?.solutionId
      ? params.value.solutionId
      : params.value.name;
    const name: string = params.value.name;
    const eGui = document.createElement('span');
    eGui.setAttribute(
      'style',
      'display:flex;flex-direction:row;justify-content:space-between;align-items: center'
    );
    eGui.innerHTML = `<span id="repo-${id}"> <i class="ra-icon-ide-lg-solution"> </i> ${
      name !== undefined ? name : this.associateRepository
    } </span> <i class="ra-icon-ide-sm-caret-down">`;
    return eGui;
  };

  getRepoNameInEditor = (params: ICellRendererParams): HTMLSpanElement => {
    const id = params.value?.solutionId
      ? params.value.solutionId
      : params.value.name;
    const name: string = params.value.name;
    const eGui = document.createElement('span');
    eGui.innerHTML = `<span id="repo-editor-${id}"> <i class="ra-icon-ide-lg-solution"> </i> ${
      name !== undefined ? name : this.associateRepository
    } </span>`;
    return eGui;
  };

  getProjectName = (params: ICellRendererParams): HTMLSpanElement => {
    const id = params.value?.solutionId
      ? params.value.solutionId
      : params.value.name;
    const name: string = params.value.name;
    const eGui = document.createElement('span');
    eGui.setAttribute(
      'style',
      'display:flex;flex-direction:row;justify-content:space-between;align-items: center'
    );
    eGui.innerHTML = `<span id="project-${id}"> <i class="ra-icon-ide-sm-pull-from-running-project"> </i> ${
      name !== undefined ? name : this.associateProject
    } </span> <i class="ra-icon-ide-sm-caret-down">`;
    return eGui;
  };

  getProjectNameInEditor = (params: ICellRendererParams): HTMLSpanElement => {
    const id = params.value?.solutionId
      ? params.value.solutionId
      : params.value.name;
    const name: string = params.value.name;
    const eGui = document.createElement('span');
    eGui.innerHTML = `<span id="project-editor-${id}"> <i class="ra-icon-ide-sm-pull-from-running-project"> </i> ${
      name !== undefined ? name : this.associateProject
    } </span>`;
    return eGui;
  };

  cellEditorSelector = (params: any): any => {
    if (params.data.associatedRepo.name !== this.associateRepository) {
      const repoId = params.data.associatedRepo.solutionId;
      const projectsByRepo = this.projectsBySolution.find(
        (sol) => sol.repositoryId === repoId
      ) as ProjectsBySolution;

      return {
        component: 'agRichSelectCellEditor',
        params: {
          cellRenderer: this.getProjectNameInEditor,
          values: projectsByRepo.projects,
        },
      };
    }
  };

  updateTooltip = (params: ValueGetterParams): boolean => {
    const association: Association = params.data;
    return this.isValidAssociation(association);
  };

  initializeAGGrid(): void {
    this.gridOptions = {
      columnDefs: [
        {
          width: 30,
          cellStyle: { border: 'none' },
          cellRenderer: TooltipMessageComponent,
          valueGetter: this.updateTooltip,
          cellClass: 'cell-header-checkbox',
        },
        {
          field: 'fileName',
          headerName: this.translateService.instant('columnsTable.fileName'),
          flex: 1,
          cellRenderer: this.getFilenameIcon,
          cellStyle: { background: 'white', border: 'none' },
          sortable: true,
          filter: true,
          filterParams: {
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
            ],
            trimInput: true,
            filterPlaceholder: this.translateService.instant(
              'pushToVault.filters.name'
            ),
            maxNumConditions: 1,
          },
        },
        {
          field: 'associatedRepo',
          headerName: this.translateService.instant(
            'pushToVault.summaryContent.associatedRepo'
          ),
          flex: 1,
          editable: true,
          cellRenderer: this.getRepoName,
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            cellRenderer: this.getRepoNameInEditor,
            values: this.repositories,
          },
          cellStyle: { background: 'white', border: 'none' },
          sortable: true,
          filter: true,
          filterParams: {
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
            ],
            trimInput: true,
            filterPlaceholder: this.translateService.instant(
              'pushToVault.filters.repo'
            ),
            maxNumConditions: 1,
          },
        },
        {
          field: 'associatedProject',
          headerName: this.translateService.instant(
            'pushToVault.summaryContent.associatedProject'
          ),
          flex: 1,
          editable: true,
          cellRenderer: this.getProjectName,
          cellEditorSelector: this.cellEditorSelector,
          cellStyle: { background: 'white', border: 'none' },
          sortable: true,
          filter: true,
          filterParams: {
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
            ],
            trimInput: true,
            filterPlaceholder: this.translateService.instant(
              'pushToVault.filters.project'
            ),
            maxNumConditions: 1,
          },
        },
        {
          field: 'commitMessage',
          headerName: this.translateService.instant(
            'pushToVault.commitMessage'
          ),
          flex: 1,
          cellEditor: InputComponent,
          cellStyle: { background: 'white', border: 'none' },
          sortable: true,
          editable: true,
          filter: true,
          filterParams: {
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
            ],
            trimInput: true,
            filterPlaceholder: this.translateService.instant(
              'pushToVault.filters.commit'
            ),
            maxNumConditions: 1,
          },
        },
      ],
      defaultColDef: {
        filter: false,
        resizable: true,
      },
      suppressContextMenu: true,
      domLayout: 'autoHeight',
      icons: {
        menu: '<span class="ag-icon ra-icon-filter ra-icon-ide-md-filter active-filter"></span>',
      },
    };
  }

  initializeData(): Association[] {
    return this.files.map((file) => {
      return {
        fileName: file.filename,
        workstation: file.pk.includes('i-'),
        regionAndWorkstationId: file.sk,
        associatedRepo: {
          name: this.associateRepository,
        } as Solution,
        associatedProject: {
          name: this.associateProject,
        } as ProjectData,
        commitMessage: `InitialPush_${file.filename}`,
        path: file.pk.includes('i-') ? file.pk : file.path,
      };
    });
  }

  updateRowSource(): void {
    if (this.repositories.length > 0) {
      this.gridApi?.setRowData(this.initializeData());
    }
  }

  onGridReady(event: any): void {
    this.gridApi = event.api;
    this.isGridReady$.next(true);
    this.gridApi.sizeColumnsToFit();
    this.updateRowSource();
    this.getProjectsByRepository();
  }

  getProjectsByRepository(): void {
    for (const repo of this.repositories) {
      this.projectService
        .getProjectList(repo.solutionId, this.tenantId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((projects) => {
          const projectsBySolution: ProjectsBySolution = {
            projects: projects,
            repositorryName: repo.name,
            repositoryId: repo.solutionId,
          };
          this.projectsBySolution.push(projectsBySolution);
        });
    }
  }

  getAllRows(): RowNode[] {
    const rowData: RowNode[] = [];
    this.gridApi.forEachNode((node: RowNode) => {
      rowData.push(node);
    });
    return rowData;
  }

  isValidAssociation(association: Association): boolean {
    return (
      association.fileName !== '' &&
      association.associatedRepo.name !== this.associateRepository &&
      association.associatedProject.name !== this.associateProject &&
      association.commitMessage !== '' &&
      association.path !== ''
    );
  }

  isDataReadyForPush(): boolean {
    const allRows = this.getAllRows();
    for (const row of allRows) {
      const association = row.data;
      if (!this.isValidAssociation(association)) {
        return false;
      }
    }
    return true;
  }

  getAssociations(): Association[] {
    const associations: Association[] = [];
    const allRows = this.getAllRows();
    allRows.forEach((row) => {
      associations.push(row.data);
    });
    return associations;
  }

  increaseAssociationsMade(): number {
    let count = 0;
    const allRows = this.getAllRows();
    for (const row of allRows) {
      const association = row.data;
      if (this.isValidAssociation(association)) {
        count++;
      }
    }
    return count;
  }

  onCellChanged(event: CellValueChangedEvent): void {
    this.associationsMade = this.increaseAssociationsMade();
    let associations: Association[] = [];
    if (this.isDataReadyForPush()) {
      this.stepperCmp._disabledButtons[1] = false;
      associations = this.getAssociations();
      this.stepperCmp.items[this.numberStep - 1].data.associations =
        associations;
    } else {
      this.stepperCmp._disabledButtons[1] = true;
      this.stepperCmp.items[this.numberStep - 1].data.associations =
        associations;
    }
  }

  //TODO implement this fuction when we have an existing settings component
  goToSettingsPage(): void {}
}
