import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadProgressComponent } from './file-upload-progress.component';

@NgModule({
  declarations: [FileUploadProgressComponent],
  exports: [FileUploadProgressComponent],
  imports: [CommonModule],
})
export class FileUploadProgressModule {}
