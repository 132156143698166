import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LottieModule } from 'ngx-lottie';
import { LoadingComponent } from './loading.component';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [LoadingComponent],
  imports: [CommonModule, LottieModule.forRoot({ player: playerFactory })],
  exports: [CommonModule, LoadingComponent],
})
export class LoadingModule {}
