import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataStoredInfoComponent } from './data-stored-info.component';
import { InfoSidebarModule } from '../info-sidebar/info-sidebar.module';

@NgModule({
  declarations: [DataStoredInfoComponent],
  imports: [CommonModule, InfoSidebarModule],
  exports: [DataStoredInfoComponent],
})
export class DataStoredInfoModule {}
