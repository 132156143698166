import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainTemplateComponent } from './main-template.component';
import { MainTemplateRoutingModule } from './main-template-routing.module';
import { LoadingModule } from '../../shared/components/loading/loading.module';
import { SidebarModule } from '../navigation/sidebar/sidebar.module';
import { DashboardMenuModule } from '../dashboard-menu/dashboard-menu.module';
import { PushModule } from '../workstation-menu/push/push.module';
import { WorkstationInstancesModule } from '../workstation-instances/workstation-instances.module';
import { PushToVaultModule } from 'src/app/features/push-to-vault/push-to-vault.module';
import { QuickAccessUploadModule } from '../quick-access-upload/quick-access-upload.module';
import { PullFromVaultModule } from '../pull-from-vault/pull-from-vault.module';

@NgModule({
  declarations: [MainTemplateComponent],
  imports: [
    CommonModule,
    MainTemplateRoutingModule,
    LoadingModule,
    SidebarModule,
    DashboardMenuModule,
    PushModule,
    WorkstationInstancesModule,
    PushToVaultModule,
    QuickAccessUploadModule,
    PullFromVaultModule,
  ],
  exports: [MainTemplateComponent],
})
export class MainTemplateModule {}
