import { Component, Inject, ViewChild } from '@angular/core';
import { STEPPER_ITEM_DATA } from '@ra-web-tech-ui-toolkit/common-views/stepper';
import { AcceleratorService } from '@ra-web-tech-ui-toolkit/cdk/accelerator';
import {
  FileInputComponent,
  IFileInputConfig,
  IFileListItem,
  FileListItemStatus,
} from '@ra-web-tech-ui-toolkit/form-system/file-input';
import { UploadFileModel } from '../../../core/models/upload-file.model';
import { IListConfig } from '@ra-web-tech-ui-toolkit/lists/list';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-files',
  templateUrl: './select-files.component.html',
  styleUrls: ['./select-files.component.scss'],
  providers: [AcceleratorService],
})
export class SelectFilesComponent {
  @ViewChild('fileInput', { static: true }) fileInput?: FileInputComponent;
  inputFileConfig: IFileInputConfig = {
    isDirectory: false,
    isMultiple: true,
    supportedFilesTypes: '',
    disableSidePaddings: false,
    useButtonStyle: false,
    hideFileList: false,
    fileListHeight: '300px',
  };
  readonly maxFiles = 10;
  disabled: boolean = false;
  tooManyFilesMessage: string = this.translateService.instant(
    'quickAccessUpload.tooManyFilesMessage',
    {
      maxFiles: this.maxFiles,
    }
  );
  fileInputHintMessage: string = this.translateService.instant(
    'quickAccessUpload.fileInputHintMessage',
    {
      maxFiles: this.maxFiles,
    }
  );
  fileListConfig: IListConfig = {
    emptyState: {
      imageSource: 'assets/images/icons/file_xl.svg',
      message: this.fileInputHintMessage,
      buttons: [{ label: this.translateService.instant('fileInput.browse') }],
    },
  };
  currentFiles: IFileListItem[] = [];
  updatedStatus: boolean = false;

  constructor(
    @Inject(STEPPER_ITEM_DATA) public viewData: any,
    private readonly translateService: TranslateService
  ) {
    this.currentFiles = this.viewData.currentFiles();
  }

  onValueChange(updatedFileListItems: IFileListItem[]): void {
    if (this.updatedStatus) {
      this.updatedStatus = false;
    } else {
      this.currentFiles = updatedFileListItems.map((file) => {
        file.status = FileListItemStatus.Succeed;
        return file;
      });

      this.updatedStatus = true;
      this.selectFiles(this.currentFiles);
    }
  }

  selectFiles(fileListItems: IFileListItem[]): void {
    const uploadFiles = this.convertToUploadFiles(fileListItems);
    this.viewData.setFilesUploadedToTs(uploadFiles);
    this.disabled = this.currentFiles.length > this.maxFiles;
  }

  convertToUploadFiles(fileListItems: IFileListItem[]): UploadFileModel[] {
    return fileListItems.map((fileListItem) => {
      return {
        id: '',
        name: fileListItem.file.name,
        sizeKb: fileListItem.file.size,
        isUploadCompleted: false,
        file: fileListItem.file,
      };
    });
  }

  openFileExplorer(): void {
    this.fileInput?._buttonClicked();
  }
}
