<span id="tooltip-message-success" *ngIf="params.value === true"
  ><i id="tooltip-icon-success" class="ra-icon-ide-sm-color-check cursor"></i
></span>
<span
  id="tooltip-message-info"
  *ngIf="params.value === false"
  raUiTooltip="{{ 'pushToVault.associationsMessageTooltip' | translate }}"
  [tooltipConfig]="tooltipConfig"
  ><i id="tooltip-icon-info" class="ra-icon-ide-sm-color-info cursor"></i
></span>
