import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import {
  routerReducer,
  RouterReducerState,
  RouterState,
} from '@ngrx/router-store';
import { authReducer, AuthState } from '../auth/reducers';
import { featureFlagsReducer } from '../reducers/feature-flag/feature-flag.reducer';
import { FeatureFlagState } from '../reducers/feature-flag/feature-flag.model';
import {
  sessionReducer,
  SessionState,
} from '../reducers/session/session.reducer';
import {
  tenantInfoReducer,
  TenantInfoState,
} from '../reducers/tenant-info/tenant-info.reducer';
import { WorkspaceState } from '../models/workspace-creation.model';
import { workspaceReducer } from './workspace/workspace.reducer';
import {
  RoleActionsState,
  roleActionsReducer,
} from './role-actions/role-actions.reducer';

export interface AppState {
  router: RouterState;
  auth: AuthState;
  featureFlags: FeatureFlagState;
  session: SessionState;
  currentTenant: TenantInfoState;
  roleActions: RoleActionsState;
  workspace: WorkspaceState;
}

export const selectRouter = createFeatureSelector<RouterReducerState>('router');

export const selectRouterState = createSelector(selectRouter, (state) => state);

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  auth: authReducer,
  featureFlags: featureFlagsReducer,
  session: sessionReducer,
  currentTenant: tenantInfoReducer,
  roleActions: roleActionsReducer,
  workspace: workspaceReducer,
};
