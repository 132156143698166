import { Injectable } from '@angular/core';
import {
  IGainsightCustomEvent,
  IGainsightOrganizationContext,
  IGainsightUserProfile,
} from '../../models/gainsight.model';

@Injectable({
  providedIn: 'root',
})
export class GainsightService {
  private readonly aptrinsic: (...__: any[]) => void;
  /**
   *
   */

  noop(...__: any[]): void {}
  constructor() {
    this.aptrinsic = this.noop;
    if ('aptrinsic' in window) {
      //@ts-expect-error
      this.aptrinsic = window.aptrinsic;
    } else {
      //TODO: Add logger
      // eslint-disable-next-line no-console
      console.log('Gainsight SDK not injected; Analytics disabled');
    }
  }
  pushUserProfile(userProfile: IGainsightUserProfile): any {
    try {
      this.aptrinsic('identify', userProfile);
    } catch (error) {
      // TO DO: review error handling
    }
  }

  reset(): void {
    //aptrinsic('reset');
    this.aptrinsic('reset');
  }

  setGlobalContext(context: IGainsightOrganizationContext): any {
    this.aptrinsic('set', 'globalContext', context);
  }

  pushCustomEvent({ eventName, eventPayload }: IGainsightCustomEvent): any {
    try {
      // @ts-expect-error
      aptrinsic('track', eventName, eventPayload);
    } catch (error) {
      // TO DO: review error handling
    }
  }
}
