import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleStepComponent } from './title-step.component';
import { TranslateModule } from '@ngx-translate/core';
import { RaUiButtonModule } from '@ra-web-tech-ui-toolkit/buttons/button';
import { RaUiStaticTextModule } from '@ra-web-tech-ui-toolkit/common-utils/static-text';
import { DataStoredInfoModule } from '../data-stored-info/data-stored-info.module';

@NgModule({
  declarations: [TitleStepComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RaUiButtonModule,
    RaUiStaticTextModule,
    DataStoredInfoModule,
  ],
  exports: [TitleStepComponent],
})
export class TitleStepModule {}
