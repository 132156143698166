<div class="loading" *ngIf="!repositories">
  <app-loading id="loading" [width]="'300px'"></app-loading>
</div>
<div *ngIf="repositories$ | async">
  <app-title-step
    [iconTitle]="'ra-icon-ide-md-push'"
    [labelTitle]="'pushToVault.btnPushToVault' | translate"
    [numberStep]="isSelectedRegionDisabeld ? 2 : 3"
    [subtitle]="'pushToVault.associateFiles' | translate"
    [region]="region"
  >
  </app-title-step>
  <div class="subtext">
    <i
      id="icon-info-associations"
      class="ra-icon-ide-md-info-filled icon-color"
    ></i>
    <p id="associations-text-1">
      {{ "pushToVault.associateFilesText" | translate }}
    </p>
    <p id="associations-text-2" class="settings" (click)="goToSettingsPage()">
      {{ "pushToVault.settings" | translate }}
    </p>
  </div>
  <div class="fields">
    <p id="associations-text-3">
      ({{ associationsMade }} {{ "pushToVault.of" | translate }}
      {{ files.length }})
      {{ "pushToVault.fullyAssociatedFiles" | translate }}
    </p>
  </div>
  <ag-grid-angular
    id="table-files"
    class="ag-theme-rockwell"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    (cellValueChanged)="onCellChanged($event)"
  ></ag-grid-angular>
</div>
