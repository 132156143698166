/* eslint-disable sonarjs/no-duplicate-string */
import { Injectable } from '@angular/core';
import {
  ConfigService,
  TwinStudioConfiguration,
} from '../config/config.service';
import { NavItem, NavItemActions } from '@rockwell-automation-inc/layout';

@Injectable({
  providedIn: 'root',
})
export class NavbarItemsService {
  helpItems: NavItem[] = [
    {
      action: NavItemActions.blank,
      raIconClass: 'ra-icon-ide-md-open',
      displayName: 'Online Help',
      id: 'online-help',
      route: `${this.configService.config.onlineHelp}`,
    },
    {
      action: NavItemActions.blank,
      raIconClass: 'ra-icon-ide-md-open',
      displayName: 'Getting Started',
      id: 'getting-started',
      route: `${this.configService.config.onlineHelp}/getting_started/getting_started.html`,
    },
    {
      action: 'custom',
      raIconClass: 'ra-icon-ide-md-open',
      displayName: 'Contact Support',
      id: 'contact-support',
    },
    {
      action: 'custom',
      raIconClass: 'ra-icon-ide-md-open',
      displayName: 'Live Chat',
      id: 'live-chat',
    },
    {
      action: 'custom',
      raIconClass: 'ra-icon-ide-lg-feedback',
      displayName: 'Provide Feedback',
      id: 'provide-feedback',
    },
    {
      action: NavItemActions.blank,
      raIconClass: 'ra-icon-ide-md-open',
      displayName: 'Release Notes',
      id: 'release-notes',
      route: `${this.configService.config.onlineHelp}/rel_notes.html`,
    },
    {
      displayName: 'About',
      expander: true,
      id: 'about',
      children: [
        {
          action: NavItemActions.blank,
          raIconClass: 'ra-icon-ide-md-open',
          displayName: 'Legal',
          id: 'legal',
          route:
            'https://www.rockwellautomation.com/en-us/company/about-us/legal-notices.html',
        },
        {
          action: NavItemActions.blank,
          raIconClass: 'ra-icon-ide-md-open',
          displayName: 'License Agreement',
          id: 'license-agreement',
          route:
            'https://www.rockwellautomation.com/en-us/company/about-us/legal-notices/end-user-license-agreement.html',
        },
        {
          action: NavItemActions.blank,
          raIconClass: 'ra-icon-ide-md-open',
          displayName: 'Privacy Policy',
          id: 'privacy-policy',
          route:
            'https://www.rockwellautomation.com/en-us/company/about-us/legal-notices/privacy-and-cookiespolicy.html',
        },
        {
          action: NavItemActions.blank,
          raIconClass: 'ra-icon-ide-md-open',
          displayName: 'Terms of Use',
          id: 'terms-of-use',
          route:
            'https://www.rockwellautomation.com/en-us/company/about-us/legal-notices/terms-and-conditionsofaccess.html',
        },
      ],
    },
  ];

  organizationItems: NavItem[] = [
    {
      displayName: 'ORGANIZATION',
      id: 'organization',
      isHeading: true,
      children: [
        {
          action: NavItemActions.self,
          displayName: 'Create Organization',
          id: 'create-org',
          route: `${this.configService.config.csApiBaseUrl}/organization/create`,
        },
        {
          action: NavItemActions.self,
          displayName: 'Join Organization',
          id: 'join-org',
          route: `${this.configService.config.csApiBaseUrl}/organization/join`,
        },
      ],
    },
  ];

  organizationAdminItems: NavItem[] = [
    {
      action: NavItemActions.self,
      displayName: 'Edit Organization',
      id: 'edit-org',
      route: `${this.configService.config.csApiBaseUrl}/organization/edit`,
    },
    {
      action: NavItemActions.self,
      displayName: 'Universal Credits Balance',
      id: 'ledger',
      route: `${this.configService.config.csApiBaseUrl}/universalcredits/ledger`,
    },
  ];

  hubManagerItems: NavItem[] = [
    {
      displayName: 'HUB MANAGER',
      id: 'hub-manager',
      isHeading: true,
      children: [
        {
          action: 'self',
          displayName: 'Access Management',
          id: 'access-management',
          route: `${this.configService.config.csApiBaseUrl}/access-mngmt`,
        },
        {
          action: 'self',
          displayName: 'Entitlements',
          id: 'entitlements',
          route: `${this.configService.config.csApiBaseUrl}/entitlement`,
        },
        {
          action: 'self',
          displayName: 'Invite Users',
          id: 'invite-users',
          route: `${this.configService.config.csApiBaseUrl}/invite-users`,
        },
        {
          action: 'self',
          displayName: 'Approve Users',
          id: 'approve-users',
          route: `${this.configService.config.csApiBaseUrl}/approve-users`,
        },
        {
          action: 'self',
          displayName: 'Invitation Management',
          id: 'invitation-management',
          route: `${this.configService.config.csApiBaseUrl}/invitation-management`,
        },
      ],
    },
  ];

  constructor(
    private readonly configService: ConfigService<TwinStudioConfiguration>
  ) {}

  getHelpItems(): NavItem[] {
    return this.helpItems;
  }

  getBasicNavItems(): NavItem[] {
    return this.organizationItems;
  }

  getAdminNavItems(): NavItem[] {
    const adminNavItems = this.hubManagerItems.concat(this.organizationItems);
    const organizationIndex = adminNavItems.findIndex(
      (navItem) => navItem.id === 'organization'
    );
    adminNavItems[organizationIndex].children?.push(
      ...this.organizationAdminItems
    );
    return adminNavItems;
  }
}
