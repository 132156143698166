import { DatePipe } from '@angular/common';

export enum EnvironmentNames {
  Local = 'local',
  Sandbox = 'Sandbox',
  NonProd = 'NonProd',
  Demo = 'Demo',
  Prod = 'Prod',
}

export class AppUtility {
  constructor() {}

  /**
   * Note: Eventhough datePipe.transform takes a locale, the locale has to
   * be the one used when loading the app. This locale, used internally by
   * Angular, is different from the locale used by localization language
   * switching. While this internal locale can be set on the fly, it is a
   * heavy weight solution for our purpose. Since Intl.DateTimeFormat can
   * handle any locale, it will be used for getting the month name based
   * on the current language selected for localization.
   */

  /**
   * Gets date and time based on current locale.
   * Example: 02 Sep 2022  11:21 am
   *
   * @param givenDate date to convert
   * @param separateDay should date have a comma after the day
   * @param longMonth should month be long or abbreviated
   * @returns date and time as string
   */
  public static convertToDateTimeString(
    givenDate: string | number | Date,
    separateDay = false,
    longMonth = false
  ): string {
    const date = new Date(givenDate);
    const datePipe = new DatePipe('en-US');
    const time = datePipe.transform(date, 'hh:mm a');
    const calendarDate = this.convertToDateString(
      givenDate,
      separateDay,
      longMonth
    );
    return `${calendarDate}\x20\x20${time}`;
  }

  /**
   * Gets date based on current locale.
   * Examles: 02 Sep 2022
   *          02, Sep 2022
   *          02, September 2022
   *
   * @param givenDate date to convert
   * @param separateDay should date have a comma after the day
   * @param longMonth should month be long or abbreviated
   * @returns date as string
   */
  public static convertToDateString(
    givenDate: string | number | Date,
    separateDay = false,
    longMonth = false,
    lang: string | null = null
  ): string {
    if (!givenDate) {
      return '';
    }
    const date = new Date(givenDate);
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    const monthFormat = longMonth ? 'long' : 'short';
    const formatter = new Intl.DateTimeFormat(
      lang ? lang : localStorage.getItem('lang') || 'en',
      { month: monthFormat }
    );

    const rawMonth = formatter.format(date);
    const month = rawMonth[0].toUpperCase() + rawMonth.slice(1);

    return separateDay ? `${day}, ${month} ${year}` : `${day} ${month} ${year}`;
  }
}

export function getFileExtension(
  fileName: string,
  prefixPeriod: boolean = false
): string {
  if (!fileName) {
    return '';
  }
  const ext = fileName.toLowerCase().split('.').slice(-1)[0];
  return prefixPeriod ? `.${ext}` : ext;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isEmptyObject(obj: any): boolean {
  return obj === undefined || Object.keys(obj).length === 0;
}

export function regionByCountrySupported(): string | undefined {
  const currentLocation = Intl.DateTimeFormat().resolvedOptions();
  const timeZoneFound = currentLocation.timeZone;
  if (timeZoneFound === undefined) {
    return undefined;
  } else if (timeZoneFound.startsWith('America')) {
    return 'America';
  } else if (timeZoneFound.startsWith('Europe')) {
    return 'Europe';
  } else if (timeZoneFound.startsWith('Asia')) {
    return 'Ap';
  }
  return undefined;
}

/**
 * Converts text to camel case.
 * This method can also be used to derive translation keys from app names, tier sizes, etc.
 *
 * @param text string to be converted to camel case
 * @returns camel cased string
 */
export function camelCase(text: string): string {
  const words = text.split(' ');
  let camelCase = words.shift().toLowerCase();
  words.forEach((word) => {
    camelCase = camelCase
      .concat(word[0].toUpperCase())
      .concat(word.substring(1).toLowerCase());
  });
  return camelCase;
}
