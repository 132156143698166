import { Component } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-auto-sign-in',
  template: '<div>auto signin</div>',
})
export class AutoSignInComponent {
  constructor(private readonly authService: AuthService) {
    this.authService.login();
  }
}
