import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { IInnerItem, ValueType } from '@ra-web-tech-ui-toolkit/cdk/types';
import { RadioButtonConfig } from '@ra-web-tech-ui-toolkit/form-system/radio-button';
import {
  ISearchInputConfig,
  ISearchInputText,
} from '@ra-web-tech-ui-toolkit/form-system/search-input';
import { Repository } from '../select-project.component';
import { SolutionId } from '../../../../core/models/vault/solution.model';

@Component({
  selector: 'app-select-repository',
  templateUrl: './select-repository.component.html',
  styleUrls: ['./select-repository.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectRepositoryComponent implements OnInit {
  @Input() repositories!: Repository[];
  @Output() repositorySelected = new EventEmitter<Repository>();
  repositoriesView!: IInnerItem[];
  repositoriesViewOriginal!: IInnerItem[];

  searchInputText: ISearchInputText = {
    searchInputLabel: 'Search for ...',
  };

  searchConfig: ISearchInputConfig = {
    searchInputText: this.searchInputText,
  };

  config: RadioButtonConfig = new RadioButtonConfig({
    disableAcceleratorKey: false,
  });

  ngOnInit(): void {
    this.repositoriesView = this.repositories.map((repository) => {
      const repositoryView: IInnerItem = {
        label: repository.name,
        value: repository.solutionId,
        id: repository.solutionId,
        data: repository,
      };
      return repositoryView;
    });
    this.repositoriesViewOriginal = this.repositoriesView;
  }

  filterByText(criteria: string): void {
    if (criteria === '') {
      this.repositoriesView = this.repositoriesViewOriginal;
    } else {
      this.repositoriesView = this.repositoriesViewOriginal.filter(
        (repository) =>
          repository.label?.toLowerCase().includes(criteria.toLowerCase())
      );
    }
  }

  isRepositorySelected(event: ValueType): void {
    const repository = this.repositories.find(
      (repository) => repository.solutionId === (event as SolutionId)
    ) as Repository;
    this.repositorySelected.emit(repository);
  }
}
