<!-- CS nav requires embedding main content to allow sidebar overlays, see ng-content -->
<app-common-navbar-wrapper> <!-- CS NAV WRAPPER --> </app-common-navbar-wrapper>
<div>
  <mat-sidenav-container class="lemans-exclude-top-nav">
    <mat-sidenav-content class="hide-scroll-content">
      <!-- Main Content -->
      <ng-content select="[content]"></ng-content>
    </mat-sidenav-content>

    <mat-sidenav
      #sidePanelRef
      class="side-nav-slider hide-scroll flex-col"
      fixedInViewport="true"
      [position]="'end'"
      [autoFocus]="false"
    >
      <!-- Panel Content -->
      <ng-container #sidePanelContentRef></ng-container>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
