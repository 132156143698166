import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { Content } from '../../shared/components/content-host/content';
import { ErrorContentComponent } from '../../shared/components/error-content/error-content.component';
import { Dialog } from '../models/enums/dialog';
import { ErrorDialogConfig } from '../models/error-content-data.model';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private dialogRef: MatDialogRef<any>;

  constructor(private readonly dialog: MatDialog) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public open(dialogType: Dialog, options: any, text = '') {
    const dialogConfig = this.createConfig(dialogType, options, text);
    this.dialogRef = this.createDialog(dialogType, dialogConfig);
    this.dialogRef.updatePosition(dialogConfig.position);
    return this;
  }

  public close(): void {
    this.dialog.closeAll();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public confirmed() {
    return this.dialogRef?.afterClosed();
  }

  /**
   * Take a series of components and embed them within our dialog.
   * Note that this should only be called when using a component designed to host child components.
   *
   * @param content Content (component or components) we're embedding
   * @param dialogConfig Dialog configuration we're modifying
   * @param width Desired width
   * @param height Desired height
   * @returns The updated dialog configuration
   */
  private embedContent(
    content: Content[],
    dialogConfig: MatDialogConfig,
    width: number,
    height: number
  ): MatDialogConfig {
    dialogConfig.data.content = content;

    let dialogWidth: number;
    let dialogHeight: number;

    if (!width || !height) {
      // Width AND height must both have a value, otherwise defaults are applied.
      // Content is a special container that hosts a component
      // UX recommends using the Golden Ratio for these larger dialogs
      const phi = 1.618;
      dialogWidth = Math.ceil(window.innerWidth / phi);
      dialogHeight = Math.ceil(window.innerHeight / phi);
    } else {
      // The dialog's overall height will need to be factored in
      const topPx = 72;
      const bottomPx = 84;
      dialogWidth = width;
      dialogHeight = height + topPx + bottomPx;
    }

    // double and pad navbar height to clear it with centered modal
    const navbarHeight = 2 * 63 + 48;
    const maxHeight = window.innerHeight - navbarHeight;
    const adjustedHeight = dialogHeight < maxHeight ? dialogHeight : maxHeight;

    dialogConfig.maxWidth = dialogWidth;
    dialogConfig.maxHeight = maxHeight;
    dialogConfig.data.width = dialogWidth;
    dialogConfig.data.height = adjustedHeight;
    dialogConfig.disableClose = true;

    return dialogConfig;
  }

  /**
   * Initialize the dialog config for our nested content.
   *
   * @param options Dialog options used when building dialog config
   * @returns A MatDialogConfig instance
   */
  private initDialogContent(options: any, isPrompt: boolean): MatDialogConfig {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      headerIcon: options.headerIcon,
      needsToTakeAction: options.needsToTakeAction,
      title: options.title,
      message: options.message,
      cancelText: options.cancelText,
      confirmText: options.confirmText,
      isDisabled: options.isDisabled,
      isPrompt,
    };

    // TODO: Revisit UX future plans for panel view
    // dialogConfig.position = { right: "0", bottom: "0" };
    dialogConfig.data.isPanel = false;
    return dialogConfig;
  }

  /**
   * Initialize the dialog config for our error dialog, providing our own nested error
   * component.
   *
   * @param errorDetails Error dialog details
   * @returns MatErrorConfig instance
   */
  private createErrorContent(errorDetails: ErrorDialogConfig): MatDialogConfig {
    const content = [
      new Content(ErrorContentComponent, {
        originalMessage: errorDetails.rawError,
      }),
    ];

    // Create the initial dialog config...
    const dialogConfig = this.initDialogContent({}, false);
    this.embedContent(
      content,
      dialogConfig,
      errorDetails.width,
      errorDetails.height
    );

    // ... then apply our overrides.
    dialogConfig.data.headerIcon = 'assets/images/icons/error.svg';
    dialogConfig.data.title = 'Error';
    dialogConfig.data.confirmText = 'Continue';
    dialogConfig.data.cancelText = '';

    return dialogConfig;
  }

  /**
   * Initialize the dialog config for our confirmation dialog. Embedded content is optional.
   */
  private createConfirmationContent(
    options: any,
    isPrompt: boolean
  ): MatDialogConfig {
    const dialogConfig = this.initDialogContent(options, isPrompt);
    if (options.content && !isPrompt) {
      this.embedContent(
        options.content,
        dialogConfig,
        options.width,
        options.height
      );
    }

    return dialogConfig;
  }

  /**
   * Create the dialog configuration given a dialog type, options, and text.
   *
   * @param dialogType The type of dialog we've been asked to create a config for.
   * @param options Dialog options
   * @param text 'Text' (used by edit prompts)
   * @returns If we recognize the dialog type, an initialized MatDialogConfig - otherwise,
   *          a new, uninitialized instance.
   */
  private createConfig(dialogType: Dialog, options, text: string) {
    switch (dialogType) {
      case Dialog.Confirm:
      case Dialog.Prompt:
        return this.createConfirmationContent(
          options,
          dialogType === Dialog.Prompt
        );

      case Dialog.Error:
        return this.createErrorContent(options as ErrorDialogConfig);

      default:
        return new MatDialogConfig();
    }
  }

  /**
   * Open a modal dialog using the component matching the dialog type and initialized
   * with the given config.
   */
  private createDialog(dialogType, config) {
    switch (dialogType) {
      case Dialog.Confirm:
      case Dialog.Prompt:
      case Dialog.Error:
        return this.dialog.open(ConfirmDialogComponent, config);

      default:
        return;
    }
  }
}
