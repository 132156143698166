<div class="select-location">
  <app-title-step
    [labelTitle]="'quickAccessUpload.upload' | translate"
    [numberStep]="2"
    [subtitle]="'quickAccessUpload.selectLocationSubtitle' | translate"
    [iconTitle]="'ra-icon-ide-sm-upload'"
  ></app-title-step>

  <div class="home-folder" *ngIf="!uploadStarted()">
    <app-home-folder
      [showFiles]="false"
      (folderSelected)="setLocation($event)"
    ></app-home-folder>
  </div>

  <div class="loading" *ngIf="uploadStarted()">
    <p class="text">{{ "quickAccessUpload.waitMessage" | translate }}</p>
    <ra-ui-progress-bar class="progress-bar" [mode]="mode"></ra-ui-progress-bar>
  </div>
</div>
