<mat-form-field class="mat-mdc-form-field-wide">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    #singleSelect
    [id]="inputId"
    [formControl]="dataCtrl"
    (selectionChange)="onSelectionChanged($event)"
    [disableOptionCentering]="true"
    [matTooltip]="tooltip"
    matTooltipShowDelay="1500"
    matTooltipHideDelay="0"
  >
    <mat-option>
      <ngx-mat-select-search
        [preventHomeEndKeyPropagation]="true"
        [formControl]="dataFilterCtrl"
        [placeholderLabel]="placeholderLabel"
        [noEntriesFoundLabel]="noEntriesFoundLabel"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let data of filteredData | async"
      [id]="'option-' + data.displayText"
      [value]="data"
      [matTooltip]="data.displayText"
      matTooltipShowDelay="1500"
      matTooltipHideDelay="0"
      matTooltipPosition="right"
    >
      {{ data.displayText }}
    </mat-option>
  </mat-select>
</mat-form-field>
