import { createAction, props } from '@ngrx/store';

export const appInit = createAction(
  '[Localization] Starting application',
  props<{ lang: string }>()
);

export const appRefreshed = createAction(
  '[Localization] Getting previous language selected',
  props<{ lang: string }>()
);

export const switchLang = createAction(
  '[Localization] Switching language',
  props<{ lang: string }>()
);
