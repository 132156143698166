<lib-common-mat-navbar
  *ngIf="isAuthenticated$ | async"
  class="{{
    !commonNavbarService.hideNavbar
      ? 'app-navbar-container'
      : 'app-hide-navbar-container'
  }}"
  [displaySlideToggleTheme]="enableThemeSwitcher"
  [initialTheme]="theme"
  [logotypeClass]="logoTypeClass"
  [isAuthenticated]="(isAuthenticated$ | async) ?? false"
  [organizations]="(tenants$ | async) ?? []"
  [currentOranizationName]="(currentTenantName$ | async) ?? ''"
  [accountMenu]="accountMenu$ | async"
  [unreadNotificationsCount]="(unreadNotificationsCount$ | async) ?? 0"
  [notifications]="(notifications$ | async) ?? []"
  [notificationService$]="notificationPageLookupBinding()"
  [displayChangeOrganization]="true"
  [enableUserActionsSideNav]="true"
  [enableNotificationsSideNav]="true"
  [enableHelpSideNav]="true"
  [enableOrganizationSwitcher]="true"
  [helpItems]="helpItems"
  [navItems]="navItems"
  [showBanner]="false"
  [homeMenuTitle]="homeMenuTitle"
  (changeOrganization)="changeOrg($event)"
  (helpItemClick)="onMenuItem($event)"
  (menuItemClick)="onMenuItem($event)"
  (logoutClick)="onLogout()"
  (goToHomeClick)="goToHome()"
  (raLogoClick)="goToHome()"
  (notificationAction)="onNotificationAction($event)"
  (currentTheme)="onThemeChanged($event)"
>
  <div class="app-content-container">
    <router-outlet></router-outlet>
  </div>
</lib-common-mat-navbar>
