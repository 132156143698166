import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {

  constructor(
    private readonly snackBar: MatSnackBar,
  ) { }

  /**
   * open SnackBar WithAction
   *
   * @param message body of the snackbar.
   * @param duration how long the snackbar will be open. By default 2 seconds
   */
  openSnackBar(message: string, duration = 2000): void {
    this.snackBar.open(message, undefined, {
      duration: duration,
      panelClass: 'twin-studio-snack-bar',
    });
  }

  /**
   * open SnackBar WithAction
   *
   * @param message body of the snackbar.
   * @param action text that will be related to the action on the right side of the snackbar
   * @param duration how long the snackbar will be open. By default 2 seconds
   * @returns It will return a reference of the snackbar that can be used to handle the action from the caller
   * e.g. ref.onAction().subscribe(() => { *take action })
   */
  openSnackBarWithAction(
    message: string,
    action?: string,
    duration = 2000
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, action, {
      duration: duration,
      panelClass: 'twin-studio-snack-bar',
    });
  }
}
