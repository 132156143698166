import {
  ConfigService,
  TwinStudioConfiguration,
} from '../config/config.service';
import { Observable, map } from 'rxjs';
import { LCoreResponse } from '../../models/lcore-response.model';
import { Solution, SolutionId } from '../../models/vault/solution.model';
import { Injectable } from '@angular/core';
import { VaultId } from '../../models/vault/vault.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SolutionService {
  constructor(
    private readonly http: HttpClient,
    private readonly configService: ConfigService<TwinStudioConfiguration>
  ) {}

  getSolutions(vaultId: VaultId): Observable<Solution[]> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/vaults/${vaultId}/solutions`;
    return this.http
      .get<LCoreResponse<Solution[]>>(url)
      .pipe(map((response) => response.data));
  }

  getSolutionById(
    solutionId: SolutionId,
    vaultId: VaultId
  ): Observable<Solution> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/vaults/${vaultId}/solutions/${solutionId}`;
    return this.http
      .get<LCoreResponse<Solution>>(url)
      .pipe(map((response) => response.data));
  }
}
