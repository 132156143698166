import {
  ConfigService,
  TwinStudioConfiguration,
} from '../config/config.service';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, catchError, map, throwError, of } from 'rxjs';
import { LCoreResponse } from '../../models/lcore-response.model';
import {
  Project,
  ProjectDto,
  ProjectData,
  ProjStatusLabels,
} from '../../models/vault/project.model';
import { HttpClient } from '@angular/common/http';
import { SolutionId } from '../../models/vault/solution.model';
import { VaultId } from '../../models/vault/vault.model';
import { failed } from '../../../shared/utility/result';
import { ProjectViewStatusService } from './view-status/project-viewstatus.service';
import { AuthService } from '../../auth/auth.service';
import { ControllerProjectType } from '../../models/controller-project-type.model';
import { UserProfile } from '../../auth/user-profile.model';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(
    private readonly http: HttpClient,
    private readonly configService: ConfigService<TwinStudioConfiguration>,
    private readonly viewStatusService: ProjectViewStatusService,
    private readonly authService: AuthService
  ) {}

  getProjectList(
    solutionId: SolutionId,
    vaultId: VaultId
  ): Observable<ProjectData[]> {
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/idh/vaults/${vaultId}/projects?solutionId=${solutionId}`;
    return this.http.get<LCoreResponse<ProjectDto[]>>(url).pipe(
      catchError((err: unknown) => this.handleError(err)),
      map<LCoreResponse<ProjectDto[]>, Project[]>((response) =>
        response.data.map((p) => {
          const projectResult = Project.fromDto(p);
          if (failed(projectResult)) {
            throw projectResult.error;
          }
          return projectResult.value;
        })
      ),
      map<Project[], ProjectData[]>((projects: Project[]) =>
        projects.map((project) => this.convertToProjectView(project))
      ),
      mergeMap((projects: ProjectData[]) =>
        this.addUserInfoToProjectList(projects)
      )
    );
  }

  convertToProjectView(project: Project): ProjectData {
    const projectView: ProjectData = Object.assign(
      Project.empty() as ProjectData,
      project
    );
    projectView.viewStatus = this.getProjectStatus(projectView);

    return projectView;
  }

  // look up the userInfo by userId for every projectHistory record of every project
  // we will query the userProfile service who hopefully has a cached copy of the info
  // otherwise it (userProfileService) will proxy a call to the backend to get that userInfo

  // about to do the equivalent of a nested for loop, but in a stream RXJS observable way
  // because these are async operations

  public addUserInfoToProjectList(
    projects: ProjectData[]
  ): Observable<ProjectData[]> {
    if (!projects.length) {
      return of(projects);
    }
    const userIds = projects
      .map((p) => p.projectHistory.map((ph) => ph.userId))
      .flat();

    if (userIds.length < 1) {
      return of(projects);
    }
    return forkJoin(
      [...new Set(userIds)].map((uid) => this.authService.getUserInfoFor(uid))
    ).pipe(
      map((profiles) => {
        projects.forEach((project) => {
          this.mapProfilesToProjectHistoriesHelper(
            project.projectHistory,
            profiles
          );
        });
        return projects;
      })
    );
  }

  private mapProfilesToProjectHistoriesHelper(
    histories: ControllerProjectType[],
    profiles: UserProfile[]
  ): void {
    histories.forEach((history) => {
      const profile = profiles.find((p) => p.userId === history.userId);
      if (profile) {
        history.userProfile = profile;
      }
    });
  }

  getProjectStatus(project: Project): ProjStatusLabels {
    return this.viewStatusService.getProjectStatusLabel(project);
  }

  private handleError(error: unknown) {
    // Return an observable with a user-facing error message.
    return throwError(
      () =>
        new Error(
          'Something bad happened; please try again later. - project service'
        )
    );
  }
}
