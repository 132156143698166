<div>
  <mat-toolbar *ngIf="isPanel" class="navbar-modal">
    <mat-icon
      class="close-button"
      svgIcon="close-x"
      (click)="cancel()"
    ></mat-icon>
  </mat-toolbar>

  <div class="hosted-container">
    <div class="header-row header">
      <mat-icon
        *ngIf="data.headerIcon"
        class="icon"
        svgIcon="header-icon"
      ></mat-icon>
      <div
        mat-dialog-title
        id="tfield-title"
        [ngClass]="{
          'title-prompt': isPrompt,
          title: !isPrompt,
          'title-pad-icon': data.headerIcon
        }"
      >
        {{ data.title }}
      </div>
    </div>

    <div mat-dialog-content *ngIf="data.message">
      <p class="dialog-message" id="tfield-message">{{ data.message }}</p>
    </div>

    <div
      *ngIf="data.content"
      class="content-container"
      [style.height.px]="data.height - (titleHeightPixels + actionHeightPixels)"
    >
      <app-content-host
        (hostEvent)="onHostEvent($event)"
        [items]="data.content"
      ></app-content-host>
    </div>
  </div>
  <div class="footer">
    <div mat-dialog-actions>
      <app-primary-button
        class="button-confirm"
        id="btn-confirm"
        [debounceTime]="0"
        [class.cdk-visually-hidden]="data.confirmText === ''"
        [label]="data.confirmText"
        (clickEvent)="confirm()"
        [isDisabled]="isDisabled"
      ></app-primary-button>
      <app-secondary-button
        class="button-cancel"
        id="btn-cancel"
        [class.cdk-visually-hidden]="data.cancelText === ''"
        [debounceTime]="0"
        [label]="data.cancelText"
        (clickEvent)="cancel()"
      ></app-secondary-button>
    </div>
  </div>
</div>
