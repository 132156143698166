<div class="dashboard-content" *ngIf="tenantId$ | async as string">
  <div class="title">
    <p>{{ "dashboard" | translate }}</p>
  </div>
  <div class="top-content">
    <app-quick-access></app-quick-access>
  </div>
  <div class="bottom-content">
    <app-workspace-list
      *ngIf="workspacesAvailable"
      class="workspace-list"
      [tenantId]="tenantId"
    ></app-workspace-list>
  </div>
</div>
