<ra-ui-card
  id="card-{{ applicationCard.name.replace(' ', '').toLowerCase() }}"
  [config]="config"
  (onTextButtonClick)="clickedCard(cardAction.LOGO)"
  (onIconButtonClick)="clickedInfo({ content: dialogContent })"
>
  <div
    class="card-body"
    (click)="applicationCard.isActive ? clickedCard(cardAction.LOGO) : ''"
  >
    <img *ngIf="applicationCard.logo" [src]="logo" alt="logo" />
  </div>
</ra-ui-card>

<ng-template #dialogContent>
  <div class="details-content">
    <div class="top">
      <i [class]="applicationDetails.icon"></i>
      <div class="content">
        <p class="title">{{ applicationDetails.title }}</p>
        <p class="text">{{ applicationDetails.text }}</p>
        <p class="subtitle">{{ applicationDetails.subtitle }}</p>
        <ul>
          <li *ngFor="let preInstalled of applicationDetails.software">
            {{ preInstalled }}
          </li>
        </ul>
      </div>
    </div>
    <img [src]="applicationDetails.image" alt="details" />
  </div>
</ng-template>
