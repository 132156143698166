import { Component, OnInit, Input } from '@angular/core';
import { SideBarItemSelection } from '../navigation/sidebar/sidebar-options/sidebar-options.component';
import { TenantInfoFacade } from '../../core/reducers/tenant-info/tenant-info.facade';
import { tap } from 'rxjs';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.scss'],
})
export class DashboardMenuComponent implements OnInit {
  sideBarItemSelection = SideBarItemSelection;
  @Input() currentSidebarSelection!: SideBarItemSelection;
  @Input() workspacesAvailable!: boolean;
  tenantId: string | undefined = undefined;
  tenantId$ = this.tenantInfoFacade.tenantId$.pipe(
    tap((tenantId) => {
      this.tenantId = tenantId;
    })
  );

  constructor(private readonly tenantInfoFacade: TenantInfoFacade) {}

  ngOnInit(): void {}
}
