import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonNavbarWrapperComponent } from './common-navbar-wrapper/common-navbar-wrapper.component';
import { MaterialModule } from '../material.module';
import { LayoutModule } from '@rockwell-automation-inc/layout';
import { RaUiBannerModule } from '@ra-web-tech-ui-toolkit/popups/banner';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { localizationReducer } from '../core/reducers/localization/localization.reducer';
import { EffectsModule } from '@ngrx/effects';
import { LocalizationEffects } from '../core/reducers/localization/localization.effects';
import { RouterModule } from '@angular/router';
import { NotificationService } from '../core/services/navbar/notification.service';

@NgModule({
  declarations: [CommonNavbarWrapperComponent],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    TranslateModule,
    MatIconModule,
    RaUiBannerModule,
    LayoutModule,
    StoreModule.forFeature('localization', localizationReducer),
    EffectsModule.forFeature([LocalizationEffects]),
  ],
  providers: [NotificationService],
  exports: [CommonNavbarWrapperComponent],
})
export class NavbarModule {}
