import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService } from '../../../core/services/dialog.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { SharedButtonModule } from '../buttons/shared-button.module';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ContentHostComponent } from '../content-host/content-host.component';
import { ContentHostDirective } from '../content-host/content-host.directive';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    ContentHostComponent,
    ContentHostDirective,
  ],
  exports: [ConfirmDialogComponent],
  providers: [DialogService],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    SharedButtonModule,
  ],
})
export class ConfirmDialogModule {}
