import { Tenant } from 'src/app/core/models/cs/tenant-info.model';

export const userIcon = 'ra-icon-ide-lg-user';

export type UserId = string;

export class PreferenceUser {
  public lastAccessedTenantId!: string;
}

export class UserProfile {
  public company!: string;
  public email!: string;
  public location!: string;
  public businessPartnerId!: string;
  public name!: string;
  public firstName!: string;
  public lastName!: string;
  public userId!: UserId;
  public eulaAcceptanceRequired!: boolean;
  public eulaVersion!: string;
  public eulaContentUrl!: string;
  public preferences!: PreferenceUser;
  public accessibleTenants!: Tenant[];
}
