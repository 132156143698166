import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegionSelectorModule } from '../region-selector/region-selector.module';
import { TranslateModule } from '@ngx-translate/core';
import { WorkstationExplorerComponent } from './workstation-explorer.component';
import { MaterialModule } from '../../../material.module';
import { LoadingModule } from '../confirm-dialog/loading/loading.module';
import { FilterModule } from '../filter/filter.module';

@NgModule({
  declarations: [WorkstationExplorerComponent],
  exports: [WorkstationExplorerComponent],
  imports: [
    CommonModule,
    LoadingModule,
    MaterialModule,
    RegionSelectorModule,
    TranslateModule,
    FilterModule,
  ],
})
export class WorkstationExplorerModule {}
