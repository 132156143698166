import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Observable } from 'rxjs';
import { SidePanelService } from './core/services/side-panel/side-panel.service';
import { MatSidenav } from '@angular/material/sidenav';
import { BackOffPolicy, Retryable } from 'typescript-retry-decorator';
import { LoggerService } from './core/services/logger/logger.service';
import { SignalRService } from './core/services/signal-r/signal-r.service';
import { NotificationService } from './core/services/navbar/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'TwinStudio';
  loading: boolean = true;
  isAuthenticated$: Observable<boolean> | undefined;
  isAuthenticated!: boolean;
  userId: string;
  // Side panel
  @ViewChild('sidePanelRef', { static: false })
  private readonly sidePanelRef: MatSidenav = {} as MatSidenav;
  @ViewChild('sidePanelContentRef', { static: false, read: ViewContainerRef })
  private readonly sidePanelContentRef?: ViewContainerRef;

  constructor(
    public authService: AuthService,
    public logger: LoggerService,
    public readonly sidePanelService: SidePanelService,
    public readonly signalRService: SignalRService,
    private readonly notificationService: NotificationService
  ) {}

  /**
   * Retry until view is fully initialized and side panel is visible in DOM
   */
  ngAfterViewInit(): void {
    (async () => {
      await this.initSidePanelTemplateReferences();
    })();
  }

  @Retryable({
    maxAttempts: 20,
    backOffPolicy: BackOffPolicy.FixedBackOffPolicy,
    backOff: 2000,
    doRetry: (e: Error) => {
      return e.message === 'View not initialized';
    },
  })
  initSidePanelTemplateReferences(): void {
    if (!this.sidePanelRef) {
      throw new Error('View not initialized');
    }
    this.sidePanelService.setPanel(this.sidePanelRef);
    this.sidePanelService.setContentVcf(this.sidePanelContentRef);
  }

  /* eslint-disable @typescript-eslint/require-await */
  async ngOnInit(): Promise<void> {
    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.isAuthenticated$.subscribe((selectIsLoggedIn) => {
      if (selectIsLoggedIn) {
        this.isAuthenticated = true;
        this.initSignalR();
      } else {
        sessionStorage.setItem('returnUrlTwin', window.location.href);
        this.authService.login();
      }
    });
    this.notificationService.initialize();
  }

  async initSignalR(): Promise<void> {
    return this.signalRService.startConnection().catch((err) => {
      this.logger.error('Failed to start signalR connection', err);
    });
  }
}
