/* eslint-disable no-console */
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { LicenseManager } from '@ag-grid-enterprise/core';

//tslint:disable-next-line no-import-side-effect
import 'hammerjs';

/* eslint-disable-next-line @typescript-eslint/dot-notation */
LicenseManager.setLicenseKey(window['AG_GRID_KEY']);

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  ClientSideRowModelModule,
]);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
