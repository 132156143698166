import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayFilesComponent } from './display-files.component';
import { RaUiInputModule } from '@ra-web-tech-ui-toolkit/form-system/input';
import { TranslateModule } from '@ngx-translate/core';
import { RaUiTooltipModule } from '@ra-web-tech-ui-toolkit/common-utils/tooltip';

@NgModule({
  declarations: [DisplayFilesComponent],
  imports: [CommonModule, RaUiInputModule, TranslateModule, RaUiTooltipModule],
  exports: [DisplayFilesComponent],
})
export class DisplayFilesModule {}
