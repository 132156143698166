import { ProjectId, ProjType } from '../models/vault/project.model';
import { SolutionId } from '../models/vault/solution.model';
import { VaultId } from '../models/vault/vault.model';
import { IconPath } from '../services/vault/view-status/project-file-utility';
import { HistoryId } from './controller-project-type.model';
import { TenantId } from './cs/tenant-info.model';
import { ICardConfig } from '@ra-web-tech-ui-toolkit/layout/card';

export enum SessionKind {
  Logix = 'LogixDesigner',
  Emulate3d = 'Emulate3D',
  Arena = 'Arena',
  FTAManager = 'FTAManager',
  FTLogixEcho = 'Echo',
  FTViewME = 'FTViewME',
  FTViewSeServer = 'ViewSeServer',
  FTViewSeClient = 'ViewSeClient',
  Bacth = 'Bacth',
}

export const applicationName = new Map<SessionKind, string>();
applicationName.set(SessionKind.Logix, 'Studio 5000 Logix Designer®');
applicationName.set(SessionKind.Emulate3d, 'EMULATE3D');
applicationName.set(SessionKind.Arena, 'Arena®');

export enum KindFromTenantServices {
  Logix = 'Logix5000',
  Emulate3d = 'Emulate3D',
  Arena = 'Arena',
  FTAManager = 'FTRemoteActivationManager',
}

export const kindFromCsToSessionKind = new Map<
  KindFromTenantServices,
  SessionKind
>();
kindFromCsToSessionKind.set(KindFromTenantServices.Logix, SessionKind.Logix);
kindFromCsToSessionKind.set(
  KindFromTenantServices.Emulate3d,
  SessionKind.Emulate3d
);
kindFromCsToSessionKind.set(KindFromTenantServices.Arena, SessionKind.Arena);

export enum ProjectState {
  Changed = 'Changed',
  NotFound = 'NotFound',
  UptoDate = 'UptoDate',
}

export enum Action {
  Create = 'Create',
  Open = 'Open',
}

export enum Tier {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small',
}

export enum Region {
  America = 'America',
  Europe = 'Europe',
  Asia = 'Ap',
}

export enum WorkstationStatus {
  Running = 'Running',
  Starting = 'Starting',
  Stopping = 'Stopping',
  Stopped = 'Stopped',
  Pending = 'Pending',
  Deleted = 'Deleted',
  Deleting = 'Deleting',
  Started = 'Started', //TODO: Delete if not used
  Unknown = 'Unknown', //TODO: Delete if not used
  Ready = 'Ready', //TODO: Delete if not used
}

export enum CommandWorkstation {
  Stop = 1,
  Start = 2,
  Delete = 3,
}

export interface SessionFile {
  projectId: ProjectId;
  historyId: HistoryId;
  fileName: string;
  overwrite?: boolean;
  projectName: string;
  iconPath: IconPath;
  solutionName: string;
}

export interface TierInfo {
  tier: Tier;
  credits: number;
  cpu: number;
  memory: number;
}
export interface Session {
  kind: SessionKind;
  tier: TierInfo;
  region: Region;
  action: Action;
  workspace?: string;
  solutionName?: string;
  solutionId?: SolutionId;
  files?: SessionFile[];
  filesToSync?: FilesToSync[];
  tenantId: TenantId;
  vaultId: VaultId;
  isActive?: boolean;
  initurl?: string;
}

export interface PushObjectPayload {
  files: SessionFile[];
  solutionName: string;
  solutionId?: SolutionId;
  region: Region;
  tenantId: TenantId;
}

export interface FilesToSyncResponse {
  files: FilesToSync[];
  newFilesPaths?: string[];
  filesDeleted?: string[];
}

export interface EndSessionPayload {
  region: Region;
  tenantId: TenantId;
  twinStudioSessionName: SessionKind;
  tier: Tier;
}

export interface CheckConflictsPayload {
  projects?: SessionFile[];
  region: Region;
  solutionName: string;
}

export interface PushChangesPayload {
  projects?: SessionFile[] | undefined;
  projectsNewVersion?: FilesToAddNewVersion[] | undefined;
  filesToUpload?: string[] | undefined;
  region: Region;
  solutionName?: string | undefined;
}

export interface PushChangesPayloadWorkstations {
  projectId: ProjectId;
  fileName: string;
  filePath: string;
  solutionId: SolutionId;
  note: string;
}

export interface ListSolutionContentPayload {
  region: Region;
  path?: string;
  onlyFolders?: boolean;
}

export interface ListWorkspaceContentResponse {
  noConflicts?: string;
  projectReport?: ProjectTwinStudioConfirmation[];
}

export interface CreateAndSyncProjectsPayload {
  projects?: FilesToSync[];
  projectsToCreate?: NewProject[];
  region: Region;
}

export interface NewProject {
  name: string;
  description: string;
  note: string;
  filePath: string;
  solutionId: string;
  solutionName: string;
}

export interface FilesToAddNewVersion {
  projectId: string;
  fileName: string;
  filePath: string;
  solutionName: string;
  note?: string | undefined;
}

export interface FilesToAddNewVersionWorkstations {
  regionAndWorkstationId: string;
  projectId: string;
  fileName: string;
  filePath: string;
  solutionId: string;
  note: string;
}

export interface StartSessionPayload {
  tier: Tier;
  projects?: SessionFile[];
  region: Region;
  workspace?: string;
  solutionName?: string;
}

export interface ConflictsResponse {
  noConflicts?: string;
  projectReport?: ProjectTwinStudioConfirmation[];
}

export interface ProjectPushResultError {
  projectId: ProjectId;
  message: string;
}

export interface FileUploadResult {
  filename: ProjectId;
  returnedUrl: string;
}

export interface PushChangesResponse {
  projectsNotUploaded?: ProjectPushResultError[] | undefined;
  urlFilesToUpload?: FileUploadResult[] | undefined;
}

export interface StartSessionResponse {
  appStreamUrl?: string;
  projectReport?: ProjectTwinStudioConfirmation[];
}

export interface ProjectTwinStudioConfirmation {
  projectId: ProjectId;
  state: ProjectState;
}

export interface Workstation {
  application: SessionKind;
  tier: Tier;
  imageName: string;
  nickname: string;
  instances: Instance[];
}

export interface WorkstationPayload {
  application: SessionKind;
  tier: Tier;
  imageName: string;
  nickname: string;
  instances: InstancePayload[];
}

export interface InstancePayload {
  nickname: string;
}

export interface WorkstationTile {
  application: SessionKind;
  config: ICardConfig;
  image: string;
  tierMachines: string[];
}

export interface SessionStatus {
  sessionStatus: WorkstationStatus;
}

export interface EndSessionResponse {
  ok: string;
}

export interface FilesToSync {
  projectId: ProjectId;
  historyId: string;
  projectFileName: string;
  etag: string;
  isNewFile: boolean;
  solutionName?: string;
  note?: string;
}

export interface ProjectMetadata {
  historyId: HistoryId;
  projectFileName: string;
  solutionName: string;
  projectName: string;
  eTag: string;
  origin: string;
  projectId: ProjectId;
}

export interface SessionToSyncResponse {
  files: FilesToSync[];
  newFilesPaths?: string[];
  filesDeleted?: string[];
}

export interface FileRequest {
  lCoreBaseUrl: string;
  vaultId: VaultId;
  sessionKind: SessionKind;
  workspace?: string;
  region: Region;
  action: Action;
}

export interface FilesNotSynced {
  projectId: ProjectId;
  message: string;
}

export interface FilesNotCreated {
  fileName: string;
  message: string;
}

export interface SyncResponse {
  projectsNotSynced: FilesNotSynced[];
  projectsNotCreated: FilesNotCreated[];
}

export interface NewFile {
  description: string;
  name: string;
  filePath: string;
  solutionId: SolutionId;
}

export class FileFromFolder {
  constructor(
    public fileName: string,
    public projectName: string,
    public revisionHistory?: string
  ) {}
}

export interface FileData {
  fileName: string;
  projectName: string;
  commitMessage: string;
  description?: string;
  type?: ProjType;
  filePath?: string;
  solutionName?: string;
  solutionId?: SolutionId;
  projectId?: ProjectId;
  historyId?: HistoryId;
  etag?: string;
}

export interface DataProject {
  projectId: ProjectId;
  projectName: string;
}

export interface AppStreamHelper {
  // any because is comming as a value from AWS in a js
  appStreamHelper: any;
  // any because is comming as a value from AWS in a js
  appStreamEmbed: any;
}

export interface Instance {
  id: string;
  name: string;
  tier: Tier;
  status: string;
  iconPath?: string;
}

export interface WorkstationDetails {
  workstationId: string;
  status: WorkstationStatus;
  tier: Tier;
  name: string;
  application: SessionKind;
  tenantId: TenantId;
}

export interface WorkstationStatusNotification {
  instanceId: string;
  name: string;
  status: string;
  workstationId: string;
  workspaceId: string;
  actionId: string;
}

export interface WorkspaceDetails {
  workspaceId: string;
  workstations: WorkstationDetails[];

  //UI Data
  workspaceName: string;
  region: string;
  tenantId: TenantId;
}

export interface StreamUrlResponse {
  streamUrl: string;
}

export interface StreamUrlResponse {
  streamUrl: string;
}

export interface RecentFile {
  pk: string;
  sk: string;
  eventTime?: string;
  size?: string;
}

export interface GetRecentFilesResponse {
  recentFileList: RecentFile[];
}

export interface WorkstationFiles {
  filePath: string;
  fileName: string;
  size: number;
  lastModified: string;
  regionAndWorkstationId: string;
}

export interface PullFilesProjects {
  projectId: string;
  projectName: string;
  historyId: string;
  fileName: string;
  solutionName: string;
}
export interface PullFilesPayload {
  region: string;
  projects: PullFilesProjects[];
}
