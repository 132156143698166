import { createReducer, on } from '@ngrx/store';
import { VaultRoleActions } from '../../models/vault/role-actions.model';
import { setRoleActions } from './role-actions.actions';

export interface RoleActionsState {
  roleActions?: VaultRoleActions;
}

export const initialRoleActionsState: RoleActionsState = {
  roleActions: undefined,
};

export const roleActionsReducer = createReducer(
  initialRoleActionsState,

  on(setRoleActions, (state, action): RoleActionsState => {
    return {
      roleActions: action.roleActions,
    };
  })
);
